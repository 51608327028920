import React, { useState, useEffect } from 'react';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useClassName } from 'common/hooks';
import { Link } from 'react-router-dom';

import { Spacer } from '../Spacer';

import './cta.less';

const CTA = ({ cta, onClick }) => {
  const className = useClassName('ModularCTA');
  const history = useHistory();
  const [buttonExtraClasses, setButtonExtraClasses] = useState([]);

  const [buttonStyle, setButtonStyle] = useState(
    cta?.theme && {
      backgroundColor: cta.theme.background,
      color: cta.theme.textColor,
      width: cta.customWidth ? `${cta.customWidth}px` : '',
      fontSize: cta.customFontSize ? `${cta.customFontSize}px` : '',
      widthImportant: Boolean(cta.customWidth),
      border: cta.theme.borderColor ? `1px solid ${cta.theme.borderColor}` : '',
    }
  );

  const defineButtonExtraClasses = () => {
    const extraClasses = [];
    if (cta.customWidth) extraClasses.push('custom-width');
    else {
      const size = cta?.size || 'medium';
      extraClasses.push(size);
      if (cta.icon) extraClasses.push(`${size}-with-icon`);
    }
    setButtonExtraClasses(extraClasses);
  };

  useEffect(() => {
    defineButtonExtraClasses();
  }, []);

  const handleHover = () => {
    if (cta?.theme) {
      setButtonStyle({
        ...buttonStyle,
        backgroundColor: cta.theme.hoverBackground,
        color: cta.theme.hoverTextColor,
      });
    }
  };

  const handleLeave = () => {
    if (cta?.theme) {
      setButtonStyle({
        ...buttonStyle,
        backgroundColor: cta.theme.background,
        color: cta.theme.textColor,
      });
    }
  };

  const openURL = () => {
    const isExternal = cta.value.startsWith('http');
    if (isExternal) {
      window.open(cta.value, '_blank');
    } else {
      history.push(cta.value);
    }
  };

  const scrollToElement = () => {
    const element = document.getElementById(kebabCase(cta.value));
    if (element) {
      const offset = 64;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset - offset;

      return window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    if (onClick) return onClick();
    if (cta.scrollLink) return scrollToElement();
    if (cta.isLink) return openURL();
  };

  return (
    <>
      <div className={className('container')}>
        {cta.ctaType === 'link' ? (
          <Link
            to={cta.value}
            className={className('link-cta')}
            onClick={handleClick}>
            {cta.label}
          </Link>
        ) : (
          <button
            onClick={handleClick}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            className={className(['button-cta', ...buttonExtraClasses])}
            style={buttonStyle}>
            {cta.label}
            {cta.icon && (
              <img
                className={className('icon')}
                src={cta.icon.assetUrl}
                alt={cta.icon.assetAlt}
              />
            )}
          </button>
        )}
      </div>
      {Boolean(cta.spacerSize) && <Spacer size={cta.spacerSize} />}
    </>
  );
};

CTA.propTypes = {
  cta: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default CTA;
