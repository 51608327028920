import React from 'react';
import { Responsive } from 'common/lazy';

const MOBILE_MAX_WIDTH = 766;

export default class MobileOnly extends React.Component {
  render() {
    return <Responsive maxWidth={MOBILE_MAX_WIDTH} {...this.props} />;
  }
}
