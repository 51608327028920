import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class TransfersStore extends BaseStore {
  @action
  searchTransfers(body, statusKey = `searchTransfers`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      body,
      path: `/1/transfer/search`,
    })
      .then((res) => {
        status.success();
        return res;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  getTransfersReconciliation(body, statusKey = `getTransfersReconciliation`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      body,
      path: `/1/transfer/reconciliation`,
    })
      .then((res) => {
        status.success();
        return res;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
