// Class that simply wraps an occupant, unit, anchor,
// floor, and coordinate into one simple structure.

export default class Tenant {
  constructor(data) {
    this.unit = data.unit;
    this.floor = data.floor;
    this.anchor = data.anchor;
    this.occupant = data.occupant;
    this.coordinate = data.coordinate;
  }

  get name() {
    return this.occupant.data.name.en;
  }
}
