import React from 'react';
import PropTypes from 'prop-types';
import { AssetPropType } from 'public/schema';
import {
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
} from 'common/components/Responsive';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  getCategoryByCta,
  categories,
} from 'utils/analytics';
import LandingHeroLogo from './LandingHeroLogo';
import LandingHeroTitle from './LandingHeroTitle';
import LandingHeroAsset from './LandingHeroAsset';
import LandingHeroSubtitle from './LandingHeroSubtitle';
import LandingCTA from './LandingCTA';
import './landing-hero.less';

const LandingHero = ({
  id,
  venue,
  heroLogo,
  heroTitle,
  heroSubtitle,
  heroVideo,
  heroPoster,
  heroCtaText,
  heroCtaLink,
  buttonColor,
  buttonTextColor,
}) => {
  const className = useClassName('LandingHero');

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events

  const handleClick = () => {
    const category = getCategoryByCta(heroCtaLink) || categories.ERC_INFO;

    standardizedTrack(
      'click',
      'button',
      'hero',
      heroCtaText,
      category,
      trackingLabel
    );
  };

  const ContentMobile = () => (
    <>
      {heroLogo?.assetUrl && <LandingHeroLogo asset={heroLogo} />}
      {heroTitle && <LandingHeroTitle text={heroTitle} />}
      {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
        <LandingHeroAsset
          venue={venue}
          videoUrl={heroVideo?.assetUrl}
          posterUrl={heroPoster?.assetUrl}
        />
      )}
      {heroSubtitle && <LandingHeroSubtitle text={heroSubtitle} />}
      {heroCtaLink && (
        <LandingCTA
          text={heroCtaText}
          link={heroCtaLink}
          onClick={handleClick}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
        />
      )}
    </>
  );

  const ContentDesktop = () => (
    <>
      <div className={className('left-container')}>
        {heroLogo?.assetUrl && <LandingHeroLogo asset={heroLogo} />}
        {heroTitle && <LandingHeroTitle text={heroTitle} />}
        {heroSubtitle && <LandingHeroSubtitle text={heroSubtitle} />}
        {heroCtaLink && (
          <LandingCTA
            text={heroCtaText}
            link={heroCtaLink}
            onClick={handleClick}
            buttonColor={buttonColor}
            buttonTextColor={buttonTextColor}
          />
        )}
      </div>
      <div className={className('right-container')}>
        {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
          <LandingHeroAsset
            venue={venue}
            videoUrl={heroVideo?.assetUrl}
            posterUrl={heroPoster?.assetUrl}
          />
        )}
      </div>
    </>
  );

  return (
    <div id={id} className={className('container')}>
      <MobileOnly className={className('wrapper')}>
        <ContentMobile />
      </MobileOnly>
      <TabletOnly className={className('wrapper')}>
        <ContentMobile />
      </TabletOnly>
      <DesktopOnly className={className('wrapper')}>
        <ContentDesktop />
      </DesktopOnly>
    </div>
  );
};

LandingHero.propTypes = {
  id: PropTypes.string.isRequired,
  venue: PropTypes.object,
  heroTitle: PropTypes.object,
  heroSubtitle: PropTypes.object.isRequired,
  heroLogo: PropTypes.shape(AssetPropType),
  heroVideo: PropTypes.shape(AssetPropType),
  heroPoster: PropTypes.shape(AssetPropType),
  heroCtaText: PropTypes.string,
  heroCtaLink: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

LandingHero.defaultProps = {
  venue: null,
  heroTitle: null,
  heroVideo: null,
  heroPoster: null,
  heroLogo: null,
  heroCtaText: null,
  heroCtaLink: null,
  buttonColor: null,
  buttonTextColor: null,
};

export default LandingHero;
