import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { formatCurrency } from 'utils/l10n';
import Asset from './Asset';
import ModalGallery from './ModalGallery';
import Tooltip from './Tooltip';

import './pricing-card.less';

const PricingCard = ({ pricingCard, size }) => {
  const className = useClassName('ModularPricingCard');
  const [headerExtraClasses, setHeaderExtraClasses] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const defineHeaderExtraClasses = () => {
    if (!pricingCard.asset)
      setHeaderExtraClasses(['card-header-main', `card-header-${size}`]);
  };

  useEffect(() => {
    defineHeaderExtraClasses();
  }, []);

  const handleHeaderClick = () => {
    if (!pricingCard.asset && pricingCard.imageGallery) setOpenModal(true);
  };

  const handleImageClick = () => {
    if (pricingCard.imageGallery) setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const getPrice = () => {
    if (pricingCard.regularPrice && pricingCard.peakPrice) return null;
    if (pricingCard.regularPrice) return pricingCard.regularPrice;
    if (pricingCard.peakPrice) return pricingCard.peakPrice;
    return null;
  };

  const renderPrice = (isRegular, price) => (
    <div className={className('card-price')}>
      <div className={className('card-price-container')}>
        <p className={className('card-price-title')}>
          {isRegular ? 'Non-Peak Price' : 'Peak Price'}
        </p>
        {!isRegular && pricingCard.tooltipPeak && (
          <Tooltip
            iconName="question-circle"
            tooltipText={pricingCard.tooltipPeak}
          />
        )}
      </div>
      <p className={className('card-price-value')}>
        {formatCurrency(price * 100)}
      </p>
    </div>
  );

  const renderModalGallery = () =>
    pricingCard.imageGallery && (
      <ModalGallery
        overlineText={pricingCard.description}
        title={pricingCard.title}
        price={getPrice()}
        body={pricingCard.body}
        imageGallery={pricingCard.imageGallery}
        open={openModal}
        handleClose={handleCloseModal}
      />
    );

  return (
    <div className={className(['card', `card-${size}`])}>
      {pricingCard.asset && (
        <div
          className={className(['card-image', `card-image-${size}`])}
          onClick={handleImageClick}>
          <Asset asset={pricingCard.asset} />
        </div>
      )}
      <div
        className={className(['card-header', ...headerExtraClasses])}
        onClick={handleHeaderClick}>
        <p className={className('card-title')}>{pricingCard.title}</p>
        <p className={className('card-description')}>
          {pricingCard.description}
        </p>
      </div>
      <div className={className('card-prices')}>
        {pricingCard.regularPrice &&
          renderPrice(true, pricingCard.regularPrice)}
        {pricingCard.peakPrice && renderPrice(false, pricingCard.peakPrice)}
      </div>
      {pricingCard.imageGallery && renderModalGallery()}
    </div>
  );
};

PricingCard.propTypes = {
  pricingCard: PropTypes.object.isRequired,
  size: PropTypes.string,
};

PricingCard.defaultProps = {
  size: 'big',
};

export default PricingCard;
