import React from 'react';
import { Redirect } from 'react-router-dom';
import { Component } from 'common/helpers';
import {
  Carousel,
  ResponsiveImageLight,
  getContentfulField,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import { track } from 'utils/analytics';
import './heroScreen.less';

const AUTO_SCROLL_DELAY = 5000;

export default class HeroScreen extends Component {
  state = {
    redirect: null,
  };

  getContentfulImageSrc(image) {
    const {
      fields: { file },
    } = getContentfulField(image);
    const { url } = getContentfulField(file);
    return url;
  }

  getCTAData(linkField) {
    if (linkField) {
      const {
        fields: { link: imageLink, externalUrl, openInNewTab },
      } = getContentfulField(linkField);

      const link = getContentfulField(imageLink);
      const isExternalUrl = getContentfulField(externalUrl);
      const shouldOpenNewTab = getContentfulField(openInNewTab);

      return { link, isExternalUrl, shouldOpenNewTab };
    }

    return {};
  }

  getHeroContent() {
    const heroContent = this.props.hero?.fields;
    if (!heroContent) return;

    const imagesCarousel = this.props.hero.fields?.imagesCarousel;
    if (!imagesCarousel) return;

    const images = getContentfulField(imagesCarousel);

    return images.map((image) => {
      const { alt, desktop, mobile, link } = image.fields;
      const imageAlt = getContentfulField(alt);
      const desktopSrc = this.getContentfulImageSrc(desktop);
      const mobileSrc = this.getContentfulImageSrc(mobile);
      const ctaData = this.getCTAData(link);
      return { imageAlt, desktopSrc, mobileSrc, ctaData };
    });
  }

  setRedirect = (ctaData) => {
    const { link, isExternalUrl, shouldOpenNewTab } = ctaData;

    if (!link) return;

    if (shouldOpenNewTab) {
      window.open(link, '_blank');
    } else if (isExternalUrl) {
      window.location.href = link;
    } else {
      this.setState({
        redirect: link,
      });
    }
  };

  handleImageClick = (ctaData) => () => {
    this.trackImageClick(ctaData.link);
    this.setRedirect(ctaData);
  };

  trackImageClick = (imageCTA) =>
    track(`Clicked on ${imageCTA} in home slider`);

  renderImages() {
    const images = this.getHeroContent();

    // For now, it only shows one image (no carousel)
    const { imageAlt, desktopSrc, mobileSrc, ctaData } = images[0];
    const imageStyle = ctaData.link && 'link';

    if (images.length === 1) {
      return (
        <div
          className={`${this.getElementClass(
            'single-image'
          )} ${this.getElementClass(imageStyle)}`}
          onClick={this.handleImageClick(ctaData)}>
          <DesktopOnly>
            <ResponsiveImageLight alt={imageAlt} src={desktopSrc} />
          </DesktopOnly>
          <MobileOnly>
            <ResponsiveImageLight alt={imageAlt} src={mobileSrc} />
          </MobileOnly>
        </div>
      );
    }

    return (
      <Carousel
        images={images}
        autoScrollTime={AUTO_SCROLL_DELAY}
        onImagePress={this.handleImageClick}
      />
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    return <div {...this.getAttrs()}>{this.renderImages()}</div>;
  }
}
