import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import './ride-info-icon.less';

const TAG_ICON = 'tag';
const HEIGHT_ICON = 'height';

const RideInfoIcon = ({ icon, color }) => {
  const className = useClassName('RideInfoIcon');

  const height = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.59744 13.036H7.84744V13.786V19.0414C7.84744 19.1544 7.75192 19.2499 7.63898 19.2499H4.46006C4.3393 19.2499 4.2516 19.1626 4.2516 19.0414V13.786V13.036H3.5016H2.95847C2.8456 13.036 2.75 12.9404 2.75 12.8275V6.90085C2.75 6.78791 2.84552 6.69238 2.95847 6.69238H9.14041C9.25344 6.69238 9.34888 6.78782 9.34888 6.90085V12.8275C9.34888 12.9405 9.25335 13.036 9.14041 13.036H8.59744Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M7.79955 2.50799C7.79955 3.4789 7.01247 4.26597 6.04156 4.26597C5.07065 4.26597 4.28357 3.4789 4.28357 2.50799C4.28357 1.53708 5.07065 0.75 6.04156 0.75C7.01247 0.75 7.79955 1.53708 7.79955 2.50799Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13 5L16 2L19 5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="bevel"
      />
      <path
        d="M13 16L16 19L19 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="bevel"
      />
      <path d="M16 3L16 18" stroke={color} strokeWidth="1.5" />
    </svg>
  );

  const tag = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1584 11.1748L11.1834 17.1498C11.0286 17.3048 10.8448 17.4277 10.6424 17.5116C10.4401 17.5955 10.2232 17.6386 10.0042 17.6386C9.78516 17.6386 9.56828 17.5955 9.36595 17.5116C9.16362 17.4277 8.97981 17.3048 8.82502 17.1498L1.66669 9.99984V1.6665H10L17.1584 8.82484C17.4688 9.13711 17.643 9.55953 17.643 9.99984C17.643 10.4401 17.4688 10.8626 17.1584 11.1748V11.1748Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83331 5.8335H5.84165"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const renderIcon = () => {
    if (icon === TAG_ICON) return tag;
    if (icon === HEIGHT_ICON) return height;
    return null;
  };

  return <div className={className('container')}>{renderIcon()}</div>;
};

RideInfoIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
};

RideInfoIcon.defaultProps = {
  color: '#FFFFFF',
};

export default RideInfoIcon;
