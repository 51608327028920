import React from 'react';
import { SVGIcon as Icon } from 'common/components';
import Modal from './Modal';
import './ticket-widget.less';
import { withRouter } from 'react-router-dom';

@withRouter
export default class TicketWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, 500);
  }

  componentWillUnmount() {
    this.setState({
      visible: false,
    });
  }

  shouldDisplay() {
    return this.props.hideRoutes.every((regexp) => {
      return !this.props.location.pathname.match(regexp);
    });
  }

  render() {
    if (!this.shouldDisplay()) return null;
    let className = 'ticket-widget-button';
    if (this.state.visible) {
      className += ' visible';
    }
    return (
      <Modal
        trigger={
          <div className={className}>
            <Icon width={50} height={25} name="buy-tickets" />
          </div>
        }
      />
    );
  }
}
