import { memoize } from 'lodash';

function argumentResolver() {
  return Array.prototype.join.call(arguments, '');
}

function wrapFunction(resolver) {
  return function (proto, key, descriptor) {
    const fn = descriptor.value;
    descriptor.value = memoize(function () {
      return fn.apply(this, arguments);
    }, resolver);
    return descriptor;
  };
}

export function cached(arg1) {
  if (typeof arg1 === 'function') {
    return wrapFunction(arg1);
  } else {
    return wrapFunction(argumentResolver).apply(this, arguments);
  }
}
