import { groupBy, flatten } from 'lodash';
import { request } from 'utils/api';
import { cached } from 'utils/helpers';
import { SVGIcon as Icon } from '../../SVGIcon';

import init from './init';
import Kiosk from './Kiosk';
import Tenant from './Tenant';

import {
  annotationForTenant,
  annotationForAmenity,
  annotationForCoordinate,
} from './annotations';

import STYLES from './styles';

const FEATURES = [
  // Overlays
  //'venue',
  'unit',
  'fixture',
  'kiosk',
  'level',
  'occupant',

  // Kind of ugly
  //'opening',

  // Unneeded?
  //'section',
  'footprint',

  // Points
  'amenity',
  'anchor',

  // Meta (unneeded?)
  //'address',
  //'building',
];

const TRANSPORT_CATEGORIES = ['elevator', 'escalator', 'stairs'];

export default class IMDFManager {
  static async getInstance() {
    await init();
    if (!this.instance) {
      this.instance = new IMDFManager();
      this.promise = this.instance.load();
    }
    await this.promise;
    return this.instance;
  }

  constructor() {
    this.bounds = {};
    this.region = {};
    this.featuresByType = {};
    this.focusedUnits = [];
  }

  setOptions(options) {
    this.options = options;
  }

  getBounds() {
    return this.bounds;
  }

  getRegion() {
    return this.region;
  }

  // Public

  getFloors() {
    return Object.entries(this.getLevelsByOrdinal()).map(
      ([ordinal, levels]) => {
        const { data } = levels[0];
        const name = data.name.en;
        const short = data.short_name.en;
        const number = Number(ordinal);
        return {
          name,
          short,
          number,
        };
      }
    );
  }

  getBaseFeatures() {
    return this.getFeatures('venue', 'footprint');
  }

  @cached
  getFloorBaseFeatures(floor, renderTenantCallout) {
    const levels = this.getLevelsForFloor(floor);
    const features = this.getFeatures(
      'unit',
      'opening',
      'kiosk',
      'fixture'
    ).filter((feature) => {
      return levels.some((l) => l.data.id === feature.data.level_id);
    });
    features.sort((a, b) => {
      const aPriority = this.getPriorityForFeature(a);
      const bPriority = this.getPriorityForFeature(b);
      return aPriority - bPriority;
    });
    return [
      ...levels,
      ...features,
      ...this.getTenantAnnotationsForFloor(floor, renderTenantCallout),
      //...this.getStructureAnchors(floor),
    ];
  }

  getStructureAnchors(floor) {
    const units = this.getFeatures('unit');
    return this.getFloorAnchors(floor).filter((anchor) => {
      const unit = units.find((unit) => unit.data.id === anchor.data.unit_id);
      return unit.data.category === 'structure';
    });
  }

  getPriorityForFeature(feature) {
    const { data } = feature;
    const type = `${data.feature_type}-${data.category || 'default'}`;
    switch (type) {
      case 'unit-structure':
        return 2;
      default:
        return 1;
    }
  }

  @cached
  getFloorAmenities(floor, category) {
    if (!floor || !category) {
      return null;
    }

    // Note that Jibestream is now providing transport in the
    // units data, even though they used to also be in amenities
    // so they need to be handled differently now. This is
    // inconsistent with the rest of the amenities.
    if (TRANSPORT_CATEGORIES.includes(category)) {
      return this.getTransportAnnotations(floor, category);
    }

    const levels = this.getLevelsForFloor(floor);
    const units = this.getFeatures('unit').filter((feature) => {
      return levels.some((l) => l.data.id === feature.data.level_id);
    });
    const amenities = this.getFeatures('amenity').filter((amenity) => {
      return (
        amenity.data.category === category &&
        units.some((l) => amenity.data.unit_ids.includes(l.data.id))
      );
    });
    return amenities;
  }

  // TODO: remove, just for debug
  getFloorAnchors(floor) {
    const levels = this.getLevelsForFloor(floor);
    const units = this.getFeatures('unit').filter((feature) => {
      return levels.some((l) => l.data.id === feature.data.level_id);
    });
    const anchors = this.getFeatures('anchor').filter((feature) => {
      return units.some((u) => u.data.id === feature.data.unit_id);
    });
    // Add selection for debug
    anchors.forEach((anchor) => {
      anchor.getFlattenedItemList().forEach((item) => {
        item.enabled = true;
        item.addEventListener('select', () => {
          console.info('selected', anchor);
        });
      });
    });
    return anchors;
  }

  // Focusing

  focusUnit(feature) {
    this.setUnitFocus(feature, true);
    this.focusedUnits.push(feature);
  }

  unfocusUnit(feature) {
    this.setUnitFocus(feature, false);
    this.focusedUnits = this.focusedUnits.filter((f) => f !== feature);
  }

  setUnitFocus(feature, focus) {
    feature.items.forEach((overlay) => {
      this.setOverlayStyles(
        overlay,
        feature.data.feature_type,
        feature.data.category,
        // Assuming unfocused units are still occupied
        focus ? 'focused' : 'occupied'
      );
    });
  }

  reset() {
    this.focusedUnits.forEach((feature) => {
      this.setUnitFocus(feature, false);
    });
    this.focusedUnits = [];
  }

  // Loading

  async load() {
    const response = await request({
      method: 'GET',
      path: '/1/map',
      params: {
        feature: FEATURES,
      },
    });
    const { bounds, region, features } = response.data;
    this.bounds = bounds;
    this.region = region;
    await Promise.all(
      flatten(
        features.map((featureCollection) => {
          return featureCollection.features.map((feature) => {
            return this.importFeature(feature);
          });
        })
      )
    );
    this.setOccupiedUnitStyles();
    this.setParkingUnitStyles();
  }

  importFeature(data) {
    return new Promise((resolve, reject) => {
      mapkit.importGeoJSON(data, {
        geoJSONDidError: () => {
          reject();
        },
        geoJSONDidComplete: (feature) => {
          feature.data.feature_type = data.feature_type;
          feature.data.id = data.id;
          Object.assign(feature.data, data.properties);
          if (!this.featuresByType[data.feature_type]) {
            this.featuresByType[data.feature_type] = [];
          }
          this.featuresByType[data.feature_type].push(feature);
          if (this.featureCanBeSelected(feature)) {
            feature.getFlattenedItemList().forEach((item) => {
              item.addEventListener('select', () => {
                if (this.options.onUnitSelect) {
                  this.options.onUnitSelect(feature);
                }
              });
            });
            feature.items.forEach((item) => {
              item.addEventListener('deselect', () => {
                if (this.options.onUnitDeselect) {
                  this.options.onUnitDeselect(feature);
                }
              });
            });
          } else {
            feature.items.forEach((item) => {
              item.enabled = false;
            });
          }
          resolve();
        },
        itemForPoint: (coordinate) => {
          if (data.feature_type === 'amenity') {
            return annotationForAmenity(data, coordinate, Icon);
          } else if (data.feature_type === 'anchor') {
            // TODO: remove me!
            return annotationForCoordinate(coordinate);
          }
        },
        styleForOverlay: (overlay) => {
          this.setOverlayStyles(
            overlay,
            data.feature_type,
            data.properties.category
          );
          return overlay.style;
        },
      });
    });
  }

  // Styles

  setOccupiedUnitStyles() {
    this.getFeatures('occupant').forEach((occupant) => {
      const unit = this.getUnitForOccupant(occupant);
      if (unit) {
        unit.getFlattenedItemList().forEach((item) => {
          this.setOverlayStyles(
            item,
            unit.data.feature_type,
            unit.data.category,
            'occupied'
          );
        });
      }
    });
  }

  setParkingUnitStyles() {
    this.getFeatures('amenity').forEach((amenity) => {
      if (amenity.data.category === 'parking') {
        const { name } = amenity.data;
        const match = name.en.match(/Parking Lot (\w+)/);
        if (match) {
          const lot = match[1].toLowerCase();
          const units = this.getUnitsForAmenity(amenity);
          units.forEach((unit) => {
            unit.getFlattenedItemList().forEach((item) => {
              this.setOverlayStyles(item, 'unit', 'parking', lot);
            });
          });
        }
      }
    });
  }

  setOverlayStyles(overlay, featureType, category, state) {
    Object.assign(
      overlay.style,
      STYLES['default'],
      STYLES[featureType] || {},
      (category && STYLES[`${featureType}-${category}`]) || {},
      (category && state && STYLES[`${featureType}-${category}-${state}`]) || {}
    );
  }

  // Kiosks

  @cached
  getKiosk(kioskId) {
    const kiosk = this.getFeatures('kiosk').find((feature) => {
      return feature.data.id === kioskId;
    });
    if (kiosk) {
      const level = this.getFeatures('level').find((feature) => {
        return feature.data.id === kiosk.data.level_id;
      });
      const floor = level.data.ordinal;
      const [lng, lat] = kiosk.data.display_point.coordinates;
      const coordinate = new mapkit.Coordinate(lat, lng);
      return new Kiosk({
        floor,
        kiosk,
        coordinate,
      });
    }
  }

  @cached
  getKioskByAmenities(kioskId) {
    const kiosk = this.getFeatures('amenity').find((feature) => {
      return feature.data.name.en === kioskId;
    });
    if (kiosk) {
      const unit = this.getFeatures('unit').find((feature) => {
        return kiosk.data.unit_ids.includes(feature.data.id);
      });
      const level = this.getFeatures('level').find((feature) => {
        return feature.data.id === unit.data.level_id;
      });
      const floor = level.data.ordinal;
      const [lng, lat] = kiosk.data.geometry.coordinates;
      const coordinate = new mapkit.Coordinate(lat, lng);
      return new Kiosk({
        floor,
        kiosk,
        coordinate,
      });
    }
  }

  // Transport

  getTransportAnnotations(floor, category) {
    const levels = this.getLevelsForFloor(floor);
    return this.getFeatures('unit')
      .filter((unit) => {
        return (
          levels.some((level) => level.data.id === unit.data.level_id) &&
          unit.data.category === category
        );
      })
      .map((unit) => {
        const { data } = unit;
        const [lng, lat] = data.display_point.coordinates;
        const coordinate = new mapkit.Coordinate(lat, lng);
        return annotationForAmenity(data, coordinate, Icon);
      });
  }

  // Tenants

  getTenantAnnotationsForFloor(floor, renderTenantCallout) {
    return this.getTenantsForFloor(floor).map((tenant) => {
      return annotationForTenant(tenant, renderTenantCallout);
    });
  }

  getTenantsForFloor(floor) {
    return this.getFeatures('occupant')
      .map((occupant) => {
        return this.getTenantByOccupantId(occupant.data.id);
      })
      .filter((tenant) => {
        return tenant.floor === floor;
      });
  }

  getTenantByOccupantId(occupantId) {
    const occupant = this.getFeatures('occupant').find((feature) => {
      return feature.data.id === occupantId;
    });
    if (occupant) {
      const anchor = this.getFeatures('anchor').find((feature) => {
        return feature.data.id === occupant.data.anchor_id;
      });
      const unit = this.getFeatures('unit').find((feature) => {
        return feature.data.id === anchor.data.unit_id;
      });
      return this.getTenant(occupant, anchor, unit);
    }
  }

  getTenantForUnit(unit) {
    const anchor = this.getFeatures('anchor').find((feature) => {
      return feature.data.unit_id === unit.data.id;
    });
    const occupant = this.getFeatures('occupant').find((feature) => {
      return feature.data.anchor_id === anchor.data.id;
    });
    return this.getTenant(occupant, anchor, unit);
  }

  getTenant(occupant, anchor, unit) {
    if (occupant && unit && anchor) {
      const level = this.getFeatures('level').find((feature) => {
        return feature.data.id === unit.data.level_id;
      });
      const floor = level.data.ordinal;
      const [lng, lat] = anchor.data.geometry.coordinates;
      const coordinate = new mapkit.Coordinate(lat, lng);
      return new Tenant({
        unit,
        floor,
        anchor,
        occupant,
        coordinate,
      });
    }
  }

  // Occupants

  //@cached
  getOccupantsForFloor(floor) {
    const levels = this.getLevelsForFloor(floor);
    const units = this.getFeatures('unit').filter((unit) => {
      return levels.some((level) => level.data.id === unit.data.level_id);
    });
    const anchors = this.getFeatures('anchor').filter((anchor) => {
      return units.some((unit) => unit.data.id === anchor.data.unit_id);
    });
    const occupants = this.getFeatures('occupant').filter((occupant) => {
      return anchors.some(
        (anchor) => anchor.data.id === occupant.data.anchor_id
      );
    });
    return occupants;
  }

  // Units

  getUnitForOccupant(occupant) {
    const anchor = this.getFeatures('anchor').find((feature) => {
      return feature.data.id === occupant.data.anchor_id;
    });
    if (anchor) {
      return this.getFeatures('unit').find((feature) => {
        return feature.data.id === anchor.data.unit_id;
      });
    }
  }

  // Amenities

  getUnitsForAmenity(amenity) {
    return this.getFeatures('unit').filter((unit) => {
      return amenity.data.unit_ids.includes(unit.data.id);
    });
  }

  // Util

  getFeatures(...types) {
    return flatten(types.map((t) => this.featuresByType[t] || []));
  }

  featureCanBeSelected() {
    // Disable all feature selection for now;
    return false;
  }

  getLevelsForFloor(floor) {
    return this.getLevelsByOrdinal()[floor];
  }

  getLevelsByOrdinal() {
    return groupBy(this.getFeatures('level'), (level) => level.data.ordinal);
  }
}
