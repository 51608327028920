import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';

import './tooltip.less';

const Tooltip = ({ iconName, tooltipText }) => {
  const className = useClassName('ModularTooltip');
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={className('container')}>
      <Icon
        name={iconName}
        className={className('icon')}
        onTouchStart={() => setShowTooltip(true)}
        onTouchEnd={() => setShowTooltip(false)}
      />
      <div className={`tooltip ${showTooltip ? 'active' : ''}`}>
        <p>{tooltipText}</p>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  iconName: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  iconName: 'question-circle',
};

export default Tooltip;
