import React from 'react';

import { useClassName } from 'common/hooks';

import useCustomStyle from '../../hooks';

import './carousel-arrows.less';

export default function CarouselArrows({ next, prev, type, style, settings }) {
  const classNames = useClassName('CLPCarouselArrows');
  const [, customStyle] = useCustomStyle(settings);

  const arrowIconStyles = Object.fromEntries(
    Object.entries(customStyle)
      .filter(([key]) => key.startsWith('--arrow-icon-'))
      .map(([key, value]) => [key.replace('--arrow-icon-', ''), value])
  );
  const arrowContainerStyles = Object.fromEntries(
    Object.entries(customStyle)
      .filter(([key]) => key.startsWith('--arrow'))
      .filter(([key]) => !key.startsWith('--arrow-icon-'))
      .map(([key, value]) => [key.replace('--arrow-', ''), value])
  );
  return (
    <div
      className={classNames([
        'container',
        type && type.toLowerCase().split(' ').join('-'),
      ])}
      style={style}>
      <div
        className={classNames('arrow')}
        onClick={prev}
        style={arrowContainerStyles}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M18.5 12H6M6 12L12 6M6 12L12 18"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...arrowIconStyles}
          />
        </svg>
      </div>
      <div
        className={classNames('arrow')}
        onClick={next}
        style={arrowContainerStyles}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M6 12L18.5 12M18.5 12L12.5 18M18.5 12L12.5 6"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...arrowIconStyles}
          />
        </svg>
      </div>
    </div>
  );
}
