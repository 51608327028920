import React from 'react';
import PropTypes from 'prop-types';
import { AssetPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import { LoaderImage } from '../Loader';
import './hero-logo.less';

const HeroLogo = ({ asset }) => {
  const className = useClassName('HeroLogo');
  const { assetDescription } = asset;

  return (
    <div
      className={className([
        'container',
        assetDescription && `container-${assetDescription}`,
      ])}>
      {asset && (
        <LoaderImage
          src={asset.assetUrl}
          alt={asset.assetAlt}
          loadingHeight={50}
          fluid
          hideLoader
        />
      )}
    </div>
  );
};

HeroLogo.propTypes = {
  asset: PropTypes.shape(AssetPropType).isRequired,
};

export default HeroLogo;
