import React from 'react';

export function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.291a6.04 6.04 0 0 0-6.04 6.042c0 1.642.741 3.175 1.953 4.749 1.022 1.327 2.33 2.624 3.721 4.004l.367.364.367-.364c1.391-1.38 2.7-2.677 3.721-4.004 1.212-1.574 1.954-3.107 1.954-4.75A6.04 6.04 0 0 0 10 2.292M2.71 8.333a7.292 7.292 0 0 1 14.583 0c0 2.039-.925 3.84-2.213 5.512-1.078 1.399-2.457 2.766-3.847 4.144q-.396.392-.79.786a.625.625 0 0 1-.884 0l-.79-.785C7.379 16.61 6 15.244 4.923 13.845 3.635 12.172 2.71 10.372 2.71 8.333M10 6.458a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75M6.875 8.333a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0"
        fill="#8C98A8"
      />
    </svg>
  );
}
