import React from 'react';
import { Component } from 'common/helpers';
import { ContentfulRichText, Layout, ContentfulImage } from 'common/components';

import './listedCards.less';

export default class ListedCards extends Component {
  render() {
    const { description, cards } = this.props;
    return (
      <div {...this.getAttrs()}>
        {description && (
          <div className={this.getElementClass('main-title')}>
            <ContentfulRichText field={description} />
          </div>
        )}
        {this.renderCards(cards)}
      </div>
    );
  }

  renderCards(cards) {
    return cards.map((card, index) => {
      const title = card.fields.title;
      const description = card.fields.description;
      const image = card.fields.image;

      return (
        <Layout
          key={index}
          className={this.getElementClass('card')}
          horizontal
          stackable>
          <Layout.Group>
            {image && (
              <div className={this.getElementClass('image')}>
                <ContentfulImage field={image} width={64} />
              </div>
            )}
          </Layout.Group>
          <Layout.Group grow>
            {title && (
              <div className={this.getElementClass('title')}>
                <ContentfulRichText field={title} />
              </div>
            )}
            {description && (
              <div className={this.getElementClass('description')}>
                <ContentfulRichText field={description} />
              </div>
            )}
          </Layout.Group>
        </Layout>
      );
    });
  }
}
