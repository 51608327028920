import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Asset from './Asset';

import './features.less';

const Features = ({ images }) => {
  const className = useClassName('ModularFeatures');

  return (
    <div className={className('container')}>
      {images.map((image, index) => (
        <div className={className('feature')} key={index}>
          <div className={className('feature-image')}>
            <Asset asset={image} />
          </div>
          <span
            className={className('feature-text')}
            {...(image.color && { style: { color: image.color } })}>
            {image.label}
          </span>
        </div>
      ))}
    </div>
  );
};

Features.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Features;
