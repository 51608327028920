/* ResponsiveImage:
 *
 * 1. Loads the perfect image URL for a given size using either
 *    our own image proxy service or Contentful.
 *
 * 2. Can either be a fixed width or will look up the element's
 *    width when it is mounted.
 *
 * 3. Accepts an "upload" prop to build URLs based on API upload
 *    objects.
 *
 * 4. Takes devicePixelRatio into account.
 *
 * 5. Accepts a "ratio" prop giving it a hint to set a temporary
 *    height override so that there is no layout thrashing. This
 *    This override is released once the image is loaded.
 *
 * 6. Caps dimensions when user is on a slow connection
 *    (and navigator.connection API is available).
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Ref } from 'common/lazy';
import { debounce } from 'lodash';
import { IMAGE_RESIZE_PROXY_URL } from 'utils/env/client';
import { navigator } from 'utils/helpers/window';
import { urlForUpload } from 'utils/api';
import { Component } from 'common/helpers';

const LOCALHOST_REG = /^(https?:)\/\/localhost/;
const CONTENTFUL_CDN_REG = /(downloads|images)\.ctfassets\.net/;

// Contentful will error at greater sizes
const CONTENTFUL_MAX_PX = 4000;

// Very large images (greater than 1200px) should only be opted into
// when bandwidth can be assessed and speed is greater than 5mb/s.
// As this API is currently only available in Chrome, assume a fast
// connection for browser that don't support this API yet.
const LOW_BANDWIDTH_SPEED = 5;
const LOW_BANDWIDTH_PX = 1200;

function hasFastConnection() {
  const { connection } = navigator;
  const downlink = connection && connection.downlink;
  return downlink ? downlink >= LOW_BANDWIDTH_SPEED : true;
}

export default class ResponsiveImage extends Component {
  static contenfulImages = {};

  static useContentfulImages(images) {
    this.contentfulImages = images;
  }

  static getContentfulBaseUrl(name) {
    const src = this.contentfulImages[name];
    if (!src) {
      throw new Error(`Couldn't find Contentful image ${name}`);
    }
    return src;
  }

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.ref = React.createRef();
  }

  // Lifecycle

  componentDidMount() {
    // Unless explicit sizing props are passed, the URL cannot be
    // calculated until the element is mounted so force a refresh here.
    this.forceUpdate();
    window.addEventListener('resize', this.onResizeDeferred);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeDeferred);
  }

  // Events

  onLoad = () => {
    this.setState({
      loaded: true,
    });
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  };

  onResize = () => {
    this.forceUpdate();
  };

  onResizeDeferred = debounce(this.onResize, 300);

  // Dimensions

  // Gets the element width, either as passed by
  // props or the mounted element's client width.
  getWidth() {
    let { width } = this.props;
    if (width) {
      return width;
    }
    width = this.getClientWidth();
    if (!width) {
      const ratio = this.getRatio();
      const height = this.props.height || this.getClientHeight();
      if (ratio && height) {
        width = Math.round(height * ratio);
      }
    }
    return width || null;
  }

  // Gets the element height, either as passed by props
  // or based on a ratio.
  getHeight() {
    let { height } = this.props;
    if (height) {
      return height;
    }
    const width = this.getWidth();
    if (width) {
      const ratio = this.getRatio(width);
      if (ratio) {
        height = Math.round(width / ratio);
      }
    } else {
      height = this.getClientHeight();
    }
    return height || null;
  }

  // Gets the ratio of the image, either as passed by props
  // or calculated based on the current dimensions.
  getRatio(width) {
    if (this.props.square) {
      return 1;
    } else if (this.props.ratio) {
      return this.props.ratio;
    } else if (width) {
      const height = this.props.height || this.getClientHeight();
      if (height) {
        return width / height;
      }
    }
  }

  // Gets the correct image width for the current pixel ratio.
  getImageWidth() {
    return this.getDeviceSize(this.getWidth());
  }

  // Gets the correct image height for the current pixel ratio.
  getImageHeight() {
    return this.getDeviceSize(this.getHeight());
  }

  getDeviceSize(px) {
    const { devicePixelRatio = 1 } = window;
    if (px && devicePixelRatio && devicePixelRatio !== 1) {
      px = Math.round(px * devicePixelRatio);
    }
    return px;
  }

  getClientWidth() {
    const el = this.ref.current;
    return el && el.clientWidth;
  }

  getClientHeight() {
    const el = this.ref.current;
    const { loaded } = this.state;
    // Note: If an alt attribute is passed, then clientHeight will
    // be non-zero, so check that the image has loaded correctly.
    return loaded && el && el.clientHeight;
  }

  // Lazy loading

  getLoading(src, width, height) {
    const { lazy } = this.props;
    if (lazy && src) {
      this.lazyWarning(src, width, height);
      return 'lazy';
    }
  }

  // Lazy loading images without dimensions can hurt performance
  // but chrome doesn't tell you which image wasn't loaded correctly
  // so manually warn here if needed.
  lazyWarning(src, width, height) {
    if (!width || !height) {
      console.warn(
        `Loading lazy image without dimensions: ${src}, ${this.props.contentfulName}`
      );
    }
  }

  // Resizing

  getResizedUrl(url, w, h) {
    if (!w && !h) {
      // No dimensions so cannot resize.
      return null;
    }
    if (!hasFastConnection()) {
      const dim = this.clampDimensions(w, h, LOW_BANDWIDTH_PX);
      w = dim.w;
      h = dim.h;
    }
    if (this.isContentfulUrl(url)) {
      return this.getContentfulResizedUrl(url, w, h);
    } else if (IMAGE_RESIZE_PROXY_URL) {
      return this.getResizeProxyUrl(url, w, h);
    } else {
      // Fallback to local URL
      return url;
    }
  }

  clampDimensions(w, h, max) {
    if (w >= max || h >= max) {
      const ratio = this.getRatio(w);
      if (w >= h) {
        w = max;
        h = Math.round(w / ratio);
      } else {
        h = max;
        w = Math.round(h * ratio);
      }
    }
    return { w, h };
  }

  getResizeProxyUrl(url, w, h) {
    const t = this.getResizeType();
    const g = this.props.gravity || 'ce';
    w = w || 0;
    h = h || 0;
    url = url.replace(LOCALHOST_REG, '$1//host.docker.internal');
    // For all options see:
    // https://github.com/imgproxy/imgproxy/blob/master/docs/generating_the_url_basic.md#generating-the-url-basic
    return `${IMAGE_RESIZE_PROXY_URL}/img/${t}/${w}/${h}/${g}/1/plain/${url}`;
  }

  // Where w1 and h1 are the input dimensions that may
  // overflow contentful's size max.
  getContentfulResizedUrl(url, w1, h1) {
    const t = this.getResizeType();
    const g = this.props.gravity || 'ce';
    const params = new URLSearchParams();
    const { w, h } = this.clampDimensions(w1, h1, CONTENTFUL_MAX_PX);
    if (w) {
      params.append('w', w);
    }
    if (h) {
      params.append('h', h);
    }
    if (g) {
      // TODO: gravity!
      //center, top, right, left, bottom.
      //top_right, top_left, bottom_right, bottom_left.
      //face for the largest face detected.
      //faces for all the faces detected.
      //params.append('h', h);
    }
    if (t === 'fill') {
      params.append('fit', 'fill');
    }
    // TODO: test
    url = url.replace(/downloads\.ctfassets\.net/, 'images.ctfassets.net');
    params.append('q', '80');
    return `${url}?${params.toString()}`;
  }

  getResizeType() {
    return this.props.type || this.props.cover ? 'fill' : 'fit';
  }

  isContentfulUrl(url) {
    return CONTENTFUL_CDN_REG.test(url);
  }

  // Helpers

  getBaseUrl() {
    const { src, contentfulName } = this.props;
    if (contentfulName) {
      return ResponsiveImage.getContentfulBaseUrl(this.props.contentfulName);
    } else if (src && typeof src === 'object') {
      return urlForUpload(src);
    } else {
      return src;
    }
  }

  getStyles(height) {
    if (this.props.cover) {
      return {
        width: '100%',
        height: '100%',
      };
    } else if (height && !this.state.loaded) {
      // Fix the element in place while unloaded.
      return {
        height: `${height}px`,
      };
    }
  }

  render() {
    const baseUrl = this.getBaseUrl();
    if (!baseUrl) {
      return <Image {...this.passProps()} />;
    }
    const src = this.getResizedUrl(
      baseUrl,
      this.getImageWidth(),
      this.getImageHeight()
    );
    const width = this.getWidth();
    const height = this.getHeight();
    const style = {
      ...(this.props.style || {}),
      ...this.getStyles(height),
    };
    return (
      <picture>
        {this.isContentfulUrl(src) && (
          <source srcSet={`${src}&fm=webp`} type="image/webp" />
        )}
        <Ref innerRef={this.ref}>
          <Image
            {...this.passProps()}
            src={src}
            style={style}
            width={width}
            height={height}
            loading={this.getLoading(src, width, height)}
            onLoad={this.onLoad}></Image>
        </Ref>
      </picture>
    );
  }
}

ResponsiveImage.propTypes = {
  upload: PropTypes.object,
  ratio: PropTypes.number,
  type: PropTypes.oneOf(['fit', 'fill', 'auto']),
  gravity: PropTypes.string,
  square: PropTypes.bool,
  contentfulName: PropTypes.string,
  cover: PropTypes.bool,
  lazy: PropTypes.bool,
  onLoad: PropTypes.func,
};

ResponsiveImage.defaultProps = {
  lazy: true,
  square: false,
  gravity: 'sm',
};
