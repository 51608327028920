import { observable, action } from 'mobx';

const UNSEEN_THRESHOLD = 1;

export default class Notifications {
  @observable items = [];

  addSuccess(message, immediate) {
    this.add(message, 'success', immediate);
  }

  addError(message, immediate) {
    this.add(message, 'error', immediate);
  }

  @action
  add(message, type, immediate) {
    this.items = this.items.concat({
      type,
      message,
      seen: immediate ? 1 : 0,
    });
  }

  @action
  remove(notification) {
    this.items = this.items.filter((n) => n !== notification);
  }

  @action
  incrementSeenCount() {
    this.items = this.items.map((item) => {
      return {
        ...item,
        seen: item.seen + 1,
      };
    });
  }

  getUnseen() {
    return this.items.filter((item) => {
      return item.seen <= UNSEEN_THRESHOLD;
    });
  }
}
