import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from 'common/lazy';
import { Component } from 'common/helpers';
import { Card } from '../Card';
import SubscriptionSection from './SubscriptionSection';

import { Spacer } from '../Spacer';
import {
  Layout,
  getContentfulField,
  getContentfulAssetUrlandAlt,
  ContentfulRichText,
  MobileOnly,
  DesktopOnly,
} from 'common/components';

import './section.less';

export default class Section extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    redirect: null,
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  getCTA(cta) {
    if (cta) {
      const {
        fields: { name, link },
      } = getContentfulField(cta);
      const ctaName = getContentfulField(name);
      const ctaLink = getContentfulField(link);
      return { ctaName, ctaLink };
    }
    return null;
  }

  renderSections() {
    const sections = this.props.sections;

    if (sections && sections.length) {
      return sections.map((section) => {
        const cards = getContentfulField(section.fields.cards);
        const name = getContentfulField(section.fields.name);
        const type = getContentfulField(section.fields.type);
        const theme = getContentfulField(section.fields.theme);
        const cta = this.getCTA(section.fields.cta);
        const ctaAlignment = getContentfulField(section.fields.ctaAlignment);

        let backgroundMobile, backgroundDesktop;

        if (section.fields.backgroundImageMobile) {
          const { assetUrl } = getContentfulAssetUrlandAlt(
            section.fields.backgroundImageMobile
          );
          backgroundMobile = assetUrl;
        }
        if (section.fields.backgroundImageDesktop) {
          const { assetUrl } = getContentfulAssetUrlandAlt(
            section.fields.backgroundImageDesktop
          );
          backgroundDesktop = assetUrl;
        }

        if (type === 'main') {
          return (
            <div key={name} {...this.getAttrs()}>
              <Container className={this.getElementClass('main')}>
                <ContentfulRichText
                  field={section.fields.title}
                  className={`${this.getElementClass('title')} ${theme}`}
                />
                <Spacer size="xs" />
                <Layout horizontal stretch>
                  <Layout.Group
                    className={this.getElementClass('cards-container')}>
                    <ContentfulRichText
                      field={section.fields.description}
                      className={`${this.getElementClass(
                        'description'
                      )} ${theme}`}
                    />
                    <DesktopOnly>{this.renderCards(cards, 'even')}</DesktopOnly>
                    <MobileOnly>{this.renderCards(cards)}</MobileOnly>
                  </Layout.Group>
                  <DesktopOnly
                    className={this.getElementClass('cards-container')}>
                    <Layout.Group>
                      {this.renderCards(cards, 'odd')}
                    </Layout.Group>
                  </DesktopOnly>
                </Layout>
              </Container>
            </div>
          );
        } else if (type === 'directory') {
          const renderContent = () => {
            return (
              <>
                {section.fields.title && (
                  <ContentfulRichText
                    field={section.fields.title}
                    className={`${this.getElementClass(
                      'title-directory'
                    )} ${theme}`}
                  />
                )}
                {(section.fields.description || cta) && (
                  <>
                    <div
                      className={this.getElementClass(
                        'top-directory',
                        ctaAlignment
                      )}>
                      {section.fields.description && (
                        <ContentfulRichText
                          field={section.fields.description}
                          className={`${this.getElementClass(
                            'description-directory'
                          )} ${theme}`}
                        />
                      )}
                      {cta && (
                        <div
                          className={this.getElementClass(
                            'directory-cta',
                            ctaAlignment
                          )}
                          onClick={() => this.setRedirect(cta.ctaLink)}>
                          {cta.ctaName}
                        </div>
                      )}
                    </div>
                  </>
                )}
                <Spacer size="s" />
                {cards && (
                  <Layout horizontal stretch spread>
                    <Layout.Group
                      className={this.getElementClass('cards-container')}>
                      <DesktopOnly>
                        {this.renderCards(cards, 'even')}
                      </DesktopOnly>
                      <MobileOnly>
                        {this.renderCards(cards, 'even')}
                        {this.renderCards(cards, 'odd')}
                      </MobileOnly>
                    </Layout.Group>
                    <DesktopOnly>
                      <Layout.Group
                        className={this.getElementClass('cards-container')}>
                        {this.renderCards(cards, 'odd')}
                      </Layout.Group>
                    </DesktopOnly>
                  </Layout>
                )}
              </>
            );
          };

          return (
            <div key={name} {...this.getAttrs()}>
              <DesktopOnly
                className={this.getElementClass('directory')}
                style={{ backgroundImage: `url(${backgroundDesktop})` }}>
                <Container>{renderContent()}</Container>
              </DesktopOnly>
              <MobileOnly
                className={this.getElementClass('directory')}
                style={{ backgroundImage: `url(${backgroundMobile})` }}>
                {renderContent()}
              </MobileOnly>
            </div>
          );
        } else if (type === 'subscription') {
          return (
            <div key={name} {...this.getAttrs()}>
              <SubscriptionSection section={section} />
            </div>
          );
        }
        return null;
      });
    }
    return null;
  }

  renderCards(cards, strategy) {
    if (cards && cards.length) {
      return cards.map((card, index) => {
        if (
          strategy &&
          ((strategy === 'odd' && !(index % 2)) ||
            (strategy === 'even' && index % 2))
        ) {
          return null;
        }
        const name = getContentfulField(card.fields.name);

        return <Card key={name} card={card} />;
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    return this.renderSections();
  }
}
