import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
  ContentfulRichText,
} from 'common/components';
import { AssetPropType } from 'public/schema';
import { LoaderImage } from '../../Loader';
import './landing-welcome-section.less';

const LandingWelcomeSection = ({
  id,
  secondaryTitle,
  primaryTitle,
  imageMobile,
  imageDesktop,
}) => {
  const className = useClassName('LandingWelcomeSection');

  const shouldRender = [
    secondaryTitle,
    primaryTitle,
    imageMobile,
    imageDesktop,
  ].some((prop) => Boolean(prop));
  if (!shouldRender) return null;

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        {(primaryTitle || secondaryTitle) && (
          <div className={className('title-container')}>
            {secondaryTitle && (
              <ContentfulRichText
                className={className('title-secondary')}
                field={secondaryTitle}
              />
            )}
            {primaryTitle && (
              <ContentfulRichText
                className={className('title-primary')}
                field={primaryTitle}
              />
            )}
          </div>
        )}
        {imageMobile && (
          <MobileOnly className={className('image-container')}>
            <LoaderImage
              src={imageMobile.assetUrl}
              alt={imageMobile.assetAlt}
              loadingHeight={300}
              hideLoader
              fluid
            />
          </MobileOnly>
        )}
        {imageDesktop && (
          <TabletOnly className={className('image-container')}>
            <LoaderImage
              src={imageDesktop.assetUrl}
              alt={imageDesktop.assetAlt}
              loadingHeight={550}
              hideLoader
              fluid
            />
          </TabletOnly>
        )}
        {imageDesktop && (
          <DesktopOnly className={className('image-container')}>
            <LoaderImage
              src={imageDesktop.assetUrl}
              alt={imageDesktop.assetAlt}
              loadingHeight={550}
              hideLoader
              fluid
            />
          </DesktopOnly>
        )}
      </div>
    </div>
  );
};

LandingWelcomeSection.propTypes = {
  id: PropTypes.string.isRequired,
  primaryTitle: PropTypes.object,
  secondaryTitle: PropTypes.object,
  imageMobile: PropTypes.shape(AssetPropType),
  imageDesktop: PropTypes.shape(AssetPropType),
};

LandingWelcomeSection.defaultProps = {
  imageMobile: null,
  imageDesktop: null,
};

export default LandingWelcomeSection;
