import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import mapLinear from './mapLinear';
import STYLES from './styles';

import './animated-polyline.less';

const CANVAS_PROPS_TO_SVG = {
  strokeColor: 'stroke',
  strokeOpacity: 'strokeOpacity',
  lineWidth: 'strokeWidth',
  lineCap: 'strokeLinecap',
  lineJoin: 'strokeLinejoin',
  lineDash: 'strokeDasharray',
  lineDashOffset: 'strokeDashoffset',
};

export default class AnimatedPolyline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rect: null,
      visible: true,
    };
    this.ref = React.createRef();
  }

  render() {
    const { element } = this.props.map;
    const el = element.getElementsByClassName('mk-map-node-element')[0];
    return ReactDOM.createPortal(
      <svg ref={this.ref} {...this.getAttrs()}>
        {this.renderPolyline()}
      </svg>,
      el
    );
  }

  renderPolyline() {
    const el = this.ref.current;
    const { segment, hidden, map } = this.props;
    if (!el || !segment || hidden) {
      return;
    }
    const { origin, size } = map.visibleMapRect;
    const { width, height } = el.getBoundingClientRect();
    const extent = new mapkit.MapPoint(
      origin.x + size.width,
      origin.y + size.height
    );
    const points = this.props.segment.coordinates
      .map((coord) => {
        const point = coord.toMapPoint();
        const x = mapLinear(point.x, origin.x, extent.x, 0, width);
        const y = mapLinear(point.y, origin.y, extent.y, 0, height);
        return [x, y].join(',');
      })
      .join(' ');
    const transform = `rotate(${map.rotation}, ${width / 2}, ${height / 2})`;
    return (
      <polyline
        points={points}
        transform={transform}
        {...this.getPolylineAttrs()}
      />
    );
  }

  getPolylineAttrs() {
    const attrs = {};
    const lineStyle = STYLES['wayfinding-line'];
    for (let [canvas, svg] of Object.entries(CANVAS_PROPS_TO_SVG)) {
      const val = lineStyle[canvas];
      if (val) {
        attrs[svg] = val;
      }
    }
    return attrs;
  }
}

AnimatedPolyline.propTypes = {
  map: PropTypes.object,
  hidden: PropTypes.bool,
  segment: PropTypes.object,
};
