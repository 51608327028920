import React from 'react';

import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';

import Actions from '../Actions';
import Link from '../Link';

import './hero.less';

export default function Hero({ component }) {
  const classNames = useClassName('CLPHero');

  const withOverlay = Boolean(
    component?.fields?.title ||
      component.fields.title ||
      component?.fields?.links?.length
  );
  return (
    <div className={classNames(['container', withOverlay && 'overlay'])}>
      <HomepageAsset
        asset={component.fields.asset}
        className={classNames('media')}
        style={{ opacity: 1 }}
      />
      {withOverlay && (
        <div>
          {Boolean(component?.fields?.title) && (
            <h1>{component.fields.title}</h1>
          )}
          {Boolean(component?.fields?.description) && (
            <h2>
              <ContentfulRichText
                field={{ 'en-US': component.fields.description }}
              />
            </h2>
          )}
          {Boolean(component?.fields?.links?.length) && (
            <Actions>
              {component.fields.links.map((link) => (
                <Link key={link.sys.id} component={link} />
              ))}
            </Actions>
          )}
        </div>
      )}
    </div>
  );
}
