import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  ResponsiveImageLight,
  MobileOnly,
  TabletOnly,
  DesktopOnlyFix as DesktopOnly,
} from 'common/components';
import { AssetPropType } from 'public/schema';
import './landing-explore-section.less';

const LandingExploreSection = ({
  id,
  imageMobile,
  imageTablet,
  imageDesktop,
}) => {
  const className = useClassName('LandingExploreSection');
  return (
    <div id={id} className={className('container')}>
      <div className={className('image-container')}>
        {imageMobile?.assetUrl && (
          <MobileOnly>
            <ResponsiveImageLight src={imageMobile.assetUrl} fluid />
          </MobileOnly>
        )}
        {imageTablet && (
          <TabletOnly>
            <ResponsiveImageLight src={imageTablet?.assetUrl} fluid />
          </TabletOnly>
        )}
        {imageDesktop && (
          <DesktopOnly>
            <ResponsiveImageLight src={imageDesktop?.assetUrl} fluid />
          </DesktopOnly>
        )}
      </div>
    </div>
  );
};

LandingExploreSection.propTypes = {
  id: PropTypes.string.isRequired,
  imageMobile: PropTypes.shape(AssetPropType),
  imageTablet: PropTypes.shape(AssetPropType),
  imageDesktop: PropTypes.shape(AssetPropType),
};

LandingExploreSection.defaultProps = {
  imageMobile: null,
  imageTablet: null,
  imageDesktop: null,
};

export default LandingExploreSection;
