import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'common/lazy';

const CustomPagination = ({ page, limit, total, onPageChange, className }) => {
  return total <= limit ? null : (
    <Pagination
      className={className}
      nextItem={
        page * limit - total < 0
          ? {
              'aria-label': 'Next Page',
              content: 'Next',
            }
          : null
      }
      prevItem={
        page !== 1
          ? {
              'aria-label': 'Previous item',
              content: 'Previous',
            }
          : null
      }
      onPageChange={onPageChange}
      activePage={page}
      firstItem={null}
      lastItem={null}
      totalPages={Math.ceil(total / limit)}
    />
  );
};

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  limit: PropTypes.number,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CustomPagination.defaultProps = {
  limit: 20,
  className: '',
};

export default CustomPagination;
