import { useWindowSize } from 'common/hooks';

export default function useLoop({ breakpoints, slidesLength }) {
  const { width } = useWindowSize();

  const currentBreakpoint = Object.keys(breakpoints)
    .map((key) => parseInt(key))
    .sort((a, b) => b - a)
    .find((size) => width > size);

  if (!currentBreakpoint) {
    return {
      loop: slidesLength > 1,
      loopAdditionalSlides: slidesLength,
      pending: !width,
    };
  }

  const loop =
    currentBreakpoint >= 0
      ? slidesLength > breakpoints[currentBreakpoint].slidesPerView
      : false;

  return {
    loop,
    loopAdditionalSlides: slidesLength,
    breakpoints,
    pending: !width,
  };
}
