import React from 'react';
import { Container } from 'common/lazy';
import { Component } from 'common/helpers';
import { ResponsiveImage } from 'common/components';
import { Spacer } from '../Spacer';

import './hero.less';

const VIDEO_URL_REG = /\.(mp4|m4v)$/i;

export default class Hero extends Component {
  getModifiers() {
    const { media, tall } = this.props;
    return [this.isVideoUrl(media) ? 'video' : null, tall ? 'tall' : null];
  }

  render() {
    return (
      <div {...this.getAttrs()}>
        {this.renderMedia()}
        <div className={this.getElementClass('content')}>
          <Container>
            {this.props.children}
            <Spacer size="s" />
          </Container>
        </div>
      </div>
    );
  }

  renderMedia() {
    const { media } = this.props;
    if (media && this.isVideoUrl(media.url)) {
      return this.renderVideo(media);
    } else {
      return this.renderImage(media);
    }
  }

  isVideoUrl(url) {
    return VIDEO_URL_REG.test(url);
  }

  renderImage(media) {
    const { contentfulImage, cover } = this.props;
    const props = { cover };
    if (contentfulImage) {
      props.contentfulName = contentfulImage;
    } else if (media) {
      props.src = media.url;
      props.ratio = media.ratio;
    }
    return <ResponsiveImage {...props} fluid />;
  }

  renderVideo(media) {
    const { fallback } = this.props;
    return (
      <video
        src={media.url}
        poster={fallback && fallback.url}
        type="video/mp4"
        className={this.getElementClass('video')}
        controls
        autoPlay
      />
    );
  }
}
