import React from 'react';
import propTypes from 'prop-types';
import { useClassName } from 'common/hooks';

import './image-item.less';

const ImageItem = ({ item, handleMenuItemClicked }) => {
  const className = useClassName('ImageItem');

  const getBackground = () => {
    const gradient =
      item.itemType === 'park' && item.theme
        ? `linear-gradient(180deg, ${item.theme.secondaryColor}  0%, ${item.theme.primaryColor} 100%)`
        : 'linear-gradient(113.01deg, rgba(0, 0, 0, 0.7) 20.55%, rgba(0, 0, 0, 0) 68.52%)';
    const imageUrl = item.asset ? `url(${item.asset.assetUrl})` : null;
    if (!imageUrl) return gradient;
    if (item.itemType === 'park')
      return [imageUrl, !item.hiddenText && gradient]
        .filter(Boolean)
        .join(', ');

    return [!item.hiddenText && gradient, imageUrl].filter(Boolean).join(', ');
  };

  return (
    <div
      className={className(['container', `container-${item.itemType}`])}
      style={{ backgroundImage: getBackground() }}
      onClick={() => item.link && handleMenuItemClicked()}>
      {!item.hiddenText && (
        <div className={className('content')}>
          <div className={className('label')}>{item.text}</div>
        </div>
      )}
    </div>
  );
};

ImageItem.propTypes = {
  item: propTypes.object.isRequired,
  handleMenuItemClicked: propTypes.func.isRequired,
};

export default ImageItem;
