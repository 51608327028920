// Arbitrary link that could contain any kind of URL.

import React from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink';
import MailLink from './MailLink';
import JumpLink from './JumpLink';
import TelLink from './TelLink';

const JUMP_LINK_REG = /^#/;
const TEL_LINK_REG = /^tel:/;
const MAIL_LINK_REG = /^mailto:/;
const EXTERNAL_LINK_REG = /^https?:/;

export default class ContentLink extends React.Component {
  render() {
    let component = null;
    const { href, onClick, ...props } = this.props;
    if (EXTERNAL_LINK_REG.test(href)) {
      component = <ExternalLink href={href} {...props} />;
    } else if (MAIL_LINK_REG.test(href)) {
      component = (
        <MailLink mail={href.replace(MAIL_LINK_REG, '')} {...props} />
      );
    } else if (TEL_LINK_REG.test(href)) {
      component = <TelLink tel={href.replace(TEL_LINK_REG, '')} {...props} />;
    } else if (JUMP_LINK_REG.test(href)) {
      component = <JumpLink hash={href} {...props} />;
    } else {
      component = <Link to={href} {...props} />;
    }

    return <span onClick={onClick}>{component}</span>;
  }
}
