import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';
import { Dropdown } from 'public/components/Dropdown';

import './select.less';

const Select = ({
  items,
  selectedItem,
  setSelectedItem,
  error,
  onBlur,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);
  const classNames = useClassName('Select');
  const { isMobile } = useWindowSize();

  const content = (
    <div className={classNames('list')}>
      {(items || []).map((item, index) => (
        <div
          key={index}
          className={classNames('item')}
          onClick={() => {
            onBlur({ value: item });
            setSelectedItem(item);
            setOpen(false);
          }}>
          <span>{item}</span>
        </div>
      ))}
    </div>
  );

  return (
    <ControlledInputContainer
      input={
        <input value={selectedItem || ''} readOnly placeholder={placeholder} />
      }
      addonRight={
        <SVGIcon name="angle-down" className={classNames('addon-right')} />
      }
      onClick={() => setOpen(true)}
      hasFocus={open}
      error={error}
      trigger={
        isMobile ? (
          <BottomSheet
            onClose={() => {
              onBlur({});
              setOpen(false);
            }}
            title="Select"
            isOpen={open}
            content={content}
          />
        ) : (
          <Dropdown
            isOpen={open}
            onClose={() => {
              onBlur({});
              setOpen(false);
            }}
            content={content}
            useTriggerWidth
          />
        )
      }
    />
  );
};

Select.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Select;
