import {
  getContentfulField,
  getContentfulAssetUrlandAlt,
} from 'common/components';

const parseAnnouncement = (announcement) => {
  const { fields } = announcement;
  const { assetUrl, assetAlt } =
    fields.image && getContentfulAssetUrlandAlt(fields.image);
  const ctaType = fields.ctaType && getContentfulField(fields.ctaType);
  const type = fields.type && getContentfulField(fields.type);
  const link = fields.link && getContentfulField(fields.link);
  const linkText = fields.linkText && getContentfulField(fields.linkText);
  const textColor = fields.textColor && getContentfulField(fields.textColor);
  const heading = fields.heading && getContentfulField(fields.heading);
  const withGradient =
    fields.withGradient && getContentfulField(fields.withGradient);
  const body = fields.body || null;
  return {
    ctaType,
    type,
    assetUrl,
    assetAlt,
    link,
    linkText,
    textColor,
    heading,
    body,
    withGradient,
  };
};

const parseTheme = (theme) => {
  return {
    primaryColor: theme.primaryColor && getContentfulField(theme.primaryColor),
    secondaryColor:
      theme.secondaryColor && getContentfulField(theme.secondaryColor),
    tertiaryColor:
      theme.tertiaryColor && getContentfulField(theme.tertiaryColor),
  };
};

const getMenuItemFields = (item) => {
  const {
    text,
    link,
    children,
    childrenTitle,
    announcement,
    isNew,
    external,
    icon,
    itemType,
    asset,
    theme,
    hiddenText,
  } = item.fields;

  return {
    text: text && getContentfulField(text),
    link: link && getContentfulField(link),
    isNew: isNew && getContentfulField(isNew),
    external: external && getContentfulField(external),
    children: children && getContentfulField(children).map(getMenuItemFields),
    childrenTitle: childrenTitle && getContentfulField(childrenTitle),
    announcement:
      announcement && parseAnnouncement(getContentfulField(announcement)),
    icon: icon && getContentfulAssetUrlandAlt(icon),
    itemType: itemType && getContentfulField(itemType),
    asset: asset && getContentfulAssetUrlandAlt(asset),
    theme: theme && parseTheme(getContentfulField(theme).fields),
    hiddenText: hiddenText && getContentfulField(hiddenText),
  };
};

export const getMenu = ({ menu }) => {
  if (!menu?.fields?.items) return null;
  const menuItems = getContentfulField(menu.fields.items);
  const items = menuItems.map((item) => getMenuItemFields(item));
  return items;
};

export const getProfileLinks = (appSession) => {
  const profileLink = appSession.token ? '/profile' : '/login';
  const profileText = appSession.token ? 'My Profile' : 'Login | Sign Up';
  return { profileLink, profileText };
};

export const fixVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
