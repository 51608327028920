import React from 'react';
import PropTypes from 'prop-types';
import { LandingHero } from './LandingHero';
import { LandingWelcomeSection } from './LandingWelcomeSection';
import { LandingFactsSection } from './LandingFactsSection';
import { LandingVenuesSection } from './LandingVenuesSection';
import { LandingExploreSection } from './LandingExploreSection';
import { LandingTitleSection } from './LandingTitleSection';
import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import { parseLanding } from 'public/helpers/contentful/parser';
import './landing-screen.less';

const LandingScreen = ({ page }) => {
  track(`${page?.slug} Viewed`);
  const className = useClassName('LandingScreen');
  const content = parseLanding(page);

  return (
    <div
      className={className('container')}
      style={{
        backgroundColor: content.backgroundColor,
        color: content.fontColor,
        ...(!content.backgroundColor && {
          backgroundImage: `url(${content?.backgroundImage?.assetUrl})`,
        }),
      }}>
      <LandingHero
        id={'hero'}
        heroLogo={content.heroLogo}
        heroTitle={content.heroTitle}
        heroVideo={content.heroVideo}
        heroPoster={content.heroPoster}
        heroSubtitle={content.heroSubtitle}
        heroCtaText={content.heroCtaText}
        heroCtaLink={content.heroCtaLink}
        buttonColor={content.buttonColor}
        buttonTextColor={content.buttonTextColor}
      />
      <LandingWelcomeSection
        id={'welcome'}
        primaryTitle={content.welcomeSectionPrimaryTitle}
        secondaryTitle={content.welcomeSectionSecondaryTitle}
        imageMobile={content.welcomeSectionImageMobile}
        imageDesktop={content.welcomeSectionImageDesktop}
      />
      <LandingExploreSection
        id={'explore'}
        imageMobile={content.exploreSectionImageMobile}
        imageTablet={content.exploreSectionImageTablet}
        imageDesktop={content.exploreSectionImageDesktop}
      />
      <div
        className={className('container')}
        style={{
          ...(content.backgroundColor && {
            backgroundImage: `url(${content?.backgroundImage?.assetUrl})`,
          }),
        }}>
        <LandingVenuesSection
          id={'venues-cards'}
          cards={content.venueDetailCards}
          defaultCategory={content.venueDetailDefaultCategory}
          categories={content.venueDetailCategories}
          fontColor={content.fontColor}
          buttonColor={content.buttonColor}
          buttonTextColor={content.buttonTextColor}
          buttonSecondaryColor={content.buttonSecondaryColor}
          buttonSecondaryTextColor={content.buttonSecondaryTextColor}
        />
        <LandingTitleSection
          id={'facts'}
          titleFirstPart={content.factsSectionTitle}
          type={'subtitle'}
          image={content.factsSectionImage}
        />
        <LandingFactsSection
          id={'facts-cards'}
          cards={content.factsCards}
          fontColor={content.fontColor}
          buttonColor={content.buttonColor}
          buttonTextColor={content.buttonTextColor}
        />
      </div>
    </div>
  );
};

LandingScreen.propTypes = {
  page: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    object: PropTypes.object.isRequired,
  }).isRequired,
};

export default LandingScreen;
