import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { LazyGlide } from 'common/lazy';
import { getCurrentLocaleCode } from 'utils/l10n';
import chevronRight from 'common/assets/chevron-right.svg';
import chevronLeft from 'common/assets/chevron-left.svg';

import { ContentfulImage } from '../Contentful';

import './slider.less';

const SLIDER_OPTIONS = {
  type: 'slider',
  startAt: 0,
  animationTimingFunc: 'ease-in-out',
  gap: 100,
  perView: 1,
};

const Slider = ({ images, contentfulImages, hideMiniatures, className }) => {
  const classNames = useClassName('Slider');
  const mainRef = useRef();
  const [mainSlider, setMainSlider] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    LazyGlide(mainRef.current, SLIDER_OPTIONS).then((slider) => {
      slider.on('run.after', () => {
        setSelectedImage(slider.index);
      });

      slider.mount();
      setMainSlider(slider);
    });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }, [mainRef]);

  const showControls = images.length > 1;

  const updateImageIndex = (index) => mainSlider.go(`=${index}`);

  return (
    <>
      <div ref={mainRef} className={`glide ${className}`}>
        {showControls && (
          <div
            className={`${classNames('controls')} glide__arrows`}
            data-glide-el="controls">
            <button
              className="glide__arrow glide__arrow--left"
              data-glide-dir="<">
              <img src={chevronLeft} width={24} height={24} />
            </button>
            <button
              className="glide__arrow glide__arrow--right"
              data-glide-dir=">">
              <img src={chevronRight} width={24} height={24} />
            </button>
          </div>
        )}
        <div className="glide__track" data-glide-el="track">
          <ul className={`${classNames('slides')} glide__slides`}>
            {images.map((image, index) => (
              <li key={index} className="glide__slide">
                {contentfulImages ? (
                  <ContentfulImage
                    field={{ [getCurrentLocaleCode(false)]: image }}
                    width={300}
                  />
                ) : (
                  <img src={image} />
                )}
              </li>
            ))}
          </ul>
        </div>
        {showControls && (
          <div className="glide__bullets" data-glide-el="controls[nav]">
            {images.map((image, index) => (
              <button
                key={index}
                className="glide__bullet"
                data-glide-dir={`=${index}`}
              />
            ))}
          </div>
        )}
      </div>
      {showControls && !hideMiniatures && (
        <div className={classNames('previews')}>
          {images.map((image, index) => (
            <figure
              key={index}
              className={`${
                index === selectedImage && classNames('selected')
              }`}>
              {contentfulImages ? (
                <ContentfulImage
                  width={300}
                  field={{ [getCurrentLocaleCode(false)]: image }}
                  onClick={() => updateImageIndex(index)}
                />
              ) : (
                <img src={image} onClick={() => updateImageIndex(index)} />
              )}
            </figure>
          ))}
        </div>
      )}
    </>
  );
};

Slider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  contentfulImages: PropTypes.bool,
};

export default Slider;
