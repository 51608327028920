import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImageLight, ContentfulRichText } from 'common/components';
import { useClassName } from 'common/hooks';
import { AssetPropType } from 'public/schema';
import './title-section.less';

const TitleSection = ({
  id,
  titleFirstPart,
  titleSecondPart,
  image,
  backgroundColor,
  titleFirstPartColor,
  titleSecondPartColor,
}) => {
  if (!titleFirstPart && !titleSecondPart && !image) return null;

  const className = useClassName('TitleSection');

  return (
    <div
      id={id}
      className={className('container')}
      style={{ backgroundColor: backgroundColor }}>
      <div className={className('wrapper')}>
        {(titleFirstPart || titleSecondPart) && (
          <div className={className('title-container')}>
            {titleFirstPart && (
              <ContentfulRichText
                className={className('title-text')}
                style={{ color: titleFirstPartColor }}
                field={titleFirstPart}
              />
            )}
            {titleSecondPart && (
              <ContentfulRichText
                className={className('title-text')}
                style={{ color: titleSecondPartColor }}
                field={titleSecondPart}
              />
            )}
          </div>
        )}
        {image?.assetUrl && (
          <div className={className('image-container')}>
            <ResponsiveImageLight
              src={image?.assetUrl}
              alt={image?.assetAlt}
              fluid
            />
          </div>
        )}
      </div>
    </div>
  );
};

TitleSection.propTypes = {
  id: PropTypes.string.isRequired,
  titleFirstPart: PropTypes.object,
  titleSecondPart: PropTypes.object,
  image: PropTypes.shape(AssetPropType),
  backgroundColor: PropTypes.string,
  titleFirstPartColor: PropTypes.string,
  titleSecondPartColor: PropTypes.string,
};

TitleSection.defaultProps = {
  titleFirstPart: null,
  titleSecondPart: null,
  image: null,
  backgroundColor: 'white',
  titleFirstPartColor: 'black',
  titleSecondPartColor: 'black',
};

export default TitleSection;
