import { once } from 'lodash';
import { request } from 'utils/api';
import { loadScript } from 'utils/helpers';

const CDN_URL = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';

export default once(async function init() {
  await loadScript(CDN_URL);
  mapkit.init({
    authorizationCallback: async (done) => {
      const response = await request({
        method: 'GET',
        path: '/1/map/token',
        params: {
          origin: window.location.origin,
        },
      });
      done(response.data);
    },
  });
});
