import React from 'react';
import { Component } from 'common/helpers';

import './select.less';

export default class Select extends Component {
  onChange = (evt) => {
    this.props.onChange(evt.target.value, evt.target.name);
  };

  onChangeNumber = (evt) => {
    this.props.onChange(Number(evt.target.value), evt.target.name);
  };

  render() {
    let { children, onChange, range } = this.props;
    if (range) {
      children = range(range[0], range[1]).map((val) => {
        return (
          <option key={val} value={val}>
            {val}
          </option>
        );
      });
      onChange = this.onChangeNumber;
    }
    return (
      <select {...this.getAttrs()} {...this.props} onChange={onChange}>
        {children}
      </select>
    );
  }
}
