import React from 'react';

import inRegions from '@segment/in-regions';
import conditionallyLoadAnalytics from '@segment/consent-manager/esm/consent-manager-builder/analytics';
import { loadPreferences } from '@segment/consent-manager';

import { SEGMENT_WRITE_KEY } from 'utils/env/client';
import { Container, Button } from 'common/lazy';
import { loadAnalytics } from 'utils/analytics';
import { Component } from 'common/helpers';
import { ExternalLink } from 'common/components';
import { Spacer } from '../Spacer';

import Modal from './Modal';
import acceptDefault from './acceptDefault';
import fetchDestinations from './fetchDestinations';

import './banner.less';

const isConsentRequired = inRegions(['CA', 'EU'])();

export default class ConsentBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    if (!isConsentRequired) {
      loadAnalytics();
    } else {
      const { destinationPreferences } = loadPreferences();
      if (destinationPreferences) {
        this.loadConditionally(destinationPreferences);
      } else {
        this.setState({
          open: true,
        });
      }
    }
  }

  async loadConditionally(destinationPreferences) {
    const destinations = await fetchDestinations();
    conditionallyLoadAnalytics({
      writeKey: SEGMENT_WRITE_KEY,
      isConsentRequired,
      destinationPreferences,
      destinations,
    });
  }

  onSave = () => {
    this.setState({
      open: false,
    });
  };

  onAcceptClick = () => {
    acceptDefault();
    this.onSave();
  };

  render() {
    const { open } = this.state;
    if (!open) {
      return null;
    }
    return (
      <div {...this.getAttrs()}>
        <Container>
          <h5 className={this.getElementClass('title')}>
            How we use data on this site
          </h5>
          <Spacer size="xs" />
          <p className={this.getElementClass('body')}>
            We use cookies (and other similar technologies) to collect data to
            improve your experience on our site. By using our website, you're
            agreeing to the collection of data as described in our{' '}
            <ExternalLink
              className={this.getElementClass('link')}
              href="/cookie-policy">
              Cookie Policy
            </ExternalLink>
            . You can change your preferences at any time.
          </p>
          <Spacer size="xs" />
          <p>
            <Modal
              onSave={this.onSave}
              trigger={<Button inverted>Manage</Button>}
            />
            <Button onClick={this.onAcceptClick} inverted primary>
              OK
            </Button>
          </p>
        </Container>
      </div>
    );
  }
}
