import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './hero-subtitle.less';

const HeroSubtitle = ({ color, text }) => {
  const className = useClassName('HeroSubtitle');

  return (
    <div className={className('container')}>
      <ContentfulRichText
        className={className('text')}
        style={{ color }}
        field={text}
      />
    </div>
  );
};

HeroSubtitle.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
};

export default HeroSubtitle;
