import React from 'react';
import PropTypes from 'prop-types';
import { AssetPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import VenueDetailsHeroLogo from './VenueDetailsHeroLogo';
import VenueDetailsHeroTitle from './VenueDetailsHeroTitle';
import VenueDetailsHeroSubtitle from './VenueDetailsHeroSubtitle';
import VenueDetailsHeroAsset from './VenueDetailsHeroAsset';
import { OperationHours } from '../../OperationHours';
import { Spacer } from '../../Spacer';
import './venue-details-hero.less';

const VenueDetailsHero = ({
  id,
  slug,
  openingHours,
  heroLogo,
  heroTitle,
  heroSubtitle,
  heroVideo,
  heroPoster,
  featuredFlags,
}) => {
  const className = useClassName('VenueDetailsHero');

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        <div className={className('first-container')}>
          {heroLogo?.assetUrl && <VenueDetailsHeroLogo asset={heroLogo} />}
          {heroTitle && <VenueDetailsHeroTitle text={heroTitle} />}
          {heroSubtitle && <VenueDetailsHeroSubtitle text={heroSubtitle} />}
          <>
            <OperationHours
              openingHours={openingHours}
              featuredFlags={featuredFlags}
            />
            <Spacer size="xs" />
          </>
        </div>
        <div className={className('second-container')}>
          {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
            <VenueDetailsHeroAsset
              slug={slug}
              videoUrl={heroVideo?.assetUrl}
              posterUrl={heroPoster?.assetUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

VenueDetailsHero.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  openingHours: PropTypes.object,
  heroTitle: PropTypes.object,
  heroSubtitle: PropTypes.object,
  heroLogo: PropTypes.shape(AssetPropType),
  heroVideo: PropTypes.shape(AssetPropType),
  heroPoster: PropTypes.shape(AssetPropType),
  featuredFlags: PropTypes.arrayOf(PropTypes.string),
};

VenueDetailsHero.defaultProps = {
  heroTitle: null,
  heroSubtitle: null,
  heroLogo: null,
  heroVideo: null,
  heroPoster: null,
};

export default VenueDetailsHero;
