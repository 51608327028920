import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

import './spacer.less';

export default class Spacer extends Component {
  getModifiers() {
    return [this.props.size];
  }

  getExtraClasses() {
    return [this.props.desktop ? 'desktop-only' : null];
  }

  render() {
    return <div {...this.getAttrs()} />;
  }
}

Spacer.propTypes = {
  desktop: PropTypes.bool,
};

Spacer.defaultProps = {
  desktop: false,
};
