import React from 'react';
import { Helmet } from 'react-helmet-async';
import { loadScript } from 'utils/helpers';

const BASE_URL = 'https://BUCKET.storage.googleapis.com/ID/FILE';
const DASH_URL = 'https://cdn.dashjs.org/latest/dash.all.min.js';
const HAS_MEDIA_SOURCE = 'MediaSource' in window;

export default class AdaptiveVideo extends React.Component {
  async componentDidMount() {
    // Need to initialize dash on each mount as by default
    // it will only initialize videos on page load.
    if (HAS_MEDIA_SOURCE) {
      await loadScript(DASH_URL);
      window.dashjs.MediaPlayerFactory.createAll();
    }
  }

  getUrl(id, file) {
    return BASE_URL.replace('BUCKET', 'americandream-staging-videos')
      .replace('ID', id)
      .replace('FILE', file);
  }

  render() {
    const { id, ...props } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <link
            href="https://americandream-staging-videos.storage.googleapis.com"
            rel="preconnect"
            crossOrigin
          />
        </Helmet>
        <video {...props} data-dashjs-player>
          <source
            src={this.getUrl(id, 'manifest.mpd')}
            type="application/dash+xml"
          />
          <source
            src={this.getUrl(id, 'master.m3u8')}
            type="application/x-mpegurl"
          />
        </video>
      </React.Fragment>
    );
  }
}
