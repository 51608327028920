import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import { RideClosuresModal } from './RideClosuresModal';
import { hasRidesUnderMaintenance } from './utils';

import './ride-closures.less';

function RideClosures({ rides }) {
  const classNames = useClassName('RideClosures');
  const [isOpen, setIsOpen] = useState(false);

  if (!hasRidesUnderMaintenance(rides)) {
    return null;
  }

  return (
    <>
      <RideClosuresModal
        ridesCards={rides}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <span className={classNames('link')} onClick={() => setIsOpen(true)}>
        View Ride Maintenance Schedule
      </span>
    </>
  );
}

RideClosures.propTypes = {
  rides: PropTypes.array,
};

export default RideClosures;
