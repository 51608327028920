// https://segmentio.github.io/consent-manager/?path=/story/ccpa-gdpr-example--basic

export const DEFAULT_PREFERENCES = {
  advertising: true,
  marketingAndAnalytics: true,
  functional: true,
};

export const CCPA_PREFERENCES = {
  advertising: true,
  functional: true,
  marketingAndAnalytics: true,
};

export const GDPR_PREFERENCES = {
  advertising: true,
  functional: true,
  marketingAndAnalytics: true,
};

export const DESTINATION_GROUPS = {
  Intercom: 'functional',
  'Facebook Pixel': 'advertising',
  'Google Tag Manager': 'advertising',
  Braze: 'marketingAndAnalytics',
  'Google Analytics': 'marketingAndAnalytics',
  'Visual Tagger': 'marketingAndAnalytics',
  Mixpanel: 'marketingAndAnalytics',
  Quantcast: 'marketingAndAnalytics',
};
