import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  getContentfulField as getField,
  MobileOnly,
  TabletOnly,
  DesktopOnlyFix as DesktopOnly,
} from 'common/components';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { RideCard } from '../RideCard';
import { Carousel } from '../../Carousel';

import './other-rides.less';

const DEFAULT_TITLE = 'Enjoy more adventures';

const trackingLabel = window.location.pathname;
const trackingCategory = trackingCategories.SLIDE_INFO;

const track = (name) => {
  standardizedTrack(
    'click',
    'button',
    'other slides section',
    name,
    trackingCategory,
    trackingLabel
  );
};

const ridesList = (rides, rideSlug) => {
  if (!rides?.length) return null;

  return rides.map((ride, i) => {
    if (ride.slug !== rideSlug)
      return <RideCard key={`ride-card-${i}`} ride={ride} />;
  });
};

const OtherRides = ({ id, venueName, rideSlug, rides, theme }) => {
  const className = useClassName('OtherRides');
  const name = venueName && getField(venueName);
  const title = name ? `${DEFAULT_TITLE} at ${name}` : DEFAULT_TITLE;

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: theme.primaryColor }}>
      <div className={className('wrapper')}>
        <div className={className('title')}>{title}</div>

        <MobileOnly className={className('cards')}>
          {ridesList(rides, rideSlug)}
          <div className={className('last-spacer')} />
        </MobileOnly>

        <TabletOnly className={className('cards')}>
          {ridesList(rides, rideSlug)}
          <div className={className('last-spacer')} />
        </TabletOnly>

        <DesktopOnly>
          <Carousel
            onPrevious={() => track('previous')}
            onNext={() => track('next')}
            type={'multi-card'}>
            {ridesList(rides, rideSlug)}
          </Carousel>
        </DesktopOnly>
      </div>
    </div>
  );
};

OtherRides.propTypes = {
  id: PropTypes.string.isRequired,
  venueName: PropTypes.object.isRequired,
  rideSlug: PropTypes.string.isRequired,
  rides: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.object.isRequired,
};

export default OtherRides;
