import React from 'react';
import PropTypes from 'prop-types';
import { SemanticElement } from 'common/helpers';
import {
  formatDate,
  getWeekdaysShort,
  getWeekdaysLong,
  getMonthsLong,
} from 'utils/l10n';
import { parseApiDate } from 'utils/api';
import { LazyDayPickerInput } from 'common/lazy';

import './date-picker.less';

export default class DatePicker extends SemanticElement {
  static className = 'date-picker';

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  onChange = (evt) => {
    this.props.onDayChange(parseApiDate(evt.target.value));
  };

  onIconClick = () => {
    this.ref.current.focus();
  };

  getSize() {
    return formatDate(this.props.date).length + 1;
  }

  render() {
    const { icon, date, onDayChange, startDate, endDate, ...props } =
      this.getProps();
    return (
      <div {...props}>
        {this.renderDatePicker(date, onDayChange, startDate, endDate)}
        <div onClick={this.onIconClick}>{icon}</div>
      </div>
    );
  }

  renderDatePicker(date, onDayChange, startDate, endDate) {
    let disabledDays = {};
    let dates = [];
    const { unavailabledates } = this.props;

    if (unavailabledates?.length) {
      dates = unavailabledates.map((obj) =>
        parseApiDate(obj.unavailableDate.split('T')[0])
      );
    }

    if (startDate) {
      disabledDays.before = startDate;
    }

    if (endDate) {
      disabledDays.after = endDate;
    }
    dates.push(disabledDays);

    return (
      <LazyDayPickerInput
        value={date || ''}
        inputProps={{
          size: this.getSize(),
          ref: this.ref,
          readOnly: true,
        }}
        dayPickerProps={{
          months: getMonthsLong(),
          weekdaysLong: getWeekdaysLong(true),
          weekdaysShort: getWeekdaysShort(true),
          modifiers: {
            highlighted: date || '',
            disabled: dates,
          },
        }}
        formatDate={(date) => (date ? formatDate(date) : '')}
        onDayChange={onDayChange}
        placeholder="Select"
        style={{ width: '100%' }}
      />
    );
  }
}

DatePicker.propTypes = {
  icon: PropTypes.element,
  fluid: PropTypes.bool,
  onDayChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  unavailabledates: PropTypes.arrayOf(PropTypes.object),
};

DatePicker.defaultProps = {
  fluid: false,
  date: undefined,
  startDate: null,
  endDate: null,
  unavailabledates: [],
};
