import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './ride-hero-subtitle.less';

const RideHeroSubtitle = ({ color, text }) => {
  const className = useClassName('RideHeroSubtitle');

  return (
    <div className={className('container')}>
      <ContentfulRichText
        className={className('text')}
        style={{ color }}
        field={text}
      />
    </div>
  );
};

RideHeroSubtitle.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
};

export default RideHeroSubtitle;
