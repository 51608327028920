import React from 'react';
import { Modal as SemanticModal } from 'common/lazy';
import { SVGIcon as Icon } from 'common/components';
import { Component } from 'common/helpers';

import './modal.less';

export default class Modal extends Component {
  static Header = SemanticModal.Header;
  static Content = SemanticModal.Content;
  static Actions = SemanticModal.Actions;

  render() {
    const { onCloseClick, ...rest } = this.props;
    return (
      <SemanticModal
        {...rest}
        onClose={onCloseClick}
        closeOnEscape={true}
        closeOnDimmerClick={true}>
        <div onClick={onCloseClick} className={this.getElementClass('close')}>
          <span>Close</span>
          <Icon name="close" size="tiny" />
        </div>
        {this.props.children}
      </SemanticModal>
    );
  }
}
