import { useEffect, useState } from 'react';
import { lazily } from 'react-lazily';
const { DateRangePicker } = lazily(() => import('react-date-range'));

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export default function useDateRangePicker() {
  const [modules, setModules] = useState({});

  useEffect(() => {
    import('react-date-range').then(
      ({ defaultInputRanges, createStaticRanges }) => {
        setModules({ defaultInputRanges, createStaticRanges });
      }
    );
  }, []);

  return {
    defaultInputRanges: modules.defaultInputRanges,
    createStaticRanges: modules.createStaticRanges,
    DateRangePicker,
  };
}
