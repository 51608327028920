import React, { useRef, useState, useEffect } from 'react';
import { Loader } from 'common/lazy';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { CustomMessage } from '../CustomMessage';
import { SVGIcon as Icon } from 'common/components';
import { ModalCalendar } from '../ModalCalendar';
import './horizontal-date-picker.less';

const getInitialDate = (startDate) => {
  const today = DateTime.local().startOf('day');
  if (!startDate) return today;

  const initialDate = DateTime.fromISO(startDate);
  if (!initialDate.isValid) return today;
  if (initialDate < today) return today;

  return initialDate;
};

const HorizontalDatePicker = ({
  startDate,
  days,
  selectedDate,
  footerMessages,
  hiddenControls,
  onDateSelected,
  isDateUnavailable,
  isTicketsCalendar,
  priceTypes,
  onDateSelectedError,
  selectOnLoad,
}) => {
  const className = useClassName('HorizontalDatePicker');
  const datesContainerRef = useRef(null);
  const initialDate = getInitialDate(startDate);
  const [open, setOpen] = useState(false);
  const [loadingDate, setLoadingDate] = useState(null);
  const [datesRange, setDatesRange] = useState([]);

  useEffect(() => {
    const dates = Array.from({ length: days }, (v, k) =>
      initialDate.plus({ days: k }).startOf('day')
    );
    setDatesRange(dates);

    if (startDate && selectOnLoad) {
      selectDate(initialDate.toISODate());
    }
  }, []);

  const toISOString = (date) => date.toFormat('yyyy-MM-dd');

  const isSelected = (date) => toISOString(date) === selectedDate;

  const selectDate = (date) => {
    if (isDateUnavailable(date)) return;

    if (onDateSelected.constructor.name === 'AsyncFunction') {
      setLoadingDate(date);
      onDateSelected(date)
        .catch(() => {
          onDateSelectedError(date);
        })
        .finally(() => setLoadingDate(null));
    } else {
      onDateSelected(date);
    }
  };

  const updateSelectedDate = (calendarSelectedDate) => {
    setOpen(false);

    if (!calendarSelectedDate) return;

    const newDate = DateTime.fromJSDate(calendarSelectedDate);
    setDatesRange(
      Array.from({ length: days }, (v, k) => newDate.plus({ days: k }))
    );

    selectDate(toISOString(newDate));
  };

  const closeModal = () => setOpen(false);

  const findPriceType = (date) => {
    const priceType = priceTypes.find((priceType) => date === priceType.date);
    if (!priceType) return ['regular-date'];
    return ['price-type', priceType.type];
  };

  const renderDates = () => {
    return (
      <div ref={datesContainerRef} className={className('dates-container')}>
        {datesRange.map((date) => (
          <div key={date.toISO()} className={className('day-container')}>
            {loadingDate === toISOString(date) ? (
              <div
                className={className([
                  'day',
                  ...findPriceType(date.toISODate()),
                ])}>
                <Loader active inline size="tiny" />
              </div>
            ) : (
              <div
                className={`
                    ${className('day')} ${
                  isSelected(date) ? 'selected' : ''
                }                     
                    ${
                      isDateUnavailable(toISOString(date))
                        ? className('unavailable')
                        : className(findPriceType(date.toISODate()))
                    }`}
                onClick={() => selectDate(toISOString(date))}>
                <p className="week-day">{date.toFormat('EEE')}</p>
                <p className="day-number">{date.toFormat('dd')}</p>
                <p className="month">{date.toFormat('MMM')}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const isPrevDisabled = () => {
    if (!datesRange.length) return true;
    const today = DateTime.local().startOf('day');
    if (!today.diff(datesRange[0], 'days').days) return true;
    return false;
  };

  const renderPrev = () => {
    return (
      <div
        className={className(['prev', isPrevDisabled() ? 'prev-disabled' : ''])}
        onClick={() => {
          if (isPrevDisabled()) return;
          setDatesRange(
            [datesRange[0].plus({ days: -1 }), ...datesRange].slice(0, days)
          );
        }}>
        <Icon
          name={`chevron-left-small${isPrevDisabled() ? '-disabled' : ''}`}
        />
      </div>
    );
  };

  const renderNext = () => {
    return (
      <div
        className={className('next')}
        onClick={() =>
          setDatesRange(
            [
              ...datesRange,
              datesRange[datesRange.length - 1].plus({ days: 1 }),
            ].slice(1)
          )
        }>
        <Icon name="chevron-right-small" />
      </div>
    );
  };

  return (
    <div className={className('container')}>
      <div className={className('header')}>
        <h2>SELECT A DATE</h2>
        <p className="link" onClick={() => setOpen(true)}>
          Open calendar
        </p>
        <ModalCalendar
          isDateUnavailable={isDateUnavailable}
          initialDate={selectedDate}
          open={open}
          updateSelectedDate={updateSelectedDate}
          closeModal={closeModal}
          priceTypes={priceTypes}
          isTicketsCalendar={isTicketsCalendar}
        />
      </div>
      <div className={className('body')}>
        {!hiddenControls && renderPrev()}
        {renderDates()}
        {!hiddenControls && renderNext()}
      </div>
      {footerMessages && (
        <div className={className('footer')}>
          {footerMessages.map((message, index) => (
            <CustomMessage key={index}>{message}</CustomMessage>
          ))}
        </div>
      )}
    </div>
  );
};

HorizontalDatePicker.propTypes = {
  startDate: PropTypes.string,
  days: PropTypes.number,
  selectedDate: PropTypes.string,
  footerMessages: PropTypes.array,
  hiddenControls: PropTypes.bool,
  priceTypes: PropTypes.array,
  isTicketsCalendar: PropTypes.bool,
  onDateSelected: PropTypes.func.isRequired,
  isDateUnavailable: PropTypes.func,
  onDateSelectedError: PropTypes.func,
  selectOnLoad: PropTypes.bool,
};

HorizontalDatePicker.defaultProps = {
  days: 7,
  hiddenControls: false,
  priceTypes: [],
  isTicketsCalendar: false,
  isDateUnavailable: () => false,
  onDateSelectedError: () => {},
  selectOnLoad: false,
};

export default HorizontalDatePicker;
