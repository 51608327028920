import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMobile: windowSize.width < 768,
    isTablet: windowSize.width >= 768 && windowSize.width < 1128,
    isDesktop: windowSize.width >= 1128,
  };
};

export const WindowSize = ({ children }) => {
  const windowSize = useWindowSize();

  return children(windowSize);
};

export const Only = ({ children, mobile, tablet, desktop }) => {
  const { isMobile, isTablet, isDesktop } = useWindowSize();
  if (isMobile && mobile) return children;
  if (isTablet && tablet) return children;
  if (isDesktop && desktop) return children;

  return null;
};

export default useWindowSize;
