import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { useClassName } from 'common/hooks';

import './upcoming-bookings.less';

const UpcomingBookings = ({ handleLinkClick, me }) => {
  const className = useClassName('UpcomingBookings');
  const [upcomingOrders, setUpcomingOrders] = useState(me.upcomingOrders);

  useEffect(() => {
    const getUpcomingOrders = async () => {
      if (!me?.upcomingOrders) {
        const orders = await me.fetchOrders(true);
        setUpcomingOrders(orders);
      }
    };
    getUpcomingOrders();
  }, []);

  const handleBookingsClick = () =>
    handleLinkClick('/profile', 'Nav My bookings clicked');

  if (!upcomingOrders?.length) return null;

  return (
    <div className={className('container')}>
      <div className={className('content')} onClick={handleBookingsClick}>
        <div className={className('title')}>MY BOOKINGS</div>
        <div className={className('count')}>{upcomingOrders.length}</div>
      </div>
    </div>
  );
};

export default inject('me')(UpcomingBookings);
