import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ExternalOperatorsStore extends BaseStore {
  @action
  getAllOperators(statusKey = `getAllOperators`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/externaloperators`,
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          error: err.message,
        };
      });
  }

  @action
  searchByName(name, statusKey = 'searchByName') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/externaloperators/search-by-name?name=${name}`,
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          error: err.message,
        };
      });
  }
}
