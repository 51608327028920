import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImageLight, ContentfulRichText } from 'common/components';
import { useClassName } from 'common/hooks';
import { AssetPropType } from 'public/schema';
import './landing-title-section.less';

const LandingTitleSection = ({
  id,
  titleFirstPart,
  titleSecondPart,
  type,
  image,
}) => {
  if (!titleFirstPart && !titleSecondPart && !image) return null;

  const className = useClassName('LandingTitleSection');

  const titleContainerStyle =
    type === 'subtitle' ? 'subtitle-container' : 'title-container';
  const titleStyle = type === 'subtitle' ? 'subtitle-text' : 'title-text';

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        {(titleFirstPart || titleSecondPart) && (
          <div className={className(titleContainerStyle)}>
            {titleFirstPart && (
              <ContentfulRichText
                className={className(titleStyle)}
                field={titleFirstPart}
              />
            )}
            {titleSecondPart && (
              <ContentfulRichText
                className={className(titleStyle)}
                field={titleSecondPart}
              />
            )}
          </div>
        )}
      </div>
      {image?.assetUrl && (
        <div className={className('image-container')}>
          <ResponsiveImageLight
            src={image?.assetUrl}
            alt={image?.assetAlt}
            fluid
          />
        </div>
      )}
    </div>
  );
};

LandingTitleSection.propTypes = {
  id: PropTypes.string.isRequired,
  titleFirstPart: PropTypes.object,
  titleSecondPart: PropTypes.object,
  type: PropTypes.string,
  image: PropTypes.shape(AssetPropType),
};

LandingTitleSection.defaultProps = {
  titleFirstPart: null,
  titleSecondPart: null,
  image: null,
  type: 'main',
};

export default LandingTitleSection;
