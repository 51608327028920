import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  useClassName,
  LazyVisible,
  usePreload,
  preloadAssets,
  useWindowSize,
} from 'common/hooks';

import ParksGallery from '../ParksGallery';
import HomepageButton from '../../Components/Button';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import HomepageAsset from '../../Components/Asset';
import HomepageImage from '../../Components/Image';
import useSlider from '../../Components/useSlider';
import SliderBullets from '../../Components/SliderBullets';
import Center from '../../Components/Center';
import useLoop from '../../Components/useLoop';

import 'swiper/swiper.less';
import './parks.less';

function Figure({ figure, position }) {
  const classNames = useClassName('ParksSection');

  if (!figure?.fields) return null;

  return (
    <HomepageImage
      field={figure}
      className={`${classNames('figure')} ${classNames(
        `figure-${position === 'left' ? 1 : 2}`
      )}`}
    />
  );
}

function Pagination({ parks, activeIndex, slideTo }) {
  const classNames = useClassName('ParksSection');

  return (
    <div className={classNames('bullets-container')}>
      {parks.map((park, index) => (
        <div
          key={park.sys.id}
          className={classNames([
            'bullet',
            index === activeIndex && 'bullet-active',
          ])}
          style={{
            '--primary-color': park.fields.primaryColor,
            '--secondary-color': park.fields.secondaryColor,
          }}
          onClick={() => slideTo(index)}>
          <HomepageImage field={park.fields.icon} />
        </div>
      ))}
    </div>
  );
}

function Desktop({ park }) {
  const classNames = useClassName('ParksSection');
  return (
    <div className={classNames('desktop-slide')}>
      <HomepageAsset asset={park.fields.background} />
      <div>
        <span>{park.fields.description}</span>
        <div>
          {park.fields.ctas.map((cta) => {
            return (
              <HomepageButton
                key={cta.sys.id}
                cta={cta}
                className={classNames('cta')}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Mobile({ park }) {
  const classNames = useClassName('ParksSection');
  return (
    <div
      className={classNames('slide')}
      style={{
        background: `linear-gradient(180deg, ${park.fields.primaryColor} 0%, ${park.fields.secondaryColor}  100%)`,
      }}>
      <Figure figure={park.fields?.figures?.[0]} position="left" />
      <div className={classNames('content')}>
        <span>{park.fields.description}</span>
        <div>
          {park.fields.ctas.map((cta) => {
            return (
              <HomepageButton
                key={cta.sys.id}
                cta={cta}
                className={classNames('cta')}
              />
            );
          })}
        </div>
      </div>
      <Figure figure={park.fields?.figures?.[0]} position="right" />
    </div>
  );
}

export default function ParksSection({ section }) {
  const classNames = useClassName('ParksSection');
  const ref = useRef();
  const [props, methods, state] = useSlider(1);
  const { isDesktop, isMobile, isTablet } = useWindowSize();
  usePreload(() => {
    preloadAssets([
      ...section.fields.parks.map(({ fields }) => fields.icon),
      ...section.fields.parks.map(({ fields }) => fields.figures).flat(),
    ]);
  }, ref);
  const parks = section.fields.parks.filter(({ fields }) => fields.isLarge);
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        spaceBetween: 50,
        slidesPerView: 1,
      },
    },
    slidesLength: parks.length,
  });

  return (
    <div
      id={section.fields.sectionId}
      style={{
        ...(isDesktop && {
          background: `linear-gradient(180deg, #FFF 0%, ${
            parks[state.activeIndex].fields.secondaryColor
          }4d 20%)`,
        }),
      }}>
      <div className={classNames('header')}>
        <h1>{section.fields.title}</h1>
        <h2>{section.fields.subtitle}</h2>
      </div>
      <LazyVisible
        ref={ref}
        onLoad={() =>
          trackHomepageSection(() => ({
            id: section.fields.sectionId,
          }))
        }>
        <Center>
          <SliderBullets
            activeIndex={state.activeIndex}
            length={parks.length}
            slideTo={methods.slideTo}
            className={classNames('regular-bullets')}
            style={{
              background: parks[state.activeIndex].fields.secondaryColor,
            }}
            bulletColor="white"
          />
        </Center>
        <Pagination
          parks={parks}
          activeIndex={state.activeIndex}
          slideTo={methods.slideTo}
        />
        <Swiper
          {...props}
          {...loop}
          height={542}
          centeredSlides
          className={classNames('wrapper')}
          onSlideChange={({ realIndex }) =>
            trackHomepageSlide(() => ({
              activeIndex: realIndex,
              name: section.fields.parks[realIndex].fields.name,
              section: section.fields.sectionId,
              url: section.fields.parks[realIndex].fields.ctas[0].fields.url,
            }))
          }>
          {parks.map((park) => (
            <SwiperSlide key={park.sys.id}>
              {() => {
                if (isDesktop) {
                  return <Desktop park={park} />;
                }

                if (isMobile || isTablet) {
                  return <Mobile park={park} />;
                }
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </LazyVisible>
      <ParksGallery section={section} />
    </div>
  );
}
