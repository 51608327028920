import React from 'react';

import { useClassName } from 'common/hooks';

import './carousel-controls.less';

export default function CarouselControls({ children }) {
  const classNames = useClassName('CLPCarouselControls');

  return <div className={classNames('container')}>{children}</div>;
}
