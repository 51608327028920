import React from 'react';
import { Component } from 'common/helpers';
import {
  ContentLink,
  ResponsiveImage,
  ContentfulImage,
} from 'common/components';
import { Spacer } from '../Spacer';

import './horizontal.less';

export default class HorizontalCard extends Component {
  getModifiers() {
    return [this.props.hover ? 'hover' : null];
  }

  render() {
    const { href, title, body, backdrop } = this.props;
    return (
      <div {...this.getAttrs()}>
        <ContentLink href={href} className={this.getElementClass('link')}>
          <div
            className={this.getElementClass(
              'image-container',
              backdrop ? 'with-backdrop' : null
            )}>
            {this.renderImage()}
            {this.renderBackdrop()}
          </div>
          <Spacer size="xs" />
          <div className={this.getElementClass('title')}>{title}</div>
          <div className={this.getElementClass('body')}>{body}</div>
        </ContentLink>
      </div>
    );
  }

  renderImage() {
    const { image, contentfulImage } = this.props;
    if (contentfulImage) {
      return (
        <ContentfulImage
          className={this.getElementClass('image')}
          field={contentfulImage}
          fluid
        />
      );
    } else {
      return (
        <ResponsiveImage
          className={this.getElementClass('image')}
          src={image}
          fluid
        />
      );
    }
  }

  renderBackdrop() {
    const { backdrop } = this.props;
    if (backdrop) {
      return (
        <div
          style={{ backgroundColor: backdrop }}
          className={this.getElementClass('image-backdrop')}
        />
      );
    }
  }
}
