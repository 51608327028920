import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('feature')
@observer
export default class Feature extends React.Component {
  render() {
    const { feature, name, children } = this.props;
    return feature.has(name) ? children : null;
  }
}
