import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Form, Button, Message, Dimmer, Loader, Input } from 'common/lazy';
import { isValidEmail } from 'utils/helpers';
import { Spacer } from '../Spacer';

@inject('auth')
@observer
export default class RequestPasswordForm extends React.Component {
  state = {
    email: '',
    emailValid: true,
    loading: false,
    touched: false,
    error: null,
  };

  static defaultProps = {
    onSuccess: () => {},
    onFail: () => {},
  };

  static propTypes = {
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
  };

  onSubmit = async () => {
    if (!this.validate()) {
      this.setState({
        touched: true,
        error: null,
      });
      return;
    }

    this.setState({
      loading: true,
      error: null,
    });

    try {
      const { email } = this.state;
      await this.props.auth.requestPassword({ email });
      this.setState({
        loading: false,
        success: true,
      });
      this.props.onSuccess();
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
      this.props.onFail();
    }
  };

  setField = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  validate = () => {
    const emailValid = isValidEmail(this.state.email);
    this.setState({
      emailValid,
    });
    return emailValid;
  };

  render() {
    if (this.state.loading) {
      return (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      );
    }

    if (this.state.success) {
      return (
        <Message>
          <Message.Header>Mail sent!</Message.Header>
          <p>
            Please follow the instructions in the email we sent to{' '}
            <b>{this.state.email}</b>
          </p>
        </Message>
      );
    }

    const { emailValid, touched } = this.state;
    return (
      <Form
        noValidate
        error={touched && !emailValid}
        onSubmit={this.onSubmit}
        onBlur={this.validate}>
        <p>Forgot Password</p>
        <Spacer size="xs" />
        {this.renderError()}
        <Form.Field>
          <label htmlFor="email">Enter Email</label>
          <Input
            value={this.state.email}
            onChange={this.setField}
            id="email"
            name="email"
            type="email"
            error={!emailValid}
            autoComplete="email"
          />
        </Form.Field>
        <Spacer size="s" />
        <Button fluid primary size="large">
          Request Password
        </Button>
      </Form>
    );
  }

  renderError() {
    const { error, emailValid } = this.state;
    if (error) {
      return <Message negative>{error.message}</Message>;
    } else if (!emailValid) {
      return <Message negative>Valid email required</Message>;
    }
  }
}
