// Wrapper around semantic-ui Grid that inserts rows for each
// n children to not cause styling issues. Also will wrap all
// children with Grid.Column.

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'common/lazy';

export default class AutoGrid extends React.Component {
  render() {
    return <Grid {...this.props}>{this.renderRows()}</Grid>;
  }

  renderRows() {
    return this.groupRows().map((row, i) => {
      return (
        <Grid.Row key={i}>
          {row.map((el, j) => {
            return <Grid.Column key={j}>{el}</Grid.Column>;
          })}
        </Grid.Row>
      );
    });
  }

  groupRows() {
    const rows = [];
    const { columns } = this.props;
    const len = this.props.children.length;
    for (let i = 0; i < len; i += columns) {
      rows.push(this.props.children.slice(i, i + columns));
    }
    return rows;
  }
}

AutoGrid.propTypes = {
  columns: PropTypes.number.isRequired,
};

AutoGrid.defaultProps = {
  columns: 1,
};
