import React from 'react';
import PropTypes from 'prop-types';
import { AssetPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import { LoaderImage } from '../../Loader';
import './venue-details-hero-logo.less';

const VenueDetailsHeroLogo = ({ asset }) => {
  const className = useClassName('VenueDetailsHeroLogo');

  return (
    <div className={className('container')}>
      {asset && (
        <LoaderImage
          src={asset.assetUrl}
          alt={asset.assetAlt}
          loadingHeight={50}
          fluid
          hideLoader
        />
      )}
    </div>
  );
};

VenueDetailsHeroLogo.propTypes = {
  asset: PropTypes.shape(AssetPropType).isRequired,
};

export default VenueDetailsHeroLogo;
