import React from 'react';
import { Component } from 'common/helpers';

import './form-actions.less';

export default class FormActions extends Component {
  render() {
    return <div {...this.getAttrs()}>{this.props.children}</div>;
  }
}
