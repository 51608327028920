import React from 'react';
import propTypes from 'prop-types';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';

import './open-hours.less';

const OpenHours = ({ handleLinkClick }) => {
  const className = useClassName('OpenHours');

  const handleHoursClicked = () =>
    handleLinkClick('/hours-and-directions', 'View hours clicked', {
      label: 'View hours',
    });

  return (
    <div className={className('container')} onClick={handleHoursClicked}>
      <Icon name="clock-nine" className={className('clock')} />
      Directions & Open Hours
    </div>
  );
};

OpenHours.propTypes = {
  handleLinkClick: propTypes.func.isRequired,
};

export default OpenHours;
