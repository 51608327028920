import React, { useRef } from 'react';

import {
  LazyVisible,
  useClassName,
  Only,
  usePreload,
  preloadAssets,
  preloadCTAs,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSection from '../../Components/trackHomepageSection';

import './merchandise.less';

export default function MerchandiseSection({ section }) {
  const classNames = useClassName('MerchandiseSection');
  const ref = useRef();
  usePreload(() => {
    preloadAssets(section.fields.media);
    preloadCTAs([section.fields.cta]);
  }, ref);

  return (
    <LazyVisible
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <h3>{section.fields.header}</h3>
        <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
        <Only desktop>
          <HomepageButton
            cta={section.fields.cta}
            className={classNames('cta')}
          />
        </Only>
      </div>
      <div className={classNames('images')}>
        {section.fields.media.map((media, index) => (
          <HomepageImage
            field={media}
            key={media.sys.id}
            style={{ animationDelay: `${index * 150}ms` }}
          />
        ))}
      </div>
      <Only mobile tablet>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </LazyVisible>
  );
}
