import React from 'react';
import { HashLink } from 'react-router-hash-link';

export default class JumpLink extends React.Component {
  onClick(el, hash) {
    const prevHash = window.location.hash;
    if (hash === prevHash) el.preventDefault();
  }

  render() {
    const { hash, ...props } = this.props;
    return (
      <HashLink
        to={hash}
        {...props}
        onClick={(el) => this.onClick(el, hash)}
        smooth
      />
    );
  }
}
