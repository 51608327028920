import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  useClassName,
  LazyVisible,
  Only,
  preloadMedia,
  usePreload,
  preloadAssets,
  preloadCTAs,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import SliderBullets from '../../Components/SliderBullets';
import useLoop from '../../Components/useLoop';
import SliderControls from '../../Components/SliderControls';
import useSlider from '../../Components/useSlider';

import 'swiper/swiper.less';
import './passes.less';

export default function PassesSection({ section }) {
  const classNames = useClassName('PassesSection');
  const ref = useRef();
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1128: {
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
    slidesLength: section.fields.media.length,
  });
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadCTAs(section.fields.cta);
    preloadAssets(section.fields.icon);
  }, ref);
  const [props, methods, state] = useSlider();

  return (
    <LazyVisible
      className={classNames('container')}
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <HomepageImage field={section.fields.icon} />
      <div className={classNames('header')}>
        <h1>{section.fields.title}</h1>

        <Only desktop>
          <HomepageButton
            cta={section.fields.cta}
            className={classNames('cta')}
          />
        </Only>
      </div>
      <div className={classNames('content')}>
        {!loop.pending && (
          <Swiper
            height={542}
            {...loop}
            {...props}
            className={classNames('wrapper')}
            onSlideChange={({ realIndex }) => {
              trackHomepageSlide(() => ({
                activeIndex: realIndex,
                name: section.fields.media[realIndex].fields.name,
                section: section.fields.sectionId,
                url: section.fields.media[realIndex].fields.callToAction.fields
                  .url,
              }));
            }}>
            {section.fields.media.map(({ fields, sys }) => (
              <SwiperSlide
                key={sys.id}
                className={classNames('slide-container')}>
                <div key={sys.id} className={classNames('slide')}>
                  <HomepageImage
                    field={fields.desktop}
                    mobile={fields.mobile}
                  />
                  <div>
                    <h4>{fields.title}</h4>
                    <p>{fields.description}</p>
                    <HomepageButton
                      cta={fields.callToAction}
                      className={classNames('cta')}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      {loop.loop && (
        <>
          <SliderControls
            slidePrev={methods.slidePrev}
            slideNext={methods.slideNext}
          />
          <SliderBullets
            activeIndex={state.activeIndex}
            length={section.fields.media.length}
            slideTo={methods.slideTo}
            style={{ marginBottom: '24px' }}
          />
        </>
      )}
      <Only mobile tablet>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </LazyVisible>
  );
}
