import { useWindowSize } from './useWindowSize';
import { useEffect } from 'react';

export function preloadAssets(assets) {
  (Array.isArray(assets) ? assets : [assets])
    .map((asset) => asset?.fields?.file?.url)
    .filter(Boolean)
    .forEach((url) => {
      const image = new Image();
      image.src = url;
    });
}

export function preloadCTAs(ctas) {
  preloadAssets(
    (Array.isArray(ctas) ? ctas : [ctas])
      .map((cta) => cta?.fields?.icon)
      .filter(Boolean)
  );
}

export function preloadMedia(media, isDesktop) {
  for (const { fields } of Array.isArray(media) ? media : [media]) {
    preloadAssets(fields.icon);
    preloadCTAs(fields.callToAction);

    if (isDesktop) {
      preloadAssets(fields.desktop);
    } else {
      preloadAssets(fields.mobile);
    }
  }
}

export function usePreload(func, ref) {
  const { isDesktop } = useWindowSize();

  useEffect(() => {
    if (func && ref?.current) {
      const onScroll = () => {
        const y = ref?.current?.getBoundingClientRect()?.y;

        if (y <= window.innerHeight * 2) {
          window.removeEventListener('scroll', onScroll);
          try {
            func(isDesktop);
          } catch (ex) {
            console.warn('Failed to preload image');
          }
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [func, ref]);
}
