import { action } from 'mobx';
import { request } from 'utils/api';
import { track } from 'utils/analytics';
import { omit } from 'lodash';
import BaseStore from 'common/stores/BaseStore';
import appSession from 'common/stores/AppSession';

export default class AuthStore extends BaseStore {
  @action
  setPassword(body, statusKey = 'setPassword') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/set-password',
      body,
    })
      .then((resp) => {
        appSession.reset();
        appSession.setToken(resp.data.token);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  requestPassword(body, statusKey = 'requestPassword') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/request-password',
      body,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  login(body, statusKey) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/login',
      body: body,
    })
      .then((resp) => {
        appSession.reset();
        appSession.setToken(resp.data.token);
        track('Signed In');
        status.success();
        return resp.data.user;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  acceptInvite(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      request({
        method: 'POST',
        path: '/1/auth/accept-invite',
        body: omit(body, ['acceptTerms', 'email']),
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          status.success();
          resolve();
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }

  @action
  register(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      if (localStorage.referrer) {
        body.referrer = localStorage.referrer;
      }

      request({
        method: 'POST',
        path: '/1/auth/register',
        body,
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          track('Signed Up');
          status.success();
          resolve(resp.data.user);
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }

  @action
  google(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      request({
        method: 'POST',
        path: '/1/auth/google',
        body,
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          track('Signed with google');
          status.success();
          resolve(resp.data.user);
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }

  @action
  facebook(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      request({
        method: 'POST',
        path: '/1/auth/facebook',
        body,
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          track('Signed with facebook');
          status.success();
          resolve(resp.data.user);
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }

  @action
  apple(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      request({
        method: 'POST',
        path: '/1/auth/apple',
        body,
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          track('Signed with apple');
          status.success();
          resolve(resp.data.user);
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }

  @action
  microsoft(body, statusKey) {
    return new Promise((resolve, reject) => {
      const status = this.createStatus(statusKey);

      request({
        method: 'POST',
        path: '/1/auth/microsoft',
        body,
      })
        .then((resp) => {
          appSession.reset();
          appSession.setToken(resp.data.token);
          track('Signed with microsoft');
          status.success();
          resolve(resp.data.user);
        })
        .catch((err) => {
          status.error(err);
          reject(err);
        });
    });
  }
}
