import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  LazyVisible,
  useClassName,
  Only,
  preloadCTAs,
  preloadMedia,
  usePreload,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import HomepageAsset from '../../Components/Asset';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import useLoop from '../../Components/useLoop';
import useSlider from '../../Components/useSlider';
import SliderControls from '../../Components/SliderControls';
import Center from '../../Components/Center';
import SliderBullets from '../../Components/SliderBullets';

import 'swiper/swiper.less';
import './community.less';

const PREFIX = 'CommunitySection';

function CTAs({ section }) {
  const classNames = useClassName(PREFIX);

  return (
    <div className={classNames('ctas')}>
      {section.fields.ctas.map((cta) => (
        <HomepageButton
          key={cta.sys.id}
          cta={cta}
          className={classNames('cta')}
          fixed
        />
      ))}
    </div>
  );
}

export default function CommunitySection({ section }) {
  const classNames = useClassName(PREFIX);
  const ref = useRef();
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      1128: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
    slidesLength: section.fields.media.length,
  });
  const [props, methods, state] = useSlider();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadCTAs(section.fields.ctas);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <div>
          <h3>{section.fields.header}</h3>
        </div>
        <Only desktop>
          <CTAs section={section} />
        </Only>
      </div>
      <div style={{ position: 'relative' }}>
        <div className={classNames('content')}>
          {!loop.pending && (
            <Swiper
              height={542}
              {...loop}
              {...props}
              onSlideChange={({ realIndex }) =>
                trackHomepageSlide(() => ({
                  activeIndex: realIndex,
                  name: section.fields.media[realIndex].fields.name,
                  section: section.fields.sectionId,
                  url: section.fields.media[realIndex].fields.callToAction
                    .fields.url,
                }))
              }
              className={classNames('wrapper')}>
              {section.fields.media.map((media, index) => {
                return (
                  <SwiperSlide
                    key={media.sys.id}
                    className={classNames('slide-container')}>
                    <HomepageButton
                      cta={media.fields.callToAction}
                      className={classNames('slide')}
                      style={{ animationDelay: `${index * 150}ms` }}>
                      <HomepageAsset asset={media.fields.asset} />
                      <div>
                        <HomepageImage field={media.fields.icon} />
                      </div>
                    </HomepageButton>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
        {loop.loop && (
          <>
            <SliderControls
              slidePrev={methods.slidePrev}
              slideNext={methods.slideNext}
              theme="solid"
            />
            <Center>
              <SliderBullets
                activeIndex={state.activeIndex}
                length={section.fields.media.length}
                slideTo={methods.slideTo}
                style={{ marginTop: '24px' }}
              />
            </Center>
          </>
        )}
      </div>

      <Only mobile tablet>
        <CTAs section={section} />
      </Only>
    </LazyVisible>
  );
}
