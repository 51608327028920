import React from 'react';
import { useWindowSize } from 'common/hooks';

export default function HomepageImage({ field, mobile, style, className }) {
  const { isMobile } = useWindowSize();
  const image = isMobile && mobile ? mobile : field;

  if (!image) return null;

  return (
    <img
      src={image.fields.file.url}
      alt={image.fields.title}
      style={style}
      className={className}
    />
  );
}
