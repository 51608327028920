import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { SVGIcon as Icon } from 'common/components';
import { Only } from 'common/hooks';
import { track } from 'utils/analytics';
import { useClassName, useWindowSize } from 'common/hooks';
import logo from 'common/assets/ad-logo.svg';
import Menu from './Menu';
import CartBadge from './CartBadge';
import OpenHours from './OpenHours';
import MyProfile from './MyProfile';
import Translator from './Translator';
import { BookTickets } from '../../BookTickets';
import Search from '../../Search/Search';

import './menu-bar.less';

const MenuBar = () => {
  const history = useHistory();
  const className = useClassName('MenuBar');
  const menuBarRef = useRef(null);
  const { isMobile, width: windowWidth } = useWindowSize();

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [primaryClicked, setPrimaryClicked] = useState(null);
  const [hiddenMenu, setHiddenMenu] = useState(false);

  useEffect(() => document.addEventListener('click', handleClickOutside), []);

  const getMenuIconName = () => {
    if (expandedMenu) return 'nav-menu-close';
    if (isMobile) return 'nav-menu-mobile';
    return 'nav-menu';
  };

  const toggleMenu = () => {
    setExpandedMenu(!expandedMenu);
    setPrimaryClicked(null);
  };

  const handleClickOutside = (event) => {
    if (!menuBarRef?.current.contains(event.target)) {
      setExpandedMenu(false);
      setPrimaryClicked(null);
    }
  };

  const handleLinkClick = (link, event, attributes = null) => {
    track(event, attributes);
    /* 
      Next lines improves the UX by redirecting without loosing the state.
      It also prevents the menu hide effect to look non-asthetic.
    */
    if (expandedMenu) {
      setHiddenMenu(true);
      toggleMenu();
      setTimeout(() => setHiddenMenu(false), 500);
    }
    const isExternal = link.includes('http');
    if (isExternal) window.open(link, '_blank');
    else history.push(link);
  };

  const renderLeftPanel = () => (
    <div className={className('left-container')}>
      {
        <div className={className('icons-container')}>
          <Icon
            name={getMenuIconName()}
            className={className([
              'icon',
              'menu-icon',
              expandedMenu && 'icon-close',
            ])}
            onClick={toggleMenu}
          />
          <Only desktop>
            <div className={className('desktop-icons')}>
              <div className={className('icon-container')} key="cart">
                <CartBadge handleLinkClick={handleLinkClick} />
              </div>
              <div className={className('icon-container')} key="profile">
                <MyProfile handleLinkClick={handleLinkClick} />
              </div>
              <Search />
            </div>
          </Only>
        </div>
      }
    </div>
  );

  const renderLogo = () => (
    <div className={className('logo-container')}>
      <Link to="/" onClick={() => track('AmericanDream Logo Pressed')}>
        <img src={logo} alt="logo" />
      </Link>
    </div>
  );

  const renderRightPanel = () => (
    <div className={className('right-container')}>
      <Only desktop>
        <div className={className('desktop-icons')}>
          <OpenHours handleLinkClick={handleLinkClick} />
          {Boolean(windowWidth && !isMobile) && <Translator />}
          <BookTickets />
        </div>
      </Only>
      <Only mobile tablet>
        <div className={className('mobile-icons')}>
          <Search />
        </div>
      </Only>
    </div>
  );

  return (
    <div className={className('container')} ref={menuBarRef}>
      <div className={className('header')}>
        {renderLeftPanel()}
        {renderLogo()}
        {renderRightPanel()}
      </div>
      {!hiddenMenu && (
        <Menu
          expanded={expandedMenu}
          primaryClicked={primaryClicked}
          setPrimaryClicked={setPrimaryClicked}
          handleLinkClick={handleLinkClick}
        />
      )}
    </div>
  );
};

export default MenuBar;
