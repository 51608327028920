import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { VideoPlayer } from 'common/components';
import { LoaderImage } from '../../Loader';
import './venue-details-hero-asset.less';

const VenueDetailsHeroAsset = ({ slug, videoUrl, posterUrl }) => {
  const className = useClassName('VenueDetailsHeroAsset');
  return (
    <div className={className('container')}>
      {videoUrl && (
        <VideoPlayer
          id={`hero-video-${slug}`}
          name={`Hero Video ${slug}`}
          videoUrl={videoUrl}
          posterUrl={posterUrl}
          controls={false}
        />
      )}
      {posterUrl && !videoUrl && (
        <div className={className('image')}>
          <LoaderImage
            src={posterUrl}
            alt={`hero-image-${slug}`}
            hideLoader
            fluid
          />
        </div>
      )}
    </div>
  );
};

VenueDetailsHeroAsset.propTypes = {
  slug: PropTypes.string,
  videoUrl: PropTypes.string,
  posterUrl: PropTypes.string,
};

VenueDetailsHeroAsset.defaultProps = {
  slug: null,
  videoUrl: '',
  posterUrl: '',
};

export default VenueDetailsHeroAsset;
