import React from 'react';
import { LazyCSSTransition } from 'common/lazy';
import { Component } from 'common/helpers';

import './subnav-panel.less';

export default class SubNavPanel extends Component {
  render() {
    const className = this.getComponentClass();
    return (
      <LazyCSSTransition in={true} timeout={1000} classNames={className} appear>
        <div className={className}>{this.props.children}</div>
      </LazyCSSTransition>
    );
  }
}
