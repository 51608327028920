import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { parseRides } from 'public/helpers/contentful/parser';
import { RideHero } from './RideHero';
import { RideInfoPanel } from './RideInfoPanel';
import { RideGallery } from './RideGallery';
import { RideRequirements } from './RideRequirements';
import { OtherRides } from './OtherRides';
import './ride-screen.less';

const RideScreen = ({ venue, rideSlug }) => {
  const className = useClassName('RideScreen');
  const { ridesCards, theme } = parseRides(venue.content.attractionScreen);

  const ride = ridesCards.find((ride) => ride?.slug === rideSlug);

  if (!ride) return null;

  const {
    title,
    slug,
    fullDescription,
    heroVideo,
    heroPoster,
    tags,
    height,
    galleryTitle,
    gallery,
    rideInfoTitle,
    rideInfoDescription,
  } = ride;

  return (
    <div className={className('container')}>
      <RideHero
        id={'hero'}
        venue={venue}
        heroTitle={title}
        heroSubtitle={fullDescription}
        heroVideo={heroVideo}
        heroPoster={heroPoster}
        theme={theme}
      />
      <RideInfoPanel
        id={'info-panel'}
        theme={theme}
        tags={tags}
        height={height}
      />
      {gallery?.length && (
        <RideGallery
          id={'gallery'}
          galleryTitle={galleryTitle}
          gallery={gallery}
        />
      )}
      <RideRequirements
        id={'requirements'}
        title={rideInfoTitle}
        description={rideInfoDescription}
        theme={theme}
      />
      <OtherRides
        id={'other-rides'}
        rideSlug={slug}
        rides={ridesCards}
        theme={theme}
        venueName={venue?.content?.name}
      />
    </div>
  );
};

RideScreen.propTypes = {
  venue: PropTypes.shape({
    content: PropTypes.shape({
      attractionScreen: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  rideSlug: PropTypes.string.isRequired,
};

export default RideScreen;
