import React from 'react';
import { Component } from 'common/helpers';
import {
  ContentfulRichText,
  ContentfulImage,
  getContentfulAssetUrlandAlt,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import chevron from 'common/assets/chevron.svg';

import './responsiveCard.less';

export default class ResponsiveCard extends Component {
  render() {
    const { title, description, image, backgroundImage, type, children, open } =
      this.props;

    let chevronStyle = 'chevron';
    if (open) {
      chevronStyle = 'chevron-open';
    }

    if (type === 'big') {
      let backgroundAssetUrl;
      let cardStyle = 'card-big-transparent';
      let titleStyle = 'title-big';

      if (backgroundImage) {
        const backgroundAsset = getContentfulAssetUrlandAlt(backgroundImage);
        backgroundAssetUrl = backgroundAsset.assetUrl;
        cardStyle = 'card-big';
      }
      if (image) {
        titleStyle = 'title-big-short';
      }

      return (
        <div {...this.getAttrs()}>
          <div
            className={this.getElementClass(cardStyle)}
            style={{ backgroundImage: `url(${backgroundAssetUrl})` }}>
            {title && (
              <div className={this.getElementClass(titleStyle)}>
                <ContentfulRichText field={title} />
                <img
                  src={chevron}
                  className={this.getElementClass(chevronStyle)}
                />
              </div>
            )}
            {image && (
              <div className={this.getElementClass('image-big')}>
                <MobileOnly>
                  <ContentfulImage field={image} width={90} />
                </MobileOnly>
                <DesktopOnly>
                  <ContentfulImage field={image} width={200} />
                </DesktopOnly>
              </div>
            )}
            {description && (
              <div className={this.getElementClass('description-big')}>
                <ContentfulRichText field={description} />
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      );
    } else {
      return (
        <div {...this.getAttrs()}>
          <div className={this.getElementClass(`card-${type}`)}>
            <div className={this.getElementClass(`image-${type}`)}>
              <ContentfulImage field={image} width={80} />
            </div>
            {title && (
              <div className={this.getElementClass(`title-${type}`)}>
                <ContentfulRichText field={title} />
              </div>
            )}
            {description && (
              <div className={this.getElementClass(`description-${type}`)}>
                <ContentfulRichText field={description} />
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      );
    }
  }
}
