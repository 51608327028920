import { useRef, useState, useCallback, useEffect } from 'react';

function getNavbarHeight() {
  return [
    '.navigation-header__menu-container',
    '.navigation-header__banner-container',
  ].reduce(
    (acc, cur) =>
      acc +
      (document?.querySelector(cur)?.getBoundingClientRect()?.height || 0),
    0
  );
}

export default function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollY = useRef(0);

  const updateScrollDirection = useCallback(() => {
    const scrollY = window.scrollY - getNavbarHeight();
    if (Math.abs(lastScrollY.current - scrollY) > 1) {
      const direction = scrollY > lastScrollY.current ? 'down' : 'up';
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
    }

    lastScrollY.current = scrollY > 0 ? scrollY : 0;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updateScrollDirection);

    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [updateScrollDirection]);

  return scrollDirection;
}
