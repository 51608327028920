import { flatten, sumBy, round, uniq, compact, groupBy } from 'lodash';
import structuredClone from '@ungap/structured-clone';

import { track, trackBingPixel } from 'utils/analytics';
import {
  getItemTax,
  getItemPrice,
  getItemBulkDiscount,
} from './ticketInventory';

// Definitions:
// ------------
//
// Products:
//
// product_id: The external ID of the Snowcloud ticket option, exposed as "bookingItemId".
// sku: The external ID of the Snowcloud inventory item, exposed as "externalId" or "bookingItemInventoryId"..
// name: Snowcloud product name.
// price: Snowcloud price in USD rounded to 2 decimal places.
// variant: The item date as dictated by Snowcloud.
// category: The venue id.
//
// Order/Checkout:
//
// order_id: The Snowcloud bookingId.
// affiliation: "AD Web"
//
// tax: Total tax associated with transaction with discounts applied.
// discount: Total discounts associated with transaction without tax applied.
// revenue: Total without discounts or tax applied.
//
// value: Total with tax and discounts applied (Checkout Started only).
//
// subtotal: Total with discounts applied but not tax (Order Completed only).
// total: Total with discounts and tax applied (Order Completed only).
//
// coupon: Coupons applied. If a group discount is applied this will be
//         "Group Discount:" and the bulkType of the discount.
// shipping: 0.
// currency: "USD".
//

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-viewed

const getEcommerceTicketData = (
  ticket,
  venue = null,
  isProductView = false
) => ({
  currency: 'USD',
  value: ticket?.price ? toDollars(ticket.price) : 0,
  items: [
    {
      item_id: ticket?.ticketOptionId,
      item_name: ticket?.name,
      price: ticket?.price ? toDollars(ticket.price) : 0,
      quantity: isProductView ? 1 : ticket?.quantity,
      item_variant: ticket?.startTimeName || '',
      item_category: ticket?.venueId || venue?.id,
      affiliation: 'AD Web',
    },
  ],
});

const getEcomerceCartItemsData = (cartItems) => {
  const { products, total, coupon } = getPropsForCartItems(cartItems);

  return {
    currency: 'USD',
    value: total,
    coupon,
    items: products.map((product) => ({
      item_id: product?.item_id,
      item_name: product?.name,
      price: product?.price,
      quantity: product?.quantity,
      item_variant: product.startTimeName || '',
      item_category: product?.category,
      product_id: product?.product_id,
      affiliation: 'AD Web',
      venue: product?.venue,
    })),
  };
};

const getBirthdayOrderCompletedProducts = (birthday) =>
  birthday.items.map((item, index) => ({
    item_id: item.productId,
    item_name: item.name,
    index: index + 1,
    price: toDollars(item.cost),
    quantity: item.quantity,
    item_variant: item.startTime || '',
    item_category: birthday.venueSlug,
    affiliation: 'AD Web',
    product_id: item.productId,
    variant: birthday.date,
    sku: item.productId,
  }));

const getEccomerceBirthayOrderCompletedData = ({
  paymentIntentId,
  birthday,
}) => ({
  //GA4 FIELDS
  currency: 'USD',
  transaction_id: paymentIntentId,
  value: toDollars(birthday.amount),
  shipping: 0,
  tax: toDollars(
    birthday.items.reduce((acc, item) => (acc += item.cost * (item.tax / 100))),
    0
  ),
  items: getBirthdayOrderCompletedProducts(birthday),
  //END GA4 FIELDS
  revenue: toDollars(birthday.amount),
  order_id: birthday.capacityReservationId,
  affiliation: 'AD Web',
  total: toDollars(birthday.amount),
  subtotal: toDollars(birthday.amount),
});

const getEccomerceOrderCompletedData = (order, tickets, coupon) => ({
  //GA4 FIELDS
  currency: 'USD',
  transaction_id: order.bookingId,
  value: toDollars(order.totalAmount),
  coupon,
  shipping: 0,
  tax: toDollars(order.taxAmount),
  items: getEcomerceOrderCompletedProducts(order, tickets),
  //END GA4 FIELDS
  revenue: toDollars(order.subTotalAmount + order.totalDiscountNoTax),
  order_id: order.bookingId,
  affiliation: 'AD Web',
  total: toDollars(order.totalAmount),
  subtotal: toDollars(order.subTotalAmount),
  discount: toDollars(order.totalDiscountNoTax),
});

const getEcomerceOrderCompletedProducts = (order, tickets) =>
  order.items.map((item = {}, index) => {
    const ticket = tickets.find((ticket) => ticket.venueId === item.venueId);
    const venue = ticket?.venue?.name || '';
    const id = item.addon
      ? getAddonSlug(item.name)
      : ticket
      ? ticket.venue.slug
      : '';

    return {
      //GA4 FIELDS
      item_id: item.bookingId,
      item_name: item.name,
      discount: item.totalAmount - item.totalAmountDiscount,
      index: index + 1,
      price: toDollars(item.price),
      quantity: item.quantity,
      item_variant: item.sessionName || '',
      item_category: item.venueId,
      affiliation: 'AD Web',
      // END GA4 FIELDS
      product_id: id,
      sku: item.bookingItemInventoryId,
      variant: item.date,
      venue,
    };
  });

const getOrderCompletedProducts = (order, tickets) => {
  const products = order.items
    .filter((item) => !item.bundleSlug)
    .map((item = {}) => {
      const ticket = tickets.find((ticket) => ticket.venueId === item.venueId);
      const venue = ticket?.venue?.name || '';
      const id = item.addon
        ? getAddonSlug(item.name)
        : ticket
        ? ticket.venue.slug
        : '';

      return {
        product_id: id,
        name: id,
        price: toDollars(item.price),
        quantity: item.quantity,
        sku: item.bookingItemInventoryId,
        variant: item.date,
        category: item.venueId,
        venue,
      };
    });

  const groupedBundleItems = groupBy(
    tickets.filter((ticket) => ticket.bundleSlug),
    (item) => item.bundleCartId
  );
  const bundleProducts = Object.values(groupedBundleItems).map((items) => {
    const item = items[0];
    const bundle = item.bundle;

    return {
      product_id: bundle.slug,
      name: bundle.slug,
      price: bundle.amount.totalAmountWithoutTaxes,
      quantity: Object.values(item.quantities).reduce((a, b) => a + b, 0),
      variant: item.reservationDate,
      category: item.venueId,
      bundleSlug: bundle.slug,
    };
  });

  return products.concat(bundleProducts);
};

function getPropsForCartItems(cartItems) {
  let products = flatten(
    cartItems.map((cartItem) => {
      const { venue, inventory, quantities } = cartItem;

      return cartItem.tickets.map((ticket) => {
        const { price, tax } = ticket;
        const quantity = quantities[ticket.ticketOptionId];
        const discountedTax = getItemTax(ticket, inventory, quantities);
        const discountedPrice = getItemPrice(ticket, inventory, quantities);
        const itemBulkDiscount = getItemBulkDiscount(
          ticket,
          inventory,
          quantities
        );
        const discountType = itemBulkDiscount && itemBulkDiscount.bulkType;
        return {
          tax: toDollars(tax),
          price: toDollars(price),
          discountedTax: toDollars(discountedTax),
          discountedPrice: toDollars(discountedPrice),
          startTimeName: cartItem.startTimeName || '',
          quantity,
          item_id: ticket.ticketOptionId,
          product_id: `${venue.name} - ${ticket.name}`,
          sku: ticket.externalId,
          name: ticket.name,
          variant: ticket.date,
          category: venue.id,
          venue: venue.name,
          discountType,
        };
      });
    })
  );

  const coupon = getGroupDiscountCoupon(
    products,
    (product) => product.discountType
  );

  const revenue = productSum(products, (product) => product.price);
  const discount = productSum(
    products,
    (product) => product.price - product.discountedPrice
  );
  const subtotal = productSum(products, (product) => product.discountedPrice);
  const total = productSum(
    products,
    (product) => product.discountedPrice + product.discountedTax
  );
  const tax = productSum(products, (product) => product.discountedTax);

  // "value" is defined for now as the same as "total"
  // on order complete (discounted price + tax).
  const value = total;

  // Remove fields that aren't part of the ecommerce spec after summing.
  products = products.map((product) => {
    const { tax, discountedTax, discountedPrice, discountType, ...rest } =
      product;
    return {
      ...rest,
      // Price should match the discounted price that
      // comes from order completed event in the API.
      price: discountedPrice,
    };
  });

  return {
    tax,
    total,
    value,
    subtotal,
    revenue,
    discount,
    products,
    coupon,
  };
}

function getGroupDiscountCoupon(items, fn) {
  const discountTypes = compact(items.map((item) => fn(item)));
  if (discountTypes.length) {
    return uniq(discountTypes)
      .map((type) => {
        return `Group Discount: ${type}`;
      })
      .join(',');
  }
  return '';
}

function productSum(arr, fn) {
  const sum = sumBy(arr, (ticket) => {
    return fn(ticket) * ticket.quantity;
  });
  // Ensure the sum rounding is correct
  // here to prevent floating-point errors.
  return round(sum, 2);
}

function toDollars(cents) {
  return round(cents / 100, 2);
}

export function trackProductViewed(inventoryItem, venue) {
  track('Product Viewed', {
    ecommerce: getEcommerceTicketData(inventoryItem, venue, true),
    price: toDollars(inventoryItem.price),
    product_id: `${venue.name} - ${inventoryItem.name}`,
    sku: inventoryItem.externalId,
    variant: inventoryItem.date,
    category: venue.id,
    name: inventoryItem.name,
    venue: venue.name,
  });
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
export function trackProductAdded(ticket) {
  track('Product Added', {
    ecommerce: getEcommerceTicketData(ticket),
    price: toDollars(ticket.price),
    quantity: ticket.quantity,
    product_id: `${ticket.venueName} - ${ticket.name}`,
    sku: ticket.externalId,
    variant: ticket.date,
    coupon: '',
    category: ticket.venueId,
    name: ticket.name,
    venue: ticket.venueName,
  });
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
export function trackProductRemoved(ticket) {
  track('Product Removed', {
    ecommerce: getEcommerceTicketData(ticket),
    price: toDollars(ticket.price),
    quantity: ticket.quantity,
    product_id: ticket.bookingItemId,
    sku: ticket.externalId,
    variant: ticket.date,
    coupon: '',
    category: ticket.venueId,
    name: ticket.name,
  });
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#cart-viewed
export function trackCartViewed(cartItems) {
  const { products } = getPropsForCartItems(cartItems);
  const properties = products.reduce(
    (reducer, item, index) => ({
      ...reducer,
      [`product_${index}`]: item.name,
    }),
    {}
  );
  properties.ecommerce = getEcomerceCartItemsData(cartItems);
  track('Cart Viewed', properties);
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
export function trackCheckoutStarted(cartItems) {
  const { tax, value, revenue, discount, coupon } =
    getPropsForCartItems(cartItems);

  track('Checkout Started', {
    ecommerce: getEcomerceCartItemsData(cartItems),
    tax,
    value,
    revenue,
    discount,
    shipping: 0,
    currency: 'USD',
    affiliation: 'AD Web',
    coupon,
  });
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-viewed
export function trackCheckoutStepViewed(step) {
  track('Checkout Step Viewed', {
    step,
  });
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-completed
export function trackCheckoutStepCompleted(step) {
  track('Checkout Step Completed', {
    step,
  });
}

export const getAddonSlug = (name) => {
  return name
    .trim()
    .split(/[-|]/g)[0]
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[^a-zA-Z ]+/g, '')
    .split(' ')
    .filter(Boolean)
    .join('-');
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
export function trackOrderCompleted(order, tickets) {
  const coupon = getGroupDiscountCoupon(order.items, (item) => {
    return item.discountBulkType;
  });

  const ecommerce = getEccomerceOrderCompletedData(order, tickets, coupon);

  const trackProps = {
    ecommerce,
    coupon,
    order_id: order.bookingId,
    affiliation: 'AD Web',
    total: toDollars(order.totalAmount),
    subtotal: toDollars(order.subTotalAmount),
    tax: toDollars(order.taxAmount),
    discount: toDollars(order.totalDiscountNoTax),
    revenue: toDollars(order.subTotalAmount + order.totalDiscountNoTax),
    shipping: 0,
    currency: 'USD',
    products: getOrderCompletedProducts(order, tickets),
  };

  //GA4 EVENT NAME MUST BE: purchase
  track('Order Completed', structuredClone(trackProps));
  trackBingPixel('Order Completed', {
    ...structuredClone(trackProps),
    revenue_value: toDollars(order.subTotalAmount + order.totalDiscountNoTax),
  });
}

export function trackBirthdayOrderCompleted({ paymentIntentId, birthday }) {
  try {
    const ecommerce = getEccomerceBirthayOrderCompletedData({
      paymentIntentId,
      birthday,
    });
    const trackProps = {
      ecommerce,
      order_id: birthday.capacityReservationId,
      affiliation: 'AD Web',
      total: toDollars(birthday.amount),
      subtotal: toDollars(birthday.amount),
      tax: ecommerce.tax,
      revenue: toDollars(birthday.amount),
      shipping: 0,
      currency: 'USD',
      products: getBirthdayOrderCompletedProducts(birthday),
    };
    //GA4 EVENT NAME MUST BE: purchase
    track('Order Completed', structuredClone(trackProps));
    trackBingPixel('Order Completed', {
      ...structuredClone(trackProps),
      revenue_value: toDollars(birthday.amount),
    });
  } catch (e) {
    console.error('Error tracking birthday order completed', e.message);
  }
}
