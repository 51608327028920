import React from 'react';
import { debounce } from 'lodash';
import { Layout } from '../Layout';

export default class FullHeight extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      minHeight: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setHeightDeferred);
    this.setHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeightDeferred);
  }

  setHeight = () => {
    const el = this.ref.current;
    if (el) {
      this.setState({
        minHeight: Math.max(
          0,
          window.innerHeight - el.offsetParent.clientHeight + el.clientHeight
        ),
      });
    }
  };

  setHeightDeferred = debounce(this.setHeight, 200);

  getStyles() {
    if (this.state.minHeight) {
      return {
        minHeight: this.state.minHeight,
      };
    }
  }

  render() {
    return (
      <Layout forwardRef={this.ref} style={this.getStyles()} {...this.props}>
        {this.props.children}
      </Layout>
    );
  }
}

FullHeight.propTypes = {
  ...Layout.propTypes,
};

FullHeight.defaultProps = {
  ...Layout.defaultProps,
};
