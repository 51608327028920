import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ShopifyVendorsStore extends BaseStore {
  @action
  getAllVendors(statusKey = `getAllVendors`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/shopifyvendor`,
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          error: err.message,
        };
      });
  }
}
