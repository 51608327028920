import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class EventCategoriesStore extends BaseStore {
  @observable redirect = false;

  @action
  setRedirect() {
    this.redirect = true;
  }

  @action
  getAllCategories(statusKey = 'listAllEventCategories') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/eventcategory`,
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  storeEventCategory(category, statusKey = 'storeEventCategory') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/eventcategory`,
      body: {
        category,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  deleteCategory(categoryId, statusKey = 'deleteEventCategory') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/eventcategory/${categoryId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateCategory(categoryId, categoryName, statusKey = 'updateCategory') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/eventcategory/${categoryId}`,
      body: { name: categoryName },
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
