import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { SVGIcon } from 'common/components';
import { useClassName } from 'common/hooks';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { Calendar } from 'public/components/Calendar';

import './modular-date-picker.less';

const ModularDatePicker = ({ date, setDate, error, onBlur, placeholder }) => {
  const classNames = useClassName('ModularDatePicker');
  const [isOpen, setOpen] = useState(false);

  return (
    <ControlledInputContainer
      addonLeft={<SVGIcon name="date" className={classNames('addon-left')} />}
      input={
        <input
          readOnly
          placeholder={placeholder}
          value={
            date
              ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
              : ''
          }
        />
      }
      addonRight={
        <SVGIcon name="angle-down" className={classNames('addon-right')} />
      }
      hasFocus={isOpen}
      error={error}
      onClick={() => setOpen(true)}
      trigger={
        <Calendar
          initialDate={date}
          open={isOpen}
          updateSelectedDate={(date) => {
            setOpen(false);
            const isoDate = DateTime.fromJSDate(date).toISODate();
            setDate(isoDate);
            onBlur({ value: isoDate });
          }}
          closeModal={() => {
            setOpen(false);
            onBlur({});
          }}
          openModal={() => {
            setOpen(true);
          }}
        />
      }
    />
  );
};

ModularDatePicker.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ModularDatePicker;
