import { action, observable } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class OrdersStore extends BaseStore {
  @observable items = [];

  @action
  fetchPaymentMethods(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/payment/methods`,
    })
      .then(({ data }) => {
        status.success();
        this.items.replace(data);
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  deletePaymentMethod(paymentMethodId, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/payment/methods/${paymentMethodId}`,
    })
      .then(() => {
        this.items.replace(
          this.items.filter((pm) => pm.id !== paymentMethodId)
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  deleteAllPaymentMethods(statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/payment/methods`,
    })
      .then(() => {
        this.items.clear();
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
