import React from 'react';
import { Loader } from 'common/lazy';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import { FieldContainer } from '../FieldContainer';

import './controlled-input-container.less';

const ControlledInputContainer = ({
  label,
  loading,
  error,
  hasFocus,
  addonLeft,
  addonRight,
  onClick,
  input,
  trigger,
  ...rest
}) => {
  const classNames = useClassName('ControlledInputContainer');

  return (
    <FieldContainer {...rest}>
      {label}
      <div
        onClick={onClick}
        className={`${classNames([
          'container',
          hasFocus && 'focused',
          error && 'error',
        ])} ${rest.className}`}>
        {loading ? <Loader size="mini" active inline="centered" /> : addonLeft}
        {input}
        {addonRight}
      </div>
      {trigger}
      {Boolean(error) && (
        <caption className={classNames('error-message')}>{error}</caption>
      )}
    </FieldContainer>
  );
};

ControlledInputContainer.propTypes = {
  label: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  hasFocus: PropTypes.bool,
  addonLeft: PropTypes.any,
  addonRight: PropTypes.any,
  input: PropTypes.any,
  onClick: PropTypes.func,
  trigger: PropTypes.any,
};

export default ControlledInputContainer;
