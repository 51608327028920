import React from 'react';
import Modal from './Modal';

export default class ConsentBanner extends React.Component {
  render() {
    return (
      <Modal
        trigger={
          <span className={this.props.className}>
            Data Collection Preferences
          </span>
        }
      />
    );
  }
}
