import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class SearchStore extends BaseStore {
  @action
  getSearchResult(query, statusKey = 'search') {
    const status = this.createStatus(statusKey);
    const promise = request({
      path: `/1/search?q=${query}`,
      method: 'GET',
    })
      .then((resp) => {
        status.success();
        return resp.data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
    if (statusKey === 'boot') {
      this.promise = promise;
    }
    return promise;
  }
}
