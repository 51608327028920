import { action, observable } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class BetaFeaturesStore extends BaseStore {
  @observable features = new Map();

  @action
  fetch(featureName) {
    if (this.features.has(featureName)) {
      return Promise.resolve(this.features.get(featureName));
    }

    return request({
      method: 'GET',
      path: `/1/betafeatures/${featureName}`,
    }).then((data) => {
      const isEnabled = Boolean(data?.feature?.enabled);
      this.features.set(featureName, isEnabled);

      return Boolean(isEnabled);
    });
  }

  @action
  fetchAll() {
    return request({
      method: 'GET',
      path: `/1/betafeatures/`,
    });
  }

  @action
  updateEnabled(name, enabled) {
    return request({
      method: 'POST',
      path: `/1/betafeatures/${name}`,
      body: {
        enabled,
      },
    });
  }
}
