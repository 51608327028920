import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import { MailLink } from 'common/components';
import { track } from 'utils/analytics';

import './footer-list.less';

export default class FooterList extends Component {
  handleLinkClick = (name) => {
    return () => {
      track(`Footer - Viewed ${name}`);
    };
  };

  renderItem = (
    { isEmail, isExternal, title, value, hasCustomStlye },
    index
  ) => {
    if (isEmail) {
      return (
        <MailLink
          key={index}
          mail={value}
          onClick={this.handleLinkClick(title)}>
          {title}
        </MailLink>
      );
    }

    if (isExternal) {
      if (hasCustomStlye) {
        return (
          <a
            key={index}
            href={value}
            rel="noopener noreferrer"
            target="_blank"
            className={this.getElementClass('custom-link')}
            onClick={this.handleLinkClick(title)}>
            {title}
          </a>
        );
      }

      return (
        <a
          key={index}
          href={value}
          rel="noopener noreferrer"
          target="_blank"
          onClick={this.handleLinkClick(title)}>
          {title}
        </a>
      );
    }

    if (hasCustomStlye) {
      return (
        <Link
          key={index}
          to={value}
          className={this.getElementClass('custom-link')}
          onClick={this.handleLinkClick(title)}>
          {title}
        </Link>
      );
    }

    return (
      <Link key={index} to={value} onClick={this.handleLinkClick(title)}>
        {title}
      </Link>
    );
  };

  render() {
    const { items, title } = this.props;

    return (
      <div {...this.getAttrs()}>
        <span className={this.getElementClass('title')}>{title}</span>
        {items.map(this.renderItem)}
      </div>
    );
  }
}

FooterList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isEmail: PropTypes.bool,
      isExternal: PropTypes.bool,
      hasCustomStlye: PropTypes.bool,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
};

FooterList.defaultProps = {
  items: [],
};
