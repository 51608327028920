import { DateTime } from 'luxon';

// Luxon seems to have issues with ja-JP here.
// https://github.com/moment/luxon/issues/54??9
export function formatHours(arg) {
  if (arg == null) {
    throw new Error('Invalid hour');
  }
  let date;
  if (typeof arg === 'number') {
    date = new Date();
    date.setHours(arg);
  } else {
    date = arg;
  }
  return DateTime.fromJSDate(date).toFormat('ha').toLowerCase();
}

export function formatHourRange(from, to) {
  return `${formatHours(from)} - ${formatHours(to)}`;
}

export function formatHoursMinutes(hour, minutes) {
  if (hour == null) {
    throw new Error('Invalid hour');
  }
  let date;
  let dateFormat = 'h:mma';

  if (typeof hour === 'number') {
    date = new Date();
    date.setHours(hour);

    if (minutes > 0) {
      date.setMinutes(minutes);
    } else {
      dateFormat = 'ha';
    }
  } else {
    date = hour;
  }
  return DateTime.fromJSDate(date).toFormat(dateFormat).toLowerCase();
}

export function formatHourMinutesRange(
  fromHour,
  fromMinutes,
  toHour,
  toMinutes
) {
  return `${formatHoursMinutes(fromHour, fromMinutes)} - ${formatHoursMinutes(
    toHour,
    toMinutes
  )}`;
}
