import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { VideoPlayer } from 'common/components';
import { LoaderImage } from '../../Loader';
import './ride-hero-asset.less';

const RideHeroAsset = ({ venue, videoUrl, posterUrl }) => {
  const className = useClassName('RideHeroAsset');
  return (
    <div className={className('container')}>
      {videoUrl && (
        <VideoPlayer
          id={`hero-video-${venue?.slug}`}
          name={`Hero Video ${venue?.name}`}
          videoUrl={videoUrl}
          posterUrl={posterUrl}
          controls={false}
        />
      )}
      {posterUrl && !videoUrl && (
        <div className={className('image')}>
          <LoaderImage
            src={posterUrl}
            alt={`hero-image-${venue?.slug}`}
            hideLoader
            fluid
          />
        </div>
      )}
    </div>
  );
};

RideHeroAsset.propTypes = {
  venue: PropTypes.object,
  videoUrl: PropTypes.string,
  posterUrl: PropTypes.string,
};

RideHeroAsset.defaultProps = {
  venue: null,
  videoUrl: '',
  posterUrl: '',
};

export default RideHeroAsset;
