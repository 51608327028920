import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { inject } from 'mobx-react';

import {
  useClassName,
  useDOMElement,
  useWindowSize,
  useScrollDirection,
} from 'common/hooks';
import { BottomSheet } from 'public/components/BottomSheet';
import BookTicketsAnnouncement from 'public/components/BookTickets/BookTicketsAnnouncement';
import { parseAnnouncement } from 'public/helpers/contentful/parser';
import { track } from 'utils/analytics';

import HomepageImage from '../Image';

import './menu.less';

function goToSection(e, sectionId) {
  e.preventDefault();
  e.stopPropagation();

  const offset = 104;
  const element = document.getElementById(sectionId);
  const y = element.getBoundingClientRect().top + window.scrollY - offset;

  window.scrollTo({ top: y, behavior: 'instant' });
}

function MenuItem({ fields, isMobile }) {
  return (
    <a
      href={`#${fields.sectionId}`}
      onClick={(e) => {
        track('Click Home Nav Item', { label: fields.label });
        goToSection(e, fields.sectionId);
      }}>
      <HomepageImage field={fields.logo} />
      <span>{isMobile ? fields.label.split(' ')[0] : fields.label}</span>
    </a>
  );
}

function Mobile({ items, setActive }) {
  const midpoint = Math.ceil(items.length / 2);
  const firstHalf = items.slice(0, midpoint);
  const secondHalf = items.slice(-midpoint);

  return (
    <>
      {firstHalf.map(({ fields, sys }) => (
        <MenuItem key={sys.id} fields={fields} isMobile />
      ))}
      <button onClick={() => setActive(true)}>Book Tickets</button>
      {secondHalf.map(({ fields, sys }) => (
        <MenuItem key={sys.id} fields={fields} isMobile />
      ))}
    </>
  );
}

export default inject('announcements')(function Menu({ items, announcements }) {
  const classNames = useClassName('NewHomepageMenu');
  const [menuContainer] = useDOMElement('.navigation-header__menu-container');
  const scrollDirection = useScrollDirection();
  const { isMobile, isTablet } = useWindowSize();
  const [active, setActive] = useState(false);
  const [ticketOptions, setTicketsOptions] = useState([]);

  useEffect(() => {
    if (active) {
      track('Book tickets modal opened');
      setTicketsOptions(
        announcements
          .get('book-ticket')
          .map(parseAnnouncement)
          .sort((a, b) => a.order - b.order)
      );
    }
  }, [active]);

  if (!menuContainer) return null;
  return createPortal(
    <>
      <div
        className={classNames('container')}
        {...(scrollDirection === 'down' && {
          style: { height: 0, borderBottom: 'none', display: 'none' },
        })}>
        {isMobile || isTablet ? (
          <Mobile items={items} setActive={setActive} />
        ) : (
          items.map(({ fields, sys }) => (
            <MenuItem key={sys.id} fields={fields} isMobile={isMobile} />
          ))
        )}
      </div>
      <BottomSheet
        onClose={() => setActive(false)}
        isOpen={active && ticketOptions.length}
        title="Book tickets"
        content={
          <div style={{ padding: '12px' }}>
            {ticketOptions.map((option) => (
              <BookTicketsAnnouncement
                announcement={option}
                key={option.name}
                setActive={setActive}
              />
            ))}
          </div>
        }
      />
    </>,
    isMobile || isTablet ? document.querySelector('body') : menuContainer
  );
});
