import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import { ModalCalendar } from '../ModalCalendar';
import './operation-hours.less';

const TIME_ZONE = 'America/New_York';

const OperationHours = ({ openingHours, featuredFlags, furtherDays }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [date, setDate] = useState(
    DateTime.local().plus({ days: furtherDays })
  );
  const className = useClassName('OperationHours');

  const getHours = () => {
    if (!openingHours?.regular?.length && !openingHours?.irregular?.length)
      return null;

    return (
      openingHours.irregular.find(
        (irregular) => irregular.date === date.toFormat('yyyy-MM-dd')
      ) ||
      openingHours.regular.find(
        (hours) => hours.weekday === date.toFormat('cccc').toLowerCase()
      )
    );
  };

  const getTimeFromDate = (date) => date.toFormat('h:mm a');

  const updateSelectedDate = (calendarSelectedDate) => {
    setDate(DateTime.fromJSDate(calendarSelectedDate));
    setOpenCalendar(false);
  };

  const closeModal = () => setOpenCalendar(false);

  const getDateTime = (closedAllDay, temporarilyClosed, hours, minutes) => {
    return (
      !closedAllDay &&
      !temporarilyClosed &&
      DateTime.fromISO(date.toFormat('yyyy-MM-dd'))
        .setZone(TIME_ZONE)
        .set({ hours, minutes })
    );
  };

  const renderControls = () => {
    const today = DateTime.local();
    return (
      <div className={className('operation-hours-controls')}>
        <p onClick={() => setOpenCalendar(true)}>
          {today.toFormat('yyyy-MM-dd') === date.toFormat('yyyy-MM-dd')
            ? 'Today'
            : date.toFormat('LLL dd')}
          <Icon size="mini" name="angle-down" className="chevron" />
        </p>
        <ModalCalendar
          selectedDate={date.toISO()}
          open={openCalendar}
          updateSelectedDate={updateSelectedDate}
          closeModal={closeModal}
        />
      </div>
    );
  };

  const getLabel = () => {
    const comingSoon = featuredFlags.includes('Coming Soon');

    if (comingSoon) return 'Coming Soon';

    const operationHours = openingHours && getHours();
    const temporarilyClosed = openingHours
      ? openingHours.temporarilyClosed
      : false;
    const closedAllDay = operationHours ? operationHours.isClosed : true;

    if (closedAllDay || temporarilyClosed) return 'Closed';

    const startTime = getDateTime(
      closedAllDay,
      temporarilyClosed,
      operationHours?.openHour,
      operationHours?.openMinute
    );
    const endTime = getDateTime(
      closedAllDay,
      temporarilyClosed,
      operationHours?.closeHour,
      operationHours?.closeMinute
    );

    return `${getTimeFromDate(startTime)} - ${getTimeFromDate(endTime)}`;
  };

  const renderOperationHours = () => {
    return (
      <div className={className('operation-hours')}>
        <p>{getLabel()}</p>
      </div>
    );
  };

  const renderHours = () => {
    return (
      <div className={className('operation-hours-container')}>
        {renderOperationHours()}
        {renderControls()}
      </div>
    );
  };

  return <div className={className('container')}>{renderHours()}</div>;
};

OperationHours.propTypes = {
  openingHours: PropTypes.object,
  featuredFlags: PropTypes.arrayOf(PropTypes.string),
  furtherDays: PropTypes.number,
};

OperationHours.defaultProps = {
  featuredFlags: [],
  furtherDays: 0,
};

export default OperationHours;
