import React from 'react';
import { groupBy } from 'lodash';
import { Component } from 'common/helpers';
import { formatHourMinutesRange, formatDate } from 'utils/l10n';

import './hours-table.less';

export default class HoursTable extends Component {
  // TODO: clean this up
  getGroupedDays() {
    const { projectedDays, regular } = this.props.openingHours;
    let days = projectedDays || regular;
    let currentGroupKey;
    let grouper = 0;

    if (!projectedDays) {
      // If there are no projected days then organize starting with Monday.
      const index = days.findIndex((day) => day.weekday === 'monday');
      days = days.slice(index).concat(days.slice(0, index));
    }
    return groupBy(days, (day) => {
      const {
        openHour: oh,
        closeHour: ch,
        openMinute: om = 0,
        closeMinute: cm = 0,
        holidayName: hn,
      } = day;
      const ic = this.isClosed(day);
      const groupKey = `${oh}-${om}-${ch}-${cm}-${ic}-${hn}`;
      if (groupKey !== currentGroupKey) {
        currentGroupKey = groupKey;
        grouper++;
      }
      return `${groupKey}-${grouper}`;
    });
  }

  getSpanName(days) {
    let str = '';
    const first = days[0].date;
    str += this.formatDate(first);
    if (days.length > 1) {
      const last = days[days.length - 1].date;
      str += ` - ${this.formatDate(last)}`;
      str += ` (${this.formatWeekday(first)} - ${this.formatWeekday(last)})`;
    } else {
      str += ` (${this.formatWeekday(first)})`;
    }
    return str;
  }

  formatDate(date) {
    return formatDate(date, {
      month: 'short',
      day: 'numeric',
    });
  }

  formatWeekday(date) {
    return formatDate(date, {
      weekday: 'short',
    });
  }

  getSpanTimes(days) {
    // Spans are assumed to be grouped by unique times,
    // so only need the first element here.
    const day = days[0];
    if (this.isClosed(day)) {
      return `Closed`;
    } else {
      return formatHourMinutesRange(
        day.openHour,
        day.openMinute,
        day.closeHour,
        day.closeMinute
      );
    }
  }

  isClosed(day) {
    const { openHour, closeHour } = day;
    return day.isClosed || openHour == null || closeHour == null;
  }

  render() {
    return <div>{this.renderGroups()}</div>;
  }

  renderGroups() {
    const grouped = this.getGroupedDays();
    return Object.entries(grouped).map(([key, days]) => {
      const holiday = days.find((d) => d.holidayName);
      let holidayName = '';
      if (holiday) {
        holidayName = ` (${holiday.holidayName})`;
      }
      return (
        <p
          key={key}
          className={this.getElementClass('row', holiday ? 'holiday' : '')}>
          {this.getSpanName(days)}: {this.getSpanTimes(days)} {holidayName}
        </p>
      );
    });
  }
}
