import React from 'react';
import { INTERCOM_APP_ID } from 'utils/env/client';
import { loadScript } from 'utils/helpers';
import { inject } from 'mobx-react';

const INTERCOM_CDN_URL = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;

import './intercom-widget.less';

@inject('appSession', 'me')
export default class IntercomWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await loadScript(INTERCOM_CDN_URL);
    const options = {
      app_id: INTERCOM_APP_ID,
    };

    if (this.props.appSession.isLoggedIn()) {
      await this.props.me.fetch();
      const { user } = this.props.me;
      Object.assign(options, {
        email: user.email,
        name: user.name,
        user_id: user.id,
      });
    }
    window.Intercom('boot', options);
  }

  componentWillUnmount() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }

  render() {
    // Intercom widget loads into an iframe
    // so no need to render anything here.
    return null;
  }
}
