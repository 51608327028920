import { useCallback, useEffect } from 'react';
import {
  useClassName,
  useDOMElement,
  useScrollDirection,
  useScrollY,
  useWindowSize,
} from 'common/hooks';

import './chat-icon.less';

export default function ChatIcon() {
  const classNames = useClassName('ChatIcon');
  const [elem] = useDOMElement('.BeaconFabButtonFrame', true);
  const [hpMenu] = useDOMElement('.new-homepage-menu__container', true);
  const scrollDirection = useScrollDirection();
  const { scrollY } = useScrollY();
  const { isMobile } = useWindowSize();

  const getButtonPosition = useCallback(() => {
    try {
      if (scrollDirection === 'down') {
        return {
          opacity: 0,
          '--icon-offset': '0px',
        };
      }

      return {
        opacity: 1,
        '--icon-offset': isMobile
          ? `-${hpMenu?.getBoundingClientRect()?.height || 0}px`
          : '0px',
      };
    } catch {
      return {
        opacity: 0,
        '--icon-offset': '0px',
      };
    }
  }, [scrollDirection, scrollY, isMobile, hpMenu]);

  useEffect(() => {
    if (elem) {
      elem?.classList?.add(classNames('container'));
    }
  }, [elem]);

  useEffect(() => {
    if (!elem) return;

    const position = getButtonPosition();
    Object.entries(position).forEach(([key, value]) => {
      elem?.style?.setProperty(key, value, 'important');
    });
  }, [elem, getButtonPosition]);

  return null;
}
