import { DateTime } from 'luxon';
import { formatStartAndEndDate } from 'utils/helpers';

export const DEFAULT_MAINTENANCE_REASON = 'Scheduled Maintenance';
export const TEMPORARILY_CLOSED_REASON = 'Temporarily Closed';

export function parseRideMaintenanceSchedule(rides) {
  return (rides || [])
    .filter((card) => card.schedule)
    .reduce((acc, card) => {
      const now = DateTime.local();
      const closedDates = [
        ...card.schedule.closedDates.filter(({ endDate }) => now <= endDate),
        ...(card.schedule.temporarilyClosed
          ? [{ reason: TEMPORARILY_CLOSED_REASON }]
          : []),
      ];

      for (const dates of closedDates) {
        const isClosedNow = !dates.startDate || now >= dates.startDate;

        const label = isClosedNow
          ? 'Closed now'
          : formatStartAndEndDate(dates.startDate, dates.endDate);

        const item = {
          image: card.image,
          name: card.name,
          reason: `${dates.reason}${
            now >= dates.startDate
              ? ` (${formatStartAndEndDate(dates.startDate, dates.endDate)})`
              : ''
          }`,
        };

        const existing = acc.find((item) => item.label === label);
        if (existing) {
          existing.items.push(item);
        } else {
          acc.push({
            label,
            startDate: dates.startDate,
            items: [item],
          });
        }
      }

      return acc;
    }, [])
    .sort((a, b) => {
      if (!b.startDate) return 1;

      return a.startDate > b.startDate ? 1 : -1;
    });
}

export function hasRidesUnderMaintenance(rides) {
  return Boolean(parseRideMaintenanceSchedule(rides)?.length);
}
