import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Button, Modal } from 'common/lazy';
import { useClassName, useWindowSize } from 'common/hooks';
import { LazyDayPicker } from 'common/lazy';
import PriceTypeIndicator from '../PriceTypeIndicator/PriceTypeIndicator';

import './modal-calendar.less';

const ModalCalendar = ({
  initialDate,
  open,
  updateSelectedDate,
  closeModal,
  isDateUnavailable,
  trigger,
  openModal,
  footer,
  isTicketsCalendar,
  priceTypes,
}) => {
  const className = useClassName('ModalCalendar');
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(
    initialDate ? DateTime.fromISO(initialDate).toJSDate() : null
  );
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(
    DateTime.local().month
  );
  const { isMobile } = useWindowSize();
  const today = new Date();

  useEffect(() => {
    if (!initialDate) return;
    setCalendarSelectedDate(DateTime.fromISO(initialDate).toJSDate());
  }, [initialDate]);

  useEffect(() => {
    if (!priceTypes?.length) return;
    renderPriceTypeIndicator();
    const date = calendarSelectedDate
      ? DateTime.fromJSDate(calendarSelectedDate)
      : DateTime.local();
    defineCustomStyles(date);
  }, [open]);

  useEffect(() => {
    defineCustomStyles(DateTime.fromJSDate(calendarSelectedDate));
  }, [calendarSelectedDate]);

  const isDateBeforeToday = (date) => {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  };

  const isCurrentMonth = (today = DateTime.local()) =>
    today.month === currentCalendarMonth ? 'current-calendar-month' : '';

  const onDayClick = (date) => {
    if (isDateBeforeToday(date) || isDateUnavailable(toISO(date))) return;
    setCalendarSelectedDate(date);
    if (isTicketsCalendar && !isMobile) updateSelectedDate(date);
  };

  const toISO = (jsDate) => DateTime.fromJSDate(jsDate).toISODate();

  const defineCustomStyles = (date) => {
    setCurrentCalendarMonth(date.month);

    if (!priceTypes?.length) return;

    const today = DateTime.local().startOf('day');
    const monthDays = priceTypes
      .filter((priceType) => {
        const priceTypeDate = DateTime.fromISO(priceType.date).startOf('day');
        return (
          priceTypeDate >= today &&
          priceTypeDate.month === date.month &&
          priceTypeDate.year === date.year
        );
      })
      .map((priceType) => ({
        day: DateTime.fromISO(priceType.date).day,
        type: priceType.type,
      }));
    const calendarDays = document.querySelectorAll('.DayPicker-Day');

    calendarDays.forEach((calendarDay) => {
      const priceType = monthDays.find(
        (day) => day.day === Number(calendarDay.innerText)
      );
      if (!priceType) return;
      const newClass = className(`price-type-${priceType.type}`);
      if (!calendarDay.classList.contains(newClass))
        calendarDay.classList.add(newClass);
    });
  };

  const renderPriceTypeIndicator = () => {
    const datePickerCaption = document.querySelector('.DayPicker-Caption');
    if (!datePickerCaption) return;
    const priceTypeIndicator = document.createElement('div');
    priceTypeIndicator.className = className('price-indicator-container');
    datePickerCaption.appendChild(priceTypeIndicator);
    ReactDOM.render(<PriceTypeIndicator />, priceTypeIndicator);
  };

  const renderDayPicker = () => (
    <div
      className={className([
        'calendar',
        isTicketsCalendar ? 'tickets-calendar' : 'regular-calendar',
      ])}>
      <LazyDayPicker
        className={`day-picker ${isCurrentMonth()}`}
        disabledDays={[
          { before: today },
          (jsDate) => isDateUnavailable(toISO(jsDate)),
        ]}
        enableOutsideDaysClick={false}
        selectedDays={calendarSelectedDate}
        month={calendarSelectedDate}
        onDayClick={(date) => onDayClick(date)}
        onMonthChange={(date) => defineCustomStyles(DateTime.fromJSDate(date))}
      />
      {(!isTicketsCalendar || (isTicketsCalendar && isMobile)) && (
        <Button
          inverted
          onClick={() => updateSelectedDate(calendarSelectedDate)}>
          Apply
        </Button>
      )}
      {footer}
    </div>
  );

  return (
    <Modal
      basic
      open={open}
      trigger={trigger}
      onOpen={openModal}
      size="small"
      onClose={closeModal}
      className={className('modal')}>
      {renderDayPicker()}
    </Modal>
  );
};

ModalCalendar.propTypes = {
  initialDate: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isTicketsCalendar: PropTypes.bool,
  priceTypes: PropTypes.array,
  updateSelectedDate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isDateUnavailable: PropTypes.func.isRequired,
  trigger: PropTypes.any,
  footer: PropTypes.any,
  openModal: PropTypes.func,
};

ModalCalendar.defaultProps = {
  isDateUnavailable: () => false,
  openModal: () => false,
  isTicketsCalendar: false,
  priceTypes: [],
};

export default ModalCalendar;
