import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'common/lazy';
import { Component } from 'common/helpers';
import { Layout } from 'common/components';
import { formatCurrency } from 'utils/l10n';

import './totals.less';

export default class Totals extends Component {
  getSubtotalLabel() {
    const { count, subtotalLabel } = this.props;
    let label = subtotalLabel;
    if (count) {
      label += ` (${this.props.count})`;
    }
    return `${label}: `;
  }

  render() {
    const { subtotal, tax, discount = 0 } = this.props;
    let newTax = tax;

    if (discount) {
      let taxRatio = tax / subtotal;
      newTax = (subtotal - discount) * taxRatio;
    }

    const total = subtotal - discount + newTax;

    return (
      <div {...this.getAttrs()}>
        <table className={this.getElementClass('table')}>
          <tbody>
            {this.renderRow(
              this.getSubtotalLabel(),
              formatCurrency(subtotal, true)
            )}
            {!!discount &&
              this.renderRow(
                'Discount:',
                formatCurrency(-discount, true),
                'discount'
              )}
            {this.renderRow('Taxes:', formatCurrency(newTax, true), 'tax')}
          </tbody>
        </table>
        <Divider />
        <Layout className={this.getElementClass('grand')} horizontal spread>
          <Layout.Group>Total:</Layout.Group>
          <Layout.Group>{formatCurrency(total, true)}</Layout.Group>
        </Layout>
      </div>
    );
  }

  renderRow(label, value, modifier) {
    return (
      <tr className={this.getElementClass('row', modifier)}>
        <th className={this.getElementClass('label')}>{label}</th>
        <td className={this.getElementClass('value', modifier)}>{value}</td>
      </tr>
    );
  }
}

Totals.propTypes = {
  count: PropTypes.number.isRequired,
  subtotalLabel: PropTypes.string,
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  discount: PropTypes.number,
};

Totals.defaultProps = {
  subtotalLabel: 'Tickets subtotal',
  discount: 0,
};
