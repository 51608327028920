import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export default class PropertyStore extends BaseStore {
  @observable register = new Map();

  get() {
    return this.register.get('property');
  }

  getOpeningHours() {
    return this.register.get('openingHours');
  }

  parseItem(item) {
    return {
      ...item,
      updatedAt: new Date(item.updatedAt),
      createdAt: new Date(item.createdAt),
    };
  }

  @action
  fetch(statusKey = `property`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/property`,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set('property', item);
        status.success();
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/property`,
      body,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set('property', item);
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchOpeningHours(date, type, statusKey = `openingHours`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/property/openinghours/${type || ''}?date=${date}`,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set('openingHours', item);
        status.success();
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
