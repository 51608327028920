/* ResponsiveImage:
 *
 * 1. Loads the perfect image URL for a given size using Contentful.
 *
 * 2. Can either be a fixed width or will look up the element's
 *    width when it is mounted.
 *
 * 3. Takes devicePixelRatio into account.
 *
 * 4. Caps dimensions when user is on a slow connection
 *    (and navigator.connection API is available).
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Ref } from 'common/lazy';
import { debounce } from 'lodash';
import { navigator } from 'utils/helpers/window';
import { Component } from 'common/helpers';
import ResponsiveImage from './ResponsiveImage';

const CONTENTFUL_CDN_REG = /(downloads|images)\.ctfassets\.net/;

// Contentful will error at greater sizes
const CONTENTFUL_MAX_PX = 4000;

// Very large images (greater than 1200px) should only be opted into
// when bandwidth can be assessed and speed is greater than 5mb/s.
// As this API is currently only available in Chrome, assume a fast
// connection for browser that don't support this API yet.
const LOW_BANDWIDTH_SPEED = 5;
const LOW_BANDWIDTH_PX = 1200;
const IMAGE_MIME_TYPE = 'image/webp';

function hasFastConnection() {
  const { connection } = navigator;
  const downlink = connection && connection.downlink;
  return downlink ? downlink >= LOW_BANDWIDTH_SPEED : true;
}

export default class ResponsiveImageLight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widthToUse: null,
    };

    this.ref = React.createRef();
  }

  onResizeDeferred = debounce(this.getClientWidth, 300);

  componentDidMount() {
    this.onResizeDeferred();
  }

  // Dimensions

  // Gets the element width, either as passed by
  // props or the mounted element's client width.
  getWidth() {
    let { width } = this.props;
    if (width) {
      return width;
    }

    width = this.state.widthToUse;

    return width || null;
  }

  // Gets the correct image width for the current pixel ratio.
  getImageWidth() {
    return this.getDeviceSize(this.getWidth());
  }

  getDeviceSize(px) {
    const { devicePixelRatio = 1 } = window;
    if (px && devicePixelRatio && devicePixelRatio !== 1) {
      px = Math.round(px * devicePixelRatio);
    }
    return px;
  }

  getClientWidth() {
    const el = this.ref.current;
    if (el && el.clientWidth) {
      this.setState({
        widthToUse: el.clientWidth,
      });
    } else {
      this.onResizeDeferred();
    }
  }

  // Resizing
  getSizedUrl(url, w) {
    if (!w) {
      // No dimensions so cannot resize.
      return null;
    }
    if (!hasFastConnection()) {
      w = Math.min(LOW_BANDWIDTH_PX, w);
    }

    return this.getContentfulSizedUrl(url, w);
  }

  // Where w1 are the input dimensions that may
  // overflow contentful's size max.
  getContentfulSizedUrl(url, w) {
    const params = new URLSearchParams();

    const valueToUse = Math.min(w, CONTENTFUL_MAX_PX);

    params.append('w', valueToUse);

    url = url.replace(/downloads\.ctfassets\.net/, 'images.ctfassets.net');

    if (!hasFastConnection()) {
      params.append('q', '80');
    }

    return `${url}?${params.toString()}`;
  }

  isContentfulUrl(url) {
    return CONTENTFUL_CDN_REG.test(url);
  }

  render() {
    const { src: baseUrl, onLoad } = this.props;

    if (!baseUrl) {
      return null;
    }

    const style = {
      ...(this.props.style || {}),
      maxWidth: '100%',
    };

    if (!this.isContentfulUrl(baseUrl)) {
      return (
        <Ref innerRef={this.ref}>
          <Image
            {...this.passProps()}
            src={baseUrl}
            style={style}
            onLoad={onLoad}></Image>
        </Ref>
      );
    }

    const src = this.getSizedUrl(baseUrl, this.getImageWidth());

    return (
      <picture>
        <source srcSet={`${src}&fm=webp`} type={IMAGE_MIME_TYPE} />
        <Ref innerRef={this.ref}>
          <Image
            {...this.passProps()}
            src={src}
            style={style}
            onLoad={onLoad}></Image>
        </Ref>
      </picture>
    );
  }
}

ResponsiveImageLight.propTypes = {
  contentfulName: PropTypes.string,
  onLoad: PropTypes.func,
};

ResponsiveImage.defaultProps = {
  contentfulName: null,
  onLoad: null,
};
