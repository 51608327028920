import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  useClassName,
  useVisible,
  usePreload,
  preloadMedia,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageImage from '../../Components/Image';
import HomepageButton from '../../Components/Button';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import useLoop from '../../Components/useLoop';
import useSlider from '../../Components/useSlider';
import SliderBullets from '../../Components/SliderBullets';
import SliderControls from '../../Components/SliderControls';
import Center from '../../Components/Center';

import 'swiper/swiper.less';
import './testimonials.less';

export default function TestimonialsSection({ section }) {
  const classNames = useClassName('TestimonialsSection');
  const ref = useRef(null);
  const [props, methods, state] = useSlider();
  const isVisible = useVisible(ref);
  usePreload((isDesktop) => {
    preloadMedia(section.fields.testimonials, isDesktop);
  }, ref);
  const loop = useLoop({
    breakpoints: { 0: { slidesPerView: 1 } },
    slidesLength: section.fields.testimonials.length,
  });

  useEffect(() => {
    if (isVisible) {
      trackHomepageSection(() => ({
        id: section.fields.sectionId,
      }));
    }
  }, [isVisible]);

  return (
    <div
      style={{
        background: `linear-gradient(180deg, #FFF 0%, #000 100%)`,
      }}
      ref={ref}
      id={section.fields.sectionId}>
      <div
        className={classNames('container')}
        style={{
          '--primary-color': section.fields.primaryColor,
          '--secondary-color': section.fields.secondaryColor,
          ...(!isVisible && { minHeight: '1000px' }),
        }}>
        {isVisible && (
          <>
            <h1>{section.fields.title}</h1>
            <div style={{ position: 'relative' }}>
              <div className={classNames('content')}>
                <Swiper
                  height={542}
                  {...props}
                  {...loop}
                  className={classNames('wrapper')}
                  onSlideChange={({ realIndex }) =>
                    trackHomepageSlide(() => ({
                      activeIndex: realIndex,
                      name: section.fields.testimonials[realIndex].fields.name,
                      section: section.fields.sectionId,
                      url: section.fields.testimonials[realIndex].fields
                        .callToAction.fields.url,
                    }))
                  }>
                  {section.fields.testimonials.map(({ fields, sys }) => (
                    <SwiperSlide
                      key={sys.id}
                      className={classNames('slide-container')}>
                      <HomepageButton
                        cta={fields.callToAction}
                        className={classNames('slide')}>
                        <div>
                          <h3>{fields.title}</h3>
                          <div>{fields.description}</div>
                          <div>
                            <ContentfulRichText value={fields.caption} />
                            <HomepageImage field={fields.icon} />
                          </div>
                        </div>
                        <HomepageImage
                          field={fields.desktop}
                          mobile={fields.mobile}
                        />
                      </HomepageButton>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {loop.loop && (
                <>
                  <SliderControls
                    slidePrev={methods.slidePrev}
                    slideNext={methods.slideNext}
                  />
                  <Center style={{ marginTop: '24px' }}>
                    <SliderBullets
                      activeIndex={state.activeIndex}
                      length={section.fields.testimonials.length}
                      slideTo={methods.slideTo}
                    />
                  </Center>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
