import React from 'react';

import { useClassName } from 'common/hooks';

import Breadcrumbs from './Components/Breadcrumbs';
import Hero from './Components/Hero';
import Header from './Components/Header';
import Carousel from './Components/Carousel';
import List from './Components/List';
import useCustomStyle from './hooks';
import VenueDetails from './Components/Venue/Details';
import LPFrame from './Components/Frame';
import Subscribe from './Components/Custom/Subscribe';

import './section.less';

function SectionContainer({ index, id, refs, settings, children }) {
  const classNames = useClassName('CLPSection');
  const [setRef] = useCustomStyle(settings);

  return (
    <div
      className={classNames('container')}
      ref={(node) => {
        if (node) {
          refs.current[index] = node;

          setRef(node);
        }
      }}
      id={id}>
      {children}
    </div>
  );
}

export default function Section({ section, refs, index }) {
  const breadcrumbs = section.fields.components.find(
    (component) => component.sys.contentType.sys.id === 'lpBreadcrumbs'
  );

  return (
    <>
      <SectionContainer
        settings={section.fields.settings}
        id={section.fields.sectionId}
        index={index}
        refs={refs}>
        {section.fields.components.map((component, index) => {
          const func = {
            lpCard: () => Hero,
            lpHeader: () => Header,
            lpCarousel: () => Carousel,
            lpList: () => List,
            lpFrame: () => LPFrame,
            lpCustomComponent: () => {
              return {
                'Venue Detail': VenueDetails,
                Subscribe: Subscribe,
              }[component.fields.type];
            },
          }[component.sys.contentType.sys.id];

          if (!func) return null;
          const Component = func();

          if (!Component) return null;
          return <Component component={component} key={index} />;
        })}
      </SectionContainer>
      {Boolean(breadcrumbs) && (
        <Breadcrumbs component={breadcrumbs} refs={refs} />
      )}
    </>
  );
}
