import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from 'common/components';

import './loader.less';

export default class ADLoader extends Component {
  getStyles() {
    const { color, background } = this.props;
    if (color || background) {
      return {
        color,
        backgroundColor: background,
      };
    }
  }

  render() {
    return (
      <div {...this.getAttrs(true)} style={this.getStyles()}>
        <Icon name="ad-logo-half" />
      </div>
    );
  }
}

ADLoader.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
};

ADLoader.defaultProps = {};
