const { DateTime } = require('luxon');

export function addDays(startDate, totalDays) {
  return DateTime.fromJSDate(startDate).plus({ days: totalDays }).toJSDate();
}

export function subDays(startDate, totalDays) {
  return DateTime.fromJSDate(startDate).minus({ days: totalDays }).toJSDate();
}

export function subYears(startDate, totalYears) {
  return DateTime.fromJSDate(startDate).minus({ years: totalYears }).toJSDate();
}

export function differenceInDays(endDate, startDate) {
  return Math.floor(
    DateTime.fromJSDate(endDate)
      .diff(DateTime.fromJSDate(startDate), 'days')
      .toObject().days
  );
}

export function setMonth(date, month) {
  return DateTime.fromJSDate(date).set({ month }).toJSDate();
}

export function getMonth(date) {
  return DateTime.fromJSDate(date).month;
}

export function setYear(date, year) {
  return DateTime.fromJSDate(date).set({ year }).toJSDate();
}

export function getYear(date) {
  return DateTime.fromJSDate(date).year;
}

export function endOfMonth(date) {
  return DateTime.fromJSDate(date).endOf('month').toJSDate();
}

export function endOfWeek(date) {
  return DateTime.fromJSDate(date).endOf('week').toJSDate();
}

export function format(date, format) {
  return DateTime.fromJSDate(date).toFormat(format);
}

export function startOfWeek(date) {
  return DateTime.fromJSDate(date).startOf('week').toJSDate();
}

export function startOfMonth(date) {
  return DateTime.fromJSDate(date).startOf('month').toJSDate();
}
