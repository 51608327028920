import React from 'react';

export function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M6.984 7.378c.58 1.208 1.37 2.34 2.372 3.341a12.2 12.2 0 0 0 3.341 2.372c.104.05.156.075.222.094a.87.87 0 0 0 .718-.122c.056-.04.103-.088.199-.183.29-.291.437-.437.583-.532a1.67 1.67 0 0 1 1.817 0c.146.095.292.24.584.532l.162.162c.443.443.664.665.785.903a1.67 1.67 0 0 1 0 1.504c-.12.238-.342.46-.785.902l-.131.132c-.442.44-.662.662-.962.83-.333.187-.85.322-1.232.32-.344 0-.58-.067-1.05-.2a15.87 15.87 0 0 1-6.903-4.062 15.87 15.87 0 0 1-4.061-6.903c-.134-.47-.2-.706-.202-1.05a2.8 2.8 0 0 1 .32-1.232c.17-.3.39-.52.831-.962l.132-.131c.443-.443.664-.664.902-.785a1.67 1.67 0 0 1 1.504 0c.238.12.46.342.902.785l.163.162c.291.292.437.437.532.584.36.552.36 1.264 0 1.817-.095.146-.24.292-.532.583a2 2 0 0 0-.183.199.87.87 0 0 0-.122.718c.019.066.044.118.094.222"
        stroke="#8C98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
