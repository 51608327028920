import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { Link } from 'react-router-dom';
import arrow from 'common/assets/arrow-right.svg';
import './ride-hero-back.less';

const BACK_TEXT = 'Go back';

const RideHeroBack = ({ slug, color }) => {
  const className = useClassName('RideHeroBack');

  return (
    <Link to={`/venue/${slug}`} className={className('link')} style={{ color }}>
      <img src={arrow} width={16} height={16} className={className('arrow')} />
      {BACK_TEXT}
    </Link>
  );
};

RideHeroBack.propTypes = {
  slug: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RideHeroBack;
