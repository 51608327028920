import React from 'react';
import PropTypes from 'prop-types';

import { API_URL } from 'utils/env/client';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';

import googleWalletLogo from 'common/assets/google-wallet.svg';
import './wallet-button.less';

function shouldRender() {
  return ['Android'].some((environment) =>
    navigator.userAgent.toLowerCase().includes(environment.toLowerCase())
  );
}

function GoogleWalletButton({ orderId }) {
  const classNames = useClassName('WalletButton');

  const onClick = () => {
    track('Add to Wallet - Google - Success');
  };

  if (!shouldRender()) return null;
  return (
    <div className={classNames('container')}>
      <a
        href={`${API_URL}/1/passkit/download-google/${orderId}`}
        target="_blank"
        onClick={onClick}
        rel="noreferrer">
        <img
          src={googleWalletLogo}
          alt="logo"
          style={{ width: 160, marginTop: 24 }}
        />
      </a>
    </div>
  );
}

GoogleWalletButton.propTypes = {
  orderId: PropTypes.string,
};

export default GoogleWalletButton;
