import React, { useEffect } from 'react';
import { useClassName } from 'common/hooks';
import Banner from './components/Banner';
import MenuBar from './components/MenuBar';
import { fixVh } from './utils';

import './navigation-header.less';

const NavigationHeader = () => {
  const className = useClassName('NavigationHeader');

  const excludedRoutes = [/\/*-golf\/score-card*/];

  useEffect(() => {
    fixVh();
    window.addEventListener('resize', fixVh);
  }, []);

  const shouldDisplay = () => {
    return excludedRoutes.every((regexp) => {
      return !window.location.pathname.match(regexp);
    });
  };

  if (!shouldDisplay()) return null;

  return (
    <>
      <div className={className('banner-container')}>
        <Banner />
      </div>
      <div className={className('menu-container')}>
        <MenuBar />
      </div>
    </>
  );
};

export default NavigationHeader;
