import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import Submenu from './Submenu';
import CollapsibleMenu from './CollapsibleMenu';
import ImageItem from './ImageItem';

import './menu-item.less';

const MenuItem = ({
  types,
  item,
  collapsibleExpanded,
  handleCollapsibleClick,
  primaryClicked,
  setPrimaryClicked,
  handleLinkClick,
}) => {
  const className = useClassName('MenuItem');

  const [isSubmenuExpanded, setIsSubmenuExpanded] = useState(false);

  useEffect(() => {
    if (primaryClicked === item.text) {
      setIsSubmenuExpanded(true);
    } else if (!primaryClicked && isSubmenuExpanded) {
      setTimeout(() => setIsSubmenuExpanded(false), 500);
    }
  }, [primaryClicked]);

  const handleMenuItemClicked = () =>
    handleLinkClick(item.link, 'Menu sublink pressed', { label: item.text });

  const renderMenuItem = (item, index, types) => (
    <MenuItem
      item={item}
      key={`menu-item-${index}`}
      types={types}
      collapsibleExpanded={collapsibleExpanded}
      handleCollapsibleClick={handleCollapsibleClick}
      primaryClicked={primaryClicked}
      setPrimaryClicked={setPrimaryClicked}
      handleLinkClick={handleLinkClick}
    />
  );

  if (types.includes('park') || types.includes('attraction'))
    return (
      <ImageItem item={item} handleMenuItemClicked={handleMenuItemClicked} />
    );

  return (
    <div
      className={className(
        types.map((type) => `container-${type}`).concat('container')
      )}>
      <div
        className={className(
          types.map((type) => `content-${type}`).concat('content')
        )}
        onClick={() => {
          if (item.link) {
            handleMenuItemClicked();
          } else if (types.includes('primary')) {
            setPrimaryClicked(item.text);
            return;
          } else if (types.includes('collapsible-children')) {
            handleCollapsibleClick(item.text);
            return;
          }
        }}>
        {item?.icon && (
          <img
            className={className('icon')}
            src={item.icon.assetUrl}
            alt={item.icon.assetAlt}
          />
        )}
        <div className={className(['label', item.link && 'link'])}>
          {item.text}
          {item.isNew && <span className={className('new')}>NEW</span>}
          {types.includes('primary') && item.children?.length && (
            <Icon name="chevron-right" className={className('chevron-right')} />
          )}
          {types.includes('collapsible-children') && (
            <Icon
              name="angle-down"
              className={className([
                'chevron',
                collapsibleExpanded[item.text] && 'chevron-expanded',
              ])}
              color="black"
            />
          )}
        </div>
      </div>
      {item.children && types.includes('primary') && (
        <div
          className={className([
            'submenu-container',
            isSubmenuExpanded && 'submenu-containaer-expanded',
          ])}>
          <Submenu
            item={item}
            renderMenuItem={renderMenuItem}
            setPrimaryClicked={setPrimaryClicked}
            handleLinkClick={handleLinkClick}
          />
        </div>
      )}
      {item.children && types.includes('secondary') && (
        <CollapsibleMenu
          items={item.children}
          expanded={collapsibleExpanded[item.text] || false}
          renderMenuItem={renderMenuItem}
        />
      )}
    </div>
  );
};

MenuItem.propTypes = {
  types: propTypes.arrayOf(propTypes.string),
  item: propTypes.object.isRequired,
  collapsibleExpanded: propTypes.object.isRequired,
  handleCollapsibleClick: propTypes.func.isRequired,
  primaryClicked: propTypes.string,
  setPrimaryClicked: propTypes.func,
  handleLinkClick: propTypes.func.isRequired,
};

MenuItem.defaultProps = {
  types: [],
  primaryClicked: null,
  setPrimaryClicked: () => {},
};

export default MenuItem;
