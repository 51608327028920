import React from 'react';

export default function Center({ children, style }) {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...style,
      }}>
      {children}
    </div>
  );
}
