import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { AssetPropType } from 'public/schema';
import { ResponsiveImageLight, ContentfulRichText } from 'common/components';
import { DesktopOnlyFix as DesktopOnly } from 'common/components/Responsive';
import {
  standardizedTrack,
  getCategoryByCta,
  categories,
} from 'utils/analytics';
import CTA from '../CTA';
import './tickets-section.less';

const TicketsSection = ({
  id,
  ticketsSectionTitle,
  ticketsSectionSubtitle,
  ticketsSectionImage,
  ticketsSectionCtaText,
  ticketsSectionCtaLink,
  theme,
}) => {
  const className = useClassName('TicketsSection');
  const gradientColor = `linear-gradient(${theme.primaryColor}, ${theme.secondaryColor})`;

  if (
    !ticketsSectionCtaLink &&
    !ticketsSectionTitle &&
    !ticketsSectionSubtitle &&
    !ticketsSectionImage
  )
    return null;

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events

  const handleClick = () => {
    const category =
      getCategoryByCta(ticketsSectionCtaLink) || categories.ERC_INFO;

    standardizedTrack(
      'click',
      'button',
      'tickets section',
      ticketsSectionCtaText,
      category,
      trackingLabel
    );
  };

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: gradientColor }}>
      <div className={className('wrapper')}>
        {ticketsSectionImage?.assetUrl && (
          <DesktopOnly className={className('image-container')}>
            <ResponsiveImageLight
              src={ticketsSectionImage?.assetUrl}
              alt={ticketsSectionImage?.assetAlt}
              fluid
            />
          </DesktopOnly>
        )}
        <div
          className={className(
            `info-container ${!ticketsSectionImage ? 'no-image' : ''}`
          )}>
          {ticketsSectionTitle && (
            <ContentfulRichText
              className={className('title')}
              style={{ color: theme.textColor }}
              field={ticketsSectionTitle}
            />
          )}
          {ticketsSectionSubtitle && (
            <ContentfulRichText
              className={className('subtitle')}
              style={{ color: theme.textColor }}
              field={ticketsSectionSubtitle}
            />
          )}
          {ticketsSectionCtaLink && (
            <div className={className('cta-container')}>
              <CTA
                text={ticketsSectionCtaText}
                link={ticketsSectionCtaLink}
                textColor={theme.buttonTextColor}
                backgroundColor={theme.buttonColor}
                onClick={handleClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TicketsSection.propTypes = {
  id: PropTypes.string.isRequired,
  ticketsSectionTitle: PropTypes.object,
  ticketsSectionSubtitle: PropTypes.object,
  ticketsSectionImage: PropTypes.shape(AssetPropType),
  ticketsSectionCtaText: PropTypes.string,
  ticketsSectionCtaLink: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

TicketsSection.defaultProps = {
  ticketsSectionTitle: null,
  ticketsSectionSubtitle: null,
  ticketsSectionImage: null,
  ticketsSectionCtaText: '',
  ticketsSectionCtaLink: '',
};

export default TicketsSection;
