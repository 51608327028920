import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/l10n';
import { Table, Icon, Radio, Input } from 'common/lazy';
import { useClassName } from 'common/hooks';
import { CustomPagination } from 'common/components/CustomPagination';
import { ExportJson } from 'common/components/ExportJson';

import './sales-drill-down.less';

const GROUPING_MODES = {
  vendor: { name: 'vendor', value: 'productVendor' },
  product: {
    name: 'product',
    value: 'productId',
    alternateValue: 'productTitle',
  },
  variant: { name: 'variant', value: 'variantId', alternateValue: 'barcode' },
};

const SEARCH_KEYS = [
  'vendorName',
  'productTitle',
  'variantTitle',
  'barcode',
  'sku',
];

const SalesDrillDown = ({ sales, vendor }) => {
  const className = useClassName('SalesDrillDown');
  const [groupedSales, setGroupedSales] = useState([]);
  const [filteredGroupedSales, setFilteredGroupedSales] = useState([]);
  const [groupedSalesBatch, setGroupedSalesBatch] = useState([]);
  const [groupingMode, setGroupingMode] = useState(
    vendor ? GROUPING_MODES.variant : GROUPING_MODES.vendor
  );
  const [drillValue, setDrillValue] = useState({ vendor: '', product: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const limit = 15;

  useEffect(() => {
    setDrillValue({
      ...drillValue,
      vendor,
    });
  }, [sales]);

  useEffect(() => {
    setGroupedSales(getSortedGroupedSales(groupSales(groupingMode)));
  }, [groupingMode]);

  useEffect(() => {
    setGroupedSalesBatch(filteredGroupedSales.slice(0, limit));
  }, [filteredGroupedSales]);

  useEffect(() => {
    setPage(1);
    setFilteredGroupedSales(
      groupedSales.filter((sale) => {
        if (!searchTerm) return true;
        return SEARCH_KEYS.some((key) =>
          sale[key]?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [groupedSales, searchTerm]);

  const getHandleWithoutPrefix = (vendorName, handle) => {
    if (!handle) return 'Not Available';
    const vendorPrefix = vendorName.toLowerCase().replace(/[/.\s\t]+/g, '-');
    return handle.replace(`${vendorPrefix}-`, '');
  };

  const getSortedGroupedSales = (groupedSales) =>
    groupedSales?.sort((a, b) => {
      const key = Object.keys(a)[0];
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    });

  const getGroupingKey = (sale, groups = []) => {
    if (sale[groupingMode.value]) return sale[groupingMode.value];
    const saleGroup = Object.values(groups).find((g) =>
      g.find(
        (s) =>
          s[groupingMode.alternateValue] === sale[groupingMode.alternateValue]
      )
    );
    if (!saleGroup) return sale[groupingMode.alternateValue];
    return saleGroup[0][groupingMode.value];
  };

  const groupSales = (groupingMode) => {
    const groupedSales = sales
      .filter(
        (sale) =>
          (!drillValue.product ||
            (drillValue.product && sale.productTitle === drillValue.product)) &&
          (!drillValue.vendor ||
            (drillValue.vendor && sale.productVendor === drillValue.vendor))
      )
      .sort((a, b) =>
        a.productId === null ? 1 : b.productId === null ? -1 : 0
      )
      .reduce((acc, sale) => {
        const groupingKey = getGroupingKey(sale, acc);
        if (!acc[groupingKey]) acc[groupingKey] = [];
        acc[groupingKey].push(sale);
        return acc;
      }, {});

    return Object.values(groupedSales).map((groupedSale) => {
      const saleRow = {
        netQuantity: groupedSale.reduce(
          (acc, item) => (acc += item.netQuantity),
          0
        ),
        grossSales: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.grossSales), 0) * 100
        ),
        discounts: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.discounts), 0) * 100
        ),
        returns: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.returns), 0) * 100
        ),
        netSales: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.netSales), 0) * 100
        ),
        taxes: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.taxes), 0) * 100
        ),
        totalSales: formatCurrency(
          groupedSale.reduce((acc, item) => (acc += item.totalSales), 0) * 100
        ),
      };

      if (groupingMode.name === 'vendor')
        return { vendorName: groupedSale[0].productVendor, ...saleRow };

      if (groupingMode.name === 'product')
        return {
          title: groupedSale[0].productTitle,
          handle: getHandleWithoutPrefix(
            groupedSale[0].productVendor,
            groupedSale[0].productHandle
          ),
          ...saleRow,
          /// Commented for now, it can be consfusing as it shows available inventory only for variants sold in selected period
          /// Total inventory for whole product could be different if it contains more variants
          // available: uniqBy(
          //   groupedSale.map(({ variantId, availableQuantity }) => ({
          //     variantId,
          //     availableQuantity,
          //   })),
          //   'variantId'
          // ).reduce((acc, current) => (acc += current.availableQuantity), 0),
        };

      return {
        title: groupedSale[0].productTitle,
        handle: getHandleWithoutPrefix(
          groupedSale[0].productVendor,
          groupedSale[0].productHandle
        ),
        variantTitle: groupedSale[0].variantTitle || 'Default title',
        barcode: groupedSale[0].barcode,
        sku: groupedSale[0].sku,
        ...saleRow,
        available: groupedSale[0].availableQuantity,
      };
    });
  };

  const handleDrillClicked = (value) => {
    setDrillValue({
      ...drillValue,
      [groupingMode.name]: value,
    });
    setGroupingMode(
      GROUPING_MODES[groupingMode.name === 'product' ? 'variant' : 'product']
    );
  };

  const handleBackDrill = () => {
    if (groupingMode.name === 'variant') {
      setDrillValue({
        ...drillValue,
        product: '',
      });
      setGroupingMode(GROUPING_MODES['product']);
      return;
    }

    setDrillValue({
      ...drillValue,
      vendor: '',
    });
    setGroupingMode(GROUPING_MODES['vendor']);
  };

  const handleGroupingChange = (type) => {
    if (groupingMode.name === type) return;
    setDrillValue({ vendor, product: '' });
    setGroupingMode(GROUPING_MODES[type]);
  };

  const parseHeader = (value) =>
    value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });

  const isForwardDrillAvailable = () => groupingMode.name !== 'variant';

  const isBackwardDrillAvailable = () =>
    (vendor && drillValue.product) ||
    (!vendor && (drillValue.vendor || drillValue.product));

  const renderGroupingModeSelector = () => (
    <div className={className('grouping-selector')}>
      <div className={className('grouping-modes')}>
        <span className={className('group-by-label')}>Group by: </span>
        {Object.values(GROUPING_MODES)
          .filter((group) => (group.name !== 'vendor' && vendor) || !vendor)
          .map((group) => (
            <Radio
              className={className('radio')}
              key={group.name}
              type="radio"
              value={group.name}
              checked={groupingMode.name === group.name}
              label={group.name}
              onClick={() => handleGroupingChange(group.name)}
            />
          ))}
      </div>
      <div className={className('search')}>
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fluid
          icon="search"
        />
      </div>
    </div>
  );

  if (!groupedSales.length) return null;

  return (
    <div className={className('container')}>
      <div className={className('title-container')}>
        <div className={className('title')}>
          {isBackwardDrillAvailable() && (
            <Icon
              name="arrow alternate circle left outline"
              size="small"
              onClick={handleBackDrill}
              className={className('icon')}
            />
          )}
          {`Sales by ${groupingMode.name}
            ${
              drillValue.product
                ? `for ${drillValue.product.toUpperCase()}`
                : drillValue.vendor && !vendor
                ? `for ${drillValue.vendor.toUpperCase()}`
                : ''
            }`}
        </div>
        <ExportJson
          data={groupedSales}
          fileName={`${groupingMode.name}-sales`}
        />
      </div>

      {renderGroupingModeSelector()}

      <div className={className('table-containter')}>
        <Table>
          <Table.Header>
            <Table.Row>
              {Object.keys(groupedSales[0]).map((key) => (
                <Table.HeaderCell singleLine key={key}>
                  {parseHeader(key)}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {groupedSalesBatch.map((sale, index) => (
              <Table.Row key={`sale-${index}`}>
                {Object.values(sale).map((value, index) => (
                  <Table.Cell
                    key={`value-${index}`}
                    className={
                      index == 0 && isForwardDrillAvailable()
                        ? className('link')
                        : ''
                    }
                    onClick={() => {
                      if (!isForwardDrillAvailable() || index) return;
                      handleDrillClicked(value);
                    }}>
                    {value}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <CustomPagination
        page={page}
        limit={limit}
        total={filteredGroupedSales.length}
        onPageChange={(e, { activePage }) => {
          setPage(activePage);
          setGroupedSalesBatch(
            filteredGroupedSales.slice(
              (activePage - 1) * limit,
              activePage * limit
            )
          );
        }}
        className={className('pagination')}
      />
    </div>
  );
};

SalesDrillDown.propTypes = {
  sales: PropTypes.arrayOf(PropTypes.object),
  vendor: PropTypes.string,
};

SalesDrillDown.defaultProps = {
  sales: [],
  vendor: '',
};

export default SalesDrillDown;
