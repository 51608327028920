import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'common/hooks';

function useAssetField(asset) {
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  if (isMobile) return asset?.mobile;
  if (isTablet) return asset?.tablet;
  if (isDesktop) return asset?.desktop;
}

function Image({ image, className, style, ...props }) {
  return (
    <img
      src={image.fields.file.url}
      alt={image.fields.title}
      style={style}
      className={className}
      {...props}
    />
  );
}

function Video({ video, poster, style = {}, className, ...props }) {
  const videoRef = useRef(null);
  const field = useAssetField(poster?.fields);
  const [canAutoplay, setCanAutoplay] = useState(true);
  const isSafari = navigator.userAgent.toLowerCase().includes('safari');

  useEffect(() => {
    const current = videoRef.current;
    if (current) {
      current.defaultMuted = true;
      current.play().catch(() => setCanAutoplay(false));
    }
  }, []);

  if (!canAutoplay && isSafari) {
    return (
      <div
        className={`autoplay ${className}`}
        style={{
          backgroundImage: `url(${video.fields.file.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: '.6',
          ...style,
        }}
        {...props}
      />
    );
  }

  return (
    <video
      src={video.fields.file.url}
      poster={field?.fields?.file?.url}
      loop
      muted
      ref={videoRef}
      style={{ opacity: 0.6, ...style }}
      playsInline
      className={className}
      {...props}
    />
  );
}

export default function HomepageAsset({
  asset,
  poster,
  style,
  className,
  ...props
}) {
  const field = useAssetField(asset?.fields);
  if (!field) return null;

  if (field.fields.file.contentType.startsWith('image')) {
    return (
      <Image image={field} className={className} style={style} {...props} />
    );
  }

  return (
    <Video
      video={field}
      poster={poster}
      style={style}
      className={className}
      {...props}
    />
  );
}
