import { action } from 'mobx';
import BaseStore from 'common/stores/BaseStore';

const ID = '00D1U000000xmYD';
const STATUS = 'Prospecting';
const LEAD_SOURCE = 'contact form';
const LEAD_TYPE = 'Group Sales';
const FIELD_NAMES = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phone: 'phone',
  venue: '00N1U00000Ufxv8',
  numberOfPeople: '00N1U00000UfxvI',
  date: '00N1U00000UfxvD',
  groupType: '00N1U00000Ufxv3',
  leadType: '00N1U00000UsorG',
  id: 'oid',
  status: 'status',
  leadSource: 'lead_source',
  comments: '00N1U00000UfxvX',
};

export default class SalesforceStore extends BaseStore {
  @action
  async sendFormData(formData, statusKey = `sendFormData`) {
    const status = this.createStatus(statusKey);
    const {
      firstName,
      lastName,
      email,
      phone,
      venue,
      numberOfPeople,
      date,
      groupType,
      type,
      message,
      leadType,
    } = formData;
    const fields = {
      [FIELD_NAMES.firstName]: firstName,
      [FIELD_NAMES.lastName]: lastName,
      [FIELD_NAMES.email]: email,
      [FIELD_NAMES.phone]: phone,
      [FIELD_NAMES.venue]: venue,
      [FIELD_NAMES.numberOfPeople]: numberOfPeople,
      [FIELD_NAMES.date]: date,
      [FIELD_NAMES.groupType]: groupType,
      [FIELD_NAMES.leadType]: leadType || LEAD_TYPE,
      [FIELD_NAMES.id]: ID,
      [FIELD_NAMES.status]: STATUS,
      [FIELD_NAMES.leadSource]: LEAD_SOURCE,
      [FIELD_NAMES.comments]: message
        ? encodeURIComponent(`Type: ${type}\n\n${message}`)
        : null,
    };
    const body = Object.keys(fields)
      .map((key) => {
        if (fields[key]) return `${key}=${fields[key]}`;
      })
      .filter(Boolean)
      .join('&');
    try {
      await fetch(
        'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
        {
          method: 'POST',
          mode: 'no-cors',
          body,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      status.success();
    } catch (err) {
      status.error(err);
      throw err;
    }
  }
}
