import { useEffect } from 'react';
import { useDOMElement } from 'common/hooks';

export default function BrazeIframe() {
  const [elem] = useDOMElement('iframe[title="braze non-blocking modal"]');

  useEffect(() => {
    if (elem) {
      elem.contentWindow.postMessage({
        call: 'sendValue',
        value: window.innerWidth,
      });

      try {
        const body = document.querySelector('.ab-pause-scrolling');
        const touchmoveEvents = body.eventListenerList.touchmove;

        body.removeEventListener(
          'touchmove',
          touchmoveEvents[touchmoveEvents.length - 1]
        );
        body.style.setProperty('touch-action', 'auto');
      } catch (ex) {
        // Removing Braze listener that prevents moving on mobile
      }
    }
  }, [elem]);

  return null;
}
