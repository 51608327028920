import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentfulRichText } from 'common/components';
import { useClassName } from 'common/hooks';
import { AddOnCard, AddOnDetailModal } from '../AddOnCard';

import './add-ons-selector.less';

const AddOnsSelector = ({ addons, addonTitle, setQuantity, startTime }) => {
  const className = useClassName('AddOnsSelector');
  const [showAddOnDetailModal, setShowAddOnDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleAddOnDetail = (product) => {
    setShowAddOnDetailModal(true);
    setSelectedProduct(product);
  };

  const handleCloseAddOnDetail = () => {
    setShowAddOnDetailModal(false);
    setSelectedProduct(null);
  };

  const renderAddOnDetailModal = () => {
    if (!showAddOnDetailModal) {
      return null;
    }

    return (
      <AddOnDetailModal
        product={selectedProduct}
        open={showAddOnDetailModal}
        handleClose={handleCloseAddOnDetail}
      />
    );
  };

  return (
    <div className={className('container')}>
      {addonTitle && (
        <ContentfulRichText className={className('title')} field={addonTitle} />
      )}
      <div className={className('list')}>
        {addons?.map((addon) => (
          <AddOnCard
            key={addon.ticketOptionId}
            product={addon}
            startTime={startTime}
            onDetail={handleAddOnDetail}
            setQuantity={setQuantity}
          />
        ))}
      </div>
      {renderAddOnDetailModal()}
    </div>
  );
};

AddOnsSelector.propTypes = {
  addons: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuantity: PropTypes.func.isRequired,
  startTime: PropTypes.string,
};

AddOnsSelector.defaultProps = {
  addonTitle: '',
};

export default AddOnsSelector;
