import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { EXPRESS_IMAGE_URL } from 'utils/env/client';

import './venue-details-map.less';

const DEFAULT_EXPRESS_IMAGE_URL =
  'https://ad.expressimage.digital/map-module/development/index.html';

const VenueDetailsMap = ({ jibestreamDestinationId, venues }) => {
  const className = useClassName('VenueDetailsMap');

  const openVenue = async (event) => {
    if (!event?.data?.pId) return;

    const [items] = await venues.fetchItems({
      jibestreamDestinationId: `${event?.data?.pId}`,
    });
    if (!items?.length) return;

    window.open(`/venue/${items[0].slug}`, '_self');
  };

  useEffect(() => {
    window.addEventListener('message', openVenue);

    return () => {
      window.removeEventListener('message', openVenue);
    };
  }, []);

  if (!jibestreamDestinationId) return null;

  const url = `${
    EXPRESS_IMAGE_URL || DEFAULT_EXPRESS_IMAGE_URL
  }?jibestreamId=${jibestreamDestinationId}`;

  return (
    <div className={className('container')}>
      <h5>Where to find it</h5>
      <iframe frameBorder={0} src={url}></iframe>
    </div>
  );
};

VenueDetailsMap.propTypes = {
  jibestreamDestinationId: PropTypes.string,
};

export default inject('venues')(VenueDetailsMap);
