import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  MobileOnly,
  TabletOnly,
  DesktopOnlyFix as DesktopOnly,
} from 'common/components';
import { DETAIL_VENUE_PAGE_OTHER_BRANDS_TITLE } from 'common/const';
import { parseVenueDetails } from 'public/helpers/contentful/parser';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { VenueCard } from '../VenueCard';
import { Carousel } from '../../Carousel';

import './other-venues.less';

const trackingLabel = window.location.pathname;
const trackingCategory = trackingCategories.SLIDE_INFO;

const track = (name) => {
  standardizedTrack(
    'click',
    'button',
    'other brands section',
    name,
    trackingCategory,
    trackingLabel
  );
};

const venuesList = (venues, venueSlug) => {
  if (!venues?.length) return null;

  return venues.map((venue, i) => {
    if (venue?.content?.venueDetail) {
      const parsedVenue = parseVenueDetails(venue?.content?.venueDetail);
      if (parsedVenue.slug !== venueSlug)
        return <VenueCard key={`venue-card-${i}`} venue={parsedVenue} />;
    }
  });
};

const OtherVenues = ({ id, venueSlug, venues }) => {
  const className = useClassName('OtherVenues');

  if (!venues?.length || venues?.length < 2) return null;

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        <div className={className('title')}>
          {DETAIL_VENUE_PAGE_OTHER_BRANDS_TITLE}
        </div>

        <MobileOnly className={className('cards')}>
          {venuesList(venues, venueSlug)}
          <div className={className('last-spacer')} />
        </MobileOnly>

        <TabletOnly className={className('cards')}>
          {venuesList(venues, venueSlug)}
          <div className={className('last-spacer')} />
        </TabletOnly>

        <DesktopOnly>
          <Carousel
            onPrevious={() => track('previous')}
            onNext={() => track('next')}
            type={'multi-card'}>
            {venuesList(venues, venueSlug)}
          </Carousel>
        </DesktopOnly>
      </div>
    </div>
  );
};

OtherVenues.propTypes = {
  id: PropTypes.string.isRequired,
  venueSlug: PropTypes.string.isRequired,
  venues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OtherVenues;
