import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AppleWalletButton from './AppleWalletButton';
import GoogleWalletButton from './GoogleWalletButton';

function WalletButtons({ orderId, betaFeatures }) {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    betaFeatures.fetch('Wallets').then((isEnabled) => setIsEnabled(isEnabled));
  }, []);

  if (!isEnabled) return null;

  return (
    <>
      <AppleWalletButton orderId={orderId} />
      <GoogleWalletButton orderId={orderId} />
    </>
  );
}

WalletButtons.propTypes = {
  orderId: PropTypes.string,
};

export default inject('betaFeatures')(WalletButtons);
