import React from 'react';
import { Link } from 'react-router-dom';
import { sum, findLast } from 'lodash';
import { DateTime } from 'luxon';

import { isToday } from 'utils/helpers';

const ADMISSION_WARN_COUNT = 20;
const ADMISSION_DISABLE_COUNT = 40;
const CHILDREN_TICKETS = new RegExp('^(.*)AGE(.*)+5(.*)+11(.*)');
const DEFAULT_CHILDREN_CATEGORY = 'Children';
const DEFAULT_GENERAL_CATEGORY = 'General';

export function getItemName(item, inventory, quantities) {
  const discount = getItemBulkDiscount(item, inventory, quantities);
  if (discount) {
    const isBulk = discount.minQuantity > 1;
    return (
      <React.Fragment>
        {item.name}{' '}
        <b>
          {discount.bulkType}
          {isBulk ? ' group discount' : ''}
        </b>
      </React.Fragment>
    );
  } else {
    return <React.Fragment>{item.name}</React.Fragment>;
  }
}

export function getItemPrice(item, inventory, quantities) {
  return getItemOrDiscount(item, inventory, quantities).price;
}

export function getItemTax(item, inventory, quantities) {
  return getItemOrDiscount(item, inventory, quantities).tax;
}

export function getItemBulkDiscount(item, inventory, quantities = {}) {
  const discountedQuantities = {};
  for (let item of inventory) {
    const { ticketOptionId, bulkDiscounts } = item;

    if (bulkDiscounts) {
      const quantity = quantities[ticketOptionId] || 0;
      for (let discount of bulkDiscounts) {
        const { bulkType } = discount;
        if (!discountedQuantities[bulkType]) {
          discountedQuantities[bulkType] = 0;
        }
        discountedQuantities[bulkType] += quantity;
      }
    }
  }
  return findLast(item.bulkDiscounts || [], (discount) => {
    const { minQuantity, bulkType } = discount;
    return discountedQuantities[bulkType] >= minQuantity;
  });
}

export function getQuantity(item, quantities) {
  let quantity = 0;
  const addedQuantity = quantities[item.ticketOptionId];
  if (addedQuantity && addedQuantity.startTime) {
    quantity = addedQuantity.quantity;
  } else if (addedQuantity) {
    quantity = addedQuantity;
  }
  return quantity;
}

export function hasValidQuantity(item, inventory, quantities) {
  const exceedMax = exceedMaxQuantity(item, quantities);

  if (exceedMax) {
    return false;
  }

  const hasSessions =
    inventory?.some(
      (inventoryItem) => typeof inventoryItem.quantity === 'object'
    ) || false;

  if (hasSessions && !item.addon && !item.parkAccessPass) {
    const addedQuantities = Object.keys(quantities).filter(
      (q) => quantities[q].quantity > 0
    );
    const firstQuantity = quantities[addedQuantities[0]];
    const startTime = firstQuantity ? firstQuantity.startTime : null;
    const hasQuantityAdded = addedQuantities.filter(
      (id) => id === item.ticketOptionId
    ).length;
    const result =
      !hasQuantityAdded ||
      getAdmissionCount(inventory, quantities) <=
        (item.quantity && typeof item.quantity[startTime] === 'number'
          ? item.quantity[startTime]
          : 0);

    return result;
  }

  const quantity = getQuantity(item, quantities);
  if (item.bundled) {
    return quantity <= getAdmissionCount(inventory, quantities) * 2;
  } else if (item.addon) {
    return quantity <= item.quantity;
  } else {
    const itemQuantity = !isNaN(item.quantity) ? item.quantity : 0;
    return itemQuantity >= quantity;
  }
}

export function isSoldOut(item, startTime) {
  const groupQuantity = item.groupSize || 1;

  return (
    !item.quantity ||
    (Number.isInteger(item.quantity) && item.quantity < groupQuantity) ||
    (startTime && item.quantity[startTime] < groupQuantity)
  );
}

export function validateInventory(
  inventory,
  quantities,
  venue = {},
  externalBookingAvailabilities = null,
  isParkEntryEnabled = false
) {
  let canSubmit = true;
  let errorMessage;
  let errorTitle;
  const hasSessions = inventory.some(
    (inventoryItem) => typeof inventoryItem.quantity === 'object'
  );
  const admissionCount = getAdmissionCount(inventory, quantities);
  const addonCount = getAddonsCount(inventory, quantities);
  const invalidParkAccess = isParkEntryEnabled
    ? validateParkAccess({
        venue,
        inventory,
        quantities,
      })
    : null;
  const invalidMaxError = validateMaxPerTransaction(inventory, quantities);
  const invalidAdmission = validateAdmission(inventory, quantities);
  const invalidBundled = validateBundled(inventory, quantities);
  const invalidExternalBookings = externalBookingAvailabilities
    ? validateExternalBookings(
        inventory,
        venue,
        externalBookingAvailabilities,
        quantities
      )
    : false;

  if (admissionCount >= ADMISSION_DISABLE_COUNT) {
    canSubmit = false;
    errorMessage = (
      <p>
        Please complete <Link to="/group-sales">{'this form '}</Link>
        for large group purchases.
      </p>
    );
  } else if (invalidParkAccess) {
    canSubmit = false;
    errorMessage = invalidParkAccess;
  } else if (invalidMaxError) {
    canSubmit = false;
    errorMessage = invalidMaxError;
  } else if (invalidAdmission) {
    canSubmit = false;
    errorMessage = `"${invalidAdmission.name}" is not available for this date${
      hasSessions ? ' and time' : ''
    }.`;
  } else if (invalidBundled) {
    canSubmit = false;
    errorMessage = `Up to two tickets for "${invalidBundled.name}" are allowed per admission.`;
  } else if (admissionCount >= ADMISSION_WARN_COUNT) {
    errorTitle = 'Valid Id And Credit Card Required';
    errorMessage = (
      <p>
        For orders of 20 tickets or more a valid ID and the credit card used on
        this order will be required when you arrive.
      </p>
    );
  } else if (admissionCount === 0 && addonCount > 0) {
    canSubmit = false;
    errorMessage = 'Please add at least one ticket to continue your purchase.';
  } else if (admissionCount === 0) {
    canSubmit = false;
  } else if (invalidExternalBookings) {
    canSubmit = false;
    errorMessage = invalidExternalBookings;
  }

  return {
    canSubmit,
    errorTitle,
    errorMessage,
  };
}

export function addonsCount(inventory, quantities) {
  return getAddonsCount(inventory, quantities);
}

export function ticketSalesClosed(venue, reservationDate) {
  if (!venue.openingHours) return false;
  if (!venue.openingHours.projectedDays) return false;
  if (!reservationDate) return false;
  if (!isToday(reservationDate)) return false;

  const today = venue.openingHours.projectedDays[0];
  if (!today) return false;
  if (!today.closeHour) return false;

  const nj = DateTime.now().setZone('America/New_York');
  const hour = nj.hour;
  if (hour >= today.closeHour && !venue.hiddenDatePicker) {
    return true;
  }

  return false;
}

export function validateExternalBookings(
  inventory,
  venue,
  venueAvailabilities,
  quantities
) {
  if (!venue.externalBookings) return;

  const ticketOptions = Object.keys(quantities);
  const validationInventory = inventory.filter(
    (i) => ticketOptions.includes(i.ticketOptionId) && !i.addon
  );

  for (const item of validationInventory) {
    const category = CHILDREN_TICKETS.test(item?.name?.toUpperCase())
      ? DEFAULT_CHILDREN_CATEGORY
      : DEFAULT_GENERAL_CATEGORY;
    const validationItems =
      category === DEFAULT_GENERAL_CATEGORY
        ? inventory
            .filter(
              (i) => !CHILDREN_TICKETS.test(i.name.toUpperCase()) && !i.addon
            )
            .map((vi) => vi.ticketOptionId)
        : inventory
            .filter(
              (i) => CHILDREN_TICKETS.test(i.name.toUpperCase()) && !i.addon
            )
            .map((vi) => vi.ticketOptionId);

    if (!validationItems.length) continue;

    const totalQuantities = Object.entries(quantities).reduce((ac, cur) => {
      if (validationItems.includes(cur[0])) {
        return (ac += !isNaN(cur[1].quantity) ? cur[1].quantity : cur[1]);
      }
      return ac;
    }, 0);

    if (!totalQuantities) continue;

    const remainingQuantities =
      venueAvailabilities.filter(
        (rq) => rq._id.toUpperCase() === category.toUpperCase()
      )[0]?.total || 0;

    if (remainingQuantities < totalQuantities)
      return `No ${category.toLowerCase()} tickets available for ${venue.name}`;
  }
  return;
}

function getItemOrDiscount(item, inventory, quantities) {
  return getItemBulkDiscount(item, inventory, quantities) || item;
}

function getAddonParentInventory(inventory, inventoryItem) {
  return inventory.find(
    (i) => i.addons && i.addons.includes(inventoryItem.bookingItemId)
  );
}

function getAdmissionCount(inventory, quantities = {}) {
  const hasSessions = inventory.some(
    (inventoryItem) => typeof inventoryItem.quantity === 'object'
  );
  return sum(
    Object.entries(quantities).map(([ticketOptionId, quantity]) => {
      let inventoryItem = inventory.find(
        (i) => i.ticketOptionId === ticketOptionId
      );
      if (!inventoryItem) return 0;
      if (inventoryItem.addon) {
        inventoryItem =
          getAddonParentInventory(inventory, inventoryItem) || inventoryItem;
      }
      if (inventoryItem && !inventoryItem.addon) {
        const result = hasSessions ? quantity.quantity : quantity;
        const groupQuantity = inventoryItem.groupSize || 1;
        return result * groupQuantity || 0;
      } else {
        return 0;
      }
    })
  );
}

function getAddonsCount(inventory, quantities = {}) {
  const hasSessions = inventory.some(
    (inventoryItem) => typeof inventoryItem.quantity === 'object'
  );
  return sum(
    Object.entries(quantities).map(([ticketOptionId, quantity]) => {
      const inventoryItem = inventory.find(
        (i) => i.ticketOptionId === ticketOptionId
      );
      if (inventoryItem && inventoryItem.addon) {
        const result = hasSessions ? quantity.quantity : quantity;
        return result || 0;
      } else {
        return 0;
      }
    })
  );
}

function exceedMaxQuantity(item, quantities) {
  const quantity = getQuantity(item, quantities);
  return item.maxPurchase && quantity > item.maxPurchase;
}

function getTickeSimplifiedName(name) {
  return name.replace(/ \(.*\).*/i, '');
}

function validateMaxPerTransaction(inventory, quantities) {
  if (inventory.length) {
    const invalidItem = inventory.find((item) => {
      return exceedMaxQuantity(item, quantities);
    });

    if (invalidItem) {
      return `Only ${invalidItem.maxPurchase} ${getTickeSimplifiedName(
        invalidItem.name
      )} tickets can be purchased`;
    }

    return false;
  }
}

function validateParkAccess({ venue, inventory, quantities }) {
  if (!venue.isPoints) return null;
  const parkAccessProduct = inventory.find((item) => item.parkAccessPass);
  if (!parkAccessProduct) return 'Park access pass is not available';
  if (!Object.keys(quantities).length) return null;
  const totalParkAccesses = quantities[parkAccessProduct?.ticketOptionId] || 0;
  return totalParkAccesses === 0
    ? 'At least an admission ticket must be included'
    : null;
}

function validateAdmission(inventory, quantities) {
  // Once SnowCloud returns all ticket options (even those without
  // inventory) this block can be removed.
  // TODO: do all days have the exact same ticket option sets?
  // If not this may present a problem here.
  if (!inventory.length) {
    return Object.keys(quantities).some((tid) => quantities[tid] > 0);
  }
  return inventory.find((item) => {
    return !item.bundled && !hasValidQuantity(item, inventory, quantities);
  });
}

function validateBundled() {
  return false; //TODO: Add logic to validate bundles
}
