import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Asset from './Asset';
import Text from './Text';
import CTA from './CTA';

import './carousel.less';

const SPEEDS = {
  slow: 0.5,
  normal: 1,
  fast: 2,
};

const Carousel = ({ images, speed }) => {
  const className = useClassName('ModularCarousel');
  const [startPositionX, setStartPositionX] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const scrollRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    const { clientX } = e;
    setStartPositionX(clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (isMouseDown) {
      scrollRef.current.scrollLeft +=
        (startPositionX - e.clientX) * (SPEEDS[speed] || 1);
    }
  };

  const renderTextBlock = (textBlock) => {
    return (
      <div className={className('text-block')}>
        {textBlock.overline && (
          <Text key="overline" text={textBlock.overline} />
        )}
        {textBlock.title && <Text key="title" text={textBlock.title} />}
        {textBlock.body && <Text key="body" text={textBlock.body} />}
        {textBlock?.cta?.length &&
          textBlock.cta.map((cta, index) => (
            <CTA key={`cta-${index}`} cta={cta} />
          ))}
      </div>
    );
  };

  return (
    <div className={className('container')}>
      <div
        ref={scrollRef}
        className={className(['slider', isMouseDown && 'slider-active'])}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}>
        {images.map((image, index) => (
          <div
            className={className('content')}
            key={`content-${index}`}
            style={{
              backgroundColor: image.textBlock?.backgroundColor
                ? image.textBlock.backgroundColor
                : '',
            }}>
            <div className={className('image-container')}>
              <Asset asset={image} />
            </div>
            {image.textBlock && renderTextBlock(image.textBlock)}
          </div>
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  speed: PropTypes.oneOf(Object.keys(SPEEDS)),
};

Carousel.defaultProps = {
  speed: 'normal',
};

export default Carousel;
