import { hot } from 'react-hot-loader/root';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// Set smoothScroll polyfill to add Safari compatibility
import smoothscroll from 'smoothscroll-polyfill';

import { GOOGLE_CLIENT_ID } from 'utils/env/client';

import { DefaultLayout } from './containers';

smoothscroll.polyfill();

import './app.less';

class App extends React.Component {
  render() {
    return (
      <Suspense fallback={<></>}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={DefaultLayout} />
            </Switch>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Suspense>
    );
  }
}

export default hot(App);
