import React from 'react';
import { getContentfulField } from 'common/components';
import { useClassName } from 'common/hooks';

import './attraction-logo.less';

const AttractionLogo = ({ venue, bundle }) => {
  const className = useClassName('AttractionLogo');

  const getVenueImage = (venueImage) => {
    const image = getContentfulField(venueImage);
    const file = getContentfulField(image.fields.file);
    const title = getContentfulField(image.fields.title);
    const src = file.url;
    return { title, src };
  };

  if (!venue?.content?.logo && !bundle?.image) return null;

  if (bundle?.image) {
    return (
      <div className={className('image-container')}>
        <img
          className="bundle-image"
          src={bundle.image.mobileSrc}
          alt={bundle.image.imageAlt}
        />
      </div>
    );
  }

  const venueImage = getVenueImage(venue?.content?.logo);
  return (
    <div className={className('image-container')}>
      <img src={venueImage.src} alt={venueImage.title} />
    </div>
  );
};

export default AttractionLogo;
