import React from 'react';
import { Component } from 'common/helpers';

import './eyebrow.less';

export default class Eyebrow extends Component {
  render() {
    const { title, subtitle } = this.props;
    return (
      <div {...this.getAttrs()}>
        <span className={this.getElementClass('title')}>{title}</span>
        {subtitle && (
          <React.Fragment>
            {' '}
            <span className={this.getElementClass('subtitle')}>{subtitle}</span>
          </React.Fragment>
        )}
      </div>
    );
  }
}
