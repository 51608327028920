import atm from './atm.svg';
import elevator from './elevator.svg';
import escalator from './escalator.svg';
import service from './service.svg';
import parking from './parking.svg';
import restroom from './restroom.svg';
import stairs from './stairs.svg';

const ICONS = {
  atm,
  elevator,
  escalator,
  service,
  parking,
  restroom,
  stairs,
};

export default ICONS;
