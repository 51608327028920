import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';

import { useClassName, useScrollY } from 'common/hooks';
import { track } from 'utils/analytics';

import BookTicketsContainer from './BookTicketsContainer';

import './book-tickets.less';

const SCROLL_OFFSET = 50;

const BookTickets = ({ excludedRoutes, floatingComponent }) => {
  const className = useClassName('BookTickets');
  const containerRef = useRef(null);
  const [active, setActive] = useState(false);
  const [containerStyle, setContainterStyle] = useState({ zIndex: 100 });
  const { scrollY } = useScrollY();

  const shouldDisplay = () =>
    excludedRoutes.every((regexp) => !window.location.pathname.match(regexp));

  useEffect(() => {
    if (active) {
      document.body.classList.add('nonscroll');
      setContainterStyle({ zIndex: 1060 });
    } else {
      document.body.classList.remove('nonscroll');
      setTimeout(() => setContainterStyle({ zIndex: 100 }), 700);
    }
  }, [active]);

  const handleButtonClick = () => {
    if (!active) track('Book tickets modal opened');
    setActive(!active);
  };

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      className={className([
        'container',
        active && 'container-active',
        !shouldDisplay() && 'container-hidden',
        floatingComponent && 'container-floating',
        floatingComponent && scrollY > SCROLL_OFFSET && 'container-vanished',
      ])}>
      <div
        className={className(['book-button', active && 'book-button-active'])}
        onClick={handleButtonClick}>
        <span>Book Tickets</span>
      </div>
      <BookTicketsContainer active={active} setActive={setActive} />
    </div>
  );
};

BookTickets.propTypes = {
  excludedRoutes: propTypes.array,
  floatingComponent: propTypes.bool,
};

BookTickets.defaultProps = {
  excludedRoutes: [],
  floatingComponent: false,
};

export default BookTickets;
