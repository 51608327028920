import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { formatHourRange } from 'utils/l10n';

@inject('property')
@observer
export default class MallHours extends React.Component {
  state = {
    result: null,
    loading: false,
  };

  componentDidMount() {
    const today = new Date();
    this.props.property.fetchOpeningHours(
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    );
  }

  render() {
    const { property } = this.props;
    const status = property.getStatus('openingHours');
    if (status.error) {
      return <span>Could not load hours</span>;
    }
    const hours = property.getOpeningHours();
    if (status.request || !hours) {
      return <span>Loading...</span>;
    }
    const hoursObject = toJS(hours);
    const day = hoursObject.projectedDays[0];
    if (!day) return <span>Unknown</span>;
    if (day.isClosed) return <span>Closed</span>;
    return <span>{formatHourRange(day.openHour, day.closeHour)}</span>;
  }
}
