export const scrollIntoView = (element, clickDelay = 600, offset = 0) => {
  let start = null;
  const target = element && element ? element.getBoundingClientRect().top : 0;
  const firstPos = window.pageYOffset || document.documentElement.scrollTop;
  let pos = 0;

  (function () {
    var browser = ['ms', 'moz', 'webkit', 'o'];

    for (
      let x = 0, length = browser.length;
      x < length && !window.requestAnimationFrame;
      x++
    ) {
      window.requestAnimationFrame =
        window[browser[x] + 'RequestAnimationFrame'];
      window.cancelAnimationFrame =
        window[browser[x] + 'CancelAnimationFrame'] ||
        window[browser[x] + 'CancelRequestAnimationFrame'];
    }
  })();

  const showAnimation = (timestamp) => {
    if (!start) {
      start = timestamp || new Date().getTime();
    }

    let elapsed = timestamp - start;
    let progress = elapsed / clickDelay;

    const outQuad = function (n) {
      return n * (2 - n);
    };

    let easeInPercentage = +outQuad(progress).toFixed(2);

    pos =
      target === 0
        ? firstPos - firstPos * easeInPercentage
        : firstPos + target * easeInPercentage;

    window.scrollTo(0, pos - offset);

    if (
      (target !== 0 && pos >= firstPos + target) ||
      (target === 0 && pos <= 0)
    ) {
      window.cancelAnimationFrame(start);
      pos = 0;
    } else {
      window.requestAnimationFrame(showAnimation);
    }
  };
  window.requestAnimationFrame(showAnimation);
};

export const scrollTo = (element, yOffset = 0) => {
  const y =
    element.getBoundingClientRect().top +
    (window.pageYOffset || document.documentElement.scrollTop) +
    yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};
