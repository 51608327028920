import React, { useRef } from 'react';

import {
  LazyVisible,
  useClassName,
  usePreload,
  preloadAssets,
  preloadCTAs,
  preloadMedia,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSection from '../../Components/trackHomepageSection';

import './visit.less';

export default function VisitSection({ section }) {
  const classNames = useClassName('VisitSection');
  const ref = useRef();
  usePreload((isDesktop) => {
    if (isDesktop) {
      preloadAssets(section.fields.figure);
    } else {
      preloadAssets(section.fields.figureMobile);
    }

    preloadMedia(section.fields.map, isDesktop);
    preloadCTAs([
      ...section.fields.mapInfo.fields.ctas,
      ...section.fields.ctas,
    ]);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('wrapper')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('container')}>
        <HomepageImage
          field={section.fields.figure}
          mobile={section.fields.figureMobile}
          className={classNames('figure')}
        />
        <div className={classNames('header')}>
          <h3>{section.fields.header}</h3>
        </div>
        <div className={classNames('options')}>
          <div className={classNames('map')}>
            <HomepageImage
              field={section.fields.map.fields.desktop}
              mobile={section.fields.map.fields.mobile}
            />
            {Boolean(section.fields.mapInfo) && (
              <div>
                <p>{section.fields.mapInfo.fields.title}</p>
                <ContentfulRichText
                  field={{ 'en-US': section.fields.mapInfo.fields.subtitle }}
                />
                {section.fields.mapInfo.fields.ctas.map((cta) => (
                  <HomepageButton cta={cta} key={cta.sys.id} fixed={true} />
                ))}
              </div>
            )}
          </div>
          {section.fields.ctas.map((cta, index) => (
            <div
              key={cta.sys.id}
              className={classNames('link')}
              style={{ animationDelay: `${index * 150}ms` }}>
              <HomepageButton cta={cta} fixed={true} />
            </div>
          ))}
        </div>
      </div>
    </LazyVisible>
  );
}
