import React, { useEffect, useState, useRef } from 'react';
import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './menu-bar.less';

const DEFAULT_SCROLL = 120;

const MenuBar = ({ menuBar, handleCategorySelected }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [buttonsContainerExtraClasses, setButtonsContainerExtraClasses] =
    useState([]);
  const [leftControlHidden, setLeftControlHidden] = useState(true);
  const [rightControlHidden, setRightControlHidden] = useState(true);
  const scrollRef = useRef(null);
  const className = useClassName('ModularMenuBar');

  useEffect(() => {
    const selectedCategory = menuBar.categories[0];
    setCategory(selectedCategory);
    defineButtonsContainerExtraClasses();
    const rightControlHidden =
      scrollRef.current.scrollWidth <= scrollRef.current.offsetWidth;
    setRightControlHidden(rightControlHidden);
  }, []);

  const defineButtonsContainerExtraClasses = () => {
    const classes = [];
    classes.push(
      menuBar.position
        ? `category-buttons-${menuBar.position}`
        : 'category-buttons-left'
    );
    setButtonsContainerExtraClasses(classes);
  };

  const setCategory = (category) => {
    setCurrentCategory(category);
    handleCategorySelected(category);
  };

  const isSelected = (category) => currentCategory === category;

  const handleControlsVisibility = () => {
    const rightControlHidden =
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
      scrollRef.current.scrollWidth;
    const leftControlHidden = scrollRef.current.scrollLeft === 0;
    setRightControlHidden(rightControlHidden);
    setLeftControlHidden(leftControlHidden);
  };

  const handleControlClick = (side) => {
    const scroll =
      side === 'left'
        ? scrollRef.current.scrollLeft - DEFAULT_SCROLL
        : scrollRef.current.scrollLeft + DEFAULT_SCROLL;

    if (scroll < 0) scrollRef.current.scrollLeft = 0;
    else if (
      scroll >
      scrollRef.current.scrollWidth - scrollRef.current.offsetWidth
    )
      scrollRef.current.scrollLeft =
        scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;
    else scrollRef.current.scrollTo(scroll, 0);
  };

  const renderControl = (side) => {
    if (side === 'left' && leftControlHidden) return null;
    if (side === 'right' && rightControlHidden) return null;
    return (
      <div
        className={className(`control-${side}`)}
        onClick={() => handleControlClick(side)}>
        <SVGIcon name={`angle-${side}`} size="tiny" />
      </div>
    );
  };

  const renderCategoryButtons = () => {
    return (
      <div className={className('category-buttons-container')}>
        {renderControl('left')}
        <div
          className={className([
            'category-buttons',
            ...buttonsContainerExtraClasses,
          ])}
          ref={scrollRef}
          onScroll={() => handleControlsVisibility()}>
          {menuBar.categories.map((category, index) => (
            <button
              key={index}
              onClick={() => setCategory(category)}
              className={`${className('category-button')} ${
                isSelected(category) ? className('category-button-active') : ''
              }`}>
              {category}
            </button>
          ))}
        </div>
        {renderControl('right')}
      </div>
    );
  };

  return (
    <div className={className('container')}>{renderCategoryButtons()}</div>
  );
};

export default MenuBar;
