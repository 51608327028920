import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class SyncStore extends BaseStore {
  @action
  run(option) {
    return request({
      method: 'POST',
      path: `/1/sync/${option}`,
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  syncVenuePriceTypes(fileId, slugs) {
    return request({
      method: 'POST',
      path: `/1/sync/price-types`,
      body: {
        fileId,
        slugs,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  getVenuesFromFile(fileId) {
    return request({
      method: 'GET',
      path: `/1/sync/venues-from-file/${fileId}`,
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
}
