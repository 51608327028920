import React, { useEffect, useRef, useState } from 'react';
import { Divider, Input, List, Modal } from 'common/lazy';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import COUNTRIES from 'common/components/Form/TelInput/countries.json';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';

import './phone-number-input.less';

const PRIORITY_COUNTRIES = COUNTRIES.filter((c) => c.priority > 0).sort(
  (a, b) => {
    return b.priority - a.priority;
  }
);

const OTHER_COUNTRIES = COUNTRIES.filter((c) => c.priority === 0);

const INVALID_CHARS_REG = /[^\d\s()-]/g;
const STRIP_SPECIAL_REG = /[\s()-]/g;

const PhoneNumberInput = ({
  onChange,
  id,
  name,
  onBlur,
  error,
  initialValue,
  placeholder,
}) => {
  const classNames = useClassName('PhoneNumberInput');
  const inputRef = useRef();
  const [hasFocus, setFocus] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [prefix, setPrefix] = useState('+1');
  const [code, setCode] = useState('us');
  const [number, setNumber] = useState('');
  const [hiddenPrefix, setHiddenPrefix] = useState(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (initialValue) {
      setHiddenPrefix(true);
      setPrefix('');
      setNumber(initialValue);
    }
  }, []);

  const renderItems = (countries) => {
    if (!countries.length) return null;

    return (
      <List selection className={classNames('list')}>
        {countries.map((country, index) => {
          const { name, code, prefix } = country;

          const onClick = () => {
            setPrefix(prefix);
            setCode(code);
            setOpen(false);
            inputRef.current.focus();
            fireChange(prefix, number);
          };

          return (
            <List.Item
              key={index}
              className={classNames('item')}
              onClick={onClick}>
              <ReactCountryFlag svg countryCode={code} />
              <List.Content>
                <List.Header>
                  {name} {prefix}
                </List.Header>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  };

  const renderModal = () => {
    const priorityCountries = filterCountries(PRIORITY_COUNTRIES);
    const otherCountries = filterCountries(OTHER_COUNTRIES);

    const onClose = () => {
      setOpen(false);
      inputRef.current.focus();
    };

    const searchInput = (
      <Input
        className={classNames('search')}
        fluid
        placeholder="Search..."
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
      />
    );

    const content = (
      <>
        {renderItems(priorityCountries)}
        {Boolean(priorityCountries.length && otherCountries.length) && (
          <Divider fitted className={classNames('divider')} />
        )}
        {renderItems(otherCountries)}
      </>
    );

    if (isMobile) {
      return (
        <BottomSheet
          onClose={onClose}
          title={searchInput}
          isOpen={isOpen}
          content={content}
        />
      );
    }

    return (
      <Modal
        size="tiny"
        open={isOpen}
        centered={false}
        onClose={() => {
          setOpen(false);
          inputRef.current.focus();
        }}>
        <Modal.Header>
          <Input
            className={classNames('search')}
            fluid
            placeholder="Search..."
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
          />
        </Modal.Header>
        <Modal.Content scrolling className={classNames('content')}>
          {renderItems(priorityCountries)}
          {Boolean(priorityCountries.length && otherCountries.length) && (
            <Divider fitted className={classNames('divider')} />
          )}
          {renderItems(otherCountries)}
        </Modal.Content>
      </Modal>
    );
  };

  const filterCountries = (countries) =>
    countries.filter(
      (a) =>
        !search ||
        a.name.toLowerCase().includes(search.toLowerCase()) ||
        a.prefix.includes(search)
    );

  const onInputChange = (evt) => {
    const { value } = evt.target;

    const number = hiddenPrefix
      ? value
      : value.replace(INVALID_CHARS_REG, '').replace(/\s+/g, ' ');

    setNumber(number);
    fireChange(prefix, number);
  };

  const getValue = (prefix, number) => {
    if (number) {
      return (prefix + number).replace(STRIP_SPECIAL_REG, '');
    } else {
      return '';
    }
  };

  const fireChange = (prefix, number) => {
    onChange(getValue(prefix, number));
  };

  return (
    <>
      <div
        className={classNames([
          'container',
          hasFocus && 'focused',
          error && 'error',
        ])}>
        {!hiddenPrefix && (
          <div onClick={() => setOpen(true)}>
            <ReactCountryFlag svg countryCode={code} />
            <span>{prefix}</span>
            <SVGIcon name="angle-down" size="mini" />
          </div>
        )}
        <input
          ref={inputRef}
          type="text"
          value={number}
          onFocus={() => setFocus(true)}
          onBlur={(event) => {
            setFocus(false);
            onBlur &&
              onBlur({
                ...event,
                target: {
                  ...event.target,
                  value: getValue(prefix, number),
                },
              });
          }}
          onChange={onInputChange}
          id={id}
          name={name}
          placeholder={placeholder}
        />
      </div>
      {Boolean(error) && (
        <caption className={classNames('error-message')}>{error}</caption>
      )}
      {renderModal()}
    </>
  );
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
};

export default PhoneNumberInput;
