import { useWindowSize } from 'common/hooks';

export default function useCustomStyle(settings) {
  const viewport = useWindowSize();

  const style = Object.fromEntries(
    settings
      ? settings
          .map((obj) => {
            const { fields, sys } = obj;
            const type = sys.contentType.sys.id;
            const key = fields.key || fields.name;

            if (['lpAsset', 'lpLink'].includes(type)) {
              return [[key, obj]];
            }
            if (key === '--background-image') {
              return [
                [
                  'background',
                  `url(${fields.value}), linear-gradient(to bottom, #F3D8AB, #F3D8AB)`,
                ],
                ['background-blend-mode', 'multiply'],
                ['background-repeat', 'no-repeat'],
                ['background-size', 'contain'],
              ];
            }
            if (type === 'keyValue') {
              return [[key, fields.value]];
            }

            return [
              [
                key,

                (viewport.isMobile && fields.mobileValue) ||
                  (viewport.isTablet && fields.tabletValue) ||
                  (viewport.isDesktop && fields.desktopValue),
              ],
            ];
          })
          .flat()
      : []
  );

  return [
    (node) => {
      if (node) {
        for (const [key, value] of Object.entries(style)) {
          node.style.setProperty(key, value, 'important');
        }
      }
    },
    style,
  ];
}
