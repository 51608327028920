import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Message } from 'common/lazy';
import { DateTime } from 'luxon';
import { formatCurrency } from 'utils/l10n';
import { useClassName } from 'common/hooks';
import OrderDetail from './OrderDetail';
import { CustomPagination } from 'common/components/CustomPagination';
import { ExportJson } from 'common/components/ExportJson';

import './orders.less';

const Orders = ({ sales }) => {
  const [orders, setOrders] = useState([]);
  const [ordersBatch, setOrdersBatch] = useState([]);
  const [orderDetail, setOrderDetail] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 15;

  const className = useClassName('Orders');

  useEffect(() => {
    setOrders(groupSalesByOrder());
  }, [sales]);

  useEffect(() => {
    setPage(1);
    setOrdersBatch(orders.slice(0, limit));
  }, [orders]);

  const groupSalesByOrder = () => {
    const groupedSales = sales.reduce((acc, sale) => {
      if (!acc[sale.orderId]) acc[sale.orderId] = [];
      acc[sale.orderId].push(sale);
      return acc;
    }, {});

    return Object.values(groupedSales).map((order) => ({
      orderId: order[0].orderId,
      totalProducts: order.reduce((acc, item) => (acc += item.netQuantity), 0),
      totalAmount: formatCurrency(
        order.reduce((acc, item) => (acc += item.totalSales), 0) * 100
      ),
      createdAt: DateTime.fromSQL(order[0].createdAt).toFormat('LL/dd/yyyy'),
    }));
  };

  const handleOrderClick = (order) =>
    setOrderDetail({
      summary: order,
      items: sales.filter((sale) => sale.orderId === order.orderId),
    });

  const handleDetailClose = () => setOrderDetail(null);

  if (!orders.length)
    return (
      <Message info>
        <Message.Header>No results!</Message.Header>
        <Message.Content>No orders found for selected period</Message.Content>
      </Message>
    );

  return (
    <div className={className('container')}>
      <div className={className('title')}>
        Orders
        <ExportJson data={orders} fileName={'orders'} />
      </div>
      <Table>
        <Table.Header>
          <Table.Row widths="equal">
            <Table.HeaderCell>Order ID</Table.HeaderCell>
            <Table.HeaderCell>Total Products</Table.HeaderCell>
            <Table.HeaderCell>Total Amount</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ordersBatch.map((order) => (
            <Table.Row widths="equal" key={order.orderId}>
              <Table.Cell
                className={className('link')}
                onClick={() => handleOrderClick(order)}>
                {order.orderId}
              </Table.Cell>
              <Table.Cell>{order.totalProducts}</Table.Cell>
              <Table.Cell>{order.totalAmount}</Table.Cell>
              <Table.Cell>{order.createdAt}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <CustomPagination
        page={page}
        limit={limit}
        total={orders.length}
        onPageChange={(e, { activePage }) => {
          setPage(activePage);
          setOrdersBatch(
            orders.slice((activePage - 1) * limit, activePage * limit)
          );
        }}
        className={className('pagination')}
      />
      <OrderDetail orderDetail={orderDetail} handleClose={handleDetailClose} />
    </div>
  );
};

Orders.propTypes = {
  sales: PropTypes.arrayOf(PropTypes.object),
};

Orders.defaultProps = {
  sales: [],
};

export default Orders;
