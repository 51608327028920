import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Text from './Text';
import Asset from './Asset';
import CTA from './CTA';
import ImageGallery from './ImageGallery';

import './cta-block.less';

const ALIGN_SMALL_BLOCK = ['left', 'right'];

const CTABlock = ({ textBlock, background }) => {
  const className = useClassName('ModularCTABlock');
  const [containerExtraClasses, setContainerExtraClasses] = useState([]);
  const [contentExtraClasses, setContentExtraClasses] = useState([]);
  const [ctaContainerExtraClasses, setCtaContainerExtraClasses] = useState([]);
  const [titleExtraClasses, setTitleExtraClasses] = useState([]);

  const defineContainerExtraClasses = () => {
    const extraClasses = [];
    if (textBlock?.assetPosition?.toLowerCase() === 'left')
      extraClasses.push('container-reverse');
    if (textBlock.asset) extraClasses.push('container-clamp-height');
    setContainerExtraClasses(extraClasses);
  };

  const defineContentExtraClasses = () => {
    const extraClasses = [];
    if (!textBlock.asset) extraClasses.push('content-large');
    if (textBlock.textAlign) {
      extraClasses.push(`content-align-${textBlock.textAlign}`);
      if (!textBlock.asset) extraClasses.push('content-small');
    }
    setContentExtraClasses(extraClasses);
  };

  const defineCtaContainerExtraClasses = () => {
    const extraClasses = [];

    if (ALIGN_SMALL_BLOCK.includes(textBlock.textAlign)) {
      extraClasses.push('cta-container-small');
      if (!textBlock.asset && !textBlock.slider)
        extraClasses.push('cta-container-aligned-right');
    }

    setCtaContainerExtraClasses(extraClasses);
  };

  const defineTitleExtraClasses = () => {
    const extraClasses = [];
    if (!ALIGN_SMALL_BLOCK.includes(textBlock.textAlign))
      extraClasses.push('title-centered');
    setTitleExtraClasses(extraClasses);
  };

  useEffect(() => {
    defineContainerExtraClasses();
    defineContentExtraClasses();
    defineCtaContainerExtraClasses();
    defineTitleExtraClasses();
  }, []);

  return (
    <div
      className={className(['container', ...containerExtraClasses])}
      style={{ background }}>
      <div className={className(['content', ...contentExtraClasses])}>
        <div className={className('text-container')}>
          {textBlock.overline && (
            <Text
              text={textBlock.overline}
              key="overline"
              textAlign={textBlock?.textAlign}
            />
          )}
          {textBlock.title && (
            <div className={className(['title', ...titleExtraClasses])}>
              <Text
                text={textBlock.title}
                key="title"
                textAlign={textBlock?.textAlign}
              />
            </div>
          )}
          {textBlock.body && (
            <Text
              text={textBlock.body}
              key="body"
              textAlign={textBlock?.textAlign}
            />
          )}
        </div>

        {textBlock?.cta?.length && (
          <div
            className={className([
              'cta-container',
              ...ctaContainerExtraClasses,
            ])}>
            {textBlock.cta.map((cta, index) => (
              <CTA key={index} cta={cta} />
            ))}
          </div>
        )}
      </div>
      {(textBlock.asset || textBlock.slider) && (
        <div className={className('asset-container')}>
          {textBlock.slider ? (
            <ImageGallery imageGallery={textBlock.slider} />
          ) : (
            <Asset asset={textBlock.asset} />
          )}
        </div>
      )}
    </div>
  );
};

CTABlock.propTypes = {
  textBlock: PropTypes.object.isRequired,
};

export default CTABlock;
