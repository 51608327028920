import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { useClassName, useWindowSize } from 'common/hooks';
import { DesktopOnly, MobileOnly } from 'common/components';
const IMAGE_REGEX = /(jpg|jpeg|png|webp|avif|gif|svg|svg\+xml)$/;

import './asset.less';

const Asset = ({ asset }) => {
  const className = useClassName('ModularAsset');
  const { isMobile } = useWindowSize();

  const defineImageExtraClasses = () => {
    const extraClasses = [];
    if (asset.link) extraClasses.push('has-link');
    return extraClasses;
  };

  const scrollToElement = () => {
    const element = document.getElementById(kebabCase(asset.link));
    if (element) {
      const offset = 64;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset - offset;

      return window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleLinkClick = () => {
    if (!asset.link) return;
    if (['http', '/'].some((key) => asset.link.includes(key)))
      return window.open(asset.link);
    scrollToElement();
  };

  const renderImage = (url, alt) => (
    <img
      className={className(['image', ...defineImageExtraClasses()])}
      src={url}
      alt={alt}
    />
  );

  /// https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
  const renderVideo = (url, type) => (
    <video className={className('video')} controls>
      <source src={`${url}#t=0.001`} type={type} />
    </video>
  );

  const renderAsset = () => (
    <>
      <DesktopOnly className={className('media')}>
        {IMAGE_REGEX.test(asset.desktopMedia.assetContentType)
          ? renderImage(
              asset.desktopMedia.assetUrl,
              asset.desktopMedia.assetAlt
            )
          : renderVideo(
              asset.desktopMedia.assetUrl,
              asset.desktopMedia.assetContentType
            )}
      </DesktopOnly>

      <MobileOnly className={className('media')}>
        {IMAGE_REGEX.test(asset.mobileMedia.assetContentType)
          ? renderImage(asset.mobileMedia.assetUrl, asset.mobileMedia.assetAlt)
          : renderVideo(
              asset.mobileMedia.assetUrl,
              asset.mobileMedia.assetContentType
            )}
      </MobileOnly>
    </>
  );

  const renderIframe = () => (
    <iframe
      className={className('iframe')}
      src={asset.iframeUrl}
      frameBorder="0"
      allowFullScreen
      allow="xr-spatial-tracking"></iframe>
  );

  return (
    <div
      className={className('container')}
      onClick={handleLinkClick}
      style={{
        ...(Boolean(isMobile && asset.mobileHeight) && {
          height: `${asset.mobileHeight}px`,
        }),
        ...(Boolean(!isMobile && asset.desktopHeight) && {
          height: `${asset.desktopHeight}px`,
        }),
      }}>
      {asset.iframeUrl ? renderIframe() : renderAsset()}
    </div>
  );
};

Asset.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default Asset;
