import React from 'react';
import { Component } from 'common/helpers';
import {
  ContentfulImage,
  ContentLink,
  ResponsiveImage,
} from 'common/components';
import { Eyebrow } from '../Flair';

import './vertical.less';

export default class VerticalCard extends Component {
  getModifiers() {
    return [
      this.props.hover ? 'hover' : null,
      this.props.centered ? 'centered' : null,
    ];
  }

  render() {
    return <div {...this.getAttrs()}>{this.renderBody()}</div>;
  }

  renderBody() {
    const { href } = this.props;
    if (href) {
      return this.renderLink(href);
    } else {
      return this.renderContent();
    }
  }

  renderLink(href) {
    return (
      <ContentLink href={href} className={this.getElementClass('link')}>
        {this.renderContent()}
      </ContentLink>
    );
  }

  renderContent() {
    const { title, subtitle, body } = this.props;
    return (
      <React.Fragment>
        <div className={this.getElementClass('background')}>
          {this.renderImage()}
          <div className={this.getElementClass('overlay')} />
        </div>
        <div className={this.getElementClass('content')}>
          <Eyebrow title={title} subtitle={subtitle} />
          <div className={this.getElementClass('body')}>{body}</div>
        </div>
      </React.Fragment>
    );
  }

  renderImage() {
    const { image, contentfulImage } = this.props;
    if (contentfulImage) {
      return (
        <ContentfulImage
          className={this.getElementClass('image')}
          field={contentfulImage}
          ratio={0.65}
          fluid
        />
      );
    } else if (typeof image === 'string') {
      return (
        <ResponsiveImage
          className={this.getElementClass('image')}
          src={image}
          ratio={0.65}
          fluid
        />
      );
    } else {
      return <div className={this.getElementClass('image')}>{image}</div>;
    }
  }
}
