import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export default class SskReaderStore extends BaseStore {
  @action
  async createReader(body, statusKey = 'createReader') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/stripereader`,
      body,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  async updateReader(readerId, body, statusKey = 'updateReader') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/stripereader/${readerId}`,
      body,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  async deleteReader(readerId, statusKey = 'deleteReader') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/stripereader/${readerId}`,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  async searchReaders(body, statusKey = 'searchReaders') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/stripereader/search`,
      body,
    })
      .then(({ data, totalItems }) => {
        status.success();
        return { data, totalItems };
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
