import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ExternalObjectsStore extends BaseStore {
  @action
  search(params) {
    return request({
      method: 'POST',
      path: `/1/externalobjects/search`,
      body: params,
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
}
