import { useRef } from 'react';
const safeDocument = typeof document !== 'undefined' ? document : {};

export default () => {
  const hiddenBeacon = useRef();
  const { body } = safeDocument;

  const hideHelpscout = async () => {
    if (!body || !body.classList || hiddenBeacon.current) return;
    body.classList.add('helpscout-hidden');
    hiddenBeacon.current = true;
  };

  const showHelpscout = () => {
    if (!body || !body.classList || !hiddenBeacon.current) return;
    body.classList.remove('helpscout-hidden');
    hiddenBeacon.current = false;
  };

  return [hideHelpscout, showHelpscout];
};
