import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { ContentfulRichText } from 'common/components';
import { LoaderImage } from '../../Loader';
import './ride-card.less';

const trackingLabel = window.location.pathname;
const trackingCategory = trackingCategories.SLIDE_INFO;

const track = (name) => {
  standardizedTrack(
    'click',
    'button',
    'other slides section',
    name,
    trackingCategory,
    trackingLabel
  );
};

const RideCard = ({ ride }) => {
  const className = useClassName('RideCard');
  const { image, title, slug } = ride;

  const content = (
    <>
      <LoaderImage
        className={className('image')}
        src={image.assetUrl}
        alt={image.assetAlt}
        loadingHeight={200}
        fluid
      />
      <div className={className('title')}>
        <ContentfulRichText field={title} />
      </div>
    </>
  );

  if (slug) {
    return (
      <a
        className={className('container')}
        href={`${slug}`}
        onClick={() => track('Card pressed')}>
        {content}
      </a>
    );
  } else {
    return <div className={className('container')}>{content}</div>;
  }
};

RideCard.propTypes = {
  ride: PropTypes.shape({
    title: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
};

export default RideCard;
