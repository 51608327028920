import { observable, action } from 'mobx';
import { request, formatApiDate } from 'utils/api';
import BaseStore from './BaseStore';

export default class BundlesStore extends BaseStore {
  @observable register = new Map();
  @observable addons = [];
  @observable items = [];
  @observable totalItems = 0;
  @observable limit = 20;
  @observable page = 1;
  @observable categories = [];
  @observable unavailabilityDates = new Map();
  @observable productAvailability = new Map();
  @observable sort = {
    order: 'asc',
    field: 'name',
  };

  @action
  setSort({ field, order }) {
    this.sort = {
      field,
      order,
    };
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setBundle(bundle, bundleSlug) {
    this.register.set(bundleSlug, bundle);
  }

  get(id) {
    return this.register.get(id);
  }

  @action
  fetchUnavailabilityDates(productId, statusKey = `productId:${productId}`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/bundles/getUnavailabilityDates/${productId}`,
    })
      .then((data) => {
        this.unavailabilityDates.set(productId, data);
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchItemBySlug(slug, statusKey = `slug:${slug}`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/bundles/${slug}`,
    })
      .then((data) => {
        this.register.set(data.slug, data);
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  getProductAvailability(
    { productId, externalTicket = false, venueId, date, quantity },
    statusKey = 'create'
  ) {
    const status = this.createStatus(statusKey);
    const formatedDate = formatApiDate(date);
    const productAvailability = this.productAvailability.get(
      `${productId}-${formatedDate}`
    );

    if (productAvailability) return productAvailability;

    return request({
      method: 'POST',
      path: `/1/bundles/${productId}/availability`,
      body: {
        externalTicket,
        venueId,
        date: formatedDate,
        quantity,
      },
    })
      .then((data) => {
        status.success();
        this.productAvailability.set(`${productId}-${formatedDate}`, data);
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  getAddonsAvailability(addons, date, statusKey = 'addonsAv') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/bundles/addonsavailability`,
      body: {
        addonsIds: addons,
        date: formatApiDate(date),
      },
    })
      .then(({ data }) => {
        const addonsAvailability = data.addonsAvailability;
        this.updateAddons(addonsAvailability);
        status.success();
        return addonsAvailability;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  updateAddons(addons) {
    this.addons = [...this.addons, ...addons];
  }
}
