import React from 'react';
import PropTypes from 'prop-types';
import { Table, Modal, Button } from 'common/lazy';
import { formatCurrency } from 'utils/l10n';

const OrderDetail = ({ orderDetail, handleClose }) => {
  if (!orderDetail) return null;

  return (
    <Modal onClose={handleClose} open={Boolean(orderDetail)} closeIcon>
      <Modal.Header>Order {orderDetail.summary.orderId}</Modal.Header>
      <Modal.Content>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>ID</Table.Cell>
              <Table.Cell>{orderDetail.summary.orderId}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total products</Table.Cell>
              <Table.Cell>{orderDetail.summary.totalProducts}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total amount</Table.Cell>
              <Table.Cell>{orderDetail.summary.totalAmount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created at</Table.Cell>
              <Table.Cell>{orderDetail.summary.createdAt}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Discounts</Table.HeaderCell>
              <Table.HeaderCell>Returns</Table.HeaderCell>
              <Table.HeaderCell>Tax</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderDetail.items.map((item, index) => (
              <Table.Row key={`item-${index}`}>
                <Table.Cell>{item.productTitle}</Table.Cell>
                <Table.Cell>{item.netQuantity}</Table.Cell>
                <Table.Cell>{formatCurrency(item.discounts * 100)}</Table.Cell>
                <Table.Cell>{formatCurrency(item.returns * 100)}</Table.Cell>
                <Table.Cell>{formatCurrency(item.taxes * 100)}</Table.Cell>
                <Table.Cell>{formatCurrency(item.totalSales * 100)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

OrderDetail.propTypes = {
  orderDetail: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

OrderDetail.defaultProps = {
  orderDetail: null,
};

export default OrderDetail;
