import React, { useRef, useState } from 'react';

import { useClassName } from 'common/hooks';
import { Pagination } from 'public/components';
import { ControlledTabs } from 'public/components/Tabs';
import { scrollTo } from 'utils/helpers/scroll';

import Center from '../Center';
import Card from '../Card';
import useCustomStyle from '../../hooks';

import './list.less';

function ListWithTabs({ component }) {
  const classNames = useClassName('CLPList');
  const [tagIndex, setTagIndex] = useState(0);
  const [page, setPage] = useState(1);
  const ref = useRef();
  const [setRef, style] = useCustomStyle(component.fields.settings);
  const pageSize = parseInt(style['--page-size']);

  const tags = [
    ...new Set(component.fields.items.map((a) => a.fields.tags).flat()),
  ].sort(function (a, b) {
    if (!component?.fields?.tagOrder?.length) return a.localeCompare(b);

    return (
      component.fields.tagOrder.indexOf(a) -
      component.fields.tagOrder.indexOf(b)
    );
  });
  const items = component.fields.items.filter((item) =>
    item.fields?.tags?.includes(tags[tagIndex])
  );
  const pagedItems = items.filter((item, index) => {
    const indexMin = (page - 1) * pageSize;
    const indexMax = indexMin + pageSize;
    return index >= indexMin && index < indexMax;
  });
  const totalPages = Math.ceil(items.length / pageSize);

  return (
    <div
      ref={(node) => {
        ref.current = node;
        setRef(node);
      }}>
      <Center>
        <ControlledTabs
          names={tags}
          selectedIndex={tagIndex}
          styles={{ container: classNames('tabs') }}
          onClick={(tagName) => {
            setTagIndex(tags.findIndex((tag) => tag === tagName));
            setPage(1);
          }}
        />
      </Center>
      <Center className={classNames('container')} style={style}>
        {pagedItems.map((item) => (
          <Card key={item.sys.id} item={item} />
        ))}
      </Center>
      {totalPages > 1 && (
        <Center>
          <Pagination
            totalPages={Math.ceil(items.length / pageSize)}
            activePage={page}
            onPageChange={(e, data) => {
              setPage(data.activePage);

              scrollTo(ref.current, -138);
            }}
            className={classNames('pagination')}
          />
        </Center>
      )}
    </div>
  );
}

function ListWithoutTabs({ component }) {
  const classNames = useClassName('CLPList');
  const [setRef, style] = useCustomStyle(component.fields.settings);
  const pageSize = parseInt(style['--page-size']);
  const [page, setPage] = useState(1);

  const items = component.fields.items;
  const pagedItems = items.slice(0, page * pageSize);

  return (
    <div ref={setRef}>
      <Center className={classNames('container')} style={style}>
        {pagedItems.map((item) => (
          <Card key={item.sys.id} item={item} />
        ))}
      </Center>
      {pagedItems.length < items.length && (
        <Center className={classNames('explore-more')}>
          <button
            className={classNames('explore-more')}
            onClick={() => setPage((page) => page + 1)}>
            Explore More
          </button>
        </Center>
      )}
    </div>
  );
}

export default function List({ component }) {
  if (component.fields.groupTags) {
    return <ListWithTabs component={component} />;
  }

  return <ListWithoutTabs component={component} />;
}
