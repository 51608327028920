import React from 'react';
import propTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useClassName } from 'common/hooks';

import './popular-searches.less';

const PopularSearches = ({
  popularSearches,
  showResults,
  handleLinkClicked,
}) => {
  const className = useClassName('PopularSearches');

  const handlePopularSearchClick = (url, title) =>
    handleLinkClicked(
      'Popular search clicked',
      { linkTo: url, linkTitle: title },
      url
    );

  const getStyle = (popularSearch) => {
    if (!popularSearch?.mobileImage) return null;
    return {
      background: `linear-gradient(113.01deg, rgba(0, 0, 0, 0.7) 20.55%, rgba(0, 0, 0, 0) 68.52%) , url(${popularSearch.mobileImage.assetUrl})`,
    };
  };

  return (
    <ScrollContainer
      className={className('container')}
      vertical={false}
      horizontal={true}
      hideScrollbars={true}>
      <div className={className('inner-title')}>Popular Searches</div>
      <div className={className('popular-searches')}>
        {popularSearches.map((item, index) => (
          <div
            className={className([
              'popular-search',
              showResults && 'popular-search-no-results',
            ])}
            key={`popular-search-${index}`}
            onClick={() => handlePopularSearchClick(item.link, item.heading)}
            style={getStyle(item)}>
            {!showResults && (
              <img
                src={item.image.assetUrl}
                alt={item.image.assetAlt}
                className={className('popular-search-image')}
              />
            )}
            <div
              className={className([
                'popular-search-title',
                showResults && 'popular-search-title-border',
              ])}>
              {item.heading}
            </div>
          </div>
        ))}
      </div>
    </ScrollContainer>
  );
};

PopularSearches.propTypes = {
  popularSearches: propTypes.array,
  showResults: propTypes.bool,
  handleLinkClicked: propTypes.func.isRequired,
};

PopularSearches.defaultProps = {
  popularSearches: [],
  showResults: false,
};

export default PopularSearches;
