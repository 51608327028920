import { useRef, useState } from 'react';

export default function useSlider(initialSlide = 0) {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(initialSlide);

  const slideTo = (index) => swiperRef.current.slideTo(index);

  const slidePrev = () => swiperRef.current.slidePrev();

  const slideNext = () => swiperRef.current.slideNext();

  return [
    {
      onSlideChangeTransitionStart: ({ realIndex }) =>
        setActiveIndex(realIndex),
      onBeforeInit: (swiper) => {
        swiperRef.current = swiper;
      },
      initialSlide,
    },
    {
      slideTo,
      slidePrev,
      slideNext,
    },
    {
      activeIndex,
    },
  ];
}
