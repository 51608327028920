import React from 'react';
import { ResponsiveImage, ResponsiveImageLight } from '../ResponsiveImage';
import getContentfulField from './getContentfulField';

export default class ContentfulImage extends React.Component {
  render() {
    const { field, light, ...rest } = this.props;
    let image = getContentfulField(field);
    if (Array.isArray(image)) {
      image = image[0];
    }

    if (!image || !image.fields) {
      return null;
    }
    const file = getContentfulField(image.fields.file);
    const title = getContentfulField(image.fields.title);
    const src = file.url;

    let ratio = null;
    if (file.details.image) {
      const { width, height } = file.details.image;
      ratio = width / height;
    }

    return light ? (
      <ResponsiveImageLight src={src} alt={title} ratio={ratio} {...rest} />
    ) : (
      <ResponsiveImage src={src} alt={title} ratio={ratio} {...rest} />
    );
  }
}
