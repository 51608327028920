import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import './review-card.less';

const ReviewCard = ({ card, style }) => {
  const className = useClassName('ReviewCard');
  const { title, description, author } = card;

  return (
    <div className={className('container')} style={style}>
      {title && (
        <ContentfulRichText className={className('title')} field={title} />
      )}
      {description && (
        <ContentfulRichText
          className={className('description')}
          field={description}
        />
      )}
      {author && <div className={className('author')}>{author}</div>}
    </div>
  );
};

ReviewCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.object,
    description: PropTypes.object,
    author: PropTypes.string,
  }).isRequired,
};

export default ReviewCard;
