import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

import './mast.less';

const SUPPORTED = document.body.style.mixBlendMode === '';

export default class Mast extends Component {
  static Top = class MastTop extends Component {
    render() {
      return <div {...this.getAttrs()}>{this.props.children}</div>;
    }
  };

  static Content = class MastContent extends Component {
    render() {
      return (
        <div {...this.getAttrs()}>
          {SUPPORTED && (
            <svg
              className={this.getElementClass('overlay')}
              viewBox="0 0 100 100"
              preserveAspectRatio="none">
              <polygon
                className={this.getElementClass('polygon')}
                points="0 0 100 0 0 100"
              />
            </svg>
          )}
          {this.props.children}
        </div>
      );
    }
  };

  getModifiers() {
    return [this.props.inverted ? 'inverted' : null];
  }

  render() {
    return <div {...this.getAttrs()}>{this.props.children}</div>;
  }
}

Mast.propTypes = {
  inverted: PropTypes.bool,
};

Mast.defaultProps = {
  inverted: false,
};
