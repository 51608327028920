// Removing lazy load due to a issue with the ResponsiveImage component
// Looks like we can't lazy load the Ref component
import {
  Container as SemanticContainer,
  Dropdown as SemanticDropdown,
  DropdownDivider as SemanticDropdownDivider,
  DropdownHeader as SemanticDropdownHeader,
  DropdownItem as SemanticDropdownItem,
  DropdownMenu as SemanticDropdownMenu,
  DropdownSearchInput as SemanticDropdownSearchInput,
  Icon as SemanticIcon,
  IconGroup as SemanticIconGroup,
  Menu as SemanticMenu,
  MenuMenu as SemanticMenuMenu,
  MenuItem as SemanticMenuItem,
  Message as SemanticMessage,
  MessageContent as SemanticMessageContent,
  MessageHeader as SemanticMessageHeader,
  MessageItem as SemanticMessageItem,
  MessageList as SemanticMessageList,
  Label as SemanticLabel,
  LabelDetail as SemanticLabelDetail,
  LabelGroup as SemanticLabelGroup,
  Form as SemanticForm,
  FormGroup as SemanticFormGroup,
  FormButton as SemanticFormButton,
  FormCheckbox as SemanticFormCheckbox,
  FormDropdown as SemanticFormDropdown,
  FormField as SemanticFormField,
  FormInput as SemanticFormInput,
  FormRadio as SemanticFormRadio,
  FormSelect as SemanticFormSelect,
  FormTextArea as SemanticFormTextArea,
  Grid as SemanticGrid,
  GridColumn as SemanticGridColumn,
  GridRow as SemanticGridRow,
  Table as SemanticTable,
  TableBody as SemanticTableBody,
  TableCell as SemanticTableCell,
  TableFooter as SemanticTableFooter,
  TableHeader as SemanticTableHeader,
  TableHeaderCell as SemanticTableHeaderCell,
  TableRow as SemanticTableRow,
  Input as SemanticInput,
  Checkbox as SemanticCheckbox,
  Select as SemanticSelect,
  Popup as SemanticPopup,
  PopupContent as SemanticPopupContent,
  PopupHeader as SemanticPopupHeader,
  Button as SemanticButton,
  ButtonContent as SemanticButtonContent,
  ButtonGroup as SemanticButtonGroup,
  ButtonOr as SemanticButtonOr,
  Header as SemanticHeader,
  HeaderContent as SemanticHeaderContent,
  HeaderSubheader as SemanticHeaderSubheader,
  Modal as SemanticModal,
  ModalActions as SemanticModalActions,
  ModalHeader as SemanticModalHeader,
  ModalContent as SemanticModalContent,
  ModalDescription as SemanticModalDescription,
  Image as SemanticImage,
  ImageGroup as SemanticImageGroup,
  Divider as SemanticDivider,
  Loader as SemanticLoader,
  Dimmer as SemanticDimmer,
  Pagination as SemanticPagination,
  Segment as SemanticSegment,
  SegmentGroup as SemanticSegmentGroup,
  SegmentInline as SemanticSegmentInline,
  List as SemanticList,
  ListContent as SemanticListContent,
  ListDescription as SemanticListDescription,
  ListHeader as SemanticListHeader,
  ListIcon as SemanticListIcon,
  ListItem as SemanticListItem,
  ListList as SemanticListList,
  Card as SemanticCard,
  CardContent as SemanticCardContent,
  CardDescription as SemanticCardDescription,
  CardGroup as SemanticCardGroup,
  CardHeader as SemanticCardHeader,
  CardMeta as SemanticCardMeta,
  Progress as SemanticProgress,
  TextArea as SemanticTextArea,
  Ref as SemanticRef,
  Statistic as SemanticStatistic,
  StatisticGroup as SemanticStatisticGroup,
  StatisticLabel as SemanticStatisticLabel,
  StatisticValue as SemanticStatisticValue,
  Accordion as SemanticAccordion,
  AccordionAccordion as SemanticAccordionAccordion,
  AccordionContent as SemanticAccordionContent,
  AccordionPanel as SemanticAccordionPanel,
  AccordionTitle as SemanticAccordionTitle,
  Radio as SemanticRadio,
  Breadcrumb as SemanticBreadcrumb,
  BreadcrumbSection as SemanticBreadcrumbSection,
  BreadcrumbDivider as SemanticBreadcrumbDivider,
  Flag as SemanticFlag,
  Responsive as SemanticResponsive,
  Step as SemanticStep,
  StepContent as SemanticStepContent,
  StepDescription as SemanticStepDescription,
  StepGroup as SemanticStepGroup,
  StepTitle as SemanticStepTitle,
} from 'semantic-ui-react';

export const Container = SemanticContainer;
SemanticDropdown.Divider = SemanticDropdownDivider;
SemanticDropdown.Header = SemanticDropdownHeader;
SemanticDropdown.Item = SemanticDropdownItem;
SemanticDropdown.Menu = SemanticDropdownMenu;
SemanticDropdown.SearchInput = SemanticDropdownSearchInput;
export const Dropdown = SemanticDropdown;
SemanticIcon.Group = SemanticIconGroup;
export const Icon = SemanticIcon;
SemanticMenu.Menu = SemanticMenuMenu;
SemanticMenu.Item = SemanticMenuItem;
export const Menu = SemanticMenu;
SemanticMessage.Content = SemanticMessageContent;
SemanticMessage.Header = SemanticMessageHeader;
SemanticMessage.Item = SemanticMessageItem;
SemanticMessage.List = SemanticMessageList;
export const Message = SemanticMessage;
export const MessageContent = SemanticMessageContent;
SemanticLabel.Detail = SemanticLabelDetail;
SemanticLabel.Group = SemanticLabelGroup;
export const Label = SemanticLabel;
SemanticForm.Group = SemanticFormGroup;
SemanticButton.Content = SemanticButtonContent;
SemanticButton.Group = SemanticButtonGroup;
SemanticButton.Or = SemanticButtonOr;
SemanticForm.Button = SemanticFormButton;
SemanticForm.Checkbox = SemanticFormCheckbox;
SemanticForm.Dropdown = SemanticFormDropdown;
SemanticForm.Field = SemanticFormField;
SemanticForm.Input = SemanticFormInput;
SemanticForm.Radio = SemanticFormRadio;
SemanticForm.Select = SemanticFormSelect;
SemanticForm.TextArea = SemanticFormTextArea;
export const Form = SemanticForm;
export const FormGroup = SemanticFormGroup;
SemanticGrid.Column = SemanticGridColumn;
SemanticGrid.Row = SemanticGridRow;
export const Grid = SemanticGrid;
SemanticTable.Body = SemanticTableBody;
SemanticTable.Cell = SemanticTableCell;
SemanticTable.Footer = SemanticTableFooter;
SemanticTable.Header = SemanticTableHeader;
SemanticTable.HeaderCell = SemanticTableHeaderCell;
SemanticTable.Row = SemanticTableRow;
export const Table = SemanticTable;
export const Input = SemanticInput;
export const Checkbox = SemanticCheckbox;
export const Select = SemanticSelect;
SemanticPopup.Content = SemanticPopupContent;
SemanticPopup.Header = SemanticPopupHeader;
export const Popup = SemanticPopup;
export const Button = SemanticButton;
SemanticHeader.Content = SemanticHeaderContent;
SemanticHeader.Subheader = SemanticHeaderSubheader;
export const Header = SemanticHeader;
SemanticModal.Actions = SemanticModalActions;
SemanticModal.Header = SemanticModalHeader;
SemanticModal.Content = SemanticModalContent;
SemanticModal.Description = SemanticModalDescription;
export const Modal = SemanticModal;
export const ModalActions = SemanticModalActions;
export const ModalHeader = SemanticModalHeader;
export const ModalContent = SemanticModalContent;
SemanticImage.Group = SemanticImageGroup;
export const Image = SemanticImage;
export const Divider = SemanticDivider;
export const Loader = SemanticLoader;
export const Dimmer = SemanticDimmer;
export const Pagination = SemanticPagination;
SemanticSegment.Group = SemanticSegmentGroup;
SemanticSegment.Inline = SemanticSegmentInline;
export const Segment = SemanticSegment;
SemanticList.Content = SemanticListContent;
SemanticList.Description = SemanticListDescription;
SemanticList.Header = SemanticListHeader;
SemanticList.Icon = SemanticListIcon;
SemanticList.Item = SemanticListItem;
SemanticList.List = SemanticListList;
export const List = SemanticList;
SemanticCard.Content = SemanticCardContent;
SemanticCard.Description = SemanticCardDescription;
SemanticCard.Group = SemanticCardGroup;
SemanticCard.Header = SemanticCardHeader;
SemanticCard.Meta = SemanticCardMeta;
export const Card = SemanticCard;
export const Progress = SemanticProgress;
export const TextArea = SemanticTextArea;
export const TableCell = SemanticTableCell;
export const TableRow = SemanticTableRow;
export const Ref = SemanticRef;
SemanticStatistic.Group = SemanticStatisticGroup;
SemanticStatistic.Label = SemanticStatisticLabel;
SemanticStatistic.Value = SemanticStatisticValue;
export const Statistic = SemanticStatistic;
SemanticAccordion.Accordion = SemanticAccordionAccordion;
SemanticAccordion.Content = SemanticAccordionContent;
SemanticAccordion.Panel = SemanticAccordionPanel;
SemanticAccordion.Title = SemanticAccordionTitle;
export const Accordion = SemanticAccordion;
export const Radio = SemanticRadio;
SemanticBreadcrumb.Section = SemanticBreadcrumbSection;
SemanticBreadcrumb.Divider = SemanticBreadcrumbDivider;
export const Breadcrumb = SemanticBreadcrumb;
export const Flag = SemanticFlag;
export const Responsive = SemanticResponsive;
SemanticStep.Content = SemanticStepContent;
SemanticStep.Description = SemanticStepDescription;
SemanticStep.Group = SemanticStepGroup;
SemanticStep.Title = SemanticStepTitle;
export const Step = SemanticStep;
