import inRegions from '@segment/in-regions';

import {
  DEFAULT_PREFERENCES,
  GDPR_PREFERENCES,
  CCPA_PREFERENCES,
} from './const';

export default function getInitialPreferences() {
  const inCA = inRegions(['CA']);
  const inEU = inRegions(['EU']);
  if (inCA()) {
    return CCPA_PREFERENCES;
  } else if (inEU()) {
    return GDPR_PREFERENCES;
  } else {
    return DEFAULT_PREFERENCES;
  }
}
