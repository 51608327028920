import { once } from 'lodash';
import { loadScript } from 'utils/helpers';

import {
  JIBESTREAM_CLIENT_ID,
  JIBESTREAM_CLIENT_SECRET,
  JIBESTREAM_CUSTOMER_ID,
  JIBESTREAM_VENUE_ID,
} from 'utils/env/client';

import { MAP_STYLE } from './const';

const API_URL = 'https://api.jibestream.com';
const JMAP_CDN_URL = 'https://cdn.jibestream.com/web/4.6.4/jmap.min.js';
const UIKIT_CDN_URL =
  'https://cdn.jibestream.com/web/plugins/mapuikit/v1.1.0/mapuikit.js';

export const getActiveVenue = once(async () => {
  await loadScript(JMAP_CDN_URL);
  return new Promise(function (resolve, reject) {
    const core = new jmap.core.JCore({
      auth: new jmap.core.Auth(JIBESTREAM_CLIENT_ID, JIBESTREAM_CLIENT_SECRET),
      customerId: JIBESTREAM_CUSTOMER_ID,
      host: API_URL,
    });
    core.populateVenueWithDefaultBuilding(
      JIBESTREAM_VENUE_ID,
      (error, activeVenue) => {
        if (error) {
          reject(error);
        } else {
          resolve(activeVenue);
        }
      }
    );
  });
});

export const getMap = once(async () => {
  await Promise.all([loadScript(JMAP_CDN_URL), loadScript(UIKIT_CDN_URL)]);
  return new Promise((resolve) => {
    const mapEl = document.createElement('div');
    mapEl.className = 'map';
    document.body.appendChild(mapEl);
    const jibestream = jmap.init({
      host: API_URL,
      auth: new jmap.core.Auth(JIBESTREAM_CLIENT_ID, JIBESTREAM_CLIENT_SECRET),
      customerId: JIBESTREAM_CUSTOMER_ID,
      venueId: JIBESTREAM_VENUE_ID,
      applyDisplayMode: true,
      showAllAmenities: true,
      showAllPathTypes: true,
      jsonStyle: MAP_STYLE,
      width: 200,
      height: 200,
    });
    jmap.dispatcher.subscribe('ready', () => {
      const { control } = jibestream;
      const uiKit = new MapUIKit(control, {
        padding: [20, 20, 20, 20],
      });
      resolve({
        mapEl,
        uiKit,
        control,
      });
    });
  });
});
