import React from 'react';
import PropTypes from 'prop-types';
import { Component } from '../../helpers';
import { ResponsiveImage } from '../ResponsiveImage';

import './animated-sprite.less';

export default class AnimatedSprite extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      frame: 0,
    };
    this.active = false;
  }

  componentDidMount() {
    this.active = true;
    this.nextFrame();
  }

  componentWillUnmount() {
    this.active = false;
  }

  nextFrame = () => {
    if (this.active) {
      this.setState({
        frame: this.state.frame + 1,
      });
      const { fps } = this.props;
      setTimeout(this.nextFrame, 1000 / fps);
    }
  };

  getStyles() {
    const { frames } = this.props;
    const { frame } = this.state;
    const pct = (100 / frames) * (frame % frames);
    const transform = `translateX(-${pct}%)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  render() {
    const { contentfulName } = this.props;
    return (
      <div ref={this.ref} {...this.getAttrs(true)}>
        <img
          src={ResponsiveImage.getContentfulBaseUrl(contentfulName)}
          style={this.getStyles()}
          className={this.getElementClass('image')}
        />
      </div>
    );
  }
}

AnimatedSprite.propTypes = {
  fps: PropTypes.number,
  frames: PropTypes.number.isRequired,
  contentfulName: PropTypes.string,
};

AnimatedSprite.defaultProps = {
  fps: 24,
};
