import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { SemanticElement } from 'common/helpers';

import './reveal.less';

export default class Reveal extends SemanticElement {
  static className = 'reveal';

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    this.forceUpdate();
  }, 200);

  getStyles() {
    if (this.canApplyStyles()) {
      const { open } = this.props;
      const el = this.ref.current;
      const height = open && el ? el.scrollHeight : 0;
      return {
        height,
      };
    }
  }

  canApplyStyles() {
    if (this.props.mobile) {
      return window.innerWidth <= 767;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div {...this.getProps()} style={this.getStyles()} ref={this.ref}>
        {this.props.children}
      </div>
    );
  }
}

Reveal.propTypes = {
  open: PropTypes.bool,
  mobile: PropTypes.bool,
};

Reveal.defaultProps = {
  open: false,
  mobile: false,
};
