import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { LoaderImage } from '../../Loader';
import './venue-card.less';

const trackingLabel = window.location.pathname;
const trackingCategory = trackingCategories.SLIDE_INFO;

const track = (name) => {
  standardizedTrack(
    'click',
    'button',
    'other venues section',
    name,
    trackingCategory,
    trackingLabel
  );
};

const VenueCard = ({ venue }) => {
  const className = useClassName('VenueCard');
  const { image, cardTitle, slug } = venue;

  const content = (
    <>
      <LoaderImage
        className={className('image')}
        src={image.assetUrl}
        alt={image.assetAlt}
        loadingHeight={200}
        fluid
      />
      <div className={className('title')}>{cardTitle}</div>
    </>
  );

  if (slug) {
    return (
      <a
        className={className('container')}
        href={`${slug}`}
        onClick={() => track('Venue card pressed')}>
        {content}
      </a>
    );
  } else {
    return <div className={className('container')}>{content}</div>;
  }
};

VenueCard.propTypes = {
  venue: PropTypes.shape({
    cardTitle: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
};

export default VenueCard;
