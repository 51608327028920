import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
``;
import { Component } from 'common/helpers';
import {
  getContentfulField,
  Badge,
  Layout,
  SVGIcon as Icon,
} from 'common/components';
import { track } from 'utils/analytics';
import { Banner } from '../../Banner';
import { CartBadge } from '../../CartBadge';
import Subnav from './Subnav';
import logo from 'common/assets/logo-text-black.svg';
import './desktop-header.less';

@inject('menu', 'appSession', 'property')
@observer
@withRouter
export default class DesktopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subnavActive: false,
      focusedNav: null,
      error: null,
    };
  }

  componentDidMount() {
    this.setupRouteEvents();
  }

  componentWillUnmount() {
    this.destroyRouteEvents();
  }

  routeDidUpdate() {
    this.setState({
      subnavActive: false,
    });
  }

  onNavLinkEnter = () => {
    this.setState({
      subnavActive: false,
    });
  };

  onSubnavLinkEnter = (title, items, announcement) => {
    this.setState({
      subnavActive: true,
      subnavTitle: title,
      subnavItems: items,
      subnavAnnouncement: announcement,
    });
  };

  onNavLeave = () => {
    this.setState({
      subnavActive: false,
    });
  };

  getModifiers() {
    return [this.state.subnavActive ? 'subnav-active' : null];
  }

  handleLogoClick = () => track('AmericanDream Logo Pressed');

  renderMenu() {
    const menuItems = getContentfulField(this.props.menu.menu.fields.items);

    return menuItems.map((item) => {
      if (!item?.fields) return null;

      const {
        text,
        link,
        children,
        childrenTitle,
        announcement,
        isNew,
        external,
      } = item.fields;

      const itemText = text && getContentfulField(text);
      const itemLink = link && getContentfulField(link);
      const itemIsNew = isNew && getContentfulField(isNew);
      const itemExternal = external && getContentfulField(external);
      const itemChildren = children && getContentfulField(children);
      const itemChildrenTitle =
        childrenTitle && getContentfulField(childrenTitle);
      const itemAnnouncement = announcement && getContentfulField(announcement);

      if (!itemText) return null;

      if (itemChildren) {
        return this.renderSubnavLink(
          itemText,
          itemChildrenTitle,
          itemChildren,
          itemAnnouncement,
          itemIsNew
        );
      } else {
        return this.renderNavLink(itemText, itemLink, itemIsNew, itemExternal);
      }
    });
  }

  render() {
    return (
      <header
        className={this.getComponentClass()}
        onMouseLeave={this.onNavLeave}>
        <Banner />
        <Layout className={this.getElementClass('main')}>
          <Layout.Group>
            <NavLink
              to="/"
              className={this.getElementClass('logo')}
              onClick={this.handleLogoClick}>
              <img src={logo} width="225" height="40" alt="AMERICAN DREAM" />
            </NavLink>
          </Layout.Group>
          <Layout.Group className={this.getElementClass('menu')}>
            <div>
              <div className={this.getElementClass('top')}>
                {this.renderHoursOfOperation()}
              </div>
            </div>

            <div>
              <div className={this.getElementClass('top')}>
                {this.renderMenu()}
              </div>
              <Subnav
                active={this.state.subnavActive}
                title={this.state.subnavTitle}
                items={this.state.subnavItems}
                announcement={this.state.subnavAnnouncement}
              />
            </div>
            <div
              className={this.getElementClass('icons')}
              onMouseEnter={this.onNavLinkEnter}>
              <Link to="/profile" className={this.getElementClass('icon-link')}>
                <Icon name="user" size="small" />
              </Link>
              <Link to="/cart" className={this.getElementClass('icon-link')}>
                <CartBadge />
              </Link>
            </div>
          </Layout.Group>
        </Layout>
      </header>
    );
  }

  renderNavLink(name, path, isNew = false, external = false) {
    if (external) {
      return (
        <Link
          key={name}
          to={{ pathname: path }}
          className={this.getElementClass('nav-link')}
          target="_blank"
          onClick={() => this.onLinkPressed(name)}
          onMouseEnter={() => this.onNavLinkEnter(name)}>
          {name}
          {isNew && <Badge text="NEW" />}
        </Link>
      );
    }
    return (
      <NavLink
        key={name}
        to={path}
        className={this.getElementClass('nav-link')}
        onClick={() => this.onLinkPressed(name)}
        onMouseEnter={() => this.onNavLinkEnter(name)}>
        {name}
        {isNew && <Badge text="NEW" />}
      </NavLink>
    );
  }

  renderSubnavLink(name, title, items, announcement, isNew = false) {
    return (
      <span
        key={name}
        className={this.getElementClass('subnav-link')}
        onMouseEnter={() => this.onSubnavLinkEnter(title, items, announcement)}>
        <span className={this.getElementClass('subnav-content')}>
          {name}
          {isNew && <Badge text="NEW" />}
        </span>
      </span>
    );
  }

  onLinkPressed = (name) => {
    track('Menu link pressed', { label: name });
  };

  handleShowHoursClicked = () => {
    track('View hours opened', { label: 'View hours' });
    this.props.history.push('/hours-and-directions');
  };

  renderHoursOfOperation() {
    return (
      <div
        className={this.getElementClass('nav-hours')}
        onClick={this.handleShowHoursClicked}
        onMouseEnter={() => this.onNavLinkEnter()}>
        <div className={this.getElementClass('hours-title')}>
          <Icon name="clock-nine" className={this.getElementClass('clock')} />
          Open hours
          <Icon
            name="chevron-right"
            className={this.getElementClass('chevron')}
          />
        </div>
      </div>
    );
  }
}
