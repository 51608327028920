import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'common/lazy';
import { NumericUpDown } from 'common/components';
import { useClassName } from 'common/hooks';
import { ProductPropType } from 'public/schema';
import { isSoldOut } from 'common/helpers';
import { parseApiDate } from 'utils/api';
import './add-on-card.less';

const COST_INDICATOR = '$';

const AddOnCard = ({ product, onDetail, setQuantity, startTime }) => {
  const [quantity, setTicketQuantity] = useState(0);
  const className = useClassName('AddOnCard');

  const parsePrice = () => {
    const price = (parseFloat(product.price) / 100).toFixed(2);
    return price % 1 != 0 ? price : parseInt(price);
  };

  const getSoldOutLabel = (item) =>
    parseApiDate(item.date) < new Date() ? 'No Longer Available' : 'Sold Out';

  const handleChange = (quantity) => {
    setQuantity(product, quantity);
    setTicketQuantity(quantity);
  };

  return (
    <Container className={className('container')}>
      <div className={className('image-container')}>
        <img
          src={product.image}
          alt={product.name}
          className={className('image')}
        />
        <div className={className('price-container')}>
          <span className={className('price')}>
            {COST_INDICATOR}
            {parsePrice()}
          </span>
        </div>
      </div>
      <div className={className('info-container')}>
        <div className={className('name-container')}>
          <span className={className('name')}>{product.name}</span>
        </div>
        <div className={className('description-container')}>
          <div
            className={className('description')}
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </div>
        <div className={className('footer')}>
          <span
            className={className('details-link')}
            onClick={() => onDetail(product)}>
            View details
          </span>
          {isSoldOut(product, startTime) ? (
            <span className={className('sold-out')}>
              {getSoldOutLabel(product)}
            </span>
          ) : (
            <NumericUpDown
              value={quantity}
              min={0}
              max={product.quantity}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

AddOnCard.propTypes = {
  product: PropTypes.shape(ProductPropType).isRequired,
  onDetail: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  startTime: PropTypes.string,
};

export default AddOnCard;
