import { observable, action, reaction } from 'mobx';
import { localStorage } from 'utils/helpers';
import { captureError } from 'utils/sentry';
import { parseApiDate } from 'utils/api';

const LOCAL_STORAGE_KEY = 'checkout';
export default class Checkout {
  constructor() {
    reaction(
      () => this.checkout,
      (checkout) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(checkout));
      }
    );
  }

  @observable
  checkout = this.hydrateCheckout();

  hydrateCheckout() {
    const str = localStorage.getItem(LOCAL_STORAGE_KEY);
    let checkout = {};
    if (str) {
      try {
        checkout = JSON.parse(str);
      } catch (err) {
        captureError(err);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
    return checkout;
  }

  @action
  update(props) {
    const { venue, startTime, reservationDate, addons, tickets } = props;
    this.updateCheckout({
      venue,
      startTime,
      reservationDate: parseApiDate(reservationDate),
      addons,
      tickets,
    });
    return true;
  }

  @action
  finish(response) {
    this.updateCheckout(response);
  }

  updateCheckout(checkout) {
    this.checkout = checkout;
  }
}
