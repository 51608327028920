import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';

const getLinks = (type) => {
  const directory = { url: '/directory', title: 'Directory' };

  return (
    {
      luxury: [{ url: '/the-avenue', title: 'The Avenue' }],
      restaurant: [
        directory,
        { url: '/directory/dining', title: 'Eat & Dine' },
      ],
      shopping: [directory, { url: '/directory/shopping', title: 'Shops' }],
      entertainment: [
        directory,
        { url: '/directory/entertainment', title: 'Entertainment' },
      ],
    }[type] || [directory]
  );
};

const VenueDetailsBreadcrumb = ({ title, type }) => {
  return <Breadcrumb links={[...getLinks(type), { url: '#', title: title }]} />;
};

VenueDetailsBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default VenueDetailsBreadcrumb;
