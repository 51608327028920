import React from 'react';
import propTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';

import './cart-badge.less';

const CartBadge = observer(({ handleLinkClick, cart }) => {
  const className = useClassName('CartBadge');
  const quantity = cart.getTotalQuantity();

  return (
    <div
      className={className('container')}
      onClick={() => handleLinkClick('/cart', 'Nav cart clicked')}>
      <Icon name="nav-cart" className={className('icon')} />
      {Boolean(quantity) && (
        <div className={className('quantity')}>{quantity}</div>
      )}
      <div className={className('cart-label')}>My Cart</div>
    </div>
  );
});

CartBadge.propTypes = {
  handleLinkClick: propTypes.func.isRequired,
};

export default inject('cart')(CartBadge);
