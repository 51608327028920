import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import AddOnCardNew from './AddOnCardNew';
import './add-ons-selector-new.less';

const AddOnsSelectorNew = ({ addons, newAddon, handleSetQuantity }) => {
  const className = useClassName('AddOnsSelectorNew');
  const [selectedCategory, setSelectedCategory] = useState('All');

  ///REMOVE AFTER DISPLAYING CATEGORIES AGAIN
  useEffect(() => {
    setSelectedCategory('All');
  }, []);
  //////////////////////////////////////////

  const getAddonsCategories = () => {
    return Object.keys(addons).sort((a, b) => {
      if (a === 'Featured') return -1;
      if (b === 'Featured') return 1;
      if (a === 'Other') return 1;
      if (b === 'Other') return -1;
      return 0;
    });
  };

  const renderAddonsCategories = () => {
    //Hiding for now
    // const addonsCategories = getAddonsCategories();
    // addonsCategories.unshift('All');
    // return (
    //     <div className={className('addons-categories')}>
    //         {
    //             addonsCategories.map(category =>
    //                 <div
    //                     key={category}
    //                     className={`category ${category === selectedCategory ? 'selected' : ''}`}
    //                     onClick={() => setSelectedCategory(category)}
    //                 >
    //                     {category}
    //                 </div>
    //             )
    //         }
    //     </div>
    // );
    return null;
  };

  const renderAddons = () => {
    const addonsCategories = getAddonsCategories().filter((category) => {
      if (selectedCategory === 'All') return true;
      return category === selectedCategory;
    });
    return (
      <div className={className('categories-container')}>
        {addonsCategories.map((category) => (
          <div key={category} className={className('addons-container')}>
            {/* //Hiding for now <p className='title'>{category}</p> */}
            {addons[category].map((addon) => (
              <AddOnCardNew
                key={addon.ticketOptionId}
                product={addon}
                currentQuantity={addon.currentQuantity}
                minimumQuantity={addon.minimumQuantity}
                setQuantity={handleSetQuantity}
                newAddon={newAddon}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={className('container')}>
      {renderAddonsCategories()}
      {renderAddons()}
    </div>
  );
};

AddOnsSelectorNew.propTypes = {
  addons: PropTypes.object.isRequired,
  handleSetQuantity: PropTypes.func.isRequired,
  newAddon: PropTypes.bool,
};

AddOnsSelectorNew.defaultProps = {
  newAddon: true,
};

export default AddOnsSelectorNew;
