import React from 'react';
import { PropTypes } from 'prop-types';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';
import './custom-message.less';

const CustomMessage = ({ type, children }) => {
  const className = useClassName('CustomMessage');
  const colors = {
    info: 'grey',
    success: 'green',
    warning: 'yellow',
    error: 'red',
  };
  const icons = {
    info: 'info',
    success: 'success-circle',
    warning: 'exclamation-triangle',
    error: 'exclamation-triangle',
  };
  const iconName = icons[type];
  const color = colors[type] || 'grey';

  return (
    <div className={`${className('container')} ${type}`}>
      <Icon name={iconName} color={color} transparent />
      <div className="content">{children}</div>
    </div>
  );
};

CustomMessage.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  children: PropTypes.node.isRequired,
};

CustomMessage.defaultProps = {
  type: 'info',
};

export default CustomMessage;
