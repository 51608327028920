import { observable, action, autorun } from 'mobx';
import { localStorage } from 'utils/helpers';

const LOCAL_STORAGE_KEY = 'features';

const KNOWN_FEATURES = ['buy-tickets'];

export default class FeatureStore {
  @observable
  features = this.hydrate();

  constructor() {
    autorun(() => {
      // mobx won't run this function without .join because it sucks.
      const str = Array.from(this.features).join(',');
      if (str) {
        localStorage.setItem(LOCAL_STORAGE_KEY, str);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    });
  }

  has(name) {
    return this.features.has(name);
  }

  @action
  add(name) {
    return this.features.add(name);
  }

  @action
  remove(name) {
    this.features.delete(name);
  }

  @action
  clear() {
    this.features.clear();
  }

  exists(name) {
    return KNOWN_FEATURES.includes(name);
  }

  hydrate() {
    const str = localStorage.getItem(LOCAL_STORAGE_KEY);
    return new Set(str ? str.split(',') : []);
  }
}
