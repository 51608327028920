import qsStringify from './query-stringify';
import appSession from 'common/stores/AppSession';
import { API_URL } from '../env/client';

export default function request(options) {
  const {
    path,
    method,
    body,
    params,
    token,
    file,
    pure = false,
  } = Object.assign(
    {
      method: 'GET',
      token: appSession.token,
    },
    options
  );

  const headers = Object.assign(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    options.headers || {}
  );

  const paramsPath = Object.keys(params || {}).length
    ? `?${qsStringify(params)}`
    : '';
  const endpoint = `${API_URL.replace(/\/$/, '')}/${path.replace(
    /^\//,
    ''
  )}${paramsPath}`;
  if (token) headers.Authorization = `Bearer ${token}`;
  let promise;
  if (method.toUpperCase() === 'GET') {
    promise = fetch(endpoint, { headers });
  } else {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      delete headers['Content-Type'];
      promise = fetch(endpoint, {
        method,
        headers,
        body: formData,
      });
    } else {
      promise = fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify(pure ? body : cleanBody(body)),
      });
    }
  }

  return promise.then((res) => {
    if (res.status === 401 && appSession.token) {
      // If a 401 occurred and there is a token,
      // assume the token is bad, reset it and reload the page.
      appSession.reset();
      window.location.reload();
    } else if (res.status === 204) {
      return;
    }
    if (
      ['text/csv', 'application/pdf'].includes(
        res.headers.get('Content-type')
      ) &&
      res.headers.get('Content-Disposition').includes('filename=')
    ) {
      return res.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        const filename = res.headers
          .get('Content-Disposition')
          .split(';')[1]
          .replace('filename=', '');

        a.download = filename.trim();
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        return null;
      });
    }

    return res.text().then((response) => {
      let json;
      try {
        json = JSON.parse(response);
      } catch (e) {
        throw new Error('Bad JSON response from API');
      }
      if (!json) throw new Error('Null JSON response from API');
      const { error } = json;
      if (error) {
        const err = new Error(error.message);
        err.details = error.details || [];
        err.status = res.status;
        throw err;
      }
      return json;
    });
  });
}

function cleanBody(body) {
  if (body) {
    Object.keys(body).forEach((key) => {
      if (body[key] == null) {
        delete body[key];
      }
    });
  }
  return body;
}
