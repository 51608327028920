import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';

const getLinks = (type) => {
  const event = { url: '/events/category', title: 'Events' };
  const links = [event];
  if (type.slug && type.name) {
    links.push({ url: `/venue/${type.slug}`, title: type.name });
  }
  return links;
};

const EventDetailsBreadcrumb = ({ type }) => {
  return <Breadcrumb links={[...getLinks(type)]} />;
};

EventDetailsBreadcrumb.propTypes = {
  type: PropTypes.shape({
    name: PropTypes.any,
    slug: PropTypes.any,
  }),
};

export default EventDetailsBreadcrumb;
