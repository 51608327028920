import React from 'react';

import { useClassName } from 'common/hooks';
import { CustomTabs } from 'public/components/Tabs';
import VenueBreadcrumb from 'public/components/Breadcrumb';
import { scrollTo } from 'utils/helpers/scroll';

import Link from '../Link';

import './breadcrumbs.less';

export default function Breadcrumbs({ component, refs }) {
  const classNames = useClassName('CLPBreadcrumbs');

  const onClickTab = (item) => {
    if (item.sys.contentType.sys.id === 'keyValue') {
      scrollTo(
        refs.current.find((ref) => ref.id === item.fields.value),
        -48
      );
    }
  };

  if (component.fields.type === 'Type B') {
    return (
      <VenueBreadcrumb
        links={component.fields.items.map((item) => ({
          url: item.fields.value,
          title: item.fields.key,
        }))}
        className={classNames('type-b')}
      />
    );
  }

  return (
    <CustomTabs
      fixed
      styles={{ container: classNames('container') }}
      refs={refs}>
      {component.fields.items.map((item) => {
        if (item.sys.contentType.sys.id === 'keyValue') {
          return (
            <span
              key={item.sys.id}
              name={item.fields.value}
              onClick={() => onClickTab(item)}>
              {item.fields.key}
            </span>
          );
        }

        return <Link key={item.sys.id} component={item} />;
      })}
    </CustomTabs>
  );
}
