import React, { useEffect } from 'react';
import HeroSection from './Sections/Hero';
import ParksSection from './Sections/Parks';
import PassesSection from './Sections/Passes';

import './animations.less';
import TestimonialsSection from './Sections/Testimonials';
import StoresSection from './Sections/Stores';
import DiningSection from './Sections/Stores/Dining';
import EventsSection from './Sections/Events';
import MerchandiseSection from './Sections/Merchandise';
import VisitSection from './Sections/Visit';
import CommunitySection from './Sections/Community';
import NewsSection from './Sections/News';
import Menu from './Components/Menu';

function getType(section) {
  return section.sys.contentType.sys.id;
}

function renderSection(section, sections) {
  const type = getType(section);

  if (type === 'sectionHero') {
    return <HeroSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionParks') {
    return <ParksSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionPasses') {
    return <PassesSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionTestimonials') {
    return <TestimonialsSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionStores' && section.fields.type === 'large') {
    const smallSection = sections.find(
      (a) => getType(a) === 'sectionStores' && a.fields.type === 'small'
    );
    return (
      <StoresSection
        large={section}
        small={smallSection}
        key={section.sys.id}
      />
    );
  }

  if (type === 'sectionStores' && section.fields.type === 'dining') {
    return <DiningSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionEvents') {
    return <EventsSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionMerchandise') {
    return <MerchandiseSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionVisit') {
    return <VisitSection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionCommunity') {
    return <CommunitySection section={section} key={section.sys.id} />;
  }

  if (type === 'sectionNews') {
    return <NewsSection section={section} key={section.sys.id} />;
  }

  return null;
}

export default function Home({ entry }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sections = entry.fields.sections;

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        {sections.map((section) => renderSection(section, sections))}
        <Menu items={entry.fields.menu} />
      </div>
    </>
  );
}
