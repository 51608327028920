import React from 'react';
import { withRouter } from 'react-router-dom';
import { Component } from 'common/helpers';
import { track } from 'utils/analytics';
import {
  getContentfulField,
  getContentfulAssetUrlandAlt,
  ContentfulRichText,
  ResponsiveImageLight,
  DesktopOnly,
  MobileOnly,
  Video,
} from 'common/components';
import { Spacer } from '../Spacer';
import arrow from 'common/assets/arrow-right.svg';
import linkArrow from 'common/assets/link-right.svg';

import './card.less';

@withRouter
export default class Card extends Component {
  state = {
    redirect: null,
  };

  getCTALink(cta) {
    if (cta) {
      const {
        fields: { link, name },
      } = getContentfulField(cta);
      const url = getContentfulField(link);
      const text = getContentfulField(name);

      return { url, text };
    }
    return null;
  }

  getCardContent(card) {
    const {
      sys: { id },
      fields: {
        name,
        title,
        description,
        responsiveImage,
        logo,
        type,
        cta,
        themeColor,
        video,
        subcards,
      },
    } = card;

    const cardType = type && getContentfulField(type);
    const cardLink = cta && this.getCTALink(cta);
    const theme = themeColor && getContentfulField(themeColor);
    const cardName = getContentfulField(name);
    const subcardsList = getContentfulField(subcards);

    let cardLogo, logoAlt, cardImage;

    if (logo) {
      const { assetUrl, assetAlt } = getContentfulAssetUrlandAlt(logo);
      cardLogo = assetUrl;
      logoAlt = assetAlt;
    }

    if (responsiveImage) {
      const {
        fields: { alt, desktop, mobile },
      } = getContentfulField(responsiveImage);
      const imageAlt = alt && getContentfulField(alt);
      const { assetUrl: desktopSrc } = desktop
        ? getContentfulAssetUrlandAlt(desktop)
        : {};
      const { assetUrl: mobileSrc } = mobile
        ? getContentfulAssetUrlandAlt(mobile)
        : {};
      cardImage = { imageAlt, desktopSrc, mobileSrc };
    }

    const { assetUrl: videoSrc } = video
      ? getContentfulAssetUrlandAlt(video)
      : {};

    return {
      id,
      cardName,
      title,
      description,
      cardType,
      cardLink,
      cardLogo,
      logoAlt,
      theme,
      cardImage,
      videoSrc,
      subcardsList,
    };
  }

  redirect = (name, path) => {
    this.trackCardPress(name);
    if (path.includes('http')) {
      window.open(path, '_blank');
    } else {
      this.props.history.push(path);
    }
  };

  trackCardPress = (name) => {
    track('Card pressed', { label: name });
  };

  handleVideoPlay = (id, name, evt) => {
    evt.preventDefault();
    const video = document.getElementById(`video-${id}`);
    if (!video) return null;

    const playStatus = this.state.play || {};

    if (playStatus[id]) {
      playStatus[id] = false;
      video.pause();
    } else {
      playStatus[id] = true;
      video.controls = true;
      video.play();

      track('Video Play', { label: name });

      video.onended = () => {
        track('Video Ended', { label: name });
      };
    }

    this.setState({ play: playStatus });
  };

  renderMultiLinkSubcards = (subcardsList) => {
    if (subcardsList && subcardsList.length) {
      return subcardsList.map((subcard, index) => {
        const { cardName, title, cardLink } = this.getCardContent(subcard);

        return (
          <div
            key={index}
            className="cta"
            onClick={() => this.redirect(cardName, cardLink.url)}>
            <ContentfulRichText field={title} />
            <img src={linkArrow} width={8} height={16} />
          </div>
        );
      });
    }
  };

  render() {
    const {
      id,
      cardName,
      title,
      description,
      cardType,
      cardLink,
      cardLogo,
      logoAlt,
      theme,
      cardImage,
      videoSrc,
      subcardsList,
    } = this.getCardContent(this.props.card);

    if (cardType === 'attraction') {
      return (
        <div
          {...this.getAttrs()}
          onClick={() => this.redirect(cardName, cardLink.url)}
          className={`card ${this.getElementClass('attraction-card')}`}>
          {cardImage?.desktopSrc && (
            <DesktopOnly className={this.getElementClass('main-image')}>
              <ResponsiveImageLight
                alt={cardImage.imageAlt}
                src={cardImage.desktopSrc}
                fluid
              />
            </DesktopOnly>
          )}
          {cardImage?.mobileSrc && (
            <MobileOnly className={this.getElementClass('main-image')}>
              <ResponsiveImageLight
                alt={cardImage.imageAlt}
                src={cardImage.mobileSrc}
                fluid
              />
            </MobileOnly>
          )}
          <div className={this.getElementClass('content')}>
            <div className={this.getElementClass('top')}>
              {cardLogo && (
                <div className={this.getElementClass('logo')}>
                  <ResponsiveImageLight alt={logoAlt} src={cardLogo} fluid />
                </div>
              )}
              <div
                className={this.getElementClass('cta')}
                style={{ backgroundColor: theme }}>
                <DesktopOnly className={this.getElementClass('arrow')}>
                  <img src={arrow} width={32} height={32} />
                </DesktopOnly>
                <MobileOnly className={this.getElementClass('arrow')}>
                  <img src={arrow} width={24} height={24} />
                </MobileOnly>
              </div>
            </div>
            {description && (
              <div className={this.getElementClass('description')}>
                <ContentfulRichText field={description} />
              </div>
            )}
          </div>
        </div>
      );
    } else if (cardType === 'directory' && !videoSrc) {
      return (
        <div
          {...this.getAttrs()}
          onClick={() => this.redirect(cardName, cardLink.url)}>
          {cardImage?.desktopSrc && (
            <DesktopOnly>
              <ResponsiveImageLight
                className={this.getElementClass('directory-main-image')}
                alt={cardImage.imageAlt}
                src={cardImage.desktopSrc}
                width={500}
              />
            </DesktopOnly>
          )}
          {cardImage?.mobileSrc && (
            <MobileOnly>
              <ResponsiveImageLight
                className={this.getElementClass('directory-main-image')}
                alt={cardImage.imageAlt}
                src={cardImage.mobileSrc}
                fluid
              />
            </MobileOnly>
          )}
        </div>
      );
    } else if (cardType === 'directory' && videoSrc) {
      return (
        <div {...this.getAttrs()}>
          <div
            className={this.getElementClass('video-container')}
            onClick={(evt) => this.handleVideoPlay(id, cardName, evt)}>
            <DesktopOnly>
              <Video
                id={`video-${id}`}
                className={this.getElementClass('video')}
                src={videoSrc}
                poster={`${cardImage.desktopSrc}?w=900`}
                preload="metadata"
                controls={false}
                controlsList="nodownload"
              />
            </DesktopOnly>
            <MobileOnly>
              <Video
                id={`video-${id}`}
                className={`${this.getElementClass('video')} ${
                  this.state.play && 'play'
                }`}
                src={videoSrc}
                poster={`${cardImage.desktopSrc}?w=600`}
                preload="metadata"
                controls={false}
                controlsList="nodownload"
              />
            </MobileOnly>
          </div>
        </div>
      );
    } else if (cardType === 'text') {
      return (
        <div
          {...this.getAttrs()}
          className={`card ${this.getElementClass('text-card')}`}>
          {cardImage?.desktopSrc && (
            <DesktopOnly className="main-image">
              <ResponsiveImageLight
                alt={cardImage.imageAlt}
                src={cardImage.desktopSrc}
                fluid
              />
            </DesktopOnly>
          )}
          {cardImage?.mobileSrc && (
            <MobileOnly className="main-image">
              <ResponsiveImageLight
                alt={cardImage.imageAlt}
                src={cardImage.mobileSrc}
                fluid
              />
            </MobileOnly>
          )}
          {title && (
            <>
              <Spacer size="s" />
              <ContentfulRichText field={title} className={`title ${theme}`} />
            </>
          )}
          {description && (
            <>
              <Spacer size="s" />
              <ContentfulRichText
                field={description}
                className={`description ${theme}`}
              />
            </>
          )}
        </div>
      );
    } else if (cardType === 'info-two-columns') {
      return (
        <a
          href={cardLink.url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => this.trackCardPress(cardName)}>
          <div
            {...this.getAttrs()}
            className={`card ${this.getElementClass('two-columns-card')}`}>
            <div className="left-content">
              <DesktopOnly>
                <ResponsiveImageLight alt={logoAlt} src={cardLogo} width={80} />
              </DesktopOnly>
              <ContentfulRichText field={title} className="title" />
              <ContentfulRichText field={description} className="description" />
            </div>
            {cardImage?.desktopSrc && (
              <DesktopOnly
                className="main-image"
                alt={cardImage.imageAlt}
                style={{
                  backgroundImage: `url(${cardImage.desktopSrc})`,
                }}></DesktopOnly>
            )}
            {cardImage?.mobileSrc && (
              <MobileOnly
                className="main-image"
                alt={cardImage.imageAlt}
                style={{
                  backgroundImage: `url(${cardImage.mobileSrc})`,
                }}></MobileOnly>
            )}
          </div>
        </a>
      );
    } else if (cardType === 'info') {
      return (
        <div
          {...this.getAttrs()}
          className={`card ${this.getElementClass('info-card')}`}>
          {title && (
            <>
              <ContentfulRichText field={title} className="title" />
              <Spacer size="xs" />
            </>
          )}
          {description && (
            <>
              <ContentfulRichText field={description} className="description" />
              <Spacer size="xs" />
            </>
          )}
          {cardLink && (
            <div
              className="cta"
              onClick={() => this.redirect(cardName, cardLink.url)}>
              {cardLink.text}
            </div>
          )}
        </div>
      );
    } else if (cardType === 'multi-link') {
      return (
        <div
          {...this.getAttrs()}
          className={`card ${this.getElementClass('multi-link-card')}`}>
          <ContentfulRichText field={title} className="title" />
          <Spacer size="xs" />
          {this.renderMultiLinkSubcards(subcardsList)}
        </div>
      );
    }
    return null;
  }
}
