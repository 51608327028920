import React from 'react';
import PropTypes from 'prop-types';
import {
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
} from 'common/components';
import { useClassName } from 'common/hooks';

import './attraction-screen-divider.less';

const AttractionScreenDivider = ({ color, background }) => {
  const className = useClassName('AttractionScreenDivider');

  return (
    <div
      className={className('container')}
      style={{ backgroundColor: background }}>
      <DesktopOnly>
        <svg
          width="100%"
          height="188"
          viewBox="0 0 1460 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 1001.247 146.235 C 841.342 189.085 698.847 35.163 535.097 13.406 C 425.518 -1.154 256.101 70.403 181.796 65.295 C 137.514 62.251 118.236 29.105 62.535 13.36 C 39.05 6.721 23.394 5.15 1.512 3.128 C -3.86 2.632 -1.713 -8.915 -2.535 -19.775 C -5.575 -59.927 18.666 -143.429 96.708 -134.007 C 272.162 -112.823 190.342 33.619 268.079 -123.842 C 347.367 -284.452 275.306 -60.999 427.778 -145.655 C 568.191 -223.611 412.59 -266.675 570.841 -251.492 C 728.96 -236.316 745.453 -284.573 868.018 -178.768 C 998.039 -66.524 1169.901 -337.778 1251.986 -183.622 C 1340.086 -18.159 1472.236 -203.484 1466.402 -23.172 C 1464.233 43.852 1344.15 28.84 1295.777 17.915 C 1256.67 9.082 1240.336 13.366 1194.846 28.857 C 1123.585 53.123 1074.456 126.617 1001.247 146.235 Z"
            fill={color}
          />
        </svg>
      </DesktopOnly>
      <TabletOnly>
        <svg width="100%" height="55">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M -713.517 -137.447 C -597.95 -89.231 -340.029 -94.077 -168.747 -61.038 C -6.436 -29.729 55.642 45.155 252.778 48.944 C 448.599 52.708 553.904 -14.83 719.829 -43.96 C 900.519 -75.679 1211.589 -71.417 1265.609 -128.862 C 1320.699 -187.453 1018.399 -224.975 943.529 -281.849 C 874.579 -334.224 1012.799 -409.682 851.979 -443.064 C 691.289 -476.419 470.4 -419.207 269.997 -421.657 C 57.402 -424.256 -137.777 -476.716 -339.175 -457.625 C -555.34 -437.134 -721.293 -381.344 -791.844 -321.001 C -862.076 -260.931 -839.526 -190.019 -713.517 -137.447 Z"
            transform="matrix(-1, 0, 0, -1, 441.119507, -412.702698)"
            fill={color}
          />
        </svg>
      </TabletOnly>
      <MobileOnly>
        <svg width="100%" height="55">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M -302.373 -136.919 C -254.22 -88.704 -146.753 -93.55 -75.386 -60.511 C -7.756 -29.202 18.11 45.682 100.25 49.471 C 181.842 53.235 225.719 -14.303 294.856 -43.433 C 370.14 -75.152 499.755 -70.889 522.262 -128.335 C 545.218 -186.926 419.258 -224.447 388.062 -281.322 C 359.333 -333.697 416.926 -409.155 349.917 -442.537 C 282.964 -475.892 190.926 -418.68 107.425 -421.13 C 18.843 -423.729 -62.481 -476.189 -146.397 -457.098 C -236.466 -436.607 -305.613 -380.817 -335.01 -320.474 C -364.273 -260.404 -354.877 -189.492 -302.373 -136.919 Z"
            transform="matrix(-1, 0, 0, -1, 173.650452, -411.648712)"
            fill={color}
          />
        </svg>
      </MobileOnly>
    </div>
  );
};

AttractionScreenDivider.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
};

AttractionScreenDivider.defaultProps = {
  color: '#ffffff',
  background: '#ffffff',
};

export default AttractionScreenDivider;
