import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class DiscountsStore extends BaseStore {
  @action
  redeem(discountCode, productQuantities) {
    const body = { discountCode, productQuantities };

    return request({
      method: 'POST',
      path: '/1/discounts/usage',
      body: body,
    })
      .then((data) => {
        return {
          ...data,
          token: localStorage.getItem('jwt'),
        };
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  delete(discountCode) {
    return request({
      method: 'DELETE',
      path: `/1/discounts/usage/${discountCode}`,
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
}
