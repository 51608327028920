import React from 'react';

import { useClassName } from 'common/hooks';

import './actions.less';

export default function Actions({ className, style, children }) {
  const classNames = useClassName('Actions');

  return (
    <div className={`${classNames('container')} ${className}`} style={style}>
      {children}
    </div>
  );
}
