import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import PlusIcon from 'common/assets/ic-plus.svg';
import PlusDisabledIcon from 'common/assets/ic-plus-disabled.svg';
import MinusIcon from 'common/assets/ic-minus.svg';
import MinusDisabledIcon from 'common/assets/ic-minus-disabled.svg';
import RightIcon from 'common/assets/chevron-right.svg';
import RightDisabledIcon from 'common/assets/chevron-right-disabled.svg';
import LeftIcon from 'common/assets/chevron-left.svg';
import LeftDisabledIcon from 'common/assets/chevron-left-disabled.svg';
import './up-down-button.less';

const UpDownButton = ({
  onClick,
  limit,
  incremental,
  value,
  disabled,
  arrowDisplay,
  style,
}) => {
  const className = useClassName('UpDownButton');

  const getDisabledStyle = () => (value === limit || disabled) && 'disabled';

  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (incremental && value < limit) {
      onClick();
      return;
    }

    if (!incremental && value > limit) {
      onClick();
    }
  };

  const getSource = () => {
    const incrementIcon = arrowDisplay ? RightIcon : PlusIcon;
    const decrementIcon = arrowDisplay ? LeftIcon : MinusIcon;
    const incrementDisabledIcon = arrowDisplay
      ? RightDisabledIcon
      : PlusDisabledIcon;
    const decrementDisabledIcon = arrowDisplay
      ? LeftDisabledIcon
      : MinusDisabledIcon;

    const isDisabled = disabled || value === limit;
    if (incremental) {
      return `${isDisabled ? incrementDisabledIcon : incrementIcon}`;
    }
    return `${isDisabled ? decrementDisabledIcon : decrementIcon}`;
  };

  return (
    <div
      style={style}
      className={className([
        'button-container',
        getDisabledStyle(),
        arrowDisplay && 'arrow',
      ])}
      onClick={handleClick}>
      <img
        className={className(['button', getDisabledStyle()])}
        src={getSource()}
      />
    </div>
  );
};

UpDownButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  limit: PropTypes.number,
  incremental: PropTypes.bool,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  arrowDisplay: PropTypes.bool,
  style: PropTypes.object,
};

UpDownButton.defaultProps = {
  value: 0,
  limit: 999,
  incremental: true,
  disabled: false,
  arrowDisplay: false,
  style: null,
};

export default UpDownButton;
