import React from 'react';
import { Component } from 'common/helpers';
import './list-item.less';

export default class ListItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={this.getElementClass('container')}>
        {this.props.children}
      </div>
    );
  }
}
