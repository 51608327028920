import { getActiveVenue } from '../Jibestream/init';

export default class JibestreamAdapter {
  static async getInstance() {
    if (!this.instance) {
      this.instance = new JibestreamAdapter();
      this.promise = this.instance.load();
    }
    await this.promise;
    return this.instance;
  }

  async load() {
    this.activeVenue = await getActiveVenue();
  }

  wayfind(tenant1, tenant2) {
    const wp1 = this.getClosestWaypointToTenant(tenant1);
    const wp2 = this.getClosestWaypointToTenant(tenant2);

    const path = this.activeVenue.wayfind(wp1, wp2);
    return path.map((sequence) => {
      const cost = sequence.cost; // What is this??
      const floor = this.getFloorForMapId(sequence.mapId);
      const map = this.activeVenue.maps.getById(sequence.mapId);
      const coordinates = sequence.points.map((p) => {
        // "coordinates" is actually a map point
        return this.convertJsPointToMkCoord(p.coordinates, map);
      });
      return {
        cost,
        floor,
        coordinates,
      };
    });
  }

  getClosestWaypointToTenant(tenant) {
    const map = this.getMapByFloor(tenant.floor);
    const jsPoint = this.convertMkCoordToJsPoint(tenant.coordinate, map);
    // Jibestream calls this "coordinates" but it's actually a map point
    return this.activeVenue.getClosestWaypointToCoordinatesOnMap(jsPoint, map);
  }

  // TODO: just for debug
  getClosestCoordinateToTenant(tenant) {
    return this.convertJsPointToMkCoord(
      this.getClosestWaypointToTenant(tenant).coordinates
    );
  }

  getFloorForMapId(mapId) {
    return this.activeVenue.maps.getAll().findIndex((map) => map.id === mapId);
  }

  getMapByFloor(floor) {
    return this.activeVenue.maps.getAll()[floor];
  }

  convertMkCoordToJsPoint(mkCoord) {
    return jmap.util.convertCoordinate(
      [mkCoord.longitude, mkCoord.latitude],
      'EPSG:4326',
      'jmap:local',
      this.activeVenue
    );
  }

  convertJsPointToMkCoord(jsPoint) {
    const jsCoord = jmap.util.convertCoordinate(
      jsPoint,
      'jmap:local',
      'EPSG:4326',
      this.activeVenue
    );
    return new mapkit.Coordinate(jsCoord[1], jsCoord[0]);
  }
}
