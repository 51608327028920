import { observable, action } from 'mobx';
import { identify } from 'utils/analytics';
import { request, handleJwtError } from 'utils/api';
import BaseStore from './BaseStore';

export default class MeStore extends BaseStore {
  @observable user;
  @observable upcomingOrders;

  promise = null;

  @action
  fetch(statusKey) {
    if (this.promise && statusKey === 'boot') {
      return this.promise;
    } else {
      const status = this.createStatus(statusKey);
      const promise = request({
        method: 'GET',
        path: '/1/users/me',
      })
        .then((resp) => {
          this.user = resp.data;
          status.success();
          return this.user;
        })
        .catch((err) => {
          handleJwtError(err);
          status.error(err);
          throw err;
        });
      if (statusKey === 'boot') {
        this.promise = promise;
      }
      return promise;
    }
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    this.promise = request({
      method: 'PATCH',
      path: '/1/users/me',
      body,
    })
      .then((resp) => {
        this.user = resp.data;
        status.success();
        return this.user;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
    return this.promise;
  }

  @action
  fetchOrders(upcoming = false, statusKey = 'getOrders') {
    const status = this.createStatus(statusKey);
    this.promise = request({
      method: 'GET',
      path: `/1/users/me/orders?upcoming=${upcoming}`,
    })
      .then((resp) => {
        status.success();
        this.upcomingOrders = resp.data;
        return resp.data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      })
      .finally(() => {
        this.promise = null;
      });
    return this.promise;
  }

  @action
  updatePhone({ userId, phone }, statusKey = 'updatePhone') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PUT',
      path: '/1/auth/phone',
      body: {
        userId,
        phone,
      },
    })
      .then(() => {
        this.user.phone = phone;
        identify(this.user);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
