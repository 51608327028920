import React from 'react';
import { Modal } from 'common/components';
import Dialog from './Dialog';

export default class ConsentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  close = () => {
    this.setState({
      open: false,
    });
  };

  open = () => {
    this.setState({
      open: true,
    });
  };

  onSave = () => {
    this.props.onSave();
    this.close();
  };

  render() {
    return (
      <Modal
        open={this.state.open}
        onOpen={this.open}
        onClose={this.close}
        trigger={this.props.trigger}>
        <Dialog onCancel={this.close} onSave={this.onSave} showCancel />
      </Modal>
    );
  }
}
