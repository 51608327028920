import React, { useState } from 'react';

import { useClassName, useWindowSize } from 'common/hooks';
import { ClockIcon, ChevronDownIcon } from 'public/icons';
import { Dropdown } from 'public/components/Dropdown';
import { BottomSheet } from 'public/components/BottomSheet';

import './hours.less';

function to12h(hour, minute) {
  const timeString = `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}:00`;
  return new Date('1970-01-01T' + timeString + 'Z')
    .toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(':00', '');
}

function formatHours(hours) {
  return `${to12h(hours.openHour, hours.openMinute)} - ${to12h(
    hours.closeHour,
    hours.closeMinute
  )}`;
}

function isClosedHours(hours) {
  if (hours.isClosed) return true;

  return (
    !hours.openHour &&
    !hours.openMinute &&
    !hours.closeHour &&
    !hours.closeMinute
  );
}

function RegularHours({ venue }) {
  const classNames = useClassName('RestaurantHours');
  return (
    <div className={classNames('regular')}>
      {venue.openingHours.regular.map((regular) => (
        <div key={regular.weekday}>
          <span>
            {regular.weekday.substring(0, 1).toUpperCase()}
            {regular.weekday.substring(1, 3)}
          </span>
          <span>
            {isClosedHours(regular) ? 'Closed' : formatHours(regular)}
          </span>
        </div>
      ))}
    </div>
  );
}

export function VenueHours({ venue }) {
  const classNames = useClassName('RestaurantHours');
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useWindowSize();

  if (!venue?.openingHours) return null;
  const isClosed =
    venue.openingHours.temporarilyClosed ||
    isClosedHours(venue.openingHours.projectedDays[0]);

  return (
    <div className={classNames('wrapper')}>
      <ClockIcon />
      <div className={classNames(['container', isClosed && 'closed'])}>
        <div>
          <span
            className={classNames(['label', isClosed ? 'closed' : 'opened'])}>
            {isClosed ? 'Closed' : 'Open'}
          </span>
        </div>
        {!isClosed && <span>&#8226;</span>}
        <div>
          {!isClosed && (
            <span className={classNames('label')}>
              {formatHours(venue.openingHours.projectedDays[0])}
            </span>
          )}
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={classNames('arrow')}>
            <ChevronDownIcon />
          </div>
        </div>
        {isMobile ? (
          <BottomSheet
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            title="Hours of Operation"
            content={<RegularHours venue={venue} isClosed={isClosed} />}
          />
        ) : (
          <Dropdown
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            content={
              <RegularHours venue={venue} isClosed={isClosed} />
            }></Dropdown>
        )}
      </div>
    </div>
  );
}
