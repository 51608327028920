import { useState, useEffect } from 'react';

export const useResize = (element) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!element) return;

    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentRect.width);
      setHeight(event[0].contentRect.height);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [element]);

  return { width, height };
};

export const Resize = ({ children, element }) => {
  const windowSize = useResize(element);

  return children(windowSize);
};

export default useResize;
