import React from 'react';
import { chunk } from 'lodash';
import { Grid } from 'common/lazy';
import { Component } from 'common/helpers';
import { HorizontalCard } from '../Cards';
import { Headline } from '../Flair';
import { Spacer } from '../Spacer';

export default class NearbyVenues extends Component {
  render() {
    const { nearbyVenues } = this.props.venue;
    if (!nearbyVenues || !nearbyVenues.length) {
      return null;
    }
    const rows = chunk(nearbyVenues, 2);
    return (
      <div {...this.getAttrs()}>
        <Headline title="In The" subtitle="Neighborhood" />
        <Spacer size="m" />
        <Grid columns={2} doubling centered stackable>
          {rows.map((row, i) => {
            return (
              <Grid.Row key={i}>
                {row.map((venue) => {
                  return (
                    <Grid.Column key={venue.id}>
                      <HorizontalCard
                        image={venue.images[0]}
                        href={`/venue/${venue.slug}`}
                        body={venue.name}
                        title={venue.locationDescription}
                        hover
                      />
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    );
  }
}
