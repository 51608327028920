import React, { useState, useEffect } from 'react';
import { inject } from 'mobx-react';
import { useHistory } from 'react-router';
import { track } from 'utils/analytics';
import { useClassName, useWindowSize } from 'common/hooks';
import { parseAnnouncement } from 'public/helpers/contentful/parser';
import { SVGIcon as Icon } from 'common/components';
import { ABOUT, PARTNER, PLAN_YOUR_TRIP } from '../Footer/config';
import SearchTerms from './components/SearchTerms';
import Results from './components/Results';
import PopularSearches from './components/PopularSearches';

import './search.less';

const MIN_SEARCH_LENGTH = 3;
const MAX_RESULTS = 10;

const Search = ({ search, announcements }) => {
  const history = useHistory();
  const className = useClassName('Search');
  const { height } = useWindowSize();

  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const [popularSearches, setPopularSearches] = useState([]);

  useEffect(() => {
    handleKeyUp(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const searchAnnouncements = await announcements.fetchByType({
        type: 'popular-search',
      });
      const searchAnnouncementsList = searchAnnouncements
        .map(parseAnnouncement)
        .sort((a, b) => a.order - b.order);
      setPopularSearches(searchAnnouncementsList);
    };
    fetchAnnouncements();
  }, []);

  useEffect(() => {
    if (activeSearch) document.body.classList.add('nonscroll');
    else document.body.classList.remove('nonscroll');
  }, [activeSearch]);

  const searchInFooterArray = (array, regex) =>
    array
      .filter((item) => item.title.match(regex) || item.value.match(regex))
      .map((filteredItem) => ({
        title: filteredItem.title,
        url: filteredItem.value,
        type: 'footer-link',
      }));

  const searchInFooter = (searchValue) => {
    const footerSearch = [];
    const regex = new RegExp(searchValue, 'i');
    footerSearch.push(...searchInFooterArray(ABOUT, regex));
    footerSearch.push(...searchInFooterArray(PARTNER, regex));
    footerSearch.push(...searchInFooterArray(PLAN_YOUR_TRIP, regex));
    return footerSearch;
  };

  const getUniqueResults = (searchResults) => {
    const uniqueResults = [];
    searchResults.forEach((result) => {
      const existing = uniqueResults.findIndex(
        (r) =>
          r.title.toLowerCase() === result.title.toLowerCase() ||
          r.url.toLowerCase() === result.url.toLowerCase()
      );
      if (existing < 0) {
        uniqueResults.push(result);
      }
    });
    return uniqueResults.slice(0, MAX_RESULTS);
  };

  const handleKeyUp = async (searchTerm) => {
    if (searchTerm.length < MIN_SEARCH_LENGTH) {
      if (showResults) setShowResults(false);
      return;
    }
    track('Search', { label: searchTerm });
    const searchResults = await search.getSearchResult(searchTerm);
    const footerSearch = searchInFooter(searchTerm);
    const allResults = getUniqueResults([
      ...new Set(searchResults.concat(footerSearch)),
    ]);
    if (!allResults?.length) track('Failed search', { label: searchTerm });
    setSearchResults(allResults);
    setShowResults(true);
  };

  const handleLinkClicked = (trackEvent, trackParams, url) => {
    setActiveSearch(false);
    setTimeout(() => {
      setSearchTerm('');
      setShowResults(false);
      setSearchResults([]);
    }, 500);
    track(trackEvent, trackParams);
    if (url.includes('http') || url.includes('mailto'))
      window.open(url, '_blank');
    history.push(url);
  };

  return (
    <>
      <Icon
        name="nav-search"
        className={className('icon')}
        onClick={() => setActiveSearch(true)}
      />
      <div
        style={{ minHeight: `${height}px` }}
        className={className([
          'container',
          activeSearch && 'container-active',
          activeSearch && !showResults && 'container-active-no-results',
        ])}>
        <SearchTerms
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setActiveSearch={setActiveSearch}
        />
        <Results
          searchTerm={searchTerm}
          searchResults={searchResults}
          showResults={showResults}
          handleLinkClicked={handleLinkClicked}
        />
        {(!showResults || (showResults && !searchResults?.length)) &&
          popularSearches?.length && (
            <PopularSearches
              popularSearches={popularSearches}
              showResults={showResults}
              handleLinkClicked={handleLinkClicked}
            />
          )}
        {
          <div
            className={className([
              'bottom-image',
              !showResults && 'bottom-image-no-results',
            ])}
          />
        }
      </div>
    </>
  );
};

export default inject('search', 'announcements')(Search);
