import { action, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';
import { orderBy, isNumber, get } from 'lodash';

import { request } from 'utils/api';
import { getUtmParams } from 'utils/helpers/cookie';
import BaseStore from 'common/stores/BaseStore';

const BIRTHDAY_CHECKOUT_KEY = 'birthday-checkout';

const getPaymentData = (paymentIntent) => {
  const { card, type } = get(
    paymentIntent,
    'charges.data[0].payment_method_details',
    { card: {}, type: null }
  );

  return {
    cardBrand: card && card.brand,
    last4: card && card.last4,
    amount: paymentIntent.amount,
    type: type,
  };
};

function useTimeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class BirthdaysStore extends BaseStore {
  @observable checkout = JSON.parse(
    localStorage.getItem(BIRTHDAY_CHECKOUT_KEY)
  );

  constructor() {
    super();

    reaction(
      () => this.checkout,
      (checkout) => {
        if (checkout) {
          localStorage.setItem(BIRTHDAY_CHECKOUT_KEY, JSON.stringify(checkout));
        } else {
          localStorage.removeItem(BIRTHDAY_CHECKOUT_KEY);
        }
      }
    );
  }

  @action
  getParties(venueSlug) {
    return request({
      method: 'GET',
      path: `/1/birthdays/parties/${venueSlug}`,
    }).then((data) => {
      this.checkout = {
        ...this.checkout,
        settings: data,
      };
      return data;
    });
  }

  @action
  getProductAvailability(date, productIds) {
    return request({
      method: 'GET',
      path: `/1/birthdays/product-availability/${date}/${productIds.join(',')}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  getMerchandiseAvailability(productIds) {
    return request({
      method: 'GET',
      path: `/1/birthdays/merchandise-availability/${productIds.join(',')}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  getSessions(body) {
    return request({
      method: 'POST',
      path: `/1/birthdays/sessions/`,
      body,
    })
      .then((data) => {
        return data;
      })
      .catch(() => []);
  }

  @action
  getAdmissionSettings() {
    const { admission, settings } = this.checkout;
    return settings.admissions[admission.selected];
  }

  @action
  confirm() {
    const {
      admission,
      entertainment,
      foodAndBeverage,
      contactDetails,
      birthdayInfo,
    } = this.checkout;
    const admissionSettings = this.getAdmissionSettings();

    const body = {
      admissionProductIds: admissionSettings.admissionProductIds,
      additionalGuestsProductIds: admissionSettings.additionalGuestsProductIds,
      date: admission.date,
      startTime: admission.selectedSession.startTime,
      numberOfGuests: admission.numberOfGuests,
      entertainmentProducts: entertainment.quantities,
      foodAndBeverageProducts: foodAndBeverage.quantities,
      contactDetails,
      birthdayInfo,
    };

    return request({
      method: 'POST',
      path: `/1/birthdays/confirm/`,
      body,
    }).then((data) => {
      this.checkout = {};

      return data;
    });
  }

  @action
  setAdmission(admission, settings) {
    this.checkout = {
      ...this.checkout,
      admission,
      settings,
    };
  }

  @action
  async setTransactionDetails(paymentIntentId) {
    const mapProducts = (obj) => {
      const getProductName = (products, prodId) => {
        const filterById = ({ id }) => prodId === id;

        const product = products.find((prod) => prod.products.some(filterById));

        if (!product) return ' - ';
        if (product.products.length === 1) return product.name;

        const innerProd = product.products.find(filterById);
        return `${product.name} - ${innerProd.name}`;
      };

      return Object.entries(obj.quantities).map(([prodId, quantity]) => {
        return {
          productName: getProductName(obj.products, prodId),
          quantity,
        };
      });
    };

    const {
      admission,
      entertainment,
      foodAndBeverage,
      merchandise,
      contactDetails,
      settings,
    } = this.checkout;
    const admissionSettings = this.getAdmissionSettings();
    const [payment] = await this.getPaymentIntents({
      paymentIntentIds: [paymentIntentId],
    });

    this.checkout = {
      settings,
      transactionDetails: {
        email: contactDetails['Email'],
        admission: {
          date: admission.date,
          session: admission.selectedSession.name,
          numberOfGuests: admission.numberOfGuests,
        },
        foodAndBeverage: mapProducts(foodAndBeverage),
        entertainment: mapProducts(entertainment),
        merchandise: mapProducts(merchandise),
        images: admissionSettings.images,
        payment: getPaymentData(payment),
      },
    };
  }

  @action
  getAdmission() {
    return this?.checkout?.admission;
  }

  @action
  isNearDate(isoDate, daysInAdvance) {
    const today = DateTime.fromObject({ zone: 'America/New_York' }).startOf(
      'day'
    );
    const date = DateTime.fromISO(isoDate, { zone: 'America/New_York' });
    return date.diff(today, 'days').toObject().days < daysInAdvance;
  }

  @action
  getGuestsAvailable(session, maximumNumberOfGuests) {
    if (!session) return maximumNumberOfGuests;

    const sessionCapacity =
      (session?.additionalGuests?.capacityRemaining || 0) +
      session.guestsIncluded;
    return Math.min(maximumNumberOfGuests, sessionCapacity);
  }

  @action
  async validateAdmission(slug, settings) {
    const validate = async () => {
      try {
        const { admission } = this?.checkout || {};
        if (!admission || !settings) return {};
        if (settings.venueSlug !== slug) return {};

        const { date, selected } = admission;
        if (!date) return {};

        const selectedAdmission = settings.admissions[selected];
        if (!selectedAdmission) return {};

        const {
          admissionProductIds,
          additionalGuestsProductIds,
          daysInAdvance,
        } = selectedAdmission;
        if (this.isNearDate(date, daysInAdvance)) return {};

        const sessions = await this.getSessions({
          date,
          admissionProductIds,
          additionalGuestsProductIds,
        });

        const selectedSession =
          sessions.find(
            (session) =>
              session.startTime === admission?.selectedSession?.startTime
          ) || sessions[0];
        if (!selectedSession) {
          return {
            date,
            sessions,
            selected,
            selectedSession: null,
            numberOfGuests: admission.numberOfGuests,
          };
        }
        const numberOfGuests = Math.min(
          this.getGuestsAvailable(
            selectedSession,
            settings.admissions[selected].maximumNumberOfGuests
          ),
          admission.numberOfGuests
        );

        return {
          date,
          sessions,
          selected,
          selectedSession,
          numberOfGuests,
          isValid: true,
        };
      } catch (ex) {
        return {};
      }
    };

    const admission = await validate();
    this.setAdmission(admission, settings);
    return admission;
  }

  @action
  validateEntertainment(settings, products) {
    const validate = () => {
      try {
        const { entertainment } = this?.checkout || {};
        if (!settings || !entertainment?.quantities) return {};

        const innerProds = products.map((prod) => prod.products).flat();
        const quantities = Object.fromEntries(
          Object.entries(entertainment.quantities).filter(
            ([productId, quantity]) => {
              const product = innerProds.find((prod) => prod.id === productId);
              if (!product) return false;
              if (!isNumber(product.maxQuantity)) return true;

              return product.maxQuantity >= quantity;
            }
          )
        );

        return {
          quantities,
          products,
          isValid: true,
        };
      } catch (ex) {
        return {};
      }
    };

    const entertainment = validate();
    this.setEntertainment(entertainment);
    return entertainment;
  }

  @action
  validateMerchandise(settings, products) {
    const validate = () => {
      try {
        const { merchandise } = this?.checkout || {};
        if (!settings || !merchandise?.quantities) return {};

        const innerProds = products.map((prod) => prod.products).flat();
        const quantities = Object.fromEntries(
          Object.entries(merchandise.quantities).filter(
            ([productId, quantity]) => {
              const product = innerProds.find((prod) => prod.id === productId);
              if (!product) return false;
              if (!isNumber(product.maxQuantity)) return true;

              return product.maxQuantity >= quantity;
            }
          )
        );

        return {
          quantities,
          products,
          isValid: true,
        };
      } catch (ex) {
        return {};
      }
    };

    const merchandise = validate();
    this.setMerchandise(merchandise);
    return merchandise;
  }

  @action
  validateFoodAndBeverage(settings, products) {
    const validate = () => {
      try {
        const { foodAndBeverage } = this?.checkout || {};
        if (!settings || !foodAndBeverage?.quantities) return {};

        const innerProds = products.map((prod) => prod.products).flat();
        const quantities = Object.fromEntries(
          Object.entries(foodAndBeverage.quantities).filter(
            ([productId, quantity]) => {
              const product = innerProds.find((prod) => prod.id === productId);
              if (!product) return false;
              if (!isNumber(product.maxQuantity)) return true;

              return product.maxQuantity >= quantity;
            }
          )
        );

        return {
          quantities,
          products,
          isValid: true,
        };
      } catch (ex) {
        return {};
      }
    };

    const foodAndBeverage = validate();
    this.setFoodAndBeverage(foodAndBeverage);
    return foodAndBeverage;
  }

  @action
  setEntertainment(info) {
    this.checkout = {
      ...this.checkout,
      entertainment: info,
    };
  }

  @action
  setMerchandise(info) {
    this.checkout = {
      ...this.checkout,
      merchandise: info,
    };
  }

  @action
  update(info) {
    this.checkout = {
      ...this.checkout,
      ...info,
    };
  }

  @action
  getEntertainment() {
    return this?.checkout?.entertainment;
  }

  @action
  getMerchandise() {
    return this?.checkout?.merchandise;
  }

  @action
  setFoodAndBeverage(info) {
    this.checkout = {
      ...this.checkout,
      foodAndBeverage: info,
    };
  }

  @action
  getFoodAndBeverage() {
    return this?.checkout?.foodAndBeverage;
  }

  @action
  fillProduct = (rollerProduct, contentfulProduct, index, date) => {
    const getMaxQuantity = (prodId) => {
      if (!rollerProduct?.availabilities?.length) return null;

      const availability = rollerProduct.availabilities.find(
        (a) => a.date === date
      );
      if (!availability) return 0;

      return availability.allocations.find((a) => a.productId === prodId)
        .capacityRemaining;
    };

    return {
      ...{
        ...rollerProduct,
        products: rollerProduct.products.map((prod) => ({
          ...prod,
          maxQuantity: getMaxQuantity(prod.rollerId || prod.id),
        })),
      },
      ...contentfulProduct,
      index,
    };
  };

  fillMerchandiseProduct = (shopifyProduct, contentfulProduct, index) => {
    const getMaxQuantity = (prodId) => {
      if (!shopifyProduct?.availabilities?.allocations?.length) return 0;
      return (
        shopifyProduct.availabilities.allocations.find(
          (a) => a.productId === prodId
        ).capacityRemaining || 0
      );
    };
    return {
      ...{
        ...shopifyProduct,
        products: shopifyProduct.products.map((prod) => ({
          ...prod,
          maxQuantity: getMaxQuantity(prod.productId || prod.id),
        })),
      },
      ...contentfulProduct,
      index,
    };
  };

  @action
  async getEntertainmentProducts(admission, settings) {
    const rollerProducts = await this.getProductAvailability(
      admission.date,
      settings.entertainment.products.map((prod) => prod.productId)
    );

    return orderBy(
      rollerProducts.map((rollerProduct) => {
        const contentfulProduct = settings.entertainment.products.find(
          (contentfulProduct) =>
            contentfulProduct.productId === rollerProduct.id
        );
        const index =
          settings.entertainment.products.indexOf(contentfulProduct);
        return this.fillProduct(
          rollerProduct,
          contentfulProduct,
          index,
          admission.date
        );
      }),
      'index'
    );
  }

  @action
  async getMerchandiseProducts(admission, settings) {
    if (!settings.merchandise) return [];

    const shopifyProducts = await this.getMerchandiseAvailability(
      settings.merchandise.products.map((prod) => prod.productId)
    );

    return orderBy(
      shopifyProducts.map((shopifyProduct) => {
        const contentfulProduct = settings.merchandise.products.find(
          (contentfulProduct) =>
            contentfulProduct.productId === shopifyProduct.id
        );
        const index = settings.merchandise.products.indexOf(contentfulProduct);
        return this.fillMerchandiseProduct(
          shopifyProduct,
          contentfulProduct,
          index
        );
      }),
      'index'
    );
  }

  @action
  async getFoodAndBeverageProducts(admission, settings) {
    const rollerProducts = await this.getProductAvailability(
      admission.date,
      settings.foodAndBeverage.products.map((prod) => prod.productId)
    );

    return orderBy(
      rollerProducts.map((rollerProduct) => {
        const contentfulProduct = settings.foodAndBeverage.products.find(
          (contentfulProduct) =>
            contentfulProduct.productId === rollerProduct.id
        );
        const index =
          settings.foodAndBeverage.products.indexOf(contentfulProduct);
        return this.fillProduct(
          rollerProduct,
          contentfulProduct,
          index,
          admission.date
        );
      }),
      'index'
    );
  }

  @action
  setInfo(info) {
    this.checkout = {
      ...this.checkout,
      ...info,
    };
  }

  @action
  createPaymentIntent() {
    const {
      admission,
      entertainment,
      foodAndBeverage,
      merchandise,
      contactDetails,
      birthdayInfo,
      settings,
    } = this.checkout;

    const body = {
      venueSlug: settings.venueSlug,
      selected: admission.selected,
      date: admission.date,
      startTime: admission.selectedSession.startTime,
      numberOfGuests: admission.numberOfGuests,
      addonsQuantities: {
        ...entertainment.quantities,
        ...foodAndBeverage.quantities,
      },
      merchandiseQuantities: merchandise.quantities,
      contactDetails,
      birthdayInfo,
      utmParams: getUtmParams(),
    };

    return request({
      method: 'POST',
      path: `/1/birthdays/create-payment-intent/`,
      body,
    }).then((data) => {
      return data;
    });
  }

  @action
  createBooking({ paymentIntentId }) {
    const {
      admission,
      entertainment,
      foodAndBeverage,
      merchandise,
      contactDetails,
      birthdayInfo,
      settings,
    } = this.checkout;

    const body = {
      venueSlug: settings.venueSlug,
      selected: admission.selected,
      date: admission.date,
      startTime: admission.selectedSession.startTime,
      numberOfGuests: admission.numberOfGuests,
      addonsQuantities: {
        ...entertainment.quantities,
        ...foodAndBeverage.quantities,
      },
      merchandiseQuantities: merchandise.quantities,
      contactDetails,
      birthdayInfo,
      paymentIntentId,
    };

    return request({
      method: 'POST',
      path: `/1/birthdays/create-booking/`,
      body,
    }).then((data) => {
      return data;
    });
  }

  @action
  async getSettings(slug) {
    try {
      const settings = await this.getParties(slug);
      return { isEnabled: Boolean(settings?.admissions?.length), settings };
    } catch (e) {
      return { isEnabled: false };
    }
  }

  @action
  getBirthdays({ page, limit, search }) {
    return request({
      method: 'GET',
      path: `/1/birthdays?page=${page}&limit=${limit}&search=${search}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  getPaymentIntents({ paymentIntentIds }) {
    if (!paymentIntentIds) return Promise.resolve([]);

    return request({
      method: 'GET',
      path: `/1/birthdays/payment-intents/${paymentIntentIds.join(',')}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  async waitComplete(id, current = 1, total = 5, delay = 2000) {
    const retry = async () => {
      if (current >= total) {
        return { id };
      }

      return await this.waitComplete(id, current + 1);
    };

    try {
      await useTimeout(delay);

      const [intent] = await request({
        method: 'GET',
        path: `/1/birthdays/payment-intents/${id}`,
      });

      // The payment failed, used to get response from external sources (Klarna, Affirm...)
      if (intent?.last_payment_error) {
        return { error: intent.last_payment_error.message, id };
      }

      // The payment was not yet processed by Stripe
      if (!intent?.latest_charge) {
        return await retry();
      }

      return { id };
    } catch (ex) {
      // An internal error happened on our API due to instability
      return await retry();
    }
  }

  @action
  getInvoices({ invoiceIds }) {
    if (!invoiceIds) return Promise.resolve([]);

    return request({
      method: 'GET',
      path: `/1/birthdays/invoices/${invoiceIds.join(',')}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  getBookingDetails({ bookingId }) {
    return request({
      method: 'GET',
      path: `/1/birthdays/booking/${bookingId}`,
    }).then((data) => {
      return data;
    });
  }

  @action
  sendInvoice({ uniqueId, bookingReference, amount }) {
    return request({
      method: 'POST',
      path: `/1/birthdays/invoice`,
      body: { uniqueId, bookingReference, amount },
    }).then((data) => {
      return data;
    });
  }
}
