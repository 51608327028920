import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './ride-requirements.less';

const RideRequirements = ({ id, title, description, theme }) => {
  const className = useClassName('RideRequirements');

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: theme.tertiaryColor }}>
      <div className={className('wrapper')}>
        {title && (
          <ContentfulRichText className={className('title')} field={title} />
        )}
        {description && (
          <ContentfulRichText
            className={className('description')}
            field={description}
          />
        )}
      </div>
    </div>
  );
};

RideRequirements.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object,
  description: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

RideRequirements.defaultProps = {
  title: null,
  description: null,
};

export default RideRequirements;
