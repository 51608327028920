import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'common/lazy';
import { Component } from '../../helpers';
import ValidatedForm from './ValidatedForm';

const FORM_URL = 'https://docs.google.com/forms/d/FORM_ID/formResponse';

export default class GoogleForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = (fields) => {
    this.send(fields);
  };

  async send(fields) {
    try {
      this.setState({
        loading: true,
      });
      await fetch(this.getUrl(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'no-cors',
        method: 'POST',
        body: new URLSearchParams(fields),
      });
      this.setState({
        loading: false,
      });
      this.props.onSubmitComplete();
    } catch (err) {
      this.setState({
        loading: false,
      });
      if (this.props.onSubmitError) {
        this.props.onSubmitError(err);
      }
    }
  }

  getUrl() {
    return FORM_URL.replace(/FORM_ID/, this.props.formId);
  }

  render() {
    return (
      <ValidatedForm {...this.passProps()} onSubmit={this.onSubmit}>
        {this.renderLoading()}
        {this.props.children}
      </ValidatedForm>
    );
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }
  }
}

GoogleForms.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmitError: PropTypes.func,
  onSubmitComplete: PropTypes.func,
};

GoogleForms.defaultProps = {};
