import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './venue-details-hero-title.less';

const VenueDetailsHeroTitle = ({ text }) => {
  const className = useClassName('VenueDetailsHeroTitle');

  return (
    <div className={className('container')}>
      <ContentfulRichText className={className('text')} field={text} />
    </div>
  );
};

VenueDetailsHeroTitle.propTypes = {
  text: PropTypes.object.isRequired,
};

export default VenueDetailsHeroTitle;
