import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/lazy';
import { Modal } from '../Modal';
import { Spacer } from '../Spacer';

const Alert = ({ text, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose} onCloseClick={handleClose}>
      <Modal.Content>
        <Spacer size="s" />
        <p>{text}</p>
        <Spacer size="s" />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>OK</Button>
      </Modal.Actions>
    </Modal>
  );
};

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Alert;
