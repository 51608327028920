import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useClassName, useWindowSize } from 'common/hooks';
import { getMenu } from '../utils';
import MenuItem from './MenuItem';
import OpenHours from './OpenHours';
import Translator from './Translator';
import CartBadge from './CartBadge';
import UpcomingBookings from './UpcomingBookings';
import MyProfile from './MyProfile';

import './menu.less';

const Menu = ({
  expanded,
  primaryClicked,
  setPrimaryClicked,
  handleLinkClick,
  menu,
}) => {
  const className = useClassName('Menu');
  const { isMobile, width: windowWidth } = useWindowSize();
  const menuItems = getMenu(menu);

  const [collapsibleExpanded, setCollapsibleExpanded] = useState({});
  const menuContentRef = useRef(null);

  useEffect(() => {
    if (expanded) document.body.classList.add('nonscroll');
    else {
      document.body.classList.remove('nonscroll');
      setTimeout(() => (menuContentRef.current.scrollTop = 0), 500);
    }
  }, [expanded]);

  const handleCollapsibleClick = (id) => {
    const newExpanded = { ...collapsibleExpanded };
    Object.keys(newExpanded).forEach((key) => {
      if (key !== id) newExpanded[key] = false;
    });
    newExpanded[id] = !newExpanded[id];
    setCollapsibleExpanded(newExpanded);
  };

  return (
    <div
      className={className([
        'container',
        expanded && 'container-expanded',
        primaryClicked && 'container-primary-clicked',
      ])}>
      <div ref={menuContentRef} className={className('content')}>
        <UpcomingBookings handleLinkClick={handleLinkClick} />
        <div className={className('hours-container')}>
          <OpenHours handleLinkClick={handleLinkClick} />
          {Boolean(windowWidth && isMobile) && <Translator />}
        </div>
        {menuItems.map((item, index) => (
          <MenuItem
            item={item}
            key={`menu-item-${index}`}
            types={['primary']}
            collapsibleExpanded={collapsibleExpanded}
            handleCollapsibleClick={handleCollapsibleClick}
            primaryClicked={primaryClicked}
            setPrimaryClicked={setPrimaryClicked}
            handleLinkClick={handleLinkClick}
          />
        ))}
        <div className={className('mobile-links')}>
          <CartBadge handleLinkClick={handleLinkClick} />
          <MyProfile handleLinkClick={handleLinkClick} />
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  expanded: propTypes.bool,
  primaryClicked: propTypes.string,
  setPrimaryClicked: propTypes.func,
  handleLinkClick: propTypes.func.isRequired,
};

Menu.defaultProps = {
  expanded: false,
  primaryClicked: null,
  setPrimaryClicked: () => {},
};

export default inject('menu', 'me')(Menu);
