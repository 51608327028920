import React from 'react';
import { inject, observer } from 'mobx-react';
import { SVGIcon as Icon } from 'common/components';

@inject('appSession', 'me')
@observer
export default class FavoriteButton extends React.Component {
  componentDidMount() {
    if (this.props.appSession.isLoggedIn()) {
      this.fetch();
    }
  }

  fetch() {
    this.props.me.fetch();
  }

  unfavorite() {
    const { venue, me } = this.props;
    const { favoriteVenueIds } = me.user;
    const newFavoriteVenueIds = favoriteVenueIds.filter(
      (id) => id !== venue.id
    );
    me.update({
      favoriteVenueIds: newFavoriteVenueIds,
    });
  }

  favorite() {
    const { venue, me } = this.props;
    const { favoriteVenueIds } = me.user;
    const newFavoriteVenueIds = favoriteVenueIds.concat([venue.id]);
    me.update({
      favoriteVenueIds: newFavoriteVenueIds,
    });
  }

  renderButton(icon, onClick) {
    const { style } = this.props;
    return (
      <Icon
        onClick={() => onClick()}
        name={icon}
        width={30}
        height={30}
        style={{
          ...style,
          cursor: 'pointer',
        }}
      />
    );
  }

  render() {
    if (!this.props.appSession.isLoggedIn()) {
      return null;
    }
    const { venue, me } = this.props;
    const { user } = me;
    if (!user) {
      return this.renderButton('heart outline', () =>
        this.props.history.replace('/login')
      );
    }
    const isFavorited = user.favoriteVenueIds.includes(venue.id);
    return isFavorited
      ? this.renderButton('heart', () => this.unfavorite())
      : this.renderButton('heart outline', () => this.favorite());
  }
}
