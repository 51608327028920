import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  ResponsiveImageLight,
  MobileOnly,
  TabletOnly,
  DesktopOnlyFix as DesktopOnly,
} from 'common/components';
import { AssetPropType } from 'public/schema';
import './explore-section.less';

const ExploreSection = ({
  id,
  title,
  imageMobile,
  imageTablet,
  imageDesktop,
  theme,
}) => {
  const className = useClassName('ExploreSection');
  return (
    <div
      id={id}
      style={{ backgroundColor: theme.primaryColor }}
      className={className('container')}>
      {title && (
        <div className={className('title-container')}>
          <span
            className={className('title')}
            style={{ color: theme.textColor }}>
            {title}
          </span>
        </div>
      )}
      <div className={className('image-container')}>
        {imageMobile?.assetUrl && (
          <MobileOnly>
            <ResponsiveImageLight src={imageMobile.assetUrl} fluid />
          </MobileOnly>
        )}
        {imageTablet && (
          <TabletOnly>
            <ResponsiveImageLight src={imageTablet?.assetUrl} fluid />
          </TabletOnly>
        )}
        {imageDesktop && (
          <DesktopOnly>
            <ResponsiveImageLight src={imageDesktop?.assetUrl} fluid />
          </DesktopOnly>
        )}
      </div>
    </div>
  );
};

ExploreSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  imageMobile: PropTypes.shape(AssetPropType),
  imageTablet: PropTypes.shape(AssetPropType),
  imageDesktop: PropTypes.shape(AssetPropType),
  theme: PropTypes.object.isRequired,
};

ExploreSection.defaultProps = {
  title: '',
  imageMobile: null,
  imageTablet: null,
  imageDesktop: null,
};

export default ExploreSection;
