import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { qrCodeToDataURL } from 'common/lazy';
import qrLogo from 'common/assets/qr-logo.svg';

import QRCodeModal from './QRCodeModal';
import { AttractionLogo } from '../../AttractionLogo';
import { isTicketExpired } from '../helper';

import './order-header.less';
import { Button } from 'common/lazy';

export const OrderHeader = ({
  venue,
  bundle,
  tickets,
  bookingReference,
  order,
  handleModifyOrder,
}) => {
  const className = useClassName('OrderHeader');

  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function generateQRCodes() {
      const codes = getCodesList();
      const qrCodes = await Promise.all(
        codes.map(async (code) => ({
          srcImage: await qrCodeToDataURL(code.id, { width: 240, margin: 0 }),
          id: code.id,
          venueName: code.venueName,
        }))
      );
      setQrCodes(qrCodes);
      setLoading(false);
    }
    generateQRCodes();
  }, []);

  const getCodesList = () => {
    if (tickets.every((ticket) => !ticket.externalBookingId))
      return [{ id: bookingReference, venueName: venue.name }];

    const codes = tickets.reduce((acc, ticket) => {
      if (ticket.externalIds)
        acc.push(
          ...ticket.externalIds.map((id) => ({
            id,
            venueName: ticket.venue?.name || venue.name,
          }))
        );
      else if (ticket.externalBookingId)
        acc.push({
          id: ticket.externalBookingId,
          venueName: ticket.venue?.name || venue.name,
        });
      return acc;
    }, []);

    if (
      tickets.some((ticket) => !ticket.externalBookingId) ||
      tickets.every((ticket) => ticket.isPointsTicket)
    )
      codes.unshift({
        id: bookingReference,
        venueName: bundle ? bundle.title : venue.name,
      });

    return codes;
  };

  const renderModal = () => {
    return (
      <QRCodeModal
        qrCodes={qrCodes}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    );
  };

  const showModifyButton = () =>
    Boolean(bundle) &&
    !bundle.passportType &&
    !tickets.every(({ ticketOption }) => isTicketExpired(ticketOption.date)) &&
    !venue.stripeAccount;

  return (
    <div className={className('container')}>
      <div className={className('logo-container')}>
        <AttractionLogo venue={venue} bundle={bundle} />
        <div className={className('qr-container')}>
          <div className={className('qr-code')} onClick={() => setOpen(true)}>
            {!loading && (
              <>
                <img src={qrLogo} alt="qr-code" />
                <span>See QR</span>
              </>
            )}
          </div>
          {order.status === 'cancelled' && (
            <p className={className('cancelled')}>Cancelled</p>
          )}
        </div>
      </div>
      {showModifyButton() && (
        <div className={className('bundle-name-container')}>
          <div className={className('bundle-name')}>{bundle.title}</div>
          <Button
            className={className('modify-btn')}
            onClick={() => handleModifyOrder(order, tickets, venue, bundle)}>
            Modify
          </Button>
        </div>
      )}
      {renderModal()}
    </div>
  );
};

OrderHeader.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.object).isRequired,
  venue: PropTypes.object,
  bundle: PropTypes.object,
  bookingReference: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  handleModifyOrder: PropTypes.func.isRequired,
};
