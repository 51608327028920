import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './ride-hero-title.less';

const RideHeroTitle = ({ text, color }) => {
  const className = useClassName('RideHeroTitle');

  return (
    <div className={className('container')}>
      <ContentfulRichText
        className={className('text')}
        style={{ color }}
        field={text}
      />
    </div>
  );
};

RideHeroTitle.propTypes = {
  text: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export default RideHeroTitle;
