import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import Video from './Video';
import './player.less';

const Player = ({ id, name, videoUrl, posterUrl, controls }) => {
  const className = useClassName('Player');
  const [isPlaying, setIsPlaying] = useState(false);

  const play = (id, name, evt) => {
    evt.preventDefault();
    const video = document.getElementById(id);
    if (!video) return null;

    if (isPlaying) {
      setIsPlaying(false);
      video.pause();
    } else {
      setIsPlaying(true);
      video.controls = true;
      video.play();

      track('Video Play', { label: name });

      video.onended = () => {
        track('Video Ended', { label: name });
      };
    }
  };

  return (
    <div
      className={className('container')}
      onClick={(evt) => play(id, name, evt)}>
      <Video
        src={videoUrl}
        id={id}
        poster={posterUrl}
        preload="metadata"
        controls={controls}
        controlsList="nodownload"
        width="100%"
        height="100%"
        className={className('video')}
      />
    </div>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  posterUrl: PropTypes.string.isRequired,
  controls: PropTypes.bool.isRequired,
};

export default Player;
