import React from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from 'common/components';

@inject('appSession')
@observer
export default class AuthBadge extends Component {
  render() {
    return (
      <NavLink to="/profile/orders">
        <Icon name="user" size="small" width={16} height={16} />
      </NavLink>
    );
  }
}
