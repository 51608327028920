import { startCase } from 'lodash';
import { render } from 'react-dom';
import { getParkingLotColor } from './styles';
import ICONS from './icons';

const SLIDE_ANIMATION_DESKTOP = '300ms calloutSlideRight ease-out';
const SLIDE_ANIMATION_MOBILE = '300ms calloutSlideDown ease-out';

function getTenantDisplayPriority(tenant) {
  const { category } = tenant.occupant.data;
  switch (category) {
    case 'attraction':
      return 900;
    case 'area':
      return 899;
    case 'station':
      return 898;
    default:
      return 800;
  }
}

function getAmenityColor(data) {
  const { category, name } = data.properties;
  let color;
  if (category === 'parking') {
    color = getParkingLotColor(name && name.en);
  }
  return color || '#000';
}

function getAmenityImage(data) {
  const { category } = data.properties;
  const icon = ICONS[category];
  if (icon) {
    return {
      1: icon,
    };
  }
}

function getAmenityTitle(data) {
  const { category, name } = data.properties;
  if (category === 'service') {
    return 'Information';
  } else if (category === 'parking' && name) {
    return name.en;
  } else {
    return startCase(category);
  }
}

export function annotationForTenant(tenant, renderCallout) {
  const { data } = tenant.occupant;
  const options = {
    title: data.name.en,
    data: tenant,
    color: '#fe5b41',
    displayPriority: getTenantDisplayPriority(tenant),
  };
  if (renderCallout) {
    Object.assign(options, {
      enabled: true,
      selected: false,
      callout: {
        calloutElementForAnnotation: (annotation) => {
          const el = document.createElement('div');
          el.className = 'map-kit__callout';
          const content = el.appendChild(document.createElement('div'));
          content.className = 'map-kit__callout-content';
          renderContent(content, annotation, renderCallout);
          return el;
        },
        calloutAppearanceAnimationForAnnotation: getSlideAnimation,
      },
    });
  } else {
    options.enabled = false;
  }
  return new mapkit.MarkerAnnotation(tenant.coordinate, options);
}

export function customAnnotation(options) {
  const { lat, lng, renderCallout, ...rest } = options;
  const coordinate = new mapkit.Coordinate(lat, lng);

  const annotationOptions = {
    enabled: true,
    ...rest,
  };

  if (renderCallout) {
    annotationOptions.callout = {
      calloutElementForAnnotation: (annotation) => {
        const el = document.createElement('div');
        el.className = 'map-kit__callout';
        const content = el.appendChild(document.createElement('div'));
        content.className = 'map-kit__callout-content';
        renderContent(content, annotation, renderCallout);
        return el;
      },
      calloutAppearanceAnimationForAnnotation: getSlideAnimation,
    };
  }
  return new mapkit.MarkerAnnotation(coordinate, annotationOptions);
}

export function annotationForAmenity(data, coordinate) {
  const { category } = data.properties;
  return new mapkit.MarkerAnnotation(coordinate, {
    title: getAmenityTitle(data),
    color: getAmenityColor(data),
    glyphImage: getAmenityImage(data),
    accessibilityLabel: category,
    clusteringIdentifier: category,
    displayPriority: mapkit.Annotation.DisplayPriority.Required,
  });
}

export function annotationForKiosk(kiosk) {
  return new mapkit.Annotation(
    kiosk.coordinate,
    () => {
      const div = document.createElement('div');
      div.className = 'map-kit__kiosk';
      return div;
    },
    {
      anchorOffset: new DOMPoint(0, -10),
      displayPriority: mapkit.Annotation.DisplayPriority.Required,
      collisionMode: mapkit.Annotation.CollisionMode.Circle,
    }
  );
}

export function annotationForCluster(cluster) {
  const data = {
    properties: {
      category: cluster.clusteringIdentifier,
    },
  };
  return annotationForAmenity(data, cluster.coordinate);
}

// TODO: just for debug
export function annotationForCoordinate(coordinate, title) {
  return new mapkit.MarkerAnnotation(coordinate, {
    title,
  });
}

// Util

async function renderContent(el, annotation, fn) {
  render(await fn(annotation), el);
}

function getSlideAnimation() {
  return window.innerWidth < 768
    ? SLIDE_ANIMATION_MOBILE
    : SLIDE_ANIMATION_DESKTOP;
}
