import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class DataCache extends BaseStore {
  @action
  get(statusKey = 'getDataCache') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/datacache/cached-items`,
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  clear(type, statusKey = 'clearDataCache') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/datacache/${type}`,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
