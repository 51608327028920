import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export default class RefundsStore extends BaseStore {
  @action
  fetchOrderPayments(body, statusKey = 'fetchOrderPayments') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/newrefunds/search/orderpayments',
      body,
    })
      .then((resp) => {
        status.success();
        return {
          success: true,
          data: resp.data,
        };
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          error: err,
        };
      });
  }

  @action
  refundOrder(body, statusKey = 'refundOrder') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/newrefunds/refund',
      body,
    })
      .then((resp) => {
        if (!resp.data.success) throw new Error(resp.data.error);
        status.success();
        return resp.data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          error: err,
        };
      });
  }
}
