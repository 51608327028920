import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Asset from './Asset';

import './slider-miniatures.less';

const SliderMiniatures = ({
  images,
  currentAssetIndex,
  handleMiniatureClick,
}) => {
  const className = useClassName('ModularSliderMiniatures');
  const miniaturesScrollRef = useRef(null);
  const miniaturesRef = useRef(null);
  const [startPositionX, setStartPositionX] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    miniaturesScrollRef.current.scroll(
      currentAssetIndex * miniaturesRef.current.clientWidth,
      0
    );
  }, [currentAssetIndex]);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    const { clientX } = e;
    setStartPositionX(clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (isMouseDown) {
      miniaturesScrollRef.current.scrollLeft += startPositionX - e.clientX;
    }
  };

  const isActive = (index) =>
    index === currentAssetIndex ? 'miniature-image-active' : null;

  return (
    <div
      className={className(['container'])}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}>
      <div className={className('miniatures')} ref={miniaturesScrollRef}>
        {images.map((image, index) => (
          <div
            ref={miniaturesRef}
            key={index}
            className={className([
              'miniature',
              isMouseDown && 'miniature-scrolling',
            ])}
            onClick={() => handleMiniatureClick(index)}>
            <div className={className(['miniature-image', isActive(index)])}>
              <Asset asset={image} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SliderMiniatures.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMiniatureClick: PropTypes.func.isRequired,
  currentAssetIndex: PropTypes.number.isRequired,
};

export default SliderMiniatures;
