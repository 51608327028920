import { get } from 'lodash';
import { getCurrentLocaleCode } from 'utils/l10n';

export default function getContentfulAssetUrlandAlt(field, lang) {
  let assetUrl;
  let assetAlt;
  let assetContentType;
  let assetDescription;

  const {
    fields: { file, title, description },
  } = get(field, [lang || getCurrentLocaleCode(false)]);
  if (file) {
    const { url, contentType } = get(file, [
      lang || getCurrentLocaleCode(false),
    ]);
    assetUrl = url;
    assetContentType = contentType;
  }
  if (title) assetAlt = get(title, [lang || getCurrentLocaleCode(false)]);
  if (description)
    assetDescription = get(description, [lang || getCurrentLocaleCode(false)]);

  return { assetUrl, assetAlt, assetContentType, assetDescription };
}
