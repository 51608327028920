import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { getContentfulField, ContentfulRichText } from 'common/components';
import { SVGIcon as Icon } from 'common/components';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';

import './banner.less';

const Banner = ({ announcements }) => {
  const className = useClassName('Banner');

  const excludedRoutes = [
    /^\/cart/i,
    /\/tickets/i,
    /\/promo/i,
    /\/addons/i,
    /\/login/i,
    /\/signup/i,
    /\/order-update/i,
    /\/*-golf\/score-card*/,
  ];

  const [collapsed, setCollapsed] = useState(false);
  const [announcement, setAnnouncement] = useState(announcements.get('banner'));
  const [announcementFields, setAnnouncementFields] = useState(null);

  useEffect(() => {
    const getAnnouncement = async () => {
      if (!announcement?.object) {
        setAnnouncement(announcements.get('banner'));
      }
    };
    getAnnouncement();
  }, []);

  useEffect(() => {
    if (announcement?.object) {
      const { fields } = announcement.object;
      setAnnouncementFields({
        ...fields,
        link: getContentfulField(fields.link),
      });
    }
  }, [announcement]);

  const handleLinkClick = () => {
    const slug = announcementFields.link.replace(/.*\/\/[^/]*/, '');
    track(`Home - Top Announcement Pressed`, { label: slug });
    window.location.href = announcementFields.link;
  };

  const handleCloseClick = () => {
    setCollapsed(true);
    track('Home - Top Announcement Closed');
  };

  const shouldDisplay = () => {
    return excludedRoutes.every((regexp) => {
      return !window.location.pathname.match(regexp);
    });
  };

  return (
    shouldDisplay() &&
    announcementFields && (
      <div
        className={className([
          'container',
          collapsed && 'container-collapsed',
        ])}>
        <div className={className('text')}>
          <ContentfulRichText
            field={announcementFields.body}
            onClick={handleLinkClick}
          />
        </div>
        <div className={className('close')} onClick={handleCloseClick}>
          <Icon className={className('icon')} name="close-light" />
        </div>
      </div>
    )
  );
};

export default inject('announcements')(Banner);
