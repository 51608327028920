import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';

// Removed lazy loading due to an issue that needs to be investigated
// https://www.americandream.com/birthdays-the-gameroom
export default function LazyDayPickerInput({ ...props }) {
  return <DayPickerInput {...props} />;
}
