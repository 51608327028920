import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  LazyVisible,
  useClassName,
  Only,
  usePreload,
  preloadMedia,
  preloadAssets,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';
import useLoop from '../../Components/useLoop';
import useSlider from '../../Components/useSlider';
import SliderControls from '../../Components/SliderControls';
import SliderBullets from '../../Components/SliderBullets';
import Center from '../../Components/Center';

import 'swiper/swiper.less';
import './news.less';

export default function NewsSection({ section }) {
  const classNames = useClassName('NewsSection');
  const ref = useRef();
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1128: {
        slidesPerView: 2,
        centeredSlides: true,
      },
    },
    slidesLength: section.fields.media.length,
  });
  const [props, methods, state] = useSlider();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadAssets(section.fields.icon);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <Only desktop>
        <HomepageImage field={section.fields.icon} />
      </Only>
      <div className={classNames('header')}>
        <h3>{section.fields.title}</h3>
        <span>
          <ContentfulRichText value={section.fields.subtitle} />
        </span>
      </div>

      <div style={{ position: 'relative' }}>
        {!loop.pending && (
          <Swiper
            height={500}
            {...loop}
            {...props}
            className={classNames('wrapper')}
            onSlideChange={({ realIndex }) =>
              trackHomepageSlide(() => ({
                activeIndex: realIndex,
                name: section.fields.media[realIndex].fields.name,
                section: section.fields.sectionId,
                url: section.fields.media[realIndex].fields.callToAction.fields
                  .url,
              }))
            }>
            {section.fields.media.map(({ fields, sys }) => (
              <SwiperSlide
                key={sys.id}
                className={classNames('slide-container')}>
                {({ isActive }) => (
                  <HomepageButton
                    cta={fields.callToAction}
                    className={classNames('slide')}>
                    <HomepageImage
                      field={fields.desktop}
                      mobile={fields.mobile}
                      style={{
                        opacity: isActive ? '1' : '.4',
                      }}
                    />
                  </HomepageButton>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {loop.loop && (
          <>
            <SliderControls
              slidePrev={methods.slidePrev}
              slideNext={methods.slideNext}
              theme="solid"
            />
            <Center>
              <SliderBullets
                activeIndex={state.activeIndex}
                length={section.fields.media.length}
                slideTo={methods.slideTo}
                style={{ marginTop: '24px' }}
              />
            </Center>
          </>
        )}
      </div>
    </LazyVisible>
  );
}
