import React from 'react';
import { Component } from 'common/helpers';
import { Spacer } from '../Spacer';
import { HeroScreen } from '../HeroScreen';
import { Section } from '../Section';

import {
  getContentfulField,
  ContentfulImage,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import './cmsScreen.less';

export default class CmsScreen extends Component {
  state = {
    hero: null,
  };

  renderHero(hero) {
    return (
      <>
        <HeroScreen hero={hero} />
        <Spacer size="s" />
      </>
    );
  }

  renderSections(sections) {
    return <Section sections={sections} />;
  }

  render() {
    if (!this.props.page) return null;

    const {
      object: { fields },
    } = this.props.page;

    const hero = getContentfulField(fields.hero);
    const sections = getContentfulField(fields.sections);
    const backgroundDesktop = fields.backgroundDesktop;
    const backgroundMobile = fields.backgroundMobile;
    const bottomBackgroundDesktop = fields.bottomBackgroundDesktop;
    const bottomBackgroundMobile = fields.bottomBackgroundMobile;

    return (
      <div {...this.getAttrs()}>
        <DesktopOnly>
          <ContentfulImage
            field={backgroundDesktop}
            width={1024}
            className={this.getElementClass('hero-bg')}
            light
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            field={backgroundMobile}
            width={375}
            className={this.getElementClass('hero-bg')}
            light
          />
        </MobileOnly>
        <div className={this.getElementClass('main')}>
          {hero && this.renderHero(hero)}
          {this.renderSections(sections)}
        </div>
        <DesktopOnly>
          <ContentfulImage
            field={bottomBackgroundDesktop}
            width={1024}
            className={this.getElementClass('bottom-bg')}
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            field={bottomBackgroundMobile}
            width={375}
            className={this.getElementClass('bottom-bg')}
          />
        </MobileOnly>
      </div>
    );
  }
}
