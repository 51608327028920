import React from 'react';
import PropTypes from 'prop-types';
import { Component } from '../../helpers';

const SUPPORTED = !!window.IntersectionObserver;

export default class ScrollWaypoint extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.entered = false;
  }

  // Lifecycle

  componentDidMount() {
    this.createObserver();
  }

  componentWillUnmount() {
    this.destroyObserver();
  }

  // Observer

  createObserver() {
    if (SUPPORTED) {
      const { threshold } = this.props;
      this.observer = new IntersectionObserver(this.onElementObserve, {
        threshold,
      });
      this.observer.observe(this.ref.current);
    }
  }

  destroyObserver() {
    if (SUPPORTED) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  // Events

  onElementObserve = (entries) => {
    const [entry] = entries;
    // Activate when the element is visible or if it
    // has passed the viewport scroll.
    const entered = entry.isIntersecting || entry.boundingClientRect.top < 0;
    if (entered !== this.entered) {
      if (entered) {
        this.props.onEnter();
      } else {
        this.props.onLeave();
      }
      this.entered = entered;
    }
  };

  render() {
    return (
      <div ref={this.ref} {...this.getAttrs()}>
        {this.props.children}
      </div>
    );
  }
}

ScrollWaypoint.propTypes = {
  threshold: PropTypes.number,
};

ScrollWaypoint.defaultProps = {
  threshold: 0.5,
};
