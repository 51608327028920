import { kebabCase } from 'lodash';

const useClassName = (componentName) => {
  return (className, modifier = '') => {
    if (Array.isArray(className)) {
      return className
        .map((name) => `${kebabCase(componentName)}__${name}`)
        .join(' ');
    }

    const gen = `${kebabCase(componentName)}__${className}`;
    if (!modifier) return gen;

    return [gen, `${gen}--${modifier}`].join(' ');
  };
};

export default useClassName;
