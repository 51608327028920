import React from 'react';
import propTypes from 'prop-types';
import { useClassName } from 'common/hooks';

import './collapsible-menu.less';

const CollapsibleMenu = ({ items, expanded, renderMenuItem }) => {
  const className = useClassName('CollapsibleMenu');

  //This code helps the max-height animation to look smoothly
  const containerStyle = () => ({
    maxHeight: expanded
      ? items.some(
          (item) =>
            item?.itemType?.includes('park') ||
            item?.itemType?.includes('attraction')
        )
        ? `${items.length * 122}px`
        : `${items.length * 80}px`
      : `0`,
  });

  return (
    <div
      className={className([
        'container',
        items.length >= 10 && 'container-long-transition',
      ])}
      style={containerStyle()}>
      {items.map((item, index) =>
        renderMenuItem(item, index, [item.itemType || 'terciary'])
      )}
    </div>
  );
};

CollapsibleMenu.propTypes = {
  items: propTypes.array.isRequired,
  expanded: propTypes.bool,
  renderMenuItem: propTypes.func.isRequired,
};

CollapsibleMenu.defaultProps = {
  expanded: false,
};

export default CollapsibleMenu;
