import React from 'react';
import { Responsive } from 'common/lazy';

const DESKTOP_MIN_WIDTH = 767;

export default class DesktopOnly extends React.Component {
  render() {
    return <Responsive minWidth={DESKTOP_MIN_WIDTH} {...this.props} />;
  }
}
