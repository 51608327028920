import React from 'react';

import { useClassName } from 'common/hooks';

import './slider-controls.less';

export default function SliderControls({
  slidePrev,
  slideNext,
  theme,
  className,
}) {
  const classNames = useClassName('SliderControls');

  return (
    <>
      <div
        className={`${classNames(['prev', theme])} ${className}`}
        onClick={slidePrev}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none">
          <path
            d="M30 20H9.16666M9.16666 20L19.1667 10M9.16666 20L19.1667 30"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div
        className={`${classNames(['next', theme])} ${className}`}
        onClick={slideNext}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none">
          <path
            d="M10 20H30.8334M30.8334 20L20.8334 10M30.8334 20L20.8334 30"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </>
  );
}
