import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import { AssetPropType } from 'public/schema';
import { LoaderImage } from '../../Loader';
import './landing-info-card.less';

import { Redirect } from 'react-router-dom';
import LandingCTA from '../LandingHero/LandingCTA';

const LandingInfoCard = ({
  card,
  type,
  onClick,
  fontColor,
  buttonColor,
  buttonTextColor,
}) => {
  const className = useClassName('LandingInfoCard');
  const { title, cardTitle, description, theme, image, cta, slug } = card;
  const textColor = !theme ? fontColor : null;
  const cardTheme = theme || 'dark';

  const [redirect, setRedirect] = useState(null);

  const renderTitle = () => {
    if (type === 'venue' && cardTitle) {
      return (
        <div
          className={className(`title ${cardTheme}`)}
          style={{ color: textColor }}>
          {cardTitle}
        </div>
      );
    } else {
      return (
        <ContentfulRichText
          className={className(`title ${cardTheme}`)}
          field={title}
          style={{ color: textColor }}
        />
      );
    }
  };

  const content = (
    <>
      {image && (
        <LoaderImage
          className={className('image')}
          src={image.assetUrl}
          alt={image.assetAlt}
          loadingHeight={260}
          fluid
        />
      )}
      {renderTitle()}
      {description && (
        <ContentfulRichText
          className={className(`description ${cardTheme}`)}
          field={description}
          style={{ color: textColor }}
        />
      )}
      {cta?.ctaText && (
        <div className={className('cta')}>
          <LandingCTA
            text={cta.ctaText}
            link={cta.ctaLink}
            onClick={onClick}
            buttonColor={buttonColor}
            buttonTextColor={buttonTextColor}
          />
        </div>
      )}
    </>
  );

  if (redirect) return <Redirect push to={redirect} />;

  const handleCardClick = () => {
    const url = slug ? `/venue/${slug}` : cta.ctaLink;
    if (cta?.ctaExternal) {
      window.open(url, '_blank');
    } else {
      const isPathRelative = url.startsWith('/');
      if (isPathRelative) {
        setRedirect(url);
      } else {
        window.open(url, '_self');
      }
    }
  };

  if (slug || cta?.ctaLink) {
    return (
      <div className={className('container link')} onClick={handleCardClick}>
        {content}
      </div>
    );
  } else {
    return <div className={className('container')}>{content}</div>;
  }
};

LandingInfoCard.propTypes = {
  card: PropTypes.shape({
    cardTitle: PropTypes.string,
    description: PropTypes.object,
    cardTheme: PropTypes.string,
    category: PropTypes.string,
    image: PropTypes.shape(AssetPropType),
    cta: PropTypes.shape({
      ctaLink: PropTypes.string,
      ctaText: PropTypes.string,
      ctaColor: PropTypes.string,
      ctaExternal: PropTypes.bool,
    }),
  }).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fontColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

LandingInfoCard.defaultProps = {
  type: 'venue',
  fontColor: null,
  buttonColor: null,
  buttonTextColor: null,
};

export default LandingInfoCard;
