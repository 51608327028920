import { observable, action } from 'mobx';
import BaseStore from './BaseStore';
import { request } from 'utils/api';

export default class OpeningHoursStore extends BaseStore {
  @observable register = new Map();

  get(id) {
    return this.register.get(id);
  }

  @action
  loadVenue(id, statusKey = 'load') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/venues/${id}/openinghours`,
    })
      .then(({ data }) => {
        this.register.set(id, data);
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  storeVenue(id, body, statusKey = 'store') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/venues/${id}/openinghours`,
      body,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  loadProperty(type, statusKey = 'load') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/property/openinghours/${type}`,
    })
      .then(({ data }) => {
        this.register.set('property', data);
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  storeProperty(body, statusKey = 'store') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/property/openinghours`,
      body,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
