import React from 'react';
import { Icon } from 'common/lazy';
import {
  ContentfulImage,
  ContentfulRichText,
  ContentLink,
  getContentfulAssetUrlandAlt,
  getContentfulField,
  Layout,
} from 'common/components';
import { useClassName, useCountdown, useWindowSize } from 'common/hooks';

import './home-spotlight.less';

const getImage = (isMobile, mobileImage, image) => {
  if (isMobile) {
    return mobileImage ? getContentfulAssetUrlandAlt(mobileImage) : null;
  }

  return image ? getContentfulAssetUrlandAlt(image) : null;
};

const HomeSpotlight = ({ linkUrl, fields }) => {
  const classNames = useClassName('HomeSpotlight');
  const [diff] = useCountdown({
    isoDate: getContentfulField(fields.countdown),
  });
  const { isMobile } = useWindowSize();
  const backgroundColor =
    fields.backgroundColor && getContentfulField(fields.backgroundColor);
  const textColor = fields.textColor && getContentfulField(fields.textColor);
  const reversed = fields.reversed && getContentfulField(fields.reversed);
  const centered = fields.centered && getContentfulField(fields.centered);
  const ctaColor = fields.ctaColor && getContentfulField(fields.ctaColor);
  const fontSize = fields.fontSize && getContentfulField(fields.fontSize);
  const ctaType = fields.ctaType && getContentfulField(fields.ctaType);
  const linkText = fields.linkText && getContentfulField(fields.linkText);
  const imageCaption =
    fields.imageCaption && getContentfulField(fields.imageCaption);
  const imageCaptionBackgroundColor =
    fields.imageCaptionBackgroundColor &&
    getContentfulField(fields.imageCaptionBackgroundColor);
  const image = getImage(isMobile, fields.mobileImage, fields.image);
  const withImage = Boolean(fields.image);
  const modifier = withImage ? 'vertical' : 'horizontal';

  return (
    <div
      className={classNames('card', fields.fullImage && 'full-image')}
      style={{
        backgroundColor,
        ...(withImage &&
          fields.fullImage && {
            backgroundImage: `url(${image.assetUrl})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }),
      }}>
      <ContentLink href={linkUrl}>
        <Layout
          horizontal
          className={classNames('container', reversed && 'reversed')}>
          <Layout
            vertical={withImage}
            horizontal={!withImage}
            stackable
            padded
            className={classNames('info', modifier)}
            style={{
              ...(centered && {
                display: 'flex',
                alignItems: 'center',
              }),
            }}>
            {withImage && fields.fullImage && (
              <div
                className={classNames('help-text')}
                style={{ color: imageCaptionBackgroundColor }}>
                {imageCaption}
              </div>
            )}
            {fields.logo && (
              <Layout.Group>
                <ContentfulImage field={fields.logo} width={250} />
              </Layout.Group>
            )}
            <Layout.Group grow>
              <div
                className={classNames([
                  'body',
                  `body-${fontSize ? 'custom' : 'predefined'}-font-size`,
                  `body-${centered ? 'center' : 'left'}`,
                ])}
                style={{
                  color: textColor,
                  fontSize: fontSize && `${fontSize}px`,
                }}>
                <ContentfulRichText field={fields.body} />
              </div>
            </Layout.Group>
            {Boolean(
              diff && [diff.days, diff.hours, diff.minutes].some((a) => a > 0)
            ) && (
              <Layout.Group>
                <div className={classNames('countdown')}>
                  <div>
                    <span>{diff.days.toString().padStart(2, '0')}</span>
                    <span>Days</span>
                  </div>
                  <div>
                    <span>{diff.hours.toString().padStart(2, '0')}</span>
                    <span>Hours</span>
                  </div>
                  <div>
                    <span>{diff.minutes.toString().padStart(2, '0')}</span>
                    <span>Minutes</span>
                  </div>
                </div>
              </Layout.Group>
            )}
            {ctaType && ctaType === 'Button' ? (
              <ContentLink
                href={linkUrl}
                className={classNames('button')}
                style={{
                  backgroundColor: ctaColor,
                }}>
                {linkText}
              </ContentLink>
            ) : (
              <Layout.Group
                className={classNames('link', modifier)}
                style={{ color: ctaColor }}>
                <ContentfulRichText field={fields.linkText} />
                <Icon
                  name="arrow-right"
                  size="mini"
                  style={{ stroke: backgroundColor, backgroundColor: ctaColor }}
                />
              </Layout.Group>
            )}
          </Layout>
          {withImage && (
            <Layout.Group className={classNames('image')}>
              {!fields.fullImage && (
                <>
                  <ContentfulImage field={fields.image} width={1000} />
                  {imageCaption && (
                    <div
                      style={{
                        background: imageCaptionBackgroundColor,
                        color: textColor,
                      }}>
                      {imageCaption}
                    </div>
                  )}
                </>
              )}
            </Layout.Group>
          )}
        </Layout>
      </ContentLink>
    </div>
  );
};

export default HomeSpotlight;
