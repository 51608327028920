import React, { useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';

import './search-terms.less';

const SearchTerms = ({
  searchTerm,
  setSearchTerm,
  activeSearch,
  setActiveSearch,
}) => {
  const className = useClassName('SearchTerms');

  const searchTermRef = useRef(null);

  useEffect(() => {
    if (activeSearch) searchTermRef.current.focus();
  }, [activeSearch]);

  return (
    <div className={className('container')}>
      <div className={className('search')}>
        <Icon name="nav-search" className={className('search-icon')} />
        <input
          type="text"
          placeholder="Find your adventure"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={className('input-search')}
          ref={searchTermRef}
        />
        {Boolean(searchTerm.length) && (
          <div
            className={className('clean-container')}
            onClick={() => {
              setSearchTerm('');
            }}>
            <Icon name="close-light" className={className('clean-icon')} />
          </div>
        )}
      </div>
      <div
        className={className('close-container')}
        onClick={() => {
          setSearchTerm('');
          setActiveSearch(false);
        }}>
        Close
      </div>
    </div>
  );
};

SearchTerms.propTypes = {
  searchTerm: propTypes.string,
  setSearchTerm: propTypes.func.isRequired,
  activeSearch: propTypes.bool,
  setActiveSearch: propTypes.func.isRequired,
};

SearchTerms.defaultProps = {
  searchTerm: '',
  activeSearch: false,
};

export default SearchTerms;
