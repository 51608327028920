import React, { useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';

import './results.less';

const NO_RESULTS_MESSAGE =
  'Whoops! Please try another word, check your spelling, or browse some our favorites below.';

const Results = ({
  searchTerm,
  searchResults,
  showResults,
  handleLinkClicked,
}) => {
  const className = useClassName('Results');

  const resultsRef = useRef(null);

  useEffect(() => {
    if (showResults) {
      if (
        resultsRef?.current?.scrollHeight <=
          resultsRef?.current?.clientHeight ||
        !searchResults.length
      ) {
        resultsRef?.current?.classList?.add(className('results-last'));
      }
    }
  }, [showResults]);

  const handleResultsScroll = () => {
    const scrollHeight = resultsRef.current.scrollHeight;
    const currentScroll = resultsRef.current.scrollTop;
    const elementHeight = resultsRef.current.clientHeight + 20;
    if (currentScroll + elementHeight >= scrollHeight)
      resultsRef.current.classList.add(className('results-last'));
    else resultsRef.current.classList.remove(className('results-last'));
  };

  const handleResultClick = (title, url, index) =>
    handleLinkClicked(
      'Search result clicked',
      { label: searchTerm, url, title, index },
      url
    );

  if (!showResults) return null;

  return (
    <div className={className('container')} onScroll={handleResultsScroll}>
      <div className={className('inner-title')}>Results</div>
      {searchResults?.length ? (
        <div ref={resultsRef} className={className('results')}>
          {searchResults.map((result, index) => (
            <div
              className={className('result')}
              key={`result-${index}`}
              onClick={() =>
                handleResultClick(result.title, result.url, index)
              }>
              <Icon name="nav-search" className={className('result-icon')} />
              <div className={className('result-title')}>
                {_.startCase(result.title.toLowerCase())}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={className('no-results')}>{NO_RESULTS_MESSAGE}</div>
      )}
    </div>
  );
};

Results.propTypes = {
  searchTerm: propTypes.string,
  searchResults: propTypes.array,
  showResults: propTypes.bool,
  handleLinkClicked: propTypes.func.isRequired,
};

Results.defaultProps = {
  searchTerm: '',
  searchResults: [],
  showResults: false,
};

export default Results;
