// Class that simply wraps a kiosk, floor, and
// coordinate into one simple structure.

export default class Kiosk {
  constructor(data) {
    this.kiosk = data.kiosk;
    this.floor = data.floor;
    this.coordinate = data.coordinate;
  }
}
