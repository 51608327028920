import React, { useEffect, useState, forwardRef } from 'react';

const OPTIONS = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: 0.2,
};

const loaded = {};

const useVisible = (elementRef, id) => {
  const [isVisible, setIsVisible] = useState(loaded[id] || false);

  useEffect(() => {
    if (elementRef?.current && !isVisible) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loaded[id] = true;
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          }
        });
      }, OPTIONS);
      observer.observe(elementRef.current);
    }
  }, [elementRef, isVisible]);

  return isVisible;
};

export const LazyVisible = forwardRef(
  ({ children, className, style, id, fallback, onLoad }, ref) => {
    const isVisible = useVisible(ref, id);

    useEffect(() => {
      if (isVisible && onLoad) {
        onLoad();
      }
    }, [isVisible]);

    return (
      <div
        ref={ref}
        id={id}
        style={{
          // https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
          transform: 'translate3d(0, 0, 0)',
          ...style,
          ...(!isVisible && { minHeight: '1000px' }),
        }}
        className={className}>
        {isVisible ? children : fallback || null}
      </div>
    );
  }
);

export default useVisible;
