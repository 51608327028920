import React from 'react';

import { useClassName } from 'common/hooks';

import Center from '../Center';

import './frame.less';

export default function LPFrame({ component }) {
  const classNames = useClassName('LPFrame');

  return (
    <Center>
      <iframe
        className={classNames('container')}
        src={component.fields.url}
        scrolling="no"
        allowFullScreen></iframe>
    </Center>
  );
}
