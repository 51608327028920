import React from 'react';
import { observer, inject } from 'mobx-react';
import { Component } from 'common/helpers';
import {
  Layout,
  ContentfulRichText,
  getContentfulField,
} from 'common/components';
import { track } from 'utils/analytics';
import './banner.less';

const excludedRoutes = [
  /^\/cart/i,
  /\/tickets/i,
  /\/promo/i,
  /\/addons/i,
  /\/*-golf\/score-card*/,
];

@inject('announcements')
@observer
export default class Banner extends Component {
  handleLinkClick = (link) => {
    const slug = link.replace(/.*\/\/[^/]*/, '');
    track(`Home - Top Announcement Pressed`, { label: slug });
  };

  shouldDisplay = () => {
    return excludedRoutes.every((regexp) => {
      return !window.location.pathname.match(regexp);
    });
  };

  render() {
    const announcement = this.props.announcements.get('banner');
    if (!announcement) {
      return null;
    }
    if (!this.shouldDisplay()) return null;

    const { fields } = announcement.object;
    const link = getContentfulField(fields.link);
    return (
      <Layout {...this.getAttrs()} center>
        <div>
          <span className={this.getElementClass('text')}>
            <ContentfulRichText field={fields.body} />
          </span>{' '}
          <a
            href={link}
            className={this.getElementClass('link')}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.handleLinkClick(link)}>
            <ContentfulRichText field={fields.linkText} />
          </a>
        </div>
      </Layout>
    );
  }
}
