import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';

import stores from 'common/stores';

Element.prototype._addEventListener = Element.prototype.addEventListener;
Element.prototype.addEventListener = function (a, b, c) {
  this._addEventListener(a, b, c);
  if (!this.eventListenerList) this.eventListenerList = {};
  if (!this.eventListenerList[a]) this.eventListenerList[a] = [];
  this.eventListenerList[a].push(b);
};

class AppWrapper extends React.Component {
  render() {
    return (
      <Provider {...stores}>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

render(<AppWrapper />, document.getElementById('root'));
