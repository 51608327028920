import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import PricingCard from './PricingCard';

import './pricing-cards.less';

const PricingCards = ({ pricingCards }) => {
  const className = useClassName('ModularPricingCards');

  const getCardSize = () => {
    const { cards } = pricingCards;
    if (cards.length === 1) return 'big';
    if (cards.length === 2) return 'medium';
    if (cards.length === 3 || cards.length % 3 === 0) return 'small';
    return 'tiny';
  };

  return (
    <div className={className('container')}>
      {pricingCards.cards.map((card, index) => (
        <PricingCard key={index} pricingCard={card} size={getCardSize()} />
      ))}
    </div>
  );
};

PricingCards.propTypes = {
  pricingCards: PropTypes.object.isRequired,
};

export default PricingCards;
