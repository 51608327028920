/*
  fillColor - CSS Color
  fillOpacity - Number
  strokeColor - CSS Color
  strokeOpacity - Number
  lineWidth - Number
  lineCap - Line ends: "butt" (squared off), "square" (juts out), "round" (default)
  lineJoin - Line joins: "miter" (sharp corner), "bevel" (beveled corner), "round" (default)
  lineDash - Array of 2 values representing line length and gap length: [10, 5]
  lineDashOffset - Number: Dash offset if lineDash is set.
  fillRule: "evenodd" or "nonzero" shouldn't be applicable
*/

export default {
  default: {
    fillOpacity: 1,
    strokeOpacity: 1,
  },
  venue: {
    fillColor: '#f8f2da',
    lineWidth: 0,
  },
  footprint: {
    fillColor: '#f8f2da',
    lineWidth: 0,
  },
  level: {
    fillColor: '#fefefe',
    strokeColor: '#c6c6c6',
    lineWidth: 2,
  },
  unit: {
    fillColor: '#eee',
    strokeColor: '#fefefe',
  },
  'unit-nonpublic': {
    fillColor: '#f8f2da',
    lineWidth: 0,
  },
  'unit-structure': {
    fillColor: '#eee',
    strokeColor: '#fefefe',
  },
  'unit-structure-occupied': {
    fillColor: '#ffe6b9',
  },
  'unit-structure-focused': {
    fillColor: '#f7cd7a',
  },
  'unit-opentobelow': {
    fillColor: '#eee',
  },

  // Parking
  'unit-parking': {
    fillColor: '#eeeeee',
    strokeColor: '#fefefe',
  },
  'unit-walkway': {
    fillColor: '#fefefe',
    lineWidth: 0,
  },
  fixture: {
    fillColor: '#eee',
    strokeColor: '#fefefe',
  },
  'fixture-water': {
    fillColor: '#aee1f5',
  },
  'fixture-vegetation': {
    fillColor: '#d2f2c2',
  },
  kiosk: {
    fillColor: '#eee',
    strokeColor: '#fefefe',
  },
  'wayfinding-line': {
    lineWidth: 3,
    lineDash: [7, 7],
    strokeColor: '#007aff',
  },
};

export function getParkingLotColor(name) {
  switch (name) {
    case 'Parking Lot A':
      return '#0093b2';
    case 'Parking Lot B':
      return '#a57fb2';
    case 'Parking Lot C':
      return '#da291c';
    case 'Parking Lot D':
      return '#00816d';
    case 'Parking Lot Gold':
      return '#84754e';
    case 'Parking Lot Pearl':
      return '#c7c9c7';
    case 'Parking Lot Diamond':
      return '#545859';
  }
}
