import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import './checkbox.less';

function Checkbox({ name, onChange, checked, label, error, className }) {
  const classNames = useClassName('Checkbox');

  return (
    <div className={`${classNames('checkbox-wrapper')} ${className}`}>
      <div>
        <input
          className={classNames('checkbox')}
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <div className={classNames('checkbox-label')}>{label}</div>
      </div>
      {Boolean(error) && <caption>{error}</caption>}
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.any,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default Checkbox;
