import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'common/lazy';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from 'common/components';

import './error-message.less';

export default class ErrorMessage extends Component {
  render() {
    const { header, content } = this.props;
    return (
      <div {...this.getAttrs()}>
        <Message error>
          <div className={this.getElementClass('eyebrow')}>
            <Icon size="tiny" name="info" /> Important Information
          </div>
          {header && <Message.Header>{header}</Message.Header>}
          <Message.Content>{content}</Message.Content>
        </Message>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
};
