import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { InfoCard } from '../InfoCard';
import './rides-section.less';

const ALL_RIDES = 'all';

const RidesSection = ({ id, cards, categories, theme }) => {
  const className = useClassName('RidesSection');

  const DEFAULT_CARDS_QUANTITY = 6;
  const LOAD_MORE_TEXT = 'Load more';

  const gradientColor = `linear-gradient(${theme.secondaryColor}, ${theme.primaryColor})`;

  const [selectedCategory, setSelectedCategory] = useState(ALL_RIDES);
  const [infoCardsList, setInfoCardsList] = useState(null);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  const [ctaFontColor, setCtaFontColor] = useState(theme?.textColor);
  const [ctaBackgroundColor, setCtaBackgroundColor] = useState(null);

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events
  const trackingCategory = trackingCategories.VENUE_INFO;

  useEffect(() => {
    renderCards(ALL_RIDES, false);
  }, []);

  const onLoadMoreMouseEnter = () => {
    setCtaFontColor(theme?.primaryColor);
    setCtaBackgroundColor(theme?.textColor);
  };

  const onLoadMoreMouseLeave = () => {
    setCtaFontColor(theme?.textColor);
    setCtaBackgroundColor(null);
  };

  const renderCards = (category, showAllCards) => {
    if (!cards?.length) return null;

    // how many cards should be displayed (default initial value or all)
    const quantityToShow = showAllCards ? cards.length : DEFAULT_CARDS_QUANTITY;

    let cardsToShow = [];

    // check full list
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      // only selected category is displayed
      if (
        category === ALL_RIDES ||
        category === card.category ||
        card.categories?.includes(category)
      ) {
        if (cardsToShow.length < quantityToShow) {
          const cardComponent = <InfoCard key={i} card={card} />;
          // card added to list to show
          cardsToShow.push(cardComponent);

          // if it was the last card added, remove load more button
          if (cardsToShow.length === quantityToShow)
            setShowLoadMoreButton(false);
        } else {
          // still cards to show
          setShowLoadMoreButton(true);
        }
      }
    }

    // check if load more button should be displayed or not
    if (cardsToShow.length < quantityToShow) setShowLoadMoreButton(false);

    setInfoCardsList(cardsToShow);
  };

  const onCategoryChange = (newCategory) => {
    if (newCategory !== selectedCategory) {
      setSelectedCategory(newCategory);
      renderCards(newCategory, false);

      const name = `${newCategory} category`;

      standardizedTrack(
        'click',
        'button',
        'rides section',
        name,
        trackingCategory,
        trackingLabel
      );
    }
  };

  const onLoadMorePressed = () => {
    renderCards(selectedCategory, true);

    standardizedTrack(
      'click',
      'button',
      'rides section',
      'load more',
      trackingCategory,
      trackingLabel
    );
  };

  const categoryButton = (category) => {
    const accentStyle =
      category === selectedCategory
        ? { backgroundColor: theme.buttonColor, color: theme.buttonTextColor }
        : null;
    return (
      <div
        key={category}
        className={className('category-button')}
        style={accentStyle}
        onClick={() => onCategoryChange(category)}>
        {category}
      </div>
    );
  };

  const renderCategories = (categories) => {
    if (!categories?.length) return null;

    return categories.map((category) => categoryButton(category));
  };

  if (!cards?.length) return null;

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: gradientColor }}>
      <div className={className('categories-wrapper')}>
        <div className={className('categories-container')}>
          {categoryButton(ALL_RIDES)}
          {renderCategories(categories)}
        </div>
      </div>
      <div className={className('cards-wrapper')}>
        <div className={className('cards-container')}>{infoCardsList}</div>
        {showLoadMoreButton && (
          <div
            className={className('cta-container')}
            style={{
              color: ctaFontColor,
              backgroundColor: ctaBackgroundColor,
              borderColor: theme?.textColor,
            }}
            onClick={() => onLoadMorePressed()}
            onMouseEnter={() => onLoadMoreMouseEnter()}
            onMouseLeave={() => onLoadMoreMouseLeave()}>
            {LOAD_MORE_TEXT}
          </div>
        )}
      </div>
    </div>
  );
};

RidesSection.propTypes = {
  id: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.object,
    })
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object.isRequired,
};

RidesSection.defaultProps = {
  cards: null,
  categories: null,
};

export default RidesSection;
