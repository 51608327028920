import React from 'react';
import PropTypes from 'prop-types';
import { debounce, clamp } from 'lodash';
import { Loader } from 'common/lazy';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from '../../SVGIcon';
import { DesktopOnly, MobileOnly } from '../../Responsive';

import init from './init';
import STYLES from './styles';
import AnimatedPolyline from './AnimatedPolyline';
import JibestreamAdapter from './JibestreamAdapter';

import Tenant from './Tenant';

import {
  annotationForKiosk,
  annotationForCluster,
  customAnnotation,
} from './annotations';
import IMDFManager from './IMDFManager';

import './map-kit.less';

const RESIZE_DELAY = 200;
const MIN_CAMERA_DISTANCE = 10;
const MAX_CAMERA_DISTANCE = 640;
const ORIGIN_COORDINATE_SPAN = 0.002;
const ZOOM_MULTIPLIER = 2.2;

export default class MapKit extends Component {
  static initialize() {
    return Promise.all([this.initializeManager(), this.initializeAdapter()]);
  }

  static async initializeManager() {
    await init();
    return await IMDFManager.getInstance();
  }

  static async initializeAdapter() {
    return await JibestreamAdapter.getInstance();
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      isTransitioning: false,
    };
    this.origin = null;
    this.itemsByFloor = {};
    this.ref = React.createRef();
  }

  // Setup

  async load() {
    const [manager, adapter] = await MapKit.initialize();

    const map = new mapkit.Map(this.ref.current, {
      showsPointsOfInterest: false,
      showsMapTypeControl: false,
      showsZoomControl: false,
      showsCompass: false,
      showsScale: false,
      isRotationEnabled: false,
      isZoomEnabled: false,
      cameraBoundary: this.getRegion(manager.getBounds()),
      mapType: mapkit.Map.MapTypes.MutedStandard,
    });

    // Custom cluster that shows the basic category icon.
    map.annotationForCluster = (cluster) => {
      return annotationForCluster(cluster);
    };

    mapkit.addEventListener('error', (error) => {
      this.setState({
        error,
      });
    });

    map.addItems(manager.getBaseFeatures());
    this.addTransitionHandlers(map);
    this.checkRemoveControls();

    this.map = map;
    this.manager = manager;
    this.adapter = adapter;

    this.updateFloor(this.props.focusedFloor);

    if (this.props.onMapInitialized) {
      this.props.onMapInitialized();
    }
    if (this.props.clickAnnotation) {
      map.element.addEventListener('click', this.onMapClick);
    }
  }

  // Lifecycle

  async componentDidMount() {
    await this.load();
    if (this.props.kioskId) {
      const kiosk = this.manager.getKioskByAmenities(this.props.kioskId);
      if (kiosk) {
        this.addItemForFloor(kiosk.floor, annotationForKiosk(kiosk));
        this.map.region = this.getRegionForOrigin(kiosk);
        this.origin = kiosk;
        this.props.onKioskLoad(kiosk);
      } else {
        console.error(`Kiosk ${this.props.kioskId} could not be found.`);
        this.map.region = this.getRegion(this.manager.getRegion());
      }
    } else {
      this.map.region = this.getRegion(this.manager.getRegion());
    }
    if (this.props.annotation) {
      this.addCustomAnnotation(this.props.annotation);
    }
    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    if (this.map) {
      this.removeTransitionHandlers(this.map);
      this.cleanupAsync();
      this.manager.reset();
      this.map.element.removeEventListener('click', this.onMapClick);
      this.map.destroy();
    }
  }

  componentDidUpdate(lastProps) {
    if (this.map) {
      const { focusedFloor, focusedAmenity, focusedVenue } = this.props;

      const {
        focusedFloor: lastFloor,
        focusedAmenity: lastAmenity,
        focusedVenue: lastVenue,
      } = lastProps;

      if (focusedFloor !== lastFloor) {
        this.updateFloor(focusedFloor, lastFloor);
      }
      if (focusedAmenity !== lastAmenity) {
        this.updateAmenities(
          focusedAmenity,
          focusedFloor,
          lastAmenity,
          lastFloor
        );
      }
      if (focusedVenue !== lastVenue) {
        if (!focusedVenue) {
          this.resetToOrigin();
        } else {
          this.updateVenue(focusedVenue, lastVenue);
        }
      }
    }
  }

  // Events

  onResetButtonClick = () => {
    this.resetToOrigin();
    this.props.onMapReset(this.origin);
  };

  onRegionChangeStart = () => {
    this.onTransitionStart();
  };

  onRegionChangeEnd = () => {
    this.onTransitionEnd();
  };

  onTransitionStart = () => {
    this.setState({
      isTransitioning: true,
    });
    // Disabling animated line for now
    //this.toggleCurrentWayfindingSegment(true);
  };

  onTransitionEnd = () => {
    this.setState({
      isTransitioning: false,
    });
    // Disabling animated line for now
    //this.toggleCurrentWayfindingSegment(false);
  };

  onResizeStart = debounce(this.onTransitionStart, RESIZE_DELAY, {
    leading: true,
    trailing: false,
  });

  onResizeEnd = debounce(this.onTransitionEnd, RESIZE_DELAY);

  onRotationStart = (evt) => {
    this.onTransitionStart(evt);
    this.rotationStartTime = new Date();
  };

  onRotationEnd = (evt) => {
    if (this.isBuggyRotationClick()) {
      return;
    }
    this.onTransitionEnd(evt);
    this.rotationStartTime = null;
  };

  onZoomOutClick = () => {
    this.updateCameraDistance(1);
  };

  onZoomInClick = () => {
    this.updateCameraDistance(-1);
  };

  onMapClick = (evt) => {
    if (evt.target.parentNode !== this.map.element) {
      // This condition prevents clicks on controls. Binding to a
      // secondary click is another option to prevent conflict
      return;
    }
    var domPoint = new DOMPoint(evt.pageX, evt.pageY);
    var coordinate = this.map.convertPointOnPageToCoordinate(domPoint);
    if (this.clickAnnotation) {
      this.clickAnnotation.coordinate = coordinate;
    } else {
      this.clickAnnotation = customAnnotation({
        lat: coordinate.lat,
        lng: coordinate.lng,
        ...this.props.clickAnnotation,
      });
      this.map.addAnnotation(this.clickAnnotation);
    }
    this.clickAnnotation.selected = true;
    if (this.props.onMapClick) {
      this.props.onMapClick(coordinate, evt);
    }
  };

  // Allows custom click handling for kiosk.
  onClick(fn) {
    if (this.props.customClickHandler) {
      return this.props.customClickHandler(fn);
    } else {
      return {
        onClick: fn,
      };
    }
  }

  addTransitionHandlers() {
    //map.addEventListener('region-change-start', this.onRegionChangeStart);
    //map.addEventListener('region-change-end', this.onRegionChangeEnd);
    //map.addEventListener('rotation-start', this.onRotationStart);
    //map.addEventListener('rotation-end', this.onRotationEnd);
    //window.addEventListener('resize', this.onResizeStart);
    //window.addEventListener('resize', this.onResizeEnd);
  }

  removeTransitionHandlers() {
    //map.removeEventListener('region-change-start', this.onRegionChangeStart);
    //map.removeEventListener('region-change-end', this.onRegionChangeEnd);
    //map.removeEventListener('rotation-start', this.onRotationStart);
    //map.removeEventListener('rotation-end', this.onRotationEnd);
    //window.removeEventListener('resize', this.onResizeStart);
    //window.removeEventListener('resize', this.onResizeEnd);
  }

  cleanupAsync() {
    this.onResizeStart.cancel();
    this.onResizeEnd.cancel();
  }

  // Actions

  showItems(items) {
    // Extra padding on right and bottom for controls
    // Note that the callout pulls left on desktop so
    // add some extra padding for it here.
    const padding =
      window.innerWidth < 768
        ? new mapkit.Padding(20, 80, 20, 20)
        : new mapkit.Padding(40, 160, 40, 240);

    this.map.showItems(items, {
      animate: true,
      padding,
    });
  }

  resetToOrigin() {
    if (this.origin) {
      this.map.setRegionAnimated(this.getRegionForOrigin(this.origin));
    } else {
      this.map.setRegionAnimated(this.getRegion(this.manager.getRegion()));
    }
    this.clearWayfinding();
  }

  // Updates

  updateFloor(floor, lastFloor) {
    const { focusedAmenity, renderTenantCallout } = this.props;
    if (lastFloor >= 0) {
      this.removeItems(
        this.manager.getFloorBaseFeatures(lastFloor, renderTenantCallout)
      );
      this.removeItems(
        this.manager.getFloorAmenities(lastFloor, focusedAmenity)
      );
      this.removeItems(this.itemsByFloor[lastFloor] || []);
    }

    if (floor >= 0) {
      this.map.addItems(
        this.manager.getFloorBaseFeatures(floor, renderTenantCallout)
      );
      this.map.addItems(this.manager.getFloorAmenities(floor, focusedAmenity));
      this.map.addItems(this.itemsByFloor[floor] || []);
    }

    if (this.customAnnotation) {
      const { floor: annotationFloor } = this.customAnnotation.data;
      this.customAnnotation.selected = annotationFloor === floor;
    }
  }

  updateAmenities(amenity, floor, lastAmenity, lastFloor) {
    if (lastFloor && lastAmenity) {
      this.removeItems(this.manager.getFloorAmenities(lastFloor, lastAmenity));
    }
    if (floor && amenity) {
      this.showItems(this.manager.getFloorAmenities(floor, amenity));
    }
  }

  updateVenue(venue, lastVenue) {
    if (lastVenue) {
      this.clearWayfinding();
    }
    // Needed as seeing issues with location data occasionally
    // not appearing for venues (or may not be set yet).
    if (venue && venue.location) {
      // TODO: resolve this with tenants... where is source of truth for venue
      // location data? How does IMDF play into this?
      const { lat, lng } = venue.location;
      const tenant = new Tenant({
        floor: venue.floorLevel,
        coordinate: new mapkit.Coordinate(lat, lng),
      });
      const { featuredFlags } = venue;
      if (featuredFlags && featuredFlags.includes('Coming Soon')) {
        // If a venue is coming soon then reveal it on the map
        // but do not wayfind as the route may not exist yet.
        this.focusTenant(tenant);
      } else {
        this.wayfindToTenant(tenant);
      }
    }
  }

  addCustomAnnotation(annotation) {
    const { floor, ...options } = annotation;
    this.customAnnotation = customAnnotation({
      ...options,
      data: {
        floor,
      },
    });
    this.addItemForFloor(floor, this.customAnnotation);
    if (options.selected) {
      this.showItems(this.customAnnotation);
    }
    this.props.onAnnotationAdded(this.customAnnotation);
  }

  updateCameraDistance(step) {
    // Simple logarithmic function that
    // provides 3-4 steps zoom at a constant rate.
    const { cameraDistance } = this.map;
    const level = Math.log(cameraDistance) / Math.log(ZOOM_MULTIPLIER);
    const newDistance = clamp(
      Math.pow(ZOOM_MULTIPLIER, level + step),
      MIN_CAMERA_DISTANCE,
      MAX_CAMERA_DISTANCE
    );
    this.map.setCameraDistanceAnimated(newDistance);
  }

  // Tenants / Wayfinding

  wayfindToTenant(tenant) {
    if (tenant) {
      let wayfindingPath;
      if (this.origin) {
        wayfindingPath = this.getWayfindingPath(this.origin, tenant);
        if (wayfindingPath) {
          this.addWayfindingPath(wayfindingPath);
          const floorPath = wayfindingPath.find((p) => {
            return p.floor === tenant.floor;
          });
          if (floorPath) {
            this.showItems(floorPath.polyline);
          }
          this.wayfindingPath = wayfindingPath;
        }
      }
      if (!wayfindingPath) {
        this.focusTenant(tenant);
      }
    }
  }

  focusTenant(tenant) {
    // If no wayfinding path, then simply show the location
    this.map.setRegionAnimated(
      new mapkit.CoordinateRegion(
        tenant.coordinate,
        new mapkit.CoordinateSpan(0.0025, 0.0025)
      )
    );
  }

  getWayfindingPath(fromTenant, toTenant) {
    const path = this.adapter.wayfind(fromTenant, toTenant);
    if (path) {
      return path.map((segment) => {
        const { coordinates } = segment;
        const polyline = new mapkit.PolylineOverlay(coordinates, {
          // TODO: enabling polyline wayfinding for now
          visible: true,
          style: new mapkit.Style(STYLES['wayfinding-line']),
        });
        return {
          polyline,
          ...segment,
        };
      });
    } else {
      console.warn(
        `Could not wayfind between ${fromTenant.name} and ${toTenant.name}`
      );
    }
  }

  clearWayfinding() {
    this.removeWayfindingPath(this.wayfindingPath);
    this.wayfindingPath = null;
  }

  addWayfindingPath(wayfindingPath) {
    wayfindingPath.forEach((segment) => {
      this.addItemForFloor(segment.floor, segment.polyline);
    });
  }

  removeWayfindingPath(wayfindingPath) {
    if (wayfindingPath) {
      wayfindingPath.forEach((segment) => {
        this.removeItemForFloor(segment.floor, segment.polyline);
      });
    }
  }

  toggleCurrentWayfindingSegment(visible) {
    const segment = this.getCurrentWayfindingSegment();
    if (segment) {
      segment.polyline.visible = visible;
    }
  }

  getCurrentWayfindingSegment() {
    const wayfindingPath = this.wayfindingPath;
    return (
      wayfindingPath &&
      wayfindingPath.find((segment) => {
        return segment.floor === this.props.focusedFloor;
      })
    );
  }

  // Non-IMDF overlays/annotations

  addItemForFloor(floor, item) {
    if (!this.itemsByFloor[floor]) {
      this.itemsByFloor[floor] = [];
    }
    this.itemsByFloor[floor].push(item);
    if (floor === this.props.focusedFloor) {
      this.map.addItems(item);
    }
  }

  removeItemForFloor(floor, item) {
    if (floor && item) {
      this.itemsByFloor[floor] = this.itemsByFloor[floor].filter(
        (i) => i !== item
      );
      if (floor === this.props.focusedFloor) {
        this.removeItems(item);
      }
    }
  }

  removeItems(items) {
    // MapKit seems to error at times when removing
    // items. Just swallow the error until we can pinpoint.
    try {
      this.map.removeItems(items);
    } catch (err) {
      console.error(err);
    }
  }

  // Helpers

  getRegion(obj) {
    return new mapkit.BoundingRegion(
      obj.north,
      obj.east,
      obj.south,
      obj.west
    ).toCoordinateRegion();
  }

  getRegionForOrigin(origin) {
    const span = new mapkit.CoordinateSpan(
      ORIGIN_COORDINATE_SPAN,
      ORIGIN_COORDINATE_SPAN
    );
    return new mapkit.CoordinateRegion(origin.coordinate, span);
  }

  checkRemoveControls() {
    if (this.props.removeControls) {
      // TODO: figure this out
      setTimeout(() => {
        const el = document.querySelector('.mk-controls-container');
        if (el) {
          el.remove();
        }
      }, 1000);
    }
  }

  isBuggyRotationClick() {
    // Bug clicking rotate button fires rotate-end prematurely.
    return this.rotationStartTime && new Date() - this.rotationStartTime < 100;
  }

  // Rendering

  render() {
    return (
      <div ref={this.ref} className={this.getComponentClass()}>
        {this.renderError()}
        {this.renderControls()}
      </div>
    );
  }

  renderError() {
    if (this.state.error) {
      return (
        <div className={this.getElementClass('error')}>
          MapKit error: {this.state.error.status}
        </div>
      );
    }
  }

  renderControls() {
    if (this.state.loading) {
      return <Loader active />;
    }
    return (
      <React.Fragment>
        <div className={this.getElementClass('location-controls')}>
          {this.renderFloorSelector()}
          {this.renderResetButton()}
        </div>
        {this.renderAmenitySelector()}
        {this.renderZoomButtons()}
      </React.Fragment>
    );
  }

  renderFloorSelector() {
    return (
      <div className={this.getElementClass('floor-selector')}>
        {this.manager.getFloors().map((floor) => {
          const active = floor.number === this.props.focusedFloor;
          return (
            <div
              key={floor.name}
              title={`Floor ${floor.name}`}
              className={this.getElementClass(
                'floor-button',
                active ? 'active' : null
              )}
              {...this.onClick(() => this.props.onFloorUpdate(floor.number))}>
              <DesktopOnly>{floor.name}</DesktopOnly>
              <MobileOnly>{floor.short}</MobileOnly>
            </div>
          );
        })}
      </div>
    );
  }

  renderAmenitySelector() {
    if (this.props.renderAmenityButtons) {
      return (
        <div className={this.getElementClass('amenity-selector')}>
          {this.renderAmenityButton('restroom')}
          {this.renderAmenityButton('parking')}
          {this.renderAmenityButton('escalator')}
          {this.renderAmenityButton('elevator')}
          {this.renderAmenityButton('stairs')}
          {this.renderAmenityButton('service')}
        </div>
      );
    }
  }

  renderAmenityButton(amenity) {
    const active = amenity === this.props.focusedAmenity;
    return (
      <div
        title={amenity}
        className={this.getElementClass(
          'amenity-button',
          active ? 'active' : null
        )}
        {...this.onClick(() => this.props.onAmenityClick(amenity))}>
        <Icon name={amenity} />
      </div>
    );
  }

  renderResetButton() {
    if (this.props.renderResetButton) {
      return (
        <div
          className={this.getElementClass('reset-button')}
          {...this.onClick(this.onResetButtonClick)}>
          <span>Reset</span> <Icon name="reset" size="small" />
        </div>
      );
    }
  }

  renderAnimatedPolyline() {
    if (this.state.loading) {
      return;
    }
    return (
      <AnimatedPolyline
        map={this.map}
        segment={this.getCurrentWayfindingSegment()}
        hidden={this.state.isTransitioning}
      />
    );
  }

  renderZoomButtons() {
    if (this.props.renderZoomButtons) {
      return (
        <div className={this.getElementClass('zoom-buttons')}>
          <div
            className={this.getElementClass('zoom-button', 'in')}
            {...this.onClick(this.onZoomInClick)}>
            <Icon name="plus-light" />
          </div>
          <div
            className={this.getElementClass('zoom-button', 'out')}
            {...this.onClick(this.onZoomOutClick)}>
            <Icon name="minus-light" />
          </div>
        </div>
      );
    }
  }
}

MapKit.propTypes = {
  focusedFloor: PropTypes.number.isRequired,
  focusedAmenity: PropTypes.string,
  focusedVenue: PropTypes.object,
  kioskId: PropTypes.string,
  onMapReset: PropTypes.func,
  onFloorUpdate: PropTypes.func,
  onAmenityClick: PropTypes.func,
  onMapInitialized: PropTypes.func,
  customClickHandler: PropTypes.func,
  renderAmenityButtons: PropTypes.bool,
  renderResetButton: PropTypes.bool,
  renderZoomButtons: PropTypes.bool,
  removeControls: PropTypes.bool,
  annotation: PropTypes.object,
  clickAnnotation: PropTypes.object,
  onAnnotationAdded: PropTypes.func,
};

MapKit.defaultProps = {
  removeControls: false,
  renderResetButton: false,
  renderZoomButtons: false,
  renderAmenityButtons: false,
  onAnnotationAdded: () => {},
};
