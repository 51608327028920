import { observable, action } from 'mobx';
import BaseStore from 'common/stores/BaseStore';

export default class OrderUpdateStore extends BaseStore {
  @observable order = {};
  @observable venue = {};
  @observable currentTickets = [];
  @observable newTickets = [];
  @observable changeDateFeeTicket = null;
  @observable newAddons = [];
  @observable updatedOrder = {};
  @observable missingDateChangeFeeTicket = false;
  @observable bundle = null;

  @action
  initialize = () => {
    this.order = {};
    this.venue = {};
    this.currentTickets = [];
    this.newTickets = [];
    this.newAddons = [];
    this.updatedOrder = {};
    this.changeDateFeeTicket = null;
    this.missingDateChangeFeeTicket = false;
    this.bundle = null;
  };

  @action
  setOrder = (order) => {
    this.order = order;
  };

  @action
  setVenue = (venue) => {
    this.venue = venue;
  };

  @action
  setCurrentTickets = (currentTickets) => {
    this.currentTickets = currentTickets;
  };

  @action
  setNewTickets = (newTickets) => {
    this.newTickets = newTickets;
  };

  @action
  setChangeDateFeeTicket = (changeDateFeeTicket) => {
    this.changeDateFeeTicket = changeDateFeeTicket;
  };

  @action
  setMissingDateChangeFeeTicket = (missingDateChangeFeeTicket) => {
    this.missingDateChangeFeeTicket = missingDateChangeFeeTicket;
  };

  @action
  setNewAddons = (newAddons) => {
    this.newAddons = newAddons;
  };

  @action
  setUpdatedOrder = (updatedOrder) => {
    this.updatedOrder = updatedOrder;
  };

  @action
  setBundle = (bundle) => {
    this.bundle = bundle;
  };
}
