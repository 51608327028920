import React from 'react';
import propTypes from 'prop-types';
import { ContentfulRichText } from 'common/components';
import { useClassName } from 'common/hooks';

import './announcement.less';

const Announcement = ({ announcement, handleLinkClick }) => {
  const className = useClassName('Announcement');

  const handleClick = () =>
    handleLinkClick(announcement.link, 'Announcement pressed', {
      label: announcement.type,
    });

  return (
    <div
      className={className('container')}
      style={{
        backgroundImage: `${
          announcement?.withGradient
            ? 'linear-gradient(123.64deg, rgba(0, 0, 0, 0.7) 7.95%, rgba(0, 0, 0, 0) 79.55%) ,'
            : ''
        } url(${announcement.assetUrl})`,
        color: announcement?.textColor || '#FFF',
      }}>
      {announcement.heading && (
        <div className={className('heading')}>{announcement.heading}</div>
      )}
      {announcement.body && (
        <div className={className('body')}>
          <ContentfulRichText field={announcement.body} />
        </div>
      )}
      {announcement.link && announcement.linkText && (
        <div className={className('cta')}>
          {announcement.ctaType === 'link' ? (
            <span className={className('cta-link')} onClick={handleClick}>
              {announcement.linkText}
            </span>
          ) : (
            <button className={className('cta-button')} onClick={handleClick}>
              {announcement.linkText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

Announcement.propTypes = {
  announcement: propTypes.object.isRequired,
  handleLinkClick: propTypes.func.isRequired,
};

export default Announcement;
