import { getCurrentLocaleCode } from 'utils/l10n';

export default function unwrapContentfulFields(
  obj,
  lang = getCurrentLocaleCode(false)
) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => unwrapContentfulFields(item, lang));
  }

  const newObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === lang) {
        return unwrapContentfulFields(obj[key], lang);
      } else {
        newObj[key] = unwrapContentfulFields(obj[key], lang);
      }
    }
  }

  return newObj;
}
