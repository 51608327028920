// The API currently speaks dates in the format of YYYY-MM-DD,
// and these are assumed to be local to the server time. The
// client apps treat API dates as local, and the context of
// the site should make it clear that these days are local
// to the mall itself instead of the user's current timezone.

import { DateTime } from 'luxon';

export function formatApiDate(input) {
  if (typeof input === 'object') {
    return DateTime.fromJSDate(input).toISODate();
  } else if (typeof input === 'string') {
    return input;
  }
}

export function parseApiDate(input) {
  if (typeof input === 'object') {
    return input;
  } else if (typeof input === 'string') {
    return DateTime.fromISO(input).toJSDate();
  }
}
