import React from 'react';
import { inject, observer } from 'mobx-react';
import { Component } from 'common/helpers';
import { SVGIcon as Icon } from 'common/components';

import './cart-badge.less';

@inject('cart')
@observer
export default class CartBadge extends Component {
  render() {
    const quantity = this.props.cart.getTotalQuantity();
    return (
      <div {...this.getAttrs()}>
        {this.renderIcon(quantity)}
        {this.renderBadge(quantity)}
      </div>
    );
  }

  renderIcon(quantity) {
    const name = quantity ? 'shopping-cart-with-quantity' : 'shopping-cart';
    return <Icon name={name} size="small" />;
  }

  renderBadge(quantity) {
    if (quantity) {
      return <span className={this.getElementClass('size')}>{quantity}</span>;
    }
  }
}
