import React from 'react';

import { useClassName } from 'common/hooks';

import './center.less';

export default function Center({ children, style, className }) {
  const classNames = useClassName('CLPCenter');

  return (
    <div
      className={`${classNames('container')} ${className}`}
      ref={(node) => {
        if (node) {
          for (const [key, value] of Object.entries(style || {})) {
            node.style.setProperty(key, value, 'important');
          }
        }
      }}>
      {children}
    </div>
  );
}
