import React from 'react';
import { DateTime } from 'luxon';

import { Component } from 'common/helpers';
import { formatHourMinutesRange } from 'utils/l10n';

import './open-status.less';

export default class OpenStatus extends Component {
  isOpenForDay(day) {
    const nj = DateTime.now().setZone('America/New_York');
    const hour = nj.hour;
    const minute = nj.minute;

    if (day.isClosed) {
      return false;
    } else if (hour === day.openHour) {
      return minute > (day.openMinute || 0);
    } else if (hour === day.closeHour) {
      return minute < (day.closeMinute || 0);
    } else {
      return hour > day.openHour || hour < day.closeHour;
    }
  }

  render() {
    const { openingHours, comingSoon } = this.props;
    if (!openingHours) {
      return null;
    }
    const { projectedDays, temporarilyClosed } = this.props.openingHours;
    const today = projectedDays[0];

    if (comingSoon) {
      return (
        <span className={this.getElementClass('status')}>COMING SOON</span>
      );
    } else if (!temporarilyClosed && this.isOpenForDay(today)) {
      return (
        <React.Fragment>
          <span className={this.getElementClass('status')}>OPEN TODAY:</span>{' '}
          <span className={this.getElementClass('hours')}>
            {formatHourMinutesRange(
              today.openHour,
              today.openMinute,
              today.closeHour,
              today.closeMinute
            )}
          </span>
        </React.Fragment>
      );
    } else {
      return <span className={this.getElementClass('status')}>CLOSED</span>;
    }
  }
}
