import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class SubscriptionsStore extends BaseStore {
  @action
  fetch(statusKey = 'fetch') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/subscriptions/',
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  update(subscriptionId, inactive, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/subscriptions/${subscriptionId}`,
      body: {
        inactive,
      },
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  create(
    { group, email, firstName, lastName, zipCode, phone } = {},
    statusKey = 'create'
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/subscriptions/',
      body: {
        group,
        email,
        firstName,
        lastName,
        zipCode,
        phone,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
