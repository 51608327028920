import { useState, useEffect } from 'react';

export default function useDOMElement(selector, keepListening) {
  const [elem, setElem] = useState(null);

  useEffect(() => {
    if (!selector) return;

    const element = document.querySelector(selector);
    if (element) {
      setElem(element);
    } else {
      const observer = new MutationObserver(() => {
        const element = document.querySelector(selector);
        if (element) {
          setElem(element);
          if (!keepListening) {
            observer.disconnect();
          }
        } else {
          setElem(null);
        }
      });
      observer.observe(document, { subtree: true, childList: true });

      return () => observer.disconnect();
    }
  }, []);

  return [elem];
}
