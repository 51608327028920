import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import './field-container.less';

const FieldContainer = ({ children, error, ...rest }) => {
  const classNames = useClassName('FieldContainer');

  return (
    <div className={classNames(['field', error && 'error'])} {...rest}>
      {children}
      {Boolean(error) && <caption>{error}</caption>}
    </div>
  );
};

FieldContainer.propTypes = {
  children: PropTypes.any,
  error: PropTypes.string,
};

export default FieldContainer;
