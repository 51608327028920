import React from 'react';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Button } from 'common/lazy';
import { useClassName } from 'common/hooks';
import { CustomMessage } from '../../CustomMessage';
import { parseDate, getSessionName } from '../helper';
import calendar from 'common/assets/calendar.svg';
import './order-detail.less';

const SESSION_OFFSET = 1;
const TIME_ZONE = 'America/New_York';

export const OrderDetail = ({
  order,
  updatedOrder,
  tickets,
  venue,
  handleModifyOrder,
}) => {
  const className = useClassName('OrderDetail');

  const getIsNearSession = (date, sessionStartTime) => {
    if (!sessionStartTime) return false;

    const [hour, minute] = sessionStartTime.split(':');
    const today = DateTime.local().setZone(TIME_ZONE);
    const ticketDate = DateTime.fromISO(date)
      .setZone(TIME_ZONE)
      .set({ hour, minute })
      .minus({ hours: SESSION_OFFSET });

    return today > ticketDate;
  };

  const showModifyButton = (date, sessionStartTime) => {
    const hasBundledTickets = tickets.some(
      (item) => !!item.ticketOption.bundleSlug
    );
    const hasExternalTickets = tickets.some(
      (item) => !!item.externalBookingId && !item.ticketOption.addon
    );
    const today = DateTime.local();
    const expiredTickets = tickets.some(
      (item) => DateTime.fromISO(item.ticketOption.date) < today.startOf('day')
    );
    const isNearSession = getIsNearSession(date, sessionStartTime);

    return (
      !hasExternalTickets &&
      !hasBundledTickets &&
      !expiredTickets &&
      order.status !== 'cancelled' &&
      order?.rollerDetails?.status?.toLowerCase() !== 'cancelled' &&
      !isNearSession &&
      !venue.stripeAccount
    );
  };

  const renderDate = (date, session) => {
    if (venue.hiddenDatePicker && !session) return null;

    const hasBundledTickets = tickets.some(
      (item) => !!item.ticketOption.bundleSlug
    );

    return (
      <>
        <img src={calendar} alt="calendar" />
        <div className={className('date-time')}>
          {!venue.hiddenDatePicker && <p className="date">{parseDate(date)}</p>}
          {session && !hasBundledTickets && (
            <p className="session">{session}</p>
          )}
        </div>
      </>
    );
  };

  const renderTicket = (
    id,
    quantity,
    name,
    addon = false,
    sessionName,
    isBundleProduct = false
  ) => {
    return (
      <div key={id} className={className('ticket')}>
        <div className={className('quantity')}>(x{quantity})</div>
        <div className="name">
          <p>{name}</p>
          {isBundleProduct && sessionName && (
            <p className="session-name">{sessionName}</p>
          )}
        </div>
        {addon && <span className="addon-tag">Add-on</span>}
      </div>
    );
  };

  const renderModifyOrder = (date, session, sessionStartTime) => {
    const isButtonVisible = showModifyButton(date, sessionStartTime);

    if (!isButtonVisible && venue.hiddenDatePicker && !session) return null;

    return (
      <div className={className('modify-order')}>
        {renderDate(date, session)}
        {isButtonVisible && (
          <Button
            className="modify-btn"
            onClick={() => handleModifyOrder(order, tickets, venue)}>
            Modify
          </Button>
        )}
      </div>
    );
  };

  const renderTickets = () => {
    const groupedTickets = groupBy(
      tickets,
      (ticket) => ticket.ticketOption.date
    );
    return (
      <div className={className('tickets-container')}>
        {Object.keys(groupedTickets).map((key) => (
          <div key={key}>
            {renderModifyOrder(
              key,
              getSessionName(groupedTickets[key][0].ticketOption.sessionName),
              groupedTickets[key][0]?.ticketOption?.startTime
            )}
            {groupedTickets[key].map((ticket) =>
              renderTicket(
                ticket.id,
                ticket.ticketOption.quantity,
                ticket.ticketOption.ticketOptionName,
                ticket.ticketOption.addon,
                getSessionName(ticket.ticketOption?.sessionName),
                Boolean(ticket.bundleSlug)
              )
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={className('container')}>
      {order.id === updatedOrder.orderId &&
        updatedOrder.venueId === venue.id && (
          <CustomMessage type="success">Order updated</CustomMessage>
        )}
      {renderTickets()}
    </div>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.object.isRequired,
  updatedOrder: PropTypes.shape({
    orderId: PropTypes.string,
    venueId: PropTypes.string,
  }),
  tickets: PropTypes.array.isRequired,
  venue: PropTypes.object.isRequired,
  handleModifyOrder: PropTypes.func.isRequired,
};
