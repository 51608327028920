const STRIP_TAGS_REG = /(<([^>]+)>)/gi;

export function stripTags(str) {
  return str.replace(STRIP_TAGS_REG, '');
}

export function escape(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}
