import React, { useRef, useState } from 'react';
import { inject } from 'mobx-react';

import { SVGIcon } from 'common/components';
import { useClassName } from 'common/hooks';
import { identify, track } from 'utils/analytics';
import { isValidEmail } from 'utils/helpers';

import Center from '../../../../Home/Components/Center';
import useCustomStyle from '../../../hooks';

import './subscribe.less';

export default inject(
  'me',
  'subscriptions'
)(function Subscribe({ component, me, subscriptions }) {
  const classNames = useClassName('CLPSubscribe');
  const [, styles] = useCustomStyle(component.fields.settings);
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [signedUp, setSignedUp] = useState(false);
  const imageUrl = styles['--image-url'];

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (signedUp) return;
    const email = inputRef.current.value;
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    try {
      identify(me?.user || { email });

      await subscriptions.create({
        group: 'notify',
        email,
      });
      track('Subscription', {
        group: 'notify',
        url: location.pathname,
      });
      setSignedUp(true);
    } catch (e) {
      setError(e.message);
      console.error(`Error subscribing customer, error: ${e.message}`);
    }
  };

  return (
    <Center>
      <div className={classNames('container')}>
        <div>
          <h3>Stay in the loop!</h3>
          <p>
            Join our email list for promotions, giveaways, and other
            communications from the American Dream.
          </p>
          <div>
            <input
              type="text"
              placeholder="email@example.com"
              ref={inputRef}></input>
            {signedUp ? (
              <SVGIcon name="check" />
            ) : (
              <button onClick={handleSubscribe}>
                <span>Subscribe</span>
                <SVGIcon name="arrow-right" size="tiny" />
              </button>
            )}
          </div>
          <span>{error}</span>
        </div>
        {Boolean(imageUrl) && (
          <img src={imageUrl} alt={styles['--image-alt']} />
        )}
      </div>
    </Center>
  );
});
