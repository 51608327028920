import { observable, action } from 'mobx';
import { request, formatApiDate } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class TicketInventoryStore extends BaseStore {
  @observable inventoryForDate = new Map();
  @observable sessionsForDate = new Map();

  get(venueId, date) {
    return this.inventoryForDate.get(this.getKey(venueId, date));
  }

  getSessions(venueId, date) {
    return this.sessionsForDate.get(this.getKey(venueId, date)) || [];
  }

  getKey(venueId, date) {
    return `${venueId}-${formatApiDate(date)}`;
  }

  getInventoryItem({ venueId, reservationDate, ticketOptionId }) {
    const inventory = this.get(venueId, reservationDate);
    return inventory.find((item) => item.ticketOptionId === ticketOptionId);
  }

  @action
  search(
    { venueId, date, promoCode, forceRefresh, includeFee } = {},
    statusKey = 'search'
  ) {
    const inventory = this.get(venueId, date);
    if (inventory && !forceRefresh) {
      return;
    }

    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/ticketinventory/search',
      body: {
        venueId,
        promoCode,
        date: formatApiDate(date),
        includeFee,
      },
    })
      .then(({ data }) => {
        const inventory = data.ticketInventory;

        const sessions = data.sessions;

        this.inventoryForDate.set(this.getKey(venueId, date), inventory);

        this.sessionsForDate.set(this.getKey(venueId, date), sessions);

        status.success();
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
