import PropTypes from 'prop-types';

export const propTypes = {
  fields: PropTypes.object,
  brazeTriggers: PropTypes.shape({
    customAttributes: PropTypes.object,
    customEvent: PropTypes.string,
  }),
};

export const defaultProps = {
  fields: {},
  brazeTriggers: {
    customAttributes: {
      groupsales: 'yes',
    },
    customEvent: 'subscription groupsales form',
  },
};
