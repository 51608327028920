import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { LandingInfoCard } from '../LandingInfoCard';
import './landing-venues-section.less';

const ALL_VENUES = 'all';

const getDefaultCategory = (defaultCategory, categories) => {
  if (!defaultCategory) return ALL_VENUES;

  return categories.includes(defaultCategory) ? defaultCategory : ALL_VENUES;
};

const LandingVenuesSection = ({
  id,
  cards,
  categories,
  fontColor,
  buttonColor,
  buttonTextColor,
  buttonSecondaryColor,
  buttonSecondaryTextColor,
  defaultCategory,
}) => {
  const className = useClassName('LandingVenuesSection');
  const DEFAULT_CARDS_QUANTITY = 6;
  const LOAD_MORE_TEXT = 'Load more';
  const btnColor = buttonTextColor || '#FFFFFF'; //inverted colors
  const btnTextColor = buttonColor || '#000000'; //inverted colors
  const btnUnselectedColor = buttonSecondaryColor || '#FFFFFF'; //inverted colors
  const btnUnselectedTextColor = buttonSecondaryTextColor || '#000000'; //inverted colors

  const [selectedCategory, setSelectedCategory] = useState(
    getDefaultCategory(defaultCategory, categories)
  );
  const [infoCardsList, setInfoCardsList] = useState(null);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(
    Boolean(categories?.length)
  );
  const [ctaFontColor, setCtaFontColor] = useState(btnTextColor);
  const [ctaBackgroundColor, setCtaBackgroundColor] = useState(null);

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events
  const trackingCategory = trackingCategories.VENUE_INFO;

  useEffect(() => {
    renderCards(selectedCategory, !showLoadMoreButton);
  }, []);

  const onLoadMoreMouseEnter = () => {
    setCtaFontColor(btnColor);
    setCtaBackgroundColor(btnTextColor);
  };

  const onLoadMoreMouseLeave = () => {
    setCtaFontColor(btnTextColor);
    setCtaBackgroundColor(null);
  };

  const renderCards = (category, showAllCards) => {
    if (!cards?.length) return null;

    // how many cards should be displayed (default initial value or all)
    const quantityToShow = showAllCards ? cards.length : DEFAULT_CARDS_QUANTITY;

    let cardsToShow = [];

    // check full list
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      // only selected category is displayed
      if (category === ALL_VENUES || category === card.category) {
        if (cardsToShow.length < quantityToShow) {
          const cardComponent = (
            <LandingInfoCard
              key={i}
              card={card}
              type={'venue'}
              fontColor={fontColor}
              buttonColor={buttonColor}
              buttonTextColor={buttonTextColor}
            />
          );
          // card added to list to show
          cardsToShow.push(cardComponent);

          // if it was the last card added, remove load more button
          if (cardsToShow.length === quantityToShow)
            setShowLoadMoreButton(false);
        } else {
          // still cards to show
          setShowLoadMoreButton(true);
        }
      }
    }

    // check if load more button should be displayed or not
    if (cardsToShow.length < quantityToShow) setShowLoadMoreButton(false);

    setInfoCardsList(cardsToShow);
  };

  const onCategoryChange = (newCategory) => {
    if (newCategory !== selectedCategory) {
      setSelectedCategory(newCategory);
      renderCards(newCategory, false);

      const name = `${newCategory} category`;

      standardizedTrack(
        'click',
        'button',
        'venues section',
        name,
        trackingCategory,
        trackingLabel
      );
    }
  };

  const onLoadMorePressed = () => {
    renderCards(selectedCategory, true);

    standardizedTrack(
      'click',
      'button',
      'venues section',
      'load more',
      trackingCategory,
      trackingLabel
    );
  };

  const categoryButton = (category) => {
    const accentStyle =
      category === selectedCategory
        ? {
            backgroundColor: btnTextColor,
            color: btnColor,
            borderColor: btnTextColor,
          }
        : {
            backgroundColor: btnUnselectedColor,
            color: btnUnselectedTextColor,
            borderColor: btnUnselectedColor,
          };
    return (
      <div
        key={category}
        className={className('category-button')}
        style={accentStyle}
        onClick={() => onCategoryChange(category)}>
        {category}
      </div>
    );
  };

  const renderCategories = (categories) => {
    if (!categories?.length) return null;

    return categories.map((category) => categoryButton(category));
  };

  if (!cards?.length) return null;

  return (
    <div id={id} className={className('container')}>
      {Boolean(categories?.length) && (
        <div className={className('categories-wrapper')}>
          <div className={className('categories-container')}>
            {categoryButton(ALL_VENUES)}
            {renderCategories(categories)}
          </div>
        </div>
      )}

      <div className={className('cards-wrapper')}>
        <div className={className('cards-container')}>{infoCardsList}</div>
        {showLoadMoreButton && (
          <div
            className={className('cta-container')}
            style={{
              color: ctaFontColor,
              backgroundColor: ctaBackgroundColor,
              borderColor: btnTextColor,
            }}
            onClick={() => onLoadMorePressed()}
            onMouseEnter={() => onLoadMoreMouseEnter()}
            onMouseLeave={() => onLoadMoreMouseLeave()}>
            {LOAD_MORE_TEXT}
          </div>
        )}
      </div>
    </div>
  );
};

LandingVenuesSection.propTypes = {
  id: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.object,
    })
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  defaultCategory: PropTypes.string,
  fontColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonSecondaryColor: PropTypes.string,
  buttonSecondaryTextColor: PropTypes.string,
};

LandingVenuesSection.defaultProps = {
  cards: null,
  categories: null,
  fontColor: null,
  buttonColor: null,
  buttonTextColor: null,
  buttonSecondaryColor: null,
  buttonSecondaryTextColor: null,
};

export default LandingVenuesSection;
