import { mapValues } from 'lodash';
import { getCurrentLocaleCode } from 'utils/l10n';

// We are integrating Contentful objects into our system
// in ways that aren't always consistent, so need to map
// preview objects here so that they align with our API.
// Also note that the preview API returns with fields
// mapped to the requested locale, so need to "un-map"
// them so that clients can consume. We may need to
// take a different approach here when we deal with
// localization.

export default function mapContentfulFields(
  fields,
  localeCode = getCurrentLocaleCode(false)
) {
  return mapValues(fields, (field) => {
    return {
      [localeCode]: mapField(field, localeCode),
    };
  });
}

function mapField(field, localeCode) {
  if (typeof field === 'object' && field.sys) {
    return {
      sys: field.sys,
      fields: mapContentfulFields(field.fields, localeCode),
    };
  } else if (Array.isArray(field)) {
    return field.map((field) => {
      return mapField(field, localeCode);
    });
  } else {
    return field;
  }
}
