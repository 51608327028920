import React from 'react';

export function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="m1.667 5.834 6.804 4.763c.55.386.826.578 1.126.653.264.066.541.066.806 0 .3-.075.575-.268 1.126-.653l6.804-4.763M5.667 16.667h8.666c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.534.272-1.235.272-2.635V7.334c0-1.4 0-2.1-.272-2.635a2.5 2.5 0 0 0-1.093-1.093c-.535-.272-1.235-.272-2.635-.272H5.667c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 1.939 4.7c-.272.535-.272 1.235-.272 2.635v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.534.272 1.234.272 2.635.272"
        stroke="#8C98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
