import React from 'react';
import PropTypes from 'prop-types';
import { localStorage } from 'utils/helpers';
import Modal from './Modal';

// Keeping a list of used localStorage keys here
// to help understand persisting client states:
//
// modal-2019-11-02
// modal-2020-01-21

export default class OneOffModal extends React.Component {
  getKey() {
    return `modal-${this.props.id}`;
  }

  getDefaultOpen() {
    return !localStorage.getItem(this.getKey());
  }

  onClose = () => {
    localStorage.setItem(this.getKey(), 1);
  };

  render() {
    return (
      <Modal
        defaultOpen={this.getDefaultOpen()}
        onClose={this.onClose}
        {...this.props}>
        {this.props.children}
      </Modal>
    );
  }
}

OneOffModal.propTypes = {
  id: PropTypes.string.isRequired,
};
