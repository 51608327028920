import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
  ContentfulRichText,
} from 'common/components';
import { AssetPropType } from 'public/schema';
import { LoaderImage } from '../Loader';
import './welcome-section.less';

const WelcomeSection = ({
  id,
  secondaryTitle,
  primaryTitle,
  imageMobile,
  imageDesktop,
  theme,
}) => {
  const className = useClassName('WelcomeSection');

  // transparent gradient only useful when there is an image in mobile
  let transparentGradient = null;
  if (imageMobile) {
    const hexColor = theme.primaryColor;
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    transparentGradient = `linear-gradient(45deg, rgb(${r}, ${g}, ${b}) 30%, rgba(${r}, ${g}, ${b}, 0) 65%)`;
  }

  return (
    <div
      id={id}
      className={className('container')}
      style={{ backgroundColor: theme.primaryColor }}>
      <div className={className('wrapper')}>
        {(primaryTitle || secondaryTitle) && (
          <div
            className={className('title-container')}
            style={{ background: transparentGradient }}>
            {secondaryTitle && (
              <ContentfulRichText
                className={className('title-secondary')}
                style={{ color: theme.textColor }}
                field={secondaryTitle}
              />
            )}
            {primaryTitle && (
              <ContentfulRichText
                className={className('title-primary')}
                style={{ color: theme.textColor }}
                field={primaryTitle}
              />
            )}
          </div>
        )}
        {imageMobile && (
          <MobileOnly className={className('image-container')}>
            <LoaderImage
              src={imageMobile.assetUrl}
              alt={imageMobile.assetAlt}
              loadingHeight={300}
              width={747}
              hideLoader
              fluid
            />
          </MobileOnly>
        )}
        {imageDesktop && (
          <TabletOnly className={className('image-container')}>
            <LoaderImage
              src={imageDesktop.assetUrl}
              alt={imageDesktop.assetAlt}
              loadingHeight={550}
              width={747}
              hideLoader
              fluid
            />
          </TabletOnly>
        )}
        {imageDesktop && (
          <DesktopOnly className={className('image-container')}>
            <LoaderImage
              src={imageDesktop.assetUrl}
              alt={imageDesktop.assetAlt}
              loadingHeight={550}
              width={747}
              hideLoader
              fluid
            />
          </DesktopOnly>
        )}
      </div>
    </div>
  );
};

WelcomeSection.propTypes = {
  id: PropTypes.string.isRequired,
  primaryTitle: PropTypes.object.isRequired,
  secondaryTitle: PropTypes.object.isRequired,
  imageMobile: PropTypes.shape(AssetPropType),
  imageDesktop: PropTypes.shape(AssetPropType),
  theme: PropTypes.object.isRequired,
};

WelcomeSection.defaultProps = {
  imageMobile: null,
  imageDesktop: null,
};

export default WelcomeSection;
