import { formatHoursMinutes, getWeekdaysLong, getWeekdaysShort } from '../l10n';

const getDayFormatted = (day) => {
  if (day.isClosed) return 'Closed';

  const opensAt = formatHoursMinutes(day.openHour, day.openMinute);
  const closesAt = formatHoursMinutes(day.closeHour, day.closeMinute);

  return `${opensAt} - ${closesAt}`;
};

const dayIsConfigured = (day) =>
  day.isClosed || (day.openHour !== undefined && day.closeHour !== undefined);

export const formatRegularHours = (openingHours, ignoreClosed) => {
  if (!openingHours?.regular?.length) return [];

  const weekdays = getWeekdaysShort();

  const regularHoursOrdered = getWeekdaysLong()
    .map((weekday) =>
      openingHours.regular.find(
        (regular) => regular.weekday === weekday.toLowerCase()
      )
    )
    .filter(Boolean);
  const daysFormatted = regularHoursOrdered.map((a, i) => {
    if (!dayIsConfigured(a)) return null;

    return {
      weekday: weekdays[i],
      hours: getDayFormatted(a),
    };
  });

  const getLastNextDayWithSameHours = (hours, i) => {
    const next = daysFormatted[i + 1];
    if (!next) return i;
    if (next.hours !== hours) return i;

    return getLastNextDayWithSameHours(hours, i + 1);
  };

  const regularHoursFormatted = daysFormatted.reduce((acc, day, i) => {
    if (day === null) return acc;
    if (acc.some((a) => a.days.includes(day.weekday))) return acc;

    const lastNextDayWithSameHours = getLastNextDayWithSameHours(day.hours, i);
    const days = weekdays.slice(i, lastNextDayWithSameHours + 1);
    return [...acc, { days, hours: day.hours }];
  }, []);

  return regularHoursFormatted
    .map((a) => {
      if (a.hours === 'Closed' && ignoreClosed) return null;
      if (a.days.length === 1) return `${a.days[0]}: ${a.hours}`;

      return `${a.days[0]} - ${a.days[a.days.length - 1]}: ${a.hours}`;
    })
    .filter(Boolean);
};
