import React, { useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { inject } from 'mobx-react';

import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import { parseAnnouncement } from 'public/helpers/contentful/parser';

import BookTicketsAnnouncement from './BookTicketsAnnouncement';

import './book-tickets.less';

export default inject('announcements')(function BookTicketsContainer({
  active,
  setActive,
  announcements,
}) {
  const className = useClassName('BookTickets');
  const [bookContainerStyle, setBookContainerStyle] = useState({
    height: 0,
    overflow: 'hidden',
  });
  const [bookTicketsAnnouncements, setBookTicketsAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const bookAnnouncements = await announcements.fetchByType({
        type: 'book-ticket',
      });
      const bookTicketsAnnouncements = bookAnnouncements
        .map(parseAnnouncement)
        .sort((a, b) => a.order - b.order);
      setBookTicketsAnnouncements(bookTicketsAnnouncements);
    };
    fetchAnnouncements();
  }, []);

  useEffect(() => {
    if (active) {
      setBookContainerStyle({});
    } else {
      setTimeout(
        () => setBookContainerStyle({ height: 0, overflow: 'hidden' }),
        700
      );
    }
  }, [active]);

  return (
    <div
      style={bookContainerStyle}
      className={className([
        'book-tickets-container',
        active && 'book-tickets-container-active',
      ])}>
      <div className={className('book-tickets-header')}>
        <span>Book Tickets</span>
        <div
          className={className('close-button')}
          onClick={() => setActive(false)}>
          <Icon className={className('close-icon')} name="close-light" />
        </div>
      </div>

      <ScrollContainer
        className={className('book-tickets-body')}
        vertical={true}
        horizontal={false}
        hideScrollbars={false}>
        {bookTicketsAnnouncements?.length &&
          bookTicketsAnnouncements.map((announcement) => (
            <BookTicketsAnnouncement
              key={announcement.name}
              announcement={announcement}
              setActive={setActive}
            />
          ))}
      </ScrollContainer>
    </div>
  );
});
