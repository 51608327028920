import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import './badge.less';

const Badge = ({ text }) => {
  const className = useClassName('Badge');

  return <span className={className('text')}>{text}</span>;
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Badge;
