import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import { OrderHeader } from './components/OrderHeader';
import { OrderDetail } from './components/OrderDetail';
import WalletButtons from '../../screens/NewCheckout/Components/WalletButtons';

import './order-group.less';

const OrderGroup = ({ order, updatedOrder, handleModifyOrder }) => {
  const className = useClassName('OrderGroup');
  const orderDate = DateTime.fromISO(order.createdAt).toFormat('dd LLL yyyy');
  const { ticketGroups, bookingReference } = order;

  const expiredTickets = order.tickets.every(
    (item) =>
      DateTime.fromISO(item.ticketOption.date) < DateTime.local().startOf('day')
  );

  return (
    <div className={className('container')}>
      <div className={className('purchase-date')}>Purchased {orderDate}</div>
      {!expiredTickets && <WalletButtons orderId={order.id} />}
      <div className={className('bookings')}>
        {Object.keys(ticketGroups).map((key) => (
          <div key={key} className={className('booking-container')}>
            <OrderHeader
              venue={ticketGroups[key].venue}
              bundle={ticketGroups[key].bundle}
              tickets={ticketGroups[key].tickets}
              bookingReference={bookingReference}
              order={order}
              handleModifyOrder={handleModifyOrder}
            />
            <OrderDetail
              order={order}
              updatedOrder={updatedOrder}
              tickets={ticketGroups[key].tickets}
              venue={ticketGroups[key].venue}
              bundle={ticketGroups[key].bundle}
              handleModifyOrder={handleModifyOrder}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

OrderGroup.propTypes = {
  order: PropTypes.object.isRequired,
  updatedOrder: PropTypes.shape({
    orderId: PropTypes.string,
    venueId: PropTypes.string,
  }),
  handleModifyOrder: PropTypes.func.isRequired,
};

export default OrderGroup;
