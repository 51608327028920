import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Loader, Form, Message, Button, Input, Checkbox } from 'common/lazy';
import {
  Layout,
  MultiSelect,
  SVGIcon as Icon,
  TelInput,
} from 'common/components';
import { Component } from 'common/helpers';
import { Spacer } from '../Spacer';
import { identify, track } from 'utils/analytics';
import { isValidEmail, isValidPhone } from 'utils/helpers/validations';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from '../../../common/const';
const newsletter = 'yes';
@inject('subscriptions', 'appSession', 'me')
@observer
@withRouter
export default class NewsletterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      state: 'loading',
      email: '',
      firstName: '',
      lastName: '',
      zipCode: '',
      phone: '',
      groups: [],
      emailAccepted: true,
      smsAccepted: false,
      selectedGroupIds: [],
    };
  }

  getGroupItems() {
    const { groups } = this.state;
    return groups.map((group) => {
      const { id, name } = group;
      return {
        text: name,
        value: id,
      };
    });
  }

  componentDidMount() {
    this.setupRouteEvents();
    this.fetchGroups();
  }

  componentWillUnmount() {
    this.destroyRouteEvents();
  }

  componentDidUpdate(lastProps) {
    const { group } = this.props;
    if (group !== lastProps.group) {
      const selectedGroupIds = group ? [group.id] : [];
      this.setState({
        selectedGroupIds,
      });
    }
  }

  routeDidUpdate() {
    this.reset();
  }

  async fetchGroups() {
    const groups = await this.props.subscriptions.fetch();
    this.setState({
      groups,
      state: 'pending',
    });
    this.props.onGroupsReceived(groups);
  }

  async reset() {
    const { appSession, me } = this.props;
    const user = appSession.isLoggedIn() && (await me.fetch());
    const email = (user && user.email) || '';
    const firstName = (user && user.firstName) || '';
    const lastName = (user && user.lastName) || '';
    this.setState({
      error: null,
      phone: '',
      zipCode: '',
      email,
      firstName,
      lastName,
    });
  }

  onSubmit = async () => {
    const { appSession } = this.props;
    const {
      email,
      firstName,
      lastName,
      zipCode,
      phone,
      emailAccepted,
      smsAccepted,
      selectedGroupIds,
    } = this.state;
    let error;
    if (!firstName) {
      error = 'Please provide a first name.';
    } else if (!lastName) {
      error = 'Please provide a last name.';
    } else if (!isValidEmail(email)) {
      error = 'Please provide a valid email address.';
    } else if (phone !== '' && !isValidPhone(phone)) {
      error = 'Please provide a valid phone number.';
    } else if (!zipCode) {
      error = 'Please provide a zip code.';
    } else if (selectedGroupIds.length === 0) {
      error = 'Please choose an interest to subscribe to.';
    } else if (!emailAccepted) {
      error = 'Email consent is required.';
    } else if (smsAccepted && phone == '') {
      error = 'You need to provide a phone number';
    }
    if (error) {
      this.setState({
        error,
        status: 'error',
      });
      return;
    }
    try {
      this.setState({
        error: null,
        state: 'loading',
      });

      await Promise.all(
        selectedGroupIds.map((group) => {
          return this.props.subscriptions.create({
            group,
            email,
            firstName,
            lastName,
            zipCode,
          });
        })
      );
      if (this.state.smsAccepted) {
        await this.props.subscriptions.create({
          email,
          firstName,
          lastName,
          zipCode,
          phone,
          group: SMS_GROUP_ID,
        });
      }
      if (!appSession.isLoggedIn()) {
        // Knowingly tracking non-logged in user traits here
        // against segment's recommendation:
        // https://segment.com/docs/connections/spec/identify/#spec:identify
        identify({
          firstName,
          lastName,
          zipCode,
          email,
          phone,
          newsletter,
        });
      }
      track(`subscription newsletter form`);
      this.setState({
        state: 'success',
      });
    } catch (err) {
      this.setState({
        state: 'error',
        error: err.message,
      });
    }
  };

  onChange = (evt) => {
    const { target } = evt;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else {
      value = target.value;
    }
    this.setState({
      [target.name]: value,
    });
  };

  onTelChange = (phone) => {
    this.setState({
      phone,
    });
  };

  onGroupAdd = (groupIds, addedId) => {
    const { groups } = this.state;
    if (addedId === DEFAULT_GROUP_ID) {
      // If they add "all" subscribe them
      // to that group instead of the rest.
      groupIds = groupIds.filter((id) => {
        return id === DEFAULT_GROUP_ID;
      });
    } else if (groupIds.length < groups.length - 1) {
      // If there are any unsubscribed groups
      // then remove them from the "all" group.
      groupIds = groupIds.filter((id) => {
        return id !== DEFAULT_GROUP_ID;
      });
    }
    this.setState({
      selectedGroupIds: groupIds,
    });
  };

  onGroupRemove = (groupIds, removedId) => {
    if (removedId !== DEFAULT_GROUP_ID) {
      // If they remove any group other than
      // "all", then unsubscribe them from
      // the "all" group.
      groupIds = groupIds.filter((id) => {
        return id !== DEFAULT_GROUP_ID;
      });
    }
    this.setState({
      selectedGroupIds: groupIds,
    });
  };

  render() {
    const { group } = this.props;
    const { state, error } = this.state;
    const isLoggedIn = this.props.appSession.isLoggedIn();
    if (state === 'loading') {
      return (
        <React.Fragment>
          <Spacer size="m" />
          <Loader inline="centered" active />
          <Spacer size="m" />
        </React.Fragment>
      );
    } else if (state === 'success') {
      return (
        <div className={this.getElementClass('thank-you')}>
          <Layout horizontal center padded stackable>
            <Layout.Group>
              <Icon name="check-circle" size="large" />
            </Layout.Group>
            <Layout.Group>
              <h4>Thank you for subscribing!</h4>
            </Layout.Group>
          </Layout>
          <Spacer size="xs" />
          <p>Your subscription to our list has been confirmed.</p>
          <Spacer size="s" />
          {this.props.onBackClick && (
            <Button onClick={this.props.onBackClick}>Back</Button>
          )}
        </div>
      );
    }
    return (
      <React.Fragment>
        {group && (
          <React.Fragment>
            <h1>Get Notified!</h1>
            <Spacer size="s" />
            <p>
              I’m interested in everything at American Dream! Send me
              notifications for events, store openings, and other developments.
            </p>
            <Spacer size="s" />
          </React.Fragment>
        )}
        {error && (
          <React.Fragment>
            <Message error>{error}</Message>
            <Spacer size="xs" />
          </React.Fragment>
        )}
        <Form onSubmit={this.onSubmit} noValidate>
          <Form.Field disabled={isLoggedIn && !!this.state.firstName}>
            <label htmlFor="firstName">First Name</label>
            <Input
              value={this.state.firstName}
              disabled={isLoggedIn && !!this.state.firstName}
              onChange={this.onChange}
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="given-name"
            />
          </Form.Field>
          <Spacer size="xs" />
          <Form.Field disabled={isLoggedIn && !!this.state.lastName}>
            <label htmlFor="lastName">Last Name</label>
            <Input
              value={this.state.lastName}
              disabled={isLoggedIn && !!this.state.lastName}
              onChange={this.onChange}
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="family-name"
            />
          </Form.Field>
          <Spacer size="xs" />
          <Form.Field disabled={isLoggedIn && !!this.state.email}>
            <label htmlFor="email">Email Address</label>
            <Input
              value={this.state.email}
              disabled={isLoggedIn && !!this.state.email}
              onChange={this.onChange}
              id="email"
              name="email"
              type="text"
              autoComplete="email"
            />
          </Form.Field>

          <Spacer size="xs" />
          <Form.Field disabled={isLoggedIn && !!this.state.phone}>
            <label htmlFor="phone">Phone Number</label>
            <TelInput onChange={this.onTelChange} id="phone" name="phone" />
          </Form.Field>

          <Spacer size="xs" />
          <Form.Field>
            <label htmlFor="zipCode">Zip / Postal Code</label>
            <Input
              value={this.state.zipCode}
              onChange={this.onChange}
              id="zipCode"
              name="zipCode"
              type="text"
              autoComplete="postal-code"
            />
          </Form.Field>
          <Spacer size="xs" />
          <Form.Field>
            <label htmlFor="groups">I'm Interested In</label>
            {this.renderGroupsDropdown()}
          </Form.Field>
          <Spacer size="xs" />
          <Layout horizontal>
            <Layout.Group>
              <Checkbox
                id="emailAccepted"
                name="emailAccepted"
                checked={this.state.emailAccepted}
                onChange={this.onChange}
              />
            </Layout.Group>
            <Layout.Group grow>
              <label htmlFor="emailAccepted">
                <p>
                  Sign me up for exclusive American Dream sweepstakes,
                  promotions, emails, events, and updates. By checking this
                  option you agree to our{' '}
                  <Link to="/terms">Terms and Conditions</Link>.
                </p>
              </label>
            </Layout.Group>
          </Layout>

          <Spacer size="xs" />
          <Layout horizontal>
            <Layout.Group>
              <Checkbox
                id="smsAccepted"
                name="smsAccepted"
                checked={this.state.smsAccepted}
                onChange={this.onChange}
              />
            </Layout.Group>
            <Layout.Group grow>
              <label htmlFor="smsAccepted">
                <p>
                  Sign me up for SMS messages with news, updates and special
                  promotions at the number provided above. Msg&Data rates may
                  apply. Msg frequency varies. Reply HELP for help, STOP to
                  cancel. By checking this option you agree to our{' '}
                  <Link to="/terms">Terms and Conditions</Link>.
                </p>
              </label>
            </Layout.Group>
          </Layout>

          <Spacer size="xs" />
          <Button type="submit" fluid primary>
            Subscribe
          </Button>
        </Form>
      </React.Fragment>
    );
  }

  renderGroupsDropdown() {
    const { selectedGroupIds } = this.state;
    return (
      <MultiSelect
        name="groups"
        selected={selectedGroupIds}
        icon={this.renderSelectIcon()}
        items={this.getGroupItems()}
        onAdd={this.onGroupAdd}
        onRemove={this.onGroupRemove}
        fluid
      />
    );
  }

  renderSelectIcon() {
    const { selectedGroupIds } = this.state;
    if (selectedGroupIds.length > 0) {
      return <Icon name="check" size="tiny" />;
    }
  }
}

NewsletterForm.propTypes = {
  group: PropTypes.object,
  onBackClick: PropTypes.func,
};

NewsletterForm.defaultProps = {};
