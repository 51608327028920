export const PLAN_YOUR_TRIP = [
  {
    title: 'Hours & Directions',
    value: '/hours-and-directions',
  },
  {
    title: 'Parking',
    value: '/parking',
  },
  {
    title: 'Transportation',
    value: '/transportation',
  },
  {
    title: 'Guest Services',
    value: '/guest-services',
  },
  {
    title: 'Directory',
    value: '/directory',
  },
  {
    title: 'Map',
    value: '/map',
  },
  {
    title: 'Health Services',
    value: '/venue/hackensackmuc',
  },
  {
    title: 'Safety & Security',
    value: '/safety-and-security',
  },
];

export const PARTNER = [
  {
    title: 'Leasing',
    value: '/leasing',
  },
  {
    title: 'Artists & Entertainers',
    value: '/dream-debut',
  },
  {
    isEmail: true,
    title: 'Advertising',
    value: 'advertising@americandream.com',
  },
  {
    title: 'Private Events',
    value: '/group-sales',
  },
  {
    title: 'Groups & Charters',
    value: '/groups-and-charter-buses',
  },
  {
    title: 'Corporate Partners',
    value: '/corporate-partners',
  },
  {
    title: 'Travel & Tourism Professionals',
    value: '/travel-and-tourism-professionals',
  },
];

export const ABOUT = [
  {
    title: 'Contact Us',
    value: '/contact',
  },
  {
    title: 'Careers',
    value: '/careers',
  },
  {
    title: 'Tenant Careers',
    value: '/tenant-careers',
  },
  {
    title: 'Press',
    value: '/press',
  },
  {
    title: 'Newsletter Signup',
    value: '/newsletter',
  },
  {
    title: 'Locations',
    value: '/locations',
  },
];
