import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export default class DreamAppStore extends BaseStore {
  @action
  confirmResetPin(token, pin) {
    return request({
      method: 'PUT',
      path: `/1/dreamapp/users/reset-pin/${token}`,
      body: {
        pin,
      },
    });
  }

  @action
  confirmResetPassword(token, password) {
    return request({
      method: 'PUT',
      path: `/1/dreamapp/users/reset-password/${token}`,
      body: {
        password,
      },
    });
  }
}
