import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ContactStore extends BaseStore {
  @action
  sendContactForm(formData, statusKey = `sendContactForm`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/contact/sendForm`,
      body: {
        ...formData,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
