import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'common/lazy';
import { track } from 'utils/analytics';
import { request } from 'utils/api';
import { useClassName, useWindowSize } from 'common/hooks';
import { ContentfulImage, getContentfulField } from 'common/components';

import { Spacer } from '../Spacer';

import './not-found.less';

const parseAction = (action) => {
  const fields = getContentfulField(action).fields;
  return {
    label: getContentfulField(fields.label),
    value: getContentfulField(fields.value),
  };
};

export default function NotFound() {
  const classNames = useClassName('NotFound');
  const { isMobile } = useWindowSize();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    track('Page not found');
    request({
      method: 'GET',
      path: `/1/notfound/`,
    })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  if (error) {
    return (
      <Container>
        <Spacer size="l" />
        <h1>Sorry, that page wasn't found.</h1>
      </Container>
    );
  }

  if (!data) {
    return null;
  }

  const primaryAction = parseAction(data.primaryAction);
  const secondaryAction = parseAction(data.secondaryAction);
  return (
    <div className={classNames('wrapper')}>
      <div>
        <div className={classNames('content')}>
          <h1>{getContentfulField(data.header)}</h1>
          <h2>{getContentfulField(data.subheader)}</h2>
          <div className={classNames('actions')}>
            <Button
              as={Link}
              to={primaryAction.value}
              fluid
              primary
              className="static">
              {primaryAction.label}
            </Button>
            <Button
              as={Link}
              to={secondaryAction.value}
              fluid
              className="static">
              {secondaryAction.label}
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ContentfulImage field={isMobile ? data.imageMobile : data.image} />
      </div>
    </div>
  );
}
