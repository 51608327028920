import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Component } from 'common/helpers';
import {
  Badge,
  Layout,
  Reveal,
  SVGIcon as Icon,
  getContentfulField,
} from 'common/components';
import { track } from 'utils/analytics';
import { Announcement } from '../../Announcement';
import { Banner } from '../../Banner';
import logo from 'common/assets/logo-text-black.svg';
import { CartBadge } from '../../CartBadge';

import './mobile-nav.less';

@inject('menu', 'property', 'venues')
@observer
@withRouter
export default class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openGroups: {},
      active: false,
    };
  }

  componentDidMount() {
    this.setupRouteEvents();
  }

  componentWillUnmount() {
    this.destroyRouteEvents();
  }

  routeDidUpdate() {
    this.setState({
      active: false,
    });
  }

  onIconClick = () => {
    this.setState({
      active: !this.state.active,
    });
    if (this.state.openGroups.hours) {
      this.toggleLinkGroup('hours');
    }
  };

  onCloseClick = () => {
    this.setState({
      active: false,
    });
  };

  onLinkPressed = (name, onTrack) => {
    onTrack ? onTrack() : track('Menu link pressed', { label: name });
    this.setState({
      active: false,
    });
  };

  onSubLinkPressed = (name) => {
    track('Menu sublink pressed', { label: name });
    this.setState({
      active: false,
    });
  };

  handleLogoClick = () => track('AmericanDream Logo Pressed');

  renderMenuWithChildren = (
    index,
    text,
    title,
    items,
    announcement,
    itemIsNew
  ) => {
    const menuId = text.replace(/\s/g, '_');
    return (
      <Layout.Group
        key={`${index}-${text}`}
        {...this.getLinkGroupProps(menuId)}>
        <div className={this.getElementClass('list-header-main')}>
          <div className={this.getElementClass('list-title')}>
            {text}
            {itemIsNew && <Badge text="NEW" />}
          </div>
          <Icon name="angle-down" width={12} height={12} />
        </div>
        <Reveal open={this.state.openGroups[menuId]}>
          <p className={this.getElementClass('list-panelHeader')}>{title}</p>
          <div className={this.getElementClass('list-panelWrapper')}>
            {items.map((item, i) => {
              if (!item?.fields) return null;

              const { text, link, isNew, external } = item.fields;

              if (!link) return null;

              const itemText = text && getContentfulField(text);
              const itemLink = link && getContentfulField(link);
              const itemIsNew = isNew && getContentfulField(isNew);
              const itemExternal = external && getContentfulField(external);

              if (!itemLink) return null;

              if (itemExternal) {
                return (
                  <div
                    className={this.getElementClass('list-title')}
                    key={`${i}-${itemText}`}>
                    <Link
                      key={`${i}-${itemText}`}
                      to={{ pathname: itemLink }}
                      target="_blank"
                      className={this.getElementClass('small')}
                      onClick={() => this.onSubLinkPressed(itemText)}>
                      {itemText}
                    </Link>
                    {itemIsNew && <Badge text="NEW" />}
                  </div>
                );
              } else {
                return (
                  <div
                    className={this.getElementClass('list-title')}
                    key={`${i}-${itemText}`}>
                    <Link
                      className={this.getElementClass('small')}
                      onClick={() => this.onSubLinkPressed(itemText)}
                      to={itemLink}>
                      {itemText}
                    </Link>
                    {itemIsNew && <Badge text="NEW" />}
                  </div>
                );
              }
            })}
            <Announcement instance={announcement} />
          </div>
        </Reveal>
      </Layout.Group>
    );
  };

  renderMenuItem = (index, text, link, isNew, external) => (
    <Layout.Group
      key={`${index}-${text}`}
      className={this.getElementClass('list-group')}>
      {external ? (
        <Link
          to={{ pathname: link }}
          target="_blank"
          onClick={() => this.onLinkPressed(text)}
          className={this.getElementClass('list-header')}>
          {text}
          {isNew && <Badge text="NEW" />}
        </Link>
      ) : (
        <Link
          className={this.getElementClass('list-header')}
          onClick={() => this.onLinkPressed(text)}
          to={link}>
          {text}
          {isNew && <Badge text="NEW" />}
        </Link>
      )}
    </Layout.Group>
  );

  renderMenu() {
    const menuItems = getContentfulField(this.props.menu.menu.fields.items);

    return menuItems.map((item, i) => {
      if (!item?.fields) return null;

      const {
        text,
        link,
        children,
        childrenTitle,
        announcement,
        isNew,
        external,
      } = item.fields;

      const itemText = text && getContentfulField(text);
      const itemLink = link && getContentfulField(link);
      const itemIsNew = isNew && getContentfulField(isNew);
      const itemExternal = external && getContentfulField(external);
      const itemChildren = children && getContentfulField(children);
      const itemChildrenTitle =
        childrenTitle && getContentfulField(childrenTitle);
      const itemAnnouncement = announcement && getContentfulField(announcement);

      if (!itemText) return null;

      if (itemChildren) {
        return this.renderMenuWithChildren(
          i,
          itemText,
          itemChildrenTitle,
          itemChildren,
          itemAnnouncement,
          itemIsNew
        );
      } else {
        return this.renderMenuItem(
          i,
          itemText,
          itemLink,
          itemIsNew,
          itemExternal
        );
      }
    });
  }

  render() {
    const { active } = this.state;
    return (
      <div {...this.getAttrs()}>
        <Banner />
        <Layout className={this.getElementClass('visibleHeader')} center>
          <div
            className={`hamburger-menu-button ${active ? 'open' : ''}`}
            onClick={this.onIconClick}>
            <div className="icon"></div>
          </div>

          {active ? (
            this.renderHoursOfOperation()
          ) : (
            <NavLink
              to="/"
              className={this.getElementClass('logo')}
              onClick={this.handleLogoClick}>
              <img
                src={logo}
                className={this.getElementClass('logo-image')}
                alt="AMERICAN DREAM"
              />
            </NavLink>
          )}
        </Layout>

        <div
          className={this.getElementClass(
            'menu',
            this.state.active ? 'active' : null
          )}>
          <Layout className={this.getElementClass('section-secondary')}>
            <div className={this.getElementClass('links')}>
              <Link
                to="/cart"
                className={this.getElementClass('icon-linkSecondary')}>
                Cart
                <CartBadge />
              </Link>
              <Link
                to="/profile"
                className={this.getElementClass('icon-linkSecondary')}>
                My Bookings
                <Icon name="user" size="small" />
              </Link>
            </div>
          </Layout>
          <Layout>
            <div className={this.getElementClass('list')}>
              {this.renderMenu()}
            </div>
          </Layout>
        </div>
      </div>
    );
  }

  handleShowHoursClicked = () => {
    this.toggleLinkGroup('hours');
    this.props.history.push('/hours-and-directions');
  };

  renderHoursOfOperation() {
    return (
      <div
        className={this.getElementClass('list-header-main', 'hours')}
        onClick={this.handleShowHoursClicked}>
        <div className={this.getElementClass('hours-title')}>
          <Icon name="clock-nine" className={this.getElementClass('clock')} />
          Open hours
        </div>
        <div className={this.getElementClass('hours-info')}>
          <Icon name="chevron-right" className={'chevron'} />
        </div>
      </div>
    );
  }

  handleHoursInfoItemClick(venue) {
    return () => {
      track(`Clicked on ${venue.name}`);
      this.toggleLinkGroup('hours');
    };
  }

  trackViewInfo(openGroups, name) {
    if (openGroups[name]) {
      if (name === 'hours') track('View hours opened', { label: 'View hours' });
      if (name === 'play')
        track('View attractions opened', { label: 'View attractions' });
      if (name === 'visit') track('View visit opened', { label: 'View visit' });
      if (name === 'online-shop')
        track('View online shop opened', { label: 'View online shop' });
    }
  }

  toggleLinkGroup(name) {
    let openGroups = this.state.openGroups;
    openGroups = Object.assign({}, openGroups, {
      [name]: !openGroups[name],
    });

    this.trackViewInfo(openGroups, name);

    this.setState({
      openGroups,
    });
  }

  getLinkGroupProps(name) {
    const open = this.state.openGroups[name];
    const className = this.getElementClass('link-group', open ? 'open' : null);
    const onClick = () => this.toggleLinkGroup(name);
    return {
      onClick,
      className,
    };
  }
}
