import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { parseVenueDetails } from 'public/helpers/contentful/parser';
import { VenueDetailsHero } from './VenueDetailsHero';
import { VenueGallery } from './VenueGallery';
import { VenueDetailsInfoPanel } from './VenueDetailsInfoPanel';
import { OtherVenues } from './OtherVenues';
import VenueDetailsBreadcrumb from '../VenueDetailsBreadcrumb';
import './venue-details-screen.less';
import { VenueDetailsMap } from './VenueDetailsMap';

const VenueDetailsScreen = ({ venue, relatedVenues }) => {
  const className = useClassName('VenueDetailsScreen');

  const venueDetail = parseVenueDetails(venue?.content?.venueDetail);

  if (!venueDetail) return null;

  const {
    slug,
    title,
    heroLogo,
    heroVideo,
    heroPoster,
    fullDescription,
    detailsLinks,
    galleryTitle,
    gallery,
    cardTitle,
    type,
  } = venueDetail;

  return (
    <div className={className('container')}>
      <VenueDetailsBreadcrumb type={type} title={cardTitle} />
      <VenueDetailsHero
        id={'hero'}
        slug={slug}
        openingHours={venue.openingHours}
        featuredFlags={venue?.featuredFlags}
        heroTitle={title}
        heroSubtitle={fullDescription}
        heroLogo={heroLogo}
        heroVideo={heroVideo}
        heroPoster={heroPoster}
      />
      <VenueDetailsInfoPanel
        id={'info-panel'}
        locationDescription={venue.locationDescription}
        email={venue?.contactDetails?.email}
        phoneNo={venue?.contactDetails?.phoneNo}
        detailsLinks={detailsLinks}
        curbsidePickupInfo={venue?.curbsidePickupInfo}
        parkingDeck={venue?.parkingDeck}
      />
      <VenueDetailsMap
        jibestreamDestinationId={venue.jibestreamDestinationId}
      />
      {gallery?.length && (
        <VenueGallery
          id={'gallery'}
          galleryTitle={galleryTitle}
          gallery={gallery}
        />
      )}
      <OtherVenues
        id={'other-venues'}
        venueSlug={slug}
        venues={relatedVenues}
      />
    </div>
  );
};

VenueDetailsScreen.propTypes = {
  venue: PropTypes.shape({
    content: PropTypes.shape({
      venueDetail: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  relatedVenues: PropTypes.arrayOf(PropTypes.object),
};

VenueDetailsScreen.defaultProps = {
  relatedVenues: [],
};

export default VenueDetailsScreen;
