import { useClassName } from 'common/hooks';

import './use-pagination.less';

export function usePagination({
  horizontalClass,
  bulletClass,
  bulletActiveClass,
} = {}) {
  const classNames = useClassName('UsePagination');

  return {
    pagination: {
      clickable: true,
      horizontalClass: horizontalClass || classNames('bullets-container'),
      bulletClass: bulletClass || classNames('bullet'),
      bulletActiveClass: bulletActiveClass || classNames('bullet-active'),
    },
  };
}
