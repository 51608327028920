import React from 'react';

import { useClassName } from 'common/hooks';

import './slider-bullets.less';

export default function SliderBullets({
  activeIndex,
  length,
  slideTo,
  style,
  className,
  bulletColor,
}) {
  const classNames = useClassName('SliderBullets');
  return (
    <div className={`${classNames(['container'])} ${className}`} style={style}>
      {[...Array(length).keys()].map((index) => (
        <div
          key={index}
          onClick={() => slideTo(index)}
          {...(Boolean(bulletColor) && { style: { background: bulletColor } })}
          {...(activeIndex === index && {
            className: classNames('active'),
          })}></div>
      ))}
    </div>
  );
}
