import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { request } from 'utils/api';

const useMetadata = (pathname) => {
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    if (pathname) {
      request({
        method: 'GET',
        path: `/1/metadata?pathname=${pathname}`,
      }).then((data) => {
        setMetadata(data);
      });
    }
  }, [pathname]);

  return [metadata];
};

const MetaData = ({ pathname }) => {
  const [metadata] = useMetadata(pathname);

  if (!metadata) return null;

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
    </Helmet>
  );
};

MetaData.propTypes = {
  pathname: PropTypes.string,
};

export default MetaData;
