import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentfulRichText, SVGIcon } from 'common/components';
import CTA from './CTA';
import { useClassName } from 'common/hooks';

import './collapsible-list.less';

const CollapsibleList = ({ collapsibleList }) => {
  const className = useClassName('ModularCollapsibleList');
  const [isCollapsed, setIsCollapsed] = useState(
    collapsibleList?.initiallyCollapsed
  );
  const [listElements, setListElements] = useState(
    collapsibleList?.items.map((item) => ({
      ...item,
      visible: false,
    }))
  );

  const toggleElement = (element) => {
    setListElements(
      listElements.map((item) => {
        if (item.key !== element.key) return item;
        return { ...item, visible: !item.visible };
      })
    );
  };

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const renderValue = (value) => {
    if (!value) return null;

    return (
      <div className={className('body')}>
        {typeof value === 'object' ? (
          <ContentfulRichText field={value} />
        ) : (
          value
        )}
      </div>
    );
  };

  return (
    <div className={className('container')}>
      {collapsibleList.cta && isCollapsed && (
        <CTA cta={collapsibleList.cta} onClick={toggleCollapse} />
      )}
      <div className={className(['list', !isCollapsed && 'list-open'])}>
        {listElements.map((element, index) => (
          <div
            key={index}
            className={className([
              'content',
              element.visible && 'content-visible',
            ])}>
            <div
              onClick={() => toggleElement(element)}
              className={className('header')}>
              <h4>{element.key}</h4>
              <SVGIcon name="angle-down" size="mini" />
            </div>
            {element.visible &&
              renderValue(element.richTextValue || element.value)}
          </div>
        ))}
      </div>
    </div>
  );
};

CollapsibleList.propTypes = {
  collapsibleList: PropTypes.object,
};

export default CollapsibleList;
