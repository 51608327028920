import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import { LoaderImage } from '../../Loader';
import './ride-gallery.less';

const DEFAULT_TITLE = 'Gallery';

const className = useClassName('RideGallery');

const title = (galleryTitle) => {
  if (galleryTitle) {
    return (
      <ContentfulRichText className={className('title')} field={galleryTitle} />
    );
  } else return <div className={className('title')}>{DEFAULT_TITLE}</div>;
};

const images = (images) => {
  if (!images?.length) return null;

  return images.map((image, i) => {
    return (
      <div key={i} className={className('image-container')}>
        <LoaderImage
          className={className('image')}
          src={image.file}
          alt={image.title}
          loadingHeight={260}
          fluid
        />
      </div>
    );
  });
};

const RideGallery = ({ id, galleryTitle, gallery }) => {
  if (!gallery?.length) return null;

  return (
    <div id={id} className={className('container')}>
      {title(galleryTitle)}
      <div className={className('images-wrapper')}>{images(gallery)}</div>
    </div>
  );
};

RideGallery.propTypes = {
  id: PropTypes.string.isRequired,
  galleryTitle: PropTypes.object,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

RideGallery.defaultProps = {
  galleryTitle: null,
};

export default RideGallery;
