import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { useClassName, Only } from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../../Components/Button';
import HomepageImage from '../../../Components/Image';
import HomepageAsset from '../../../Components/Asset';

import './small.less';

export default function SmallStoresSection({ section }) {
  const classNames = useClassName('SmallStoresSection');

  return (
    <>
      <div className={classNames('video-container')}>
        <HomepageAsset asset={section.fields.video} />
        <div className={classNames('shadow')}></div>
        <div className={classNames('video-details')}>
          <h3>{section.fields.header}</h3>
          <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
          <Only desktop tablet>
            <HomepageButton
              cta={section.fields.cta}
              className={classNames('cta')}
            />
          </Only>
        </div>
      </div>
      <Swiper
        height={542}
        className={classNames('wrapper')}
        modules={[Autoplay]}
        spaceBetween={10}
        breakpoints={{
          1128: {
            spaceBetween: 30,
          },
        }}
        slidesPerView={'auto'}
        speed={3000}
        loop
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.autoplay.run();
          }, 100);
        }}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}>
        {section.fields.stores.map(({ fields, sys }) => (
          <SwiperSlide key={sys.id} className={classNames('slide-container')}>
            <HomepageButton cta={fields.callToAction} key={sys.id}>
              <HomepageImage
                field={fields.desktop}
                mobile={fields.mobile}
                className={classNames('slide')}
              />
            </HomepageButton>
          </SwiperSlide>
        ))}
      </Swiper>
      <Only mobile>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </>
  );
}
