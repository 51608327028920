export const EMAIL_REG = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PHONE_NANP_REG = /^\+1\d{10}$/i;
export const PHONE_INTL_REG = /^\+[^1]\d{3,}$/i;
export const ZIPCODE_REG = /^[^@]{0,12}$/;

export function isValidEmail(email = '') {
  return EMAIL_REG.test(email);
}

export function isValidPhone(phone = '') {
  return PHONE_NANP_REG.test(phone) || PHONE_INTL_REG.test(phone);
}

export function isValidZipCode(zipCode = '') {
  return ZIPCODE_REG.test(zipCode);
}
