import React, { useRef } from 'react';

import Section from './Section';

export default function CustomLandingPage({ page }) {
  const refs = useRef([]);

  return (
    <div style={{ overflow: 'clip' }}>
      {page.fields.sections.map((section, index) => {
        return (
          <Section section={section} key={index} refs={refs} index={index} />
        );
      })}
    </div>
  );
}
