import React from 'react';
import { Dropdown, Button, Loader } from 'common/lazy';
import { observer, inject } from 'mobx-react';
import { Modal, SVGIcon as Icon, DatePicker } from 'common/components';
import { Headline } from '../Flair';
import { request } from 'utils/api';
import { Component } from 'common/helpers';

import { withRouter } from 'react-router-dom';

import './modal.less';

function getDefaultState() {
  const minDate = new Date(2020, 9, 1);
  const today = new Date();
  const defaultDate = minDate > today ? minDate : today;

  return {
    open: false,
    venues: null,
    selectedVenue: undefined,
    selectedVenueOption: undefined,
    reservationDate: defaultDate,
  };
}

const ATTRACTIONS = [
  {
    slug: 'nickelodeon-universe',
    icon: 'coaster',
  },
  {
    slug: 'dreamworks-water-park',
    icon: 'wave',
  },
  {
    slug: 'big-snow',
    url: 'https://www.bigsnowamericandream.com',
    icon: 'ski',
  },
];

@inject('venues')
@withRouter
@observer
export default class TicketModal extends Component {
  state = getDefaultState();

  async fetchVenues() {
    try {
      const { data: venues } = await request({
        method: 'POST',
        path: `/1/venues/search`,
        body: {
          hasTickets: true,
          venueType: 'attraction',
        },
      });

      const venueOptions = venues.map((venue) => {
        return { text: venue.name, value: venue.id };
      });

      const { venue: selectedVenue, option: selectedVenueOption } =
        this.getSelected(venues, venueOptions);

      this.setState({
        venues,
        venueOptions,
        selectedVenue,
        selectedVenueOption,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  getSelected(venues, venueOptions) {
    let index = venues.findIndex((v) => {
      const { pathname } = this.props.location;
      return pathname === `/venue/${v.slug}`;
    });
    if (index === -1) {
      index = 0;
    }
    return {
      venue: venues[index],
      option: venueOptions[index],
    };
  }

  handleOnOpen = async () => {
    this.setState({
      open: true,
    });
    await this.fetchVenues();
  };

  handleOnClose = () => {
    this.setState(getDefaultState());
  };

  close = () => {
    this.setState(getDefaultState());
  };

  getFormatedDate(date) {
    const monthStr = `${date.getMonth() + 1}`;
    const dateStr = `${date.getDate()}`;
    return `${date.getFullYear()}-${monthStr.padStart(
      2,
      '0'
    )}-${dateStr.padStart(2, '0')}`;
  }

  getAttraction(venue) {
    if (!venue) {
      return null;
    } else {
      return (
        ATTRACTIONS.find((e) => e.slug === venue.slug) || {
          icon: 'ticketing',
        }
      );
    }
  }

  handleOnBtnClick = () => {
    const { selectedVenue, reservationDate } = this.state;

    // Workaround for big snow
    const attraction = this.getAttraction(selectedVenue);
    if (attraction && attraction.url) {
      window.open(attraction.url, '_blank');
    } else {
      const date = this.getFormatedDate(reservationDate);
      this.props.history.push(
        `/venue/${selectedVenue.slug}/tickets/${date}${location.search}`
      );
    }
    this.close();
  };

  render() {
    const { trigger } = this.props;
    return (
      <Modal
        black={true}
        className="ticket-widget-modal"
        onOpen={this.handleOnOpen}
        onClose={this.handleOnClose}
        size="small"
        trigger={trigger}
        open={this.state.open}>
        {this.renderContent()}
      </Modal>
    );
  }

  renderContent() {
    const { venues, selectedVenue, reservationDate, selectedVenueOption } =
      this.state;
    const attraction = this.getAttraction(selectedVenue);
    if (!venues) {
      return <Loader inline="centered" active />;
    }
    return (
      <React.Fragment>
        <Headline
          title="Book"
          subtitle="an experience"
          className={this.getElementClass('headline')}
        />
        <p>Select an attraction and pick any available date for your visit</p>
        <div className="btns-container">
          <Dropdown
            button
            className={`${this.getElementClass('dropdown')} ui button primary`}
            icon={
              <Icon
                className="icon"
                name={attraction.icon}
                width={40}
                height={20}
              />
            }
            floating
            labeled
            text={
              (selectedVenueOption && selectedVenueOption.text) || 'Attraction'
            }
            options={this.state.venues.map((venue) => {
              return { text: venue.name, value: venue.id };
            })}
            onChange={(e, { value }) =>
              this.setState({
                selectedVenueOption: this.state.venueOptions.find(
                  (option) => option.value === value
                ),
                selectedVenue: this.state.venues.find(
                  (venue) => venue.id === value
                ),
              })
            }
          />
          {!attraction.url && (
            <div className="btn-wrapper">
              <DatePicker
                icon={<Icon width={18} height={18} name="calendar" />}
                date={reservationDate}
                className={this.getElementClass('date-picker')}
                onDayChange={(date) => {
                  this.setState({
                    reservationDate: date,
                  });
                }}
                startDate={new Date()}
              />
            </div>
          )}
        </div>
        <div>
          <Button
            onClick={this.handleOnBtnClick}
            disabled={!selectedVenue || !reservationDate}
            inverted
            primary>
            Buy Tickets
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
