import React from 'react';

export function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 10a8.75 8.75 0 1 1 17.5 0 8.75 8.75 0 0 1-17.5 0M10 2.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5m0 1.357a.75.75 0 0 1 .75.75V9.69l3.209 3.21a.75.75 0 0 1-1.06 1.06l-3.43-3.429A.75.75 0 0 1 9.25 10V4.857a.75.75 0 0 1 .75-.75"
        fill="#8C98A8"
      />
    </svg>
  );
}
