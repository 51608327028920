import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  LazyVisible,
  useClassName,
  preloadMedia,
  usePreload,
  preloadCTAs,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../../Components/Button';
import HomepageImage from '../../../Components/Image';
import trackHomepageSlide from '../../../Components/trackHomepageSlide';
import trackHomepageSection from '../../../Components/trackHomepageSection';
import useLoop from '../../../Components/useLoop';
import useSlider from '../../../Components/useSlider';
import SliderControls from '../../../Components/SliderControls';
import SliderBullets from '../../../Components/SliderBullets';
import Center from '../../../Components/Center';
import HomepageAsset from '../../../Components/Asset';
import Actions from '../../../../CustomLandingPage/Components/Actions';
import Link from '../../../../CustomLandingPage/Components/Link';

import 'swiper/swiper.less';
import './small.less';

export default function DiningSection({ section }) {
  const classNames = useClassName('DiningSection');
  const ref = useRef();
  const loop = useLoop({
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1128: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
    slidesLength: section.fields.stores.length,
  });
  const [props, methods, state] = useSlider();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.stores, isDesktop);
    preloadCTAs(section.fields.cta);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('video-container')}>
        <HomepageAsset asset={section.fields.video} />
        <div className={classNames('shadow')}></div>
        <div className={classNames('video-details')}>
          <h3>{section.fields.header}</h3>
          <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
          <HomepageButton
            cta={section.fields.cta}
            className={classNames('cta')}
          />
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div className={classNames('content')}>
          {!loop.pending && (
            <Swiper
              height={542}
              {...props}
              {...loop}
              className={classNames('wrapper')}
              onSlideChange={({ realIndex }) => {
                trackHomepageSlide(() => ({
                  activeIndex: realIndex,
                  name: section.fields.stores[realIndex].fields.name,
                  section: section.fields.sectionId,
                  url: section.fields.stores[realIndex].fields.callToAction
                    .fields.url,
                }));
              }}>
              {section.fields.stores.map(({ fields, sys }, index) => {
                return (
                  <SwiperSlide
                    key={sys.id}
                    className={classNames('slide-container')}>
                    <div
                      className={classNames('slide')}
                      style={{
                        borderRadius: '40px',
                        animationDelay: `${index * 50}ms`,
                      }}>
                      <HomepageImage
                        field={fields.desktop}
                        mobile={fields.mobile}
                      />
                      <div>
                        <span>{fields.name}</span>
                        {Boolean(fields?.CTAs?.length) && (
                          <Actions>
                            {fields.CTAs.map((cta, index) => (
                              <Link
                                component={{
                                  ...cta,
                                  fields: {
                                    ...cta.fields,
                                    theme:
                                      index === 0 ? 'light' : 'transparent',
                                  },
                                }}
                                key={cta.sys.id}
                              />
                            ))}
                          </Actions>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
        {loop.loop && (
          <>
            <SliderControls
              slidePrev={methods.slidePrev}
              slideNext={methods.slideNext}
              theme="solid"
              className={classNames('arrow')}
            />
            <Center>
              <SliderBullets
                activeIndex={state.activeIndex}
                length={section.fields.stores.length}
                slideTo={methods.slideTo}
                style={{ marginTop: '24px' }}
              />
            </Center>
          </>
        )}
      </div>
    </LazyVisible>
  );
}
