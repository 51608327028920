import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';

const getLinks = (type) => {
  const deal = { url: '/deals/category', title: 'Deals' };

  return [deal, { url: `/venue/${type.slug}`, title: type.name }];
};

const DealDetailsBreadcrumb = ({ type }) => {
  return <Breadcrumb links={[...getLinks(type)]} />;
};

DealDetailsBreadcrumb.propTypes = {
  type: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

export default DealDetailsBreadcrumb;
