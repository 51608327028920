import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import { Layout } from '../Layout';

import './letter-selector.less';

const LETTERS = 'abcdefghijklmnopqrstuvwxyz'.split('');

export default class LetterSelector extends Component {
  render() {
    return (
      <div {...this.getAttrs(true)}>
        <Layout horizontal spread>
          {LETTERS.map((letter) => this.renderLetter(letter))}
        </Layout>
      </div>
    );
  }

  renderLetter(letter) {
    const { active, disabled, getLinkUrl } = this.props;
    const isActive = letter === (active || '').toLowerCase();
    const isDisabled = disabled.toLowerCase().indexOf(letter) !== -1;
    const className = this.getElementClass(
      'letter',
      isActive ? 'active' : null,
      isDisabled ? 'disabled' : null
    );
    if (getLinkUrl) {
      return (
        <Link key={letter} to={getLinkUrl(letter)} className={className}>
          {letter}
        </Link>
      );
    } else {
      return (
        <span
          key={letter}
          className={className}
          onClick={() => this.onLetterClick(letter)}>
          {letter}
        </span>
      );
    }
  }
}

LetterSelector.propTypes = {
  onSelect: PropTypes.func,
  getLinkUrl: PropTypes.func,
  active: PropTypes.string,
  disabled: PropTypes.string,
};

LetterSelector.defaultProps = {
  active: '',
  disabled: '',
};
