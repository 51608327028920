import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Slider from './Slider';
import Gallery from './Gallery';
import Carousel from './Carousel';
import Features from './Features';

import './image-gallery.less';

const ImageGallery = ({ imageGallery, sliderAutoPlay, showMiniatures }) => {
  const className = useClassName('ModularImageGallery');
  const fullHeightClass =
    imageGallery.isCarousel || imageGallery.isSlider ? 'full-height' : '';

  return (
    <div className={className(['container', fullHeightClass])}>
      {imageGallery.isGallery && <Gallery images={imageGallery.images} />}
      {imageGallery.isImageCardGallery && (
        <Gallery images={imageGallery.images} isCardGallery={true} />
      )}
      {imageGallery.isCarousel && (
        <Carousel images={imageGallery.images} speed="slow" />
      )}
      {imageGallery.isSlider && (
        <Slider
          images={imageGallery.images}
          autoPlay={sliderAutoPlay}
          showControls={imageGallery.images.length > 1}
          showMiniatures={showMiniatures}
        />
      )}
      {imageGallery.isFeaturesList && <Features images={imageGallery.images} />}
    </div>
  );
};

ImageGallery.propTypes = {
  imageGallery: PropTypes.object.isRequired,
  sliderAutoPlay: PropTypes.bool,
  showMiniatures: PropTypes.bool,
};

ImageGallery.defaultProps = {
  sliderAutoPlay: false,
  showMiniatures: false,
};

export default ImageGallery;
