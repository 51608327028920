import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentLink } from 'common/components';
import { LoaderImage } from '../../Loader';
import { Modal } from 'common/components';
import locationPin from 'common/assets/location-pin.svg';
import emailLogo from 'common/assets/email.svg';
import phoneLogo from 'common/assets/phone.svg';
import parkingDeckLogo from 'common/assets/parking-deck.svg';
import curbsideLogo from 'common/assets/curbside-box.svg';

import './venue-details-info-panel.less';

const VenueDetailsInfoPanel = ({
  id,
  detailsLinks,
  locationDescription,
  email,
  phoneNo,
  parkingDeck,
  curbsidePickupInfo,
}) => {
  const className = useClassName('VenueDetailsInfoPanel');

  const renderCurbsidePickupInfo = () => {
    return (
      <Modal
        size="tiny"
        trigger={
          <div className={className('curbside-txt')}>Curbside Pickup Info</div>
        }>
        {curbsidePickupInfo}
      </Modal>
    );
  };

  const adminInfo = [
    locationDescription && {
      name: locationDescription,
      logo: {
        assetUrl: locationPin,
        assetAlt: 'Location',
      },
    },
    email && {
      name: email,
      logo: {
        assetUrl: emailLogo,
        assetAlt: 'Email',
      },
      url: `mailto:${email}`,
    },
    phoneNo && {
      name: phoneNo,
      logo: {
        assetUrl: phoneLogo,
        assetAlt: 'Phone number',
      },
      url: `tel:${phoneNo}`,
    },
    parkingDeck && {
      name: parkingDeck,
      logo: {
        assetUrl: parkingDeckLogo,
        assetAlt: 'Parking Deck',
      },
    },
    curbsidePickupInfo && {
      name: renderCurbsidePickupInfo(),
      logo: {
        assetUrl: curbsideLogo,
        assetAlt: 'Curbside Pickup Info',
      },
    },
  ].filter(Boolean);

  if (!adminInfo?.length && !detailsLinks?.length) return null;

  const renderContent = (url, name) => {
    if (!url) {
      return name;
    }
    return (
      <ContentLink href={url} className={className('info-cta')}>
        {name}
      </ContentLink>
    );
  };

  const itemsList = adminInfo.concat(detailsLinks).map((detail, index) => {
    const { name, url, logo } = detail;

    return (
      <div className={className('info')} key={`item-${index}`}>
        {logo && (
          <LoaderImage
            className={className('info-icon')}
            src={logo?.assetUrl}
            alt={logo?.assetAlt || `icon-${index}`}
            loadingHeight={20}
            hideLoader
            fluid
          />
        )}
        {renderContent(url, name)}
      </div>
    );
  });

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>{itemsList}</div>
    </div>
  );
};

VenueDetailsInfoPanel.propTypes = {
  id: PropTypes.string.isRequired,
  detailsLinks: PropTypes.arrayOf(PropTypes.object),
  locationDescription: PropTypes.string,
  email: PropTypes.string,
  phoneNo: PropTypes.string,
  parkingDeck: PropTypes.string,
  curbsidePickupInfo: PropTypes.string,
};

VenueDetailsInfoPanel.defaultProps = {
  detailsLinks: [],
};

export default VenueDetailsInfoPanel;
