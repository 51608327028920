import React from 'react';
import PropTypes from 'prop-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import getContentfulField from './getContentfulField';

export default class ContentfulPlainText extends React.Component {
  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }
    const content = getContentfulField(field);
    if (!content) {
      return null;
    } else if (typeof content === 'string') {
      return content;
    } else {
      return documentToPlainTextString(content);
    }
  }
}

ContentfulPlainText.propTypes = {
  field: PropTypes.object,
};
