import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export default class MetaDataStore extends BaseStore {
  @observable register = new Map();
  @observable items = [];
  @observable totalItems = 0;
  @observable limit = 20;
  @observable page = 1;
  @observable sort = {
    order: 'asc',
    field: 'name',
  };

  @action
  setSort({ field, order }) {
    this.sort = {
      field,
      order,
    };
  }

  @action
  setPage(page) {
    this.page = page;
  }

  get(id) {
    return this.register.get(id);
  }

  @action
  fetchItems(
    type,
    { limit = this.limit, skip = (this.page - 1) * this.limit } = {},
    statusKey = 'list'
  ) {
    this.register.clear();
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/metadata/${type}/search`,
      body: {
        limit,
        skip,
        sort: this.sort,
      },
    })
      .then(({ data }) => {
        const items = data;
        items.forEach((item) => {
          this.register.set(item.id, item);
        });
        this.items.replace(items);
        status.success();
        return items;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  async getData(metaType, statusKey = 'getMetadata') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/metadata/${metaType}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  async createData(metaType, body, statusKey = 'createData') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/metadata/${metaType}`,
      body,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  async updateData(metaId, body, statusKey = 'updateData') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/metadata/${metaId}`,
      body,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  async deleteData(metaId, statusKey = 'deleteData') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/metadata/${metaId}`,
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  async searchCategories(body, statusKey = 'searchCategories') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/metadata/search/categories`,
      body,
    })
      .then(({ data, totalItems }) => {
        status.success();
        return { data, totalItems };
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
