import React from 'react';
import PropTypes from 'prop-types';

export default class Video extends React.Component {
  static contenfulImages = {};

  static useContentfulImages(images) {
    this.contentfulImages = images;
  }

  static getContentfulBaseUrl(name) {
    const src = this.contentfulImages[name];
    if (!src) {
      throw new Error(`Couldn't find Contentful url ${name}`);
    }
    return src;
  }

  render() {
    const { contentfulName, src, autoPlay, muted, ...props } = this.props;
    const url = contentfulName
      ? Video.getContentfulBaseUrl(contentfulName)
      : src;

    // Webkit requires video to be muted to be autoplayed.
    return (
      <video
        {...props}
        src={url}
        autoPlay={autoPlay}
        muted={muted || autoPlay}
      />
    );
  }
}

Video.propTypes = {
  contentfulName: PropTypes.string,
  src: PropTypes.string,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
};

Video.defaultProps = {
  contentfulName: null,
  src: null,
  controls: true,
  autoPlay: false,
  muted: false,
  playsInline: false,
};
