import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import { ResponsiveImageLight as Image } from '../ResponsiveImage';
import { DesktopOnly, MobileOnly } from 'common/components/Responsive';
import { PaginationDots } from 'common/components/PaginationDots';
import { PaginationSwipe } from 'common/components/PaginationSwipe';
import chevronRight from 'common/assets/chevron-right.svg';
import chevronLeft from 'common/assets/chevron-left.svg';
import './carousel.less';

export default class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedIndex: 0,
    };
  }

  componentDidMount() {
    const { autoScrollTime } = this.props;

    if (autoScrollTime) {
      this.enableAutoScroll();
    }
  }

  componentWillUnmount() {
    if (this.scrollTimer) {
      clearInterval(this.scrollTimer);
    }
  }

  enableAutoScroll = () => {
    const { autoScrollTime } = this.props;
    this.scrollTimer = setInterval(this.moveNext, autoScrollTime);
  };

  stopAutoScroll = () => {
    if (this.scrollTimer) {
      clearInterval(this.scrollTimer);
    }
  };

  moveNext = () => {
    const { images } = this.props;

    this.setState(({ focusedIndex }) => {
      const next = images.length - 1 === focusedIndex ? 0 : focusedIndex + 1;

      return { focusedIndex: next };
    });
  };

  next = () => {
    if (this.scrollTimer) {
      this.stopAutoScroll();
    }

    this.moveNext();
  };

  prev = () => {
    const { images } = this.props;

    if (this.scrollTimer) {
      this.stopAutoScroll();
    }

    this.setState(({ focusedIndex }) => {
      const next = focusedIndex === 0 ? images.length - 1 : focusedIndex - 1;

      return { focusedIndex: next };
    });
  };

  getImageStyle = () => {
    const { focusedIndex } = this.state;
    const itemPercentage = focusedIndex * -100;
    const transform = `translateX(${itemPercentage}%)`;

    return { transform };
  };

  render() {
    return (
      <div {...this.getAttrs()}>
        <DesktopOnly>
          {this.renderMainContent(true)}
          {this.renderFooter()}
        </DesktopOnly>
        <MobileOnly>
          <PaginationSwipe onSwipeLeft={this.prev} onSwipeRight={this.next}>
            {this.renderMainContent()}
            {this.renderTopPagination()}
          </PaginationSwipe>
        </MobileOnly>
      </div>
    );
  }

  renderMainContent = (isDesktop) => {
    const { images } = this.props;

    return (
      <div
        className={this.getElementClass('images', !isDesktop && 'mobile')}
        onMouseOver={this.stopAutoScroll}
        onMouseLeave={this.enableAutoScroll}>
        {images.map((image, index) =>
          this.renderImage(image, index, isDesktop)
        )}
      </div>
    );
  };

  renderImage = (image, index, isDesktop) => {
    const { onImagePress } = this.props;
    const { desktopSrc, imageAlt, ctaData, mobileSrc } = image;

    return (
      <div
        key={index}
        className={this.getElementClass('image', ctaData.link && 'clickable')}
        style={this.getImageStyle()}
        onClick={onImagePress(ctaData)}>
        <Image alt={imageAlt} src={isDesktop ? desktopSrc : mobileSrc} />
      </div>
    );
  };

  renderTopPagination = () => {
    const { images } = this.props;
    const { focusedIndex } = this.state;

    return (
      <div className={this.getElementClass('topPagination')}>
        <PaginationDots items={images.length} activeIndex={focusedIndex} />
      </div>
    );
  };

  renderFooter = () => {
    const { images } = this.props;
    const { focusedIndex } = this.state;

    if (images.length <= 1) {
      return null;
    }

    return (
      <div className={this.getElementClass('footer')}>
        <div
          className={this.getElementClass('chevron', 'prev')}
          onClick={this.prev}>
          <img src={chevronLeft} width={24} height={24} />
        </div>
        <div
          className={this.getElementClass('chevron', 'next')}
          onClick={this.next}>
          <img src={chevronRight} width={24} height={24} />
        </div>
        <PaginationDots items={images.length} activeIndex={focusedIndex} />
      </div>
    );
  };
}

Carousel.propTypes = {
  autoScrollTime: PropTypes.number,
  onImagePress: PropTypes.func,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      desktopSrc: PropTypes.string.isRequired,
      imageAlt: PropTypes.string.isRequired,
      mobileSrc: PropTypes.string.isRequired,
      ctaData: PropTypes.shape({
        link: PropTypes.string,
      }),
    })
  ),
  onImageClick: PropTypes.func,
};

Carousel.defaultProps = {
  autoScrollTime: undefined,
  images: [],
  onImageClick: () => {},
  onImagePress: () => null,
};
