import React from 'react';
import propTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';
import Announcement from './Announcement';

import './submenu.less';

const Submenu = ({
  item,
  renderMenuItem,
  setPrimaryClicked,
  handleLinkClick,
}) => {
  const className = useClassName('Submenu');

  return (
    <ScrollContainer
      className={className('container')}
      vertical={true}
      horizontal={false}
      hideScrollbars={false}>
      <div
        className={className('parent-label')}
        onClick={() => setPrimaryClicked(null)}>
        <Icon
          name="back-arrow"
          className={className('back-icon')}
          color="black"
        />
        <div className={className('label')}>{item.text}</div>
        {item?.icon && (
          <img
            className={className('icon')}
            src={item.icon.assetUrl}
            alt={item.icon.assetAlt}
          />
        )}
      </div>
      {item.children.map((item, index) =>
        renderMenuItem(item, index, [
          'secondary',
          item.children ? 'collapsible-children' : '',
          item.icon ? 'secondary-icon' : '',
          index === 0 && item.icon ? 'extra-margin' : '',
        ])
      )}
      {item.announcement && (
        <div className={className('announcement-container')}>
          <Announcement
            announcement={item.announcement}
            handleLinkClick={handleLinkClick}
          />
        </div>
      )}
    </ScrollContainer>
  );
};

Submenu.propTypes = {
  item: propTypes.object.isRequired,
  renderMenuItem: propTypes.func.isRequired,
  setPrimaryClicked: propTypes.func.isRequired,
  handleLinkClick: propTypes.func.isRequired,
};

export default Submenu;
