import React from 'react';
import PropTypes from 'prop-types';
import { AssetPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import RideHeroTitle from './RideHeroTitle';
import RideHeroAsset from './RideHeroAsset';
import RideHeroSubtitle from './RideHeroSubtitle';
import RideHeroBack from './RideHeroBack';
import './ride-hero.less';

const RideHero = ({
  id,
  venue,
  heroTitle,
  heroSubtitle,
  heroVideo,
  heroPoster,
  theme,
}) => {
  const className = useClassName('RideHero');

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: theme.primaryColor }}>
      <div className={className('wrapper')}>
        <div className={className('first-container')}>
          <RideHeroBack slug={venue?.slug} color={theme.textColor} />
          {heroTitle && (
            <RideHeroTitle text={heroTitle} color={theme.textColor} />
          )}
          {heroSubtitle && (
            <RideHeroSubtitle text={heroSubtitle} color={theme.textColor} />
          )}
        </div>
        <div className={className('second-container')}>
          {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
            <RideHeroAsset
              venue={venue}
              videoUrl={heroVideo?.assetUrl}
              posterUrl={heroPoster?.assetUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

RideHero.propTypes = {
  id: PropTypes.string.isRequired,
  venue: PropTypes.object.isRequired,
  heroTitle: PropTypes.object.isRequired,
  heroSubtitle: PropTypes.object.isRequired,
  heroVideo: PropTypes.shape(AssetPropType),
  heroPoster: PropTypes.shape(AssetPropType),
  theme: PropTypes.object.isRequired,
};

RideHero.defaultProps = {
  heroVideo: null,
  heroPoster: null,
};

export default RideHero;
