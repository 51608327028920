import conditionallyLoadAnalytics from '@segment/consent-manager/esm/consent-manager-builder/analytics';
import { savePreferences } from '@segment/consent-manager';
import { SEGMENT_WRITE_KEY } from 'utils/env/client';
import { DESTINATION_GROUPS } from './const';

export default function saveGroupConsent(
  groupPrefs,
  destinations,
  shouldReload = false
) {
  const destinationPreferences = destinations.reduce(
    (destinationPrefs, destination) => {
      const group = DESTINATION_GROUPS[destination.name];
      if (group) {
        destinationPrefs[destination.id] = !!groupPrefs[group];
      }
      return destinationPrefs;
    },
    {}
  );
  savePreferences({
    destinationPreferences,
    customPreferences: groupPrefs,
  });
  conditionallyLoadAnalytics({
    writeKey: SEGMENT_WRITE_KEY,
    isConsentRequired: true,
    destinationPreferences,
    destinations,
    shouldReload,
  });
}
