// This component is a simple wrapper to apply basic css
// patterns to freeform content without needing to throw
// classes around everywhere.
import React from 'react';
import PropTypes from 'prop-types';
import { SemanticElement } from 'common/helpers';

import './content.less';

// Component name is to avoid clashes with semantic which
// has .content elements in modals, etc.
export default class HtmlContent extends SemanticElement {
  static className = 'html-content';

  render() {
    return <div {...this.getProps()}>{this.props.children}</div>;
  }
}

HtmlContent.propTypes = {
  small: PropTypes.bool,
  tight: PropTypes.bool,
};

HtmlContent.defaultProps = {
  small: false,
  tight: false,
};
