import {
  startOfWeek,
  subDays,
  startOfMonth,
  endOfWeek,
  endOfMonth,
} from 'common/helpers';

export const defaultStaticRanges = [
  {
    label: 'Today',
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    label: 'Yesterday',
    startDate: subDays(new Date(), 1),
    endDate: subDays(new Date(), 1),
  },
  {
    label: 'This week',
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
  },
  {
    label: 'This month',
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  {
    label: 'Last 30 days',
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  },
  {
    label: 'Last 60 days',
    startDate: subDays(new Date(), 60),
    endDate: new Date(),
  },
  {
    label: 'Last 90 days',
    startDate: subDays(new Date(), 90),
    endDate: new Date(),
  },
];

export const defaultStaticComparisonRanges = [
  {
    label: 'No comparison',
    isNoComparison: true,
  },
  {
    label: 'Previous period',
    isPreviosPeriod: true,
  },
  {
    label: 'Previous month',
    minusValue: 1,
    minusType: 'month',
  },
  {
    label: 'Previous year',
    minusValue: 1,
    minusType: 'year',
  },
];
