import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class InfoStore extends BaseStore {
  @observable hours = null;
  @action
  fetchHours(statusKey = 'fetchHours') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/info/hours`,
    })
      .then(({ data }) => {
        this.hours = data;
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
