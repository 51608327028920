import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import './landing-hero-subtitle.less';

const LandingHeroSubtitle = ({ text }) => {
  const className = useClassName('LandingHeroSubtitle');

  return (
    <div className={className('container')}>
      <ContentfulRichText className={className('text')} field={text} />
    </div>
  );
};

LandingHeroSubtitle.propTypes = {
  text: PropTypes.object.isRequired,
};

export default LandingHeroSubtitle;
