import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import { useClassName } from 'common/hooks';
import { LocationIcon, PhoneIcon, EmailIcon, LinkIcon } from 'public/icons';

import useCustomStyle from '../../../hooks';
import HomepageAsset from '../../../../Home/Components/Asset';
import Actions from '../../Actions';
import Link from '../../Link';
import { VenueHours } from '../../Custom/Hours';

import './venue-details.less';

function Description({ venue }) {
  const classNames = useClassName('CLPVenueDetails');
  const [showFullDescription, setShowFullDescription] = useState(false);

  const getDescription = () => {
    const contentfulDescription = venue?.content?.description?.['en-US'];
    if (contentfulDescription)
      return documentToPlainTextString(contentfulDescription);

    return venue?.description;
  };

  const description = getDescription();
  if (!description) return null;

  return (
    <div className={classNames('description')}>
      <p>
        {showFullDescription || description.length <= 200
          ? description
          : `${description.substring(0, 200)}...`}
      </p>
      {description.length > 200 && (
        <button onClick={() => setShowFullDescription(!showFullDescription)}>
          {showFullDescription ? 'Read less' : 'Read more'}
        </button>
      )}
    </div>
  );
}

export default inject('venues')(function VenueDetails({ venues, component }) {
  const [venue, setVenue] = useState(null);
  const classNames = useClassName('CLPVenueDetails');
  const params = useParams();
  const [, style] = useCustomStyle(component.fields.settings);

  useEffect(() => {
    venues.fetchItemBySlug(params.slug).then((venue) => {
      setVenue(venue);
    });
  }, []);

  if (!venue) return null;

  return (
    <div className={classNames('container')}>
      <div>
        <h1>{venue.name}</h1>
        <Description venue={venue} />
        <div className={classNames('info')}>
          <VenueHours venue={venue} />
          {Boolean(venue.locationDescription) && (
            <span>
              <LocationIcon />
              {venue.locationDescription}
            </span>
          )}
          {Boolean(venue?.contactDetails?.phoneNo?.trim()) && (
            <a href={`tel:${venue.contactDetails.phoneNo}`}>
              <PhoneIcon />
              {venue.contactDetails.phoneNo}
            </a>
          )}
          {Boolean(venue?.contactDetails?.email?.trim()) && (
            <a href={`mailto:${venue.contactDetails.email}`}>
              <EmailIcon />
              {venue.contactDetails.email}
            </a>
          )}
          {Boolean(venue?.contactDetails?.website?.trim()) && (
            <a
              href={venue.contactDetails.website}
              target="_blank"
              rel="noreferrer">
              <LinkIcon />
              {venue.contactDetails.website}
            </a>
          )}
          {Boolean(style['--primary-link'] || style['--secondary-link']) && (
            <Actions className={classNames('actions')}>
              {Boolean(style['--primary-link']) && (
                <Link
                  key={style['--primary-link'].sys.id}
                  component={style['--primary-link']}
                />
              )}
              {Boolean(style['--secondary-link']) && (
                <Link
                  key={style['--secondary-link'].sys.id}
                  component={style['--secondary-link']}
                />
              )}
            </Actions>
          )}
        </div>
      </div>
      {Boolean(style['--main-asset']) && (
        <HomepageAsset asset={style['--main-asset']} />
      )}
    </div>
  );
});
