import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

import './headline.less';

export default class Headline extends Component {
  getStyles() {
    const { style, color } = this.props;
    return Object.assign(style || {}, {
      color,
    });
  }

  getModifiers() {
    return [this.props.flipped ? 'flipped' : null];
  }

  render() {
    return (
      <div {...this.getAttrs()} style={this.getStyles()}>
        <span className={this.getElementClass('title')}>
          {this.props.title}
        </span>{' '}
        <span className={this.getElementClass('subtitle')}>
          {this.props.subtitle}
        </span>
      </div>
    );
  }
}

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  flipped: PropTypes.bool,
  color: PropTypes.string,
};

Headline.defaultProps = {
  subtitle: null,
};
