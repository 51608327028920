import React from 'react';

export function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 2.5a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0V4.31l-5.386 5.387a.75.75 0 0 1-1.061-1.06l5.386-5.387H12.5a.75.75 0 0 1-.75-.75m-5.281.917h1.864a.75.75 0 1 1 0 1.5H6.5c-.712 0-1.202 0-1.58.031-.371.03-.57.086-.714.16a1.75 1.75 0 0 0-.765.764c-.074.144-.13.343-.16.714-.03.379-.031.868-.031 1.58V13.5c0 .712 0 1.202.032 1.58.03.371.085.57.159.714.168.33.435.598.765.765.144.074.343.13.713.16.38.03.869.031 1.581.031h5.333c.713 0 1.202 0 1.581-.032.37-.03.57-.085.714-.159a1.75 1.75 0 0 0 .765-.765c.073-.144.128-.343.159-.713.03-.38.031-.869.031-1.581v-1.833a.75.75 0 0 1 1.5 0v1.864c0 .674 0 1.224-.036 1.672-.038.463-.119.882-.318 1.273a3.25 3.25 0 0 1-1.42 1.42c-.39.199-.81.28-1.273.317-.447.037-.998.037-1.671.037H6.469c-.674 0-1.224 0-1.672-.037-.463-.037-.882-.118-1.272-.317a3.25 3.25 0 0 1-1.42-1.42c-.2-.391-.28-.81-.318-1.273-.037-.447-.037-.998-.037-1.672V8.135c0-.673 0-1.224.037-1.671.037-.464.118-.882.317-1.273a3.25 3.25 0 0 1 1.42-1.42c.391-.2.81-.28 1.273-.318.448-.036.998-.036 1.672-.036"
        fill="#8C98A8"
      />
    </svg>
  );
}
