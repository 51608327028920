import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from '../SVGIcon';

import './dropdown.less';

const Dropdown = ({ selected, options, handleChange, classNames }) => {
  const [isOpen, setIsOpen] = useState(false);
  const className = useClassName('Dropdown');
  const container = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => document.removeEventListener('mousedown', handleClickOutSide);
  }, []);

  const handleClickOutSide = (e) => {
    if (container && !container.current.contains(e.target)) {
      return setIsOpen(false);
    }
  };

  return (
    <div className={`${className('container')} ${classNames}`} ref={container}>
      <div className={className('selected')} onClick={() => setIsOpen(!isOpen)}>
        <span>
          {options.find((option) => option.value === selected)?.label}
        </span>
        <Icon
          name="angle-down"
          size="mini"
          className={className([
            'angle',
            `angle-${isOpen ? 'open' : 'closed'}`,
          ])}
        />
      </div>
      <ul
        className={className([
          'dropdown',
          `dropdown-${isOpen ? 'open' : 'closed'}`,
        ])}>
        {options.map((option) => (
          <li
            key={option.label}
            className={className('option')}
            onClick={() => {
              handleChange(option.value);
              setIsOpen(false);
            }}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.proptypes = {
  selected: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
};

export default Dropdown;
