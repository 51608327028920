import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { AssetPropType } from 'public/schema';
import { isVenueOpenForDay } from 'public/helpers/functions';
import { ResponsiveImageLight, ContentfulRichText } from 'common/components';
import { formatHourMinutesRange } from 'utils/l10n';
import {
  standardizedTrack,
  getCategoryByCta,
  categories,
} from 'utils/analytics';
import { LinkCard } from '../LinkCard';
import { OperationHours } from 'public/components/OperationHours';
import './info-section.less';

const DEFAULT_ALERTS_BODY = 'No alerts';
const DEFAULT_CLOSED_MESSAGE = 'Closed';

const InfoSection = ({
  id,
  infoSectionTitle,
  openHoursTitle,
  openHoursIcon,
  openHours,
  featuredFlags,
  closedMessage,
  alertsTitle,
  alertsIcon,
  alerts,
  infoLinks,
}) => {
  const className = useClassName('InfoSection');

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events

  const handleClick = (url, type, name) => {
    const category = getCategoryByCta(url) || categories.VENUE_INFO;

    standardizedTrack(
      'click',
      type,
      'info section',
      name,
      category,
      trackingLabel
    );
  };

  const renderHours = () => {
    const { projectedDays, temporarilyClosed } = openHours;
    const today = projectedDays[0];
    const tomorrow = projectedDays[1];

    const todayStatus = isVenueOpenForDay(today)
      ? formatHourMinutesRange(
          today.openHour,
          today.openMinute,
          today.closeHour,
          today.closeMinute
        )
      : DEFAULT_CLOSED_MESSAGE;

    const tomorrowStatus = tomorrow.isClosed
      ? DEFAULT_CLOSED_MESSAGE
      : formatHourMinutesRange(
          tomorrow.openHour,
          tomorrow.openMinute,
          tomorrow.closeHour,
          tomorrow.closeMinute
        );

    return (
      <div className={className('info-hours')}>
        {temporarilyClosed ? (
          closedMessage ? (
            <ContentfulRichText
              className={className('info-body')}
              field={closedMessage}
            />
          ) : (
            <span className={className('info-body')}>
              {DEFAULT_CLOSED_MESSAGE}
            </span>
          )
        ) : (
          <>
            <span className={className('info-body')}>Today: {todayStatus}</span>
            <span className={className('info-body')}>
              Tomorrow: {tomorrowStatus}
            </span>
          </>
        )}
        <div className={className('operation-hours-container')}>
          <OperationHours
            openingHours={openHours}
            featuredFlags={featuredFlags}
            furtherDays={temporarilyClosed ? 0 : 2}
          />
        </div>
      </div>
    );
  };

  const renderLinksList = () => {
    if (!infoLinks?.length) return null;
    return infoLinks.map((link, i) => {
      return (
        <LinkCard
          key={i}
          link={link}
          onClick={() => handleClick(link.url, 'card', link.name)}
        />
      );
    });
  };

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        {infoSectionTitle && (
          <ContentfulRichText
            className={className('title')}
            field={infoSectionTitle}
          />
        )}
        <div className={className('info-container')}>
          {openHours && (
            <div className={className('info-card')}>
              {openHoursIcon && (
                <ResponsiveImageLight
                  className={className('logo')}
                  src={openHoursIcon.assetUrl}
                  alt={openHoursIcon.assetAlt}
                  fluid
                />
              )}
              {openHoursTitle && (
                <ContentfulRichText
                  className={className('info-title')}
                  field={openHoursTitle}
                />
              )}
              {renderHours()}
            </div>
          )}
          <div className={className('info-card')}>
            {alertsIcon && (
              <ResponsiveImageLight
                className={className('logo')}
                src={alertsIcon.assetUrl}
                alt={alertsIcon.assetAlt}
                fluid
              />
            )}
            {alertsTitle && (
              <ContentfulRichText
                className={className('info-title')}
                field={alertsTitle}
              />
            )}
            {alerts ? (
              <ContentfulRichText
                className={className('info-body')}
                field={alerts}
              />
            ) : (
              <span className={className('info-body')}>
                {DEFAULT_ALERTS_BODY}
              </span>
            )}
          </div>
        </div>
        <div className={className('links-container')}>{renderLinksList()}</div>
      </div>
    </div>
  );
};

InfoSection.propTypes = {
  id: PropTypes.string.isRequired,
  infoSectionTitle: PropTypes.object,
  openHoursTitle: PropTypes.object,
  openHoursIcon: PropTypes.shape(AssetPropType),
  openHours: PropTypes.object.isRequired,
  featuredFlags: PropTypes.array,
  closedMessage: PropTypes.object.isRequired,
  alertsTitle: PropTypes.object,
  alertsIcon: PropTypes.shape(AssetPropType),
  alerts: PropTypes.object,
  infoLinks: PropTypes.arrayOf(PropTypes.object),
};

InfoSection.defaultProps = {
  infoSectionTitle: null,
  openHoursTitle: null,
  openHoursIcon: null,
  alertsTitle: null,
  alertsIcon: null,
  infoLinks: null,
  featuredFlags: [],
};

export default InfoSection;
