import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

import './paginationDots.less';

export default class PaginationDots extends Component {
  renderDots = (_, index) => {
    const { activeIndex } = this.props;
    const className = this.getElementClass(
      'dot',
      activeIndex === index ? 'active' : ''
    );

    return <div key={index} className={className} />;
  };

  render() {
    const { items } = this.props;
    const list = [...Array(items)];

    return <div {...this.getAttrs()}>{list.map(this.renderDots)}</div>;
  }
}

PaginationDots.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired,
};
