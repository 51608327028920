import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentLink } from 'common/components';
import { useClassName } from 'common/hooks';
import './landing-cta.less';

const DEFAULT_BACKGROUND_COLOR = '#000000';
const DEFAULT_TEXT_COLOR = '#FFFFFF';

const LandingCTA = ({ text, link, onClick, buttonColor, buttonTextColor }) => {
  if (!link) {
    return null;
  }

  const btnColor = buttonColor || DEFAULT_BACKGROUND_COLOR;
  const btnTextColor = buttonTextColor || DEFAULT_TEXT_COLOR;

  const [hover, setHovering] = useState(false);

  const className = useClassName('LandingCTA');

  return (
    <div className={className('container')}>
      <ContentLink
        href={link}
        className={className('button')}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={onClick}
        style={{
          borderColor: btnColor,
          backgroundColor: `${hover ? btnTextColor : btnColor}`,
          color: `${hover ? btnColor : btnTextColor}`,
        }}>
        <div className={className('button-text')}>{text}</div>
      </ContentLink>
    </div>
  );
};

LandingCTA.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

LandingCTA.defaultProps = {
  buttonColor: null,
  buttonTextColor: null,
};

export default LandingCTA;
