import React, { lazy, useEffect, useState } from 'react';
const FullCalendar = lazy(() => import('@fullcalendar/react'));

export default function LazyFullCalendar({ ...props }) {
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    import('@fullcalendar/daygrid').then((dayGridPlugin) => {
      setPlugins([dayGridPlugin.default]);
    });
  }, []);

  if (!plugins?.length) return null;
  return <FullCalendar plugins={plugins} {...props} />;
}
