import React from 'react';
import { useClassName } from 'common/hooks';

import './divider.less';

function Divider({ children }) {
  const classNames = useClassName('Divider');

  return <div className={classNames('container')}>{children}</div>;
}

export default Divider;
