import React from 'react';
import { Responsive } from 'common/lazy';

const TABLET_MAX_WIDTH = 1128;
const TABLET_MIN_WIDTH = 767;

export default class TabletOnly extends React.Component {
  render() {
    return (
      <Responsive
        minWidth={TABLET_MIN_WIDTH}
        maxWidth={TABLET_MAX_WIDTH - 1}
        {...this.props}
      />
    );
  }
}
