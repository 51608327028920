// See index.js for more.
import window from 'utils/helpers/window';
export const {
  ENV,
  DEV,
  STAGING,
  PRODUCTION,
  API_URL,
  SENTRY_DSN,
  SEGMENT_WRITE_KEY,
  MOBILE_IOS_APP_URL,
  STRIPE_PUBLISHABLE_KEY,
  GOOGLE_CLIENT_ID,
  FACEBOOK_APP_ID,
  APPLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_TENANT_URL,
  IMAGE_RESIZE_PROXY_URL,
  JIBESTREAM_CLIENT_ID,
  JIBESTREAM_CLIENT_SECRET,
  JIBESTREAM_CUSTOMER_ID,
  JIBESTREAM_VENUE_ID,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_PREVIEW_TOKEN,
  CONTENTFUL_DELIVERY_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  FORMS_COMING_SOON_CONTACT,
  FORMS_COMING_SOON_SIGNUP,
  FORMS_DREAM_DEBUT,
  INTERCOM_APP_ID,
  EXPRESS_IMAGE_URL,
} = window.__ENV__ || {};
