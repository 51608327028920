import React from 'react';
import { Component } from 'common/helpers';
import { ContentfulPlainText } from 'common/components';
import HorizontalCard from './Horizontal';

export default class EventCard extends Component {
  render() {
    const { event } = this.props;
    if (event.source === 'contentful') {
      return this.renderContentfulEvent(event);
    }
  }

  renderContentfulEvent(event) {
    const { fields } = event.object;
    return (
      <HorizontalCard
        href={`/event/${event.slug}`}
        title={<ContentfulPlainText field={fields.category} />}
        subtitle={<ContentfulPlainText field={fields.displayDate} />}
        contentfulImage={fields.horizontalImage}
        body={<ContentfulPlainText field={fields.shortDescription} />}
        hover
      />
    );
  }
}
