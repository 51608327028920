import React from 'react';

export function ParkingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none">
      <rect x="0.5" y="0.5" width="47" height="47" rx="5.5" fill="white" />
      <rect x="0.5" y="0.5" width="47" height="47" rx="5.5" stroke="#E1E9F5" />
      <path
        d="M20.25 30.125H35.25C35.25 30.125 37.5 30.125 37.5 27.125C37.5 25.625 37.5 22.625 36.75 21.875C36 21.125 30.75 16.625 28.5 16.625H24C21.75 16.625 16.5 21.875 16.5 21.875L13.5 22.625C13.5 22.625 11.25 23.375 11.25 24.875V27.125C11.25 27.125 10.5 27.3785 10.5 28.5928C10.5 30.125 12 30.125 12 30.125H20.25Z"
        fill="#DD2E44"
      />
      <path
        d="M25.5 18.125H24C22.5 18.125 18 22.625 18 22.625C18 22.625 21.7478 22.4278 25.5 22.2358V18.125ZM33 20.375C32.25 19.625 29.25 18.125 27.75 18.125H27V22.1592C30.0083 22.0062 32.6865 21.875 33 21.875C33.75 21.875 33.75 21.125 33 20.375Z"
        fill="#BBDDF5"
      />
      <path
        d="M18 32.375C19.6569 32.375 21 31.0319 21 29.375C21 27.7181 19.6569 26.375 18 26.375C16.3431 26.375 15 27.7181 15 29.375C15 31.0319 16.3431 32.375 18 32.375Z"
        fill="#292F33"
      />
      <path
        d="M18 30.875C18.8284 30.875 19.5 30.2034 19.5 29.375C19.5 28.5466 18.8284 27.875 18 27.875C17.1716 27.875 16.5 28.5466 16.5 29.375C16.5 30.2034 17.1716 30.875 18 30.875Z"
        fill="#CCD6DD"
      />
      <path
        d="M30.75 32.375C32.4069 32.375 33.75 31.0319 33.75 29.375C33.75 27.7181 32.4069 26.375 30.75 26.375C29.0931 26.375 27.75 27.7181 27.75 29.375C27.75 31.0319 29.0931 32.375 30.75 32.375Z"
        fill="#292F33"
      />
      <path
        d="M30.75 30.875C31.5784 30.875 32.25 30.2034 32.25 29.375C32.25 28.5466 31.5784 27.875 30.75 27.875C29.9216 27.875 29.25 28.5466 29.25 29.375C29.25 30.2034 29.9216 30.875 30.75 30.875Z"
        fill="#CCD6DD"
      />
    </svg>
  );
}
