import { useState, useEffect } from 'react';

const useScrollY = ({ type } = { type: 'scroll' }) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const onScroll = () => setScrollY(window.scrollY);

    window.addEventListener(type, onScroll);
    onScroll();

    return () => window.removeEventListener(type, onScroll);
  }, []);

  return { scrollY };
};

export default useScrollY;
