import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { BottomSheet } from 'public/components/BottomSheet';
import { Dropdown } from 'public/components/Dropdown';
import { useClassName, useWindowSize } from 'common/hooks';
import { LazyDayPicker } from 'common/lazy';

import './calendar.less';

const Calendar = ({
  open,
  initialDate,
  updateSelectedDate,
  closeModal,
  isDateUnavailable,
  openModal,
  footer,
}) => {
  const className = useClassName('Calendar');
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(
    initialDate ? DateTime.fromISO(initialDate).toJSDate() : null
  );
  const today = new Date();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!initialDate) return;

    setCalendarSelectedDate(DateTime.fromISO(initialDate).toJSDate());
  }, [initialDate]);

  const isDateBeforeToday = (date) => {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  };

  const toISO = (jsDate) => DateTime.fromJSDate(jsDate).toISODate();

  const content = (
    <div className={className('calendar')}>
      <LazyDayPicker
        className="day-picker"
        disabledDays={[
          { before: today },
          (jsDate) => isDateUnavailable(toISO(jsDate)),
        ]}
        enableOutsideDaysClick={false}
        selectedDays={calendarSelectedDate}
        month={calendarSelectedDate}
        fixedWeeks
        onDayClick={(date) => {
          if (isDateBeforeToday(date) || isDateUnavailable(toISO(date))) return;

          updateSelectedDate(date);
        }}
      />
      {footer}
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        onOpen={openModal}
        onClose={closeModal}
        title="Select Date"
        isOpen={open}
        content={content}
      />
    );
  }

  return <Dropdown isOpen={open} onClose={closeModal} content={content} />;
};

Calendar.propTypes = {
  initialDate: PropTypes.string,
  open: PropTypes.bool.isRequired,
  updateSelectedDate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isDateUnavailable: PropTypes.func.isRequired,
  trigger: PropTypes.any,
  footer: PropTypes.any,
  openModal: PropTypes.func,
};

Calendar.defaultProps = {
  isDateUnavailable: () => false,
  openModal: () => false,
};

export default Calendar;
