import React from 'react';
import PropTypes from 'prop-types';
import Video from './Video';
import AdaptiveVideo from './AdaptiveVideo';

export default class AutoplayVideo extends React.Component {
  // Note that autoplay videos must be:
  // 1. muted (Chrome)
  // 2. inline (iOS Safari)
  // 3. may or may not preload
  render() {
    const { adaptive, ...props } = this.props;
    const Component = adaptive ? AdaptiveVideo : Video;
    return <Component {...props} autoPlay />;
  }
}

AutoplayVideo.propTypes = {
  adaptive: PropTypes.bool,
};

AutoplayVideo.defaultProps = {
  adaptive: false,
};
