import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';
import { Dropdown } from 'public/components/Dropdown';

import Checkbox from './Checkbox';

import './multiselect.less';

const MultiSelect = ({
  items,
  selectedItems,
  setSelectedItems,
  error,
  onBlur,
  placeholder,
  title,
}) => {
  const [open, setOpen] = useState(false);
  const classNames = useClassName('Multiselect');
  const { isMobile } = useWindowSize();

  const onClose = useCallback(() => {
    onBlur({ value: selectedItems });
    setOpen(false);
  }, [selectedItems]);

  const content = (
    <div className={classNames('list')}>
      <Checkbox
        label="All"
        key="All"
        className={classNames('item')}
        checked={selectedItems?.length === items.length}
        onChange={() => {
          setSelectedItems(
            selectedItems?.length === items.length ? [] : [...items]
          );
        }}
      />
      {(items || []).map((item, index) => (
        <Checkbox
          label={item}
          key={index}
          className={classNames('item')}
          checked={selectedItems?.includes(item)}
          onChange={() => {
            setSelectedItems(
              selectedItems?.includes(item)
                ? selectedItems.filter((a) => a !== item)
                : [...selectedItems, item]
            );
          }}
        />
      ))}
    </div>
  );

  return (
    <ControlledInputContainer
      input={
        <input
          value={selectedItems?.join(', ') || ''}
          readOnly
          placeholder={placeholder}
        />
      }
      addonRight={
        <SVGIcon name="angle-down" className={classNames('addon-right')} />
      }
      onClick={() => setOpen(true)}
      hasFocus={open}
      error={error}
      trigger={
        isMobile ? (
          <BottomSheet
            onClose={onClose}
            title={title}
            isOpen={open}
            content={content}
          />
        ) : (
          <Dropdown
            isOpen={open}
            onClose={onClose}
            content={content}
            useTriggerWidth
          />
        )
      }
    />
  );
};

MultiSelect.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItem: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  title: PropTypes.string,
};

export default memo(MultiSelect);
