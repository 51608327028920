import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { RideInfoIcon } from './RideInfoIcon';
import './ride-info-panel.less';

const RideInfoPanel = ({ id, height, tags, theme }) => {
  const className = useClassName('RideInfoPanel');
  const parsedTags = tags.join(', ');

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: theme.tertiaryColor }}>
      <div className={className('wrapper')}>
        {height && (
          <div className={className('info')}>
            <RideInfoIcon icon={'height'} color={theme.secondaryColor} />
            {height}
          </div>
        )}
        {parsedTags && (
          <div className={className('info')}>
            <RideInfoIcon icon={'tag'} color={theme.secondaryColor} />
            {parsedTags}
          </div>
        )}
      </div>
    </div>
  );
};

RideInfoPanel.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object.isRequired,
};

RideInfoPanel.defaultProps = {
  height: null,
  tags: [],
};

export default RideInfoPanel;
