import React, { useEffect, useRef } from 'react';
import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import './translator.less';

const LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  iw: 'Hebrew',
  pt: 'Portuguese',
  'zh-CN': 'Chinese',
  'zh-TW': 'Chinese',
  ar: 'Arabic',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  ja: 'Japanese',
};

const TRANSLATOR_SCRIPT_ID = 'ad-translator-script';

const Translator = () => {
  const className = useClassName('Translator');
  const translateContainer = useRef();

  const getLoadedScript = () =>
    document.querySelector(`#${TRANSLATOR_SCRIPT_ID}`);

  const attachTranslator = (loadedScript) => {
    if (loadedScript && translateContainer.current.childElementCount) return;
    if (loadedScript) loadedScript.remove();
    const translatorScript = document.createElement('script');
    translatorScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    translatorScript.setAttribute('id', TRANSLATOR_SCRIPT_ID);
    document.body.appendChild(translatorScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  };

  useEffect(() => {
    const loadedScript = getLoadedScript();
    if (!loadedScript) {
      window?.cookieStore?.addEventListener('change', ({ changed }) => {
        const translateCookie = changed.find(
          (cookie) => cookie.name === 'googtrans'
        );
        if (translateCookie) {
          const selectedLanguage = translateCookie.value.split('/')[2];
          track(`${LANGUAGES[selectedLanguage]} language selected`);
        }
      });
    }
    attachTranslator(loadedScript);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: Object.keys(LANGUAGES).join(),
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google-translate'
    );
  };

  return (
    <div
      className={className('container')}
      id="google-translate"
      ref={translateContainer}
    />
  );
};

export default Translator;
