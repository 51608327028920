import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Asset from './Asset';
import SliderMiniatures from './SliderMiniatures';
import chevronRight from 'common/assets/chevron-right.svg';
import chevronLeft from 'common/assets/chevron-left.svg';

import './slider.less';

const CHANGE_INTERVAL = 5000;

const Slider = ({ images, autoPlay, showControls, showMiniatures }) => {
  const className = useClassName('ModularSlider');
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState(0);
  const [slidesContainerExtraClasses, setSlidesContainerExtraClasses] =
    useState([]);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlay);
  const [autoPlayInterval, setAutoPlayInterval] = useState(null);
  const scrollRef = useRef(null);

  const defineSlidesContainerExtraClasses = () => {
    if (showMiniatures)
      setSlidesContainerExtraClasses(['slides-container-reduced']);
  };

  useEffect(() => {
    defineSlidesContainerExtraClasses();
  }, []);

  useEffect(() => {
    if (isAutoPlay) {
      if (autoPlayInterval) clearInterval(autoPlayInterval);
      const interval = setInterval(handleNext, CHANGE_INTERVAL);
      setAutoPlayInterval(interval);
    }
  }, [isAutoPlay, currentAssetIndex]);

  const scrollTo = (direction) => {
    const newIndex =
      currentAssetIndex === images.length - 1 && direction === 1
        ? 0
        : currentAssetIndex === 0 && direction === -1
        ? images.length - 1
        : currentAssetIndex + direction;

    setCurrentAssetIndex(newIndex);
    scrollRef.current.scroll(newIndex * scrollRef.current.clientWidth, 0);
  };

  const handleNext = () => scrollTo(1);

  const handlePrevious = () => scrollTo(-1);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setTouchStartPosition(touch.clientX);
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const endX = touch.clientX;

    if (Math.abs(endX - touchStartPosition) < 30) return;

    if (endX > touchStartPosition) return handlePrevious();

    return handleNext();
  };

  const handleHover = () => {
    if (autoPlay) {
      clearInterval(autoPlayInterval);
      setIsAutoPlay(false);
    }
  };

  const handleLeave = () => {
    if (autoPlay) setIsAutoPlay(true);
  };

  const handleMiniatureClick = (index) => {
    setCurrentAssetIndex(index);
    scrollRef.current.scroll(index * scrollRef.current.clientWidth, 0);
  };

  const renderPrevControl = () => (
    <div key="prev" className={className('prev')} onClick={handlePrevious}>
      <img src={chevronLeft} alt="next" className={className('control-icon')} />
    </div>
  );

  const renderNextControl = () => (
    <div key="next" className={className('next')} onClick={handleNext}>
      <img
        src={chevronRight}
        alt="next"
        className={className('control-icon')}
      />
    </div>
  );

  const renderSlides = () => (
    <div
      key="slides"
      className={className('slides')}
      ref={scrollRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}>
      {images.map((image, index) => (
        <div key={`slide-${index}`} className={className('slide')}>
          <Asset key={`asset-${index}`} asset={image} />
        </div>
      ))}
    </div>
  );

  const renderIndicators = () => (
    <div key="indicators" className={className('slide-markers')}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${className('slide-marker')} ${
            currentAssetIndex === index ? 'active' : ''
          }`}
        />
      ))}
    </div>
  );

  const renderMiniatures = () => (
    <SliderMiniatures
      images={images}
      currentAssetIndex={currentAssetIndex}
      handleMiniatureClick={handleMiniatureClick}
    />
  );

  return (
    <div className={className('container')}>
      <div
        className={className([
          'slides-container',
          ...slidesContainerExtraClasses,
        ])}>
        {showControls && renderPrevControl()}
        {renderSlides()}
        {showControls && renderNextControl()}
        {renderIndicators()}
      </div>
      {showMiniatures && renderMiniatures()}
    </div>
  );
};

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoPlay: PropTypes.bool,
  showControls: PropTypes.bool,
  showMiniatures: PropTypes.bool,
};

Slider.defaultProps = {
  autoPlay: false,
  showControls: true,
  showMiniatures: false,
};

export default Slider;
