import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import { LandingInfoCard } from '../LandingInfoCard';

import './landing-facts-section.less';

const cardsList = ({ cards, fontColor, buttonColor, buttonTextColor }) => {
  const trackingLabel = window.location.pathname //We save this here to make sure the window location is not overwritten before sending the track events
    .replace(/\/$/, ''); // Remove trailing slash

  const handleClick = (cardName, ctaText) => {
    track(`${trackingLabel}: Clicked on "${ctaText}" in "${cardName}" card`);
  };

  if (!cards?.length) return null;
  return cards.map((card, i) => {
    return (
      <LandingInfoCard
        key={i}
        card={card}
        type={'fact'}
        onClick={() => handleClick(card.name, card.cta?.ctaText)}
        fontColor={fontColor}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
      />
    );
  });
};

const LandingFactsSection = ({
  id,
  cards,
  fontColor,
  buttonColor,
  buttonTextColor,
}) => {
  if (!cards?.length) return null;

  const className = useClassName('LandingFactsSection');

  return (
    <div id={id} className={className('container')}>
      {cardsList({ cards, fontColor, buttonColor, buttonTextColor })}
    </div>
  );
};

LandingFactsSection.propTypes = {
  id: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.object,
    })
  ).isRequired,
};

export default LandingFactsSection;
