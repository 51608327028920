import React from 'react';

import { useClassName } from 'common/hooks';

import './checkbox.less';

export default function Checkbox({ checked, onChange }) {
  const classNames = useClassName('CustomCheckbox');

  return (
    <input
      type="checkbox"
      className={classNames('input')}
      checked={checked}
      onChange={onChange}
    />
  );
}
