import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ResponsiveImageLight, ContentfulRichText } from 'common/components';
import {
  standardizedTrack,
  categories as trackingCategories,
} from 'utils/analytics';
import { Carousel } from '../../Carousel';
import { ReviewCard } from '../ReviewCard';
import { AssetPropType } from 'public/schema';

import './reviews-section.less';

const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events
const trackingCategory = trackingCategories.VENUE_INFO;

const track = (name) => {
  standardizedTrack(
    'click',
    'button',
    'reviews section',
    name,
    trackingCategory,
    trackingLabel
  );
};

const cardsList = (cards) => {
  if (!cards?.length) return null;
  return cards.map((card, i) => {
    return <ReviewCard key={i} card={card} />;
  });
};

const ReviewsSection = ({ id, title, titleColor, image, cards }) => {
  const className = useClassName('ReviewsSection');

  return (
    <div id={id} className={className('container')}>
      <div className={className('wrapper')}>
        {image?.assetUrl && (
          <div className={className('image-container')}>
            <ResponsiveImageLight
              src={image?.assetUrl}
              alt={image?.assetAlt}
              fluid
            />
          </div>
        )}
        {title && (
          <div className={className('title-container')}>
            <ContentfulRichText
              className={className('title-text')}
              style={{ color: titleColor }}
              field={title}
            />
          </div>
        )}
        <div className={className('carousel-container')}>
          <Carousel
            onPrevious={() => track('previous')}
            onNext={() => track('next')}>
            {cardsList(cards)}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

ReviewsSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object,
  image: PropTypes.shape(AssetPropType),
  titleColor: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.object,
    })
  ).isRequired,
};

export default ReviewsSection;
