import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'common/lazy';
import { LazyCSSTransition } from 'common/lazy';
import { getContentfulField, Badge } from 'common/components';
import { Component } from 'common/helpers';
import { track } from 'utils/analytics';
import SubnavGroup from './SubnavGroup';
import SubnavPanel from './SubnavPanel';
import { Announcement } from '../../Announcement';

import './subnav.less';

export default class Subnav extends Component {
  static Group = SubnavGroup;
  static Panel = SubnavPanel;

  onSubLinkPressed = (name) => {
    track('Menu sublink pressed', { label: name });
  };

  render() {
    const { active, title, items, announcement } = this.props;

    if (!items) {
      return null;
    }

    const contentClass = this.getElementClass('content');

    return (
      <div {...this.getAttrs()}>
        <LazyCSSTransition in={active} timeout={150} classNames={contentClass}>
          <div className={contentClass}>
            <Container>
              <Subnav.Group active={active}>
                <Subnav.Panel>
                  <div className={this.getElementClass('header')}>{title}</div>
                  {items.map((item) => {
                    if (!item?.fields) return null;

                    const { text, link, isNew, external } = item.fields;

                    if (!link) return null;

                    const itemText = text && getContentfulField(text);
                    const itemLink = link && getContentfulField(link);
                    const itemIsNew = isNew && getContentfulField(isNew);
                    const itemExternal =
                      external && getContentfulField(external);

                    if (!itemLink) return null;

                    if (itemExternal) {
                      return (
                        <div
                          className={this.getElementClass('sublink')}
                          key={itemText}>
                          <a
                            href={itemLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={this.getElementClass('small')}
                            onClick={() => this.onSubLinkPressed(itemText)}>
                            {itemText}
                          </a>
                          {itemIsNew && <Badge text="NEW" />}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={this.getElementClass('sublink')}
                          key={itemText}>
                          <Link
                            className={this.getElementClass('small')}
                            onClick={() => this.onSubLinkPressed(itemText)}
                            to={itemLink}>
                            {itemText}
                          </Link>
                          {itemIsNew && <Badge text="NEW" />}
                        </div>
                      );
                    }
                  })}
                </Subnav.Panel>
                <Subnav.Panel className={this.getElementClass('announcement')}>
                  <Announcement instance={announcement} />
                </Subnav.Panel>
              </Subnav.Group>
            </Container>
          </div>
        </LazyCSSTransition>
      </div>
    );
  }
}
