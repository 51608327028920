import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import UpDownButton from './UpDownButton';
import './numeric-up-down.less';

const NumericUpDown = ({ value, onChange, min, max, hasError }) => {
  const className = useClassName('NumericUpDown');

  const handleDecrement = () => onChange(value - 1);
  const handleIncrement = () => onChange(value + 1);

  return (
    <div className={className(['container'])}>
      <UpDownButton
        value={value}
        onClick={handleDecrement}
        limit={min}
        incremental={false}
      />
      <div className={className('value-container')}>
        <span>{value}</span>
      </div>
      <UpDownButton
        value={value}
        onClick={handleIncrement}
        limit={max}
        disabled={hasError}
        incremental
      />
    </div>
  );
};

NumericUpDown.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

NumericUpDown.defaultProps = {
  value: 0,
  min: 0,
  max: 9999,
  hasError: false,
};

export default NumericUpDown;
