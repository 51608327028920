import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class AnnouncementsStore extends BaseStore {
  @observable announcements = new Map();

  get(id) {
    return this.announcements.get(id);
  }

  @action
  search({ type, limit, skip } = {}, statusKey = 'search') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/announcements/search',
      body: {
        type,
        limit,
        skip,
      },
    })
      .then(({ data }) => {
        const sorted = data.sort((a, b) => {
          const dateA = new Date(a.updatedAt).getTime();
          const dateB = new Date(b.updatedAt).getTime();
          return dateA - dateB;
        });

        sorted.forEach((item) => {
          this.announcements.set(item.type, item);
        });
        status.success(statusKey);
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchByType({ type }, statusKey = 'fetchByType') {
    const status = this.createStatus(statusKey);

    if (this.announcements.has(type)) {
      return Promise.resolve(this.announcements.get(type));
    }

    return request({
      method: 'POST',
      path: '/1/announcements/search',
      body: {
        type: [type],
      },
    })
      .then(({ data }) => {
        status.success(statusKey);
        this.announcements.set(type, data);
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }
}
