import { action, observable } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ShopifyStore extends BaseStore {
  @observable limit = 50;
  @observable pageInfo = null;
  @observable addressStore = false;

  @action
  setAddressStore(addressStore) {
    this.addressStore = addressStore;
  }

  @action
  setPageInfo(pageInfo) {
    this.pageInfo = pageInfo;
  }

  @action
  validateBulkInventoryUpdate(
    fileId,
    vendorName,
    statusKey = `validateBulkInventoryUpdate`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/validateBulkInventoryUpdate`,
      body: {
        fileId,
        vendorName,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error validating file',
            list: [err.message],
          },
        };
      });
  }

  @action
  validateInventoryUpdate(
    fileId,
    vendorName,
    statusKey = `validateInventoryUpdate`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/validateInventoryUpdate`,
      body: {
        fileId,
        vendorName,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error validating file',
            list: [err.message],
          },
        };
      });
  }

  @action
  updateInventory(fileId, vendorName, statusKey = `updateInventory`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/updateInventory`,
      body: {
        fileId,
        vendorName,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error processing file',
            list: [err.message],
          },
        };
      });
  }

  @action
  upsertInventoryFile(fileId, vendorName, statusKey = `upsertInventoryFile`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/upsertInventoryFile`,
      body: {
        fileId,
        vendorName,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error processing file',
            list: [err.message],
          },
        };
      });
  }

  @action
  upsertProducts(
    products,
    variantsToDelete,
    vendorName,
    statusKey = `upsertProducts`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/upsertProducts`,
      body: {
        products: products.map((product) => {
          delete product.inventory;
          delete product.total_inventory;
          delete product.total_variants;
          delete product.has_options;
          delete product.index;

          return {
            ...product,
            images: product.variants
              .map((v) => (v.image ? { src: v.image } : null))
              .filter((i) => i),
            options: product?.options?.map((option) => {
              delete option.id;
              return option;
            }),
            variants: product.variants.map((variant) => {
              variant.options.forEach((option, index) => {
                variant[`option${index + 1}`] = option;
              });
              delete variant.options;
              return variant;
            }),
          };
        }),
        variantsToDelete,
        vendorName,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error updating product(s)',
            list: [err.message],
          },
        };
      });
  }

  @action
  deleteProduct(productId, statusKey = 'deleteProduct') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/deleteProduct/${productId}`,
      body: {
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error deleting product',
            list: [err.message],
          },
        };
      });
  }

  @action
  deleteVariant(variantId, productId, statusKey = 'deleteVariant') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/deleteVariant/${variantId}`,
      body: {
        productId,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error deleting variant',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchProductsByVendor(
    { vendor, pageInfo = this.pageInfo, limit = this.limit } = {},
    statusKey = `fetchProductsByVendor`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchProductsByVendor/${encodeURIComponent(vendor)}`,
      body: {
        pageInfo,
        limit,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving products',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchProductsByVendorQL(
    { vendor, searchTerm, after, before, limit = this.limit } = {},
    statusKey = `fetchProductsByVendorQL`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchProductsByVendorQL/${encodeURIComponent(vendor)}`,
      body: {
        after,
        before,
        searchTerm,
        limit,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving products',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchProductById(productId, statusKey = `fetchProductById`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchProductByIdQL/${productId}`,
      body: {
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving product',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchProductByHandle(handle, statusKey = `fetchProductByHandle`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchProductByHandle/${handle}`,
      body: {
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving product',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchInventoryItemsByIds(ids, statusKey = 'fetchInventoryItemsByIds') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchInventoryItemsByIds?ids=${ids}`,
      body: {
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving products',
            list: [err.message],
          },
        };
      });
  }

  @action
  exportProductsByVendor(
    vendor,
    ids,
    email,
    statusKey = 'exportProductsByVendor'
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/exportProductsByVendor`,
      body: {
        vendor,
        email,
        ids,
        addressStore: this.addressStore,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error exporting products',
            list: [err.message],
          },
        };
      });
  }

  @action
  fetchSales({ startDate, endDate, vendor = null }, statusKey = 'fetchSales') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/shopify/fetchSales`,
      body: {
        startDate,
        endDate,
        vendor,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return {
          success: false,
          errors: {
            title: 'Error retrieving sales',
            list: [err.message],
          },
        };
      });
  }
}
