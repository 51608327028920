import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useClassName, useWindowSize } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import './directory-bar.less';

const MENU_ITEMS = [
  {
    label: 'Stores',
    link: '/directory/shopping',
    key: 'store',
  },
  {
    label: 'Food & Drinks',
    link: '/directory/dining',
    key: 'food',
  },
  {
    label: 'Entertainment',
    link: '/directory/entertainment',
    key: 'attraction',
  },
  {
    label: 'Deals',
    link: '/deals/category',
    key: 'deal',
  },
];

const DirectoryBar = ({ handleSearchChange, searchPlaceHolder }) => {
  const className = useClassName('DirectoryBar');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const inputRef = useRef();

  const isSelected = (item) => {
    const path = window.location.pathname;
    return path.includes(item.link) ? 'selected' : 'default';
  };

  const handleCloseSearch = () => {
    setIsSearchOpen(false);
    handleSearchChange('');
  };

  useEffect(() => {
    if (isSearchOpen) inputRef?.current?.focus();
  }, [isSearchOpen]);

  return (
    <div className={className('container')}>
      {(isSearchOpen || isMobile) && (
        <div className={className('search-container')}>
          <div className={className('search')}>
            <Icon name="search" size="small" />
            <input
              type="text"
              className={className('search-input')}
              defaultValue={''}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder={searchPlaceHolder}
              ref={inputRef}
            />
          </div>
          {!isMobile && (
            <div
              className={className('close-search')}
              onClick={handleCloseSearch}>
              Close
            </div>
          )}
        </div>
      )}
      {(!isSearchOpen || isMobile) && (
        <div className={className('menu')}>
          <div className={className('menu-items')}>
            {MENU_ITEMS.map((item) => (
              <Link
                className={className([
                  'menu-item',
                  `menu-item-${isSelected(item)}`,
                ])}
                to={item.link}
                key={item.key}>
                <Icon name={item.key} size="small" />
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
          {!isMobile && (
            <div
              className={className('search-icon')}
              onClick={() => setIsSearchOpen(true)}>
              <Icon name="search" size="tiny" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DirectoryBar.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  searchPlaceHolder: PropTypes.string,
};

DirectoryBar.defaultProps = {
  searchPlaceHolder:
    'Search Stores, Dinning, Entertainment or Parks & Attractions',
};

export default DirectoryBar;
