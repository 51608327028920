import React from 'react';
import { useHistory } from 'react-router';

import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';

export default function BookTicketsAnnouncement({ announcement, setActive }) {
  const className = useClassName('BookTickets');
  const history = useHistory();

  const handleAnnouncementClick = (link) => {
    track('Book Tickets Announcement Pressed', { label: link });
    setActive(false);
    if (link.includes('http')) window.location.href = link;
    else history.push(link);
  };

  const getBackground = (announcement) => {
    if (!announcement?.backgroundImage)
      return { backgroundColor: announcement.backgroundColor };
    return {
      backgroundImage: `url(${announcement?.backgroundImage?.assetUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  };
  return (
    <div
      className={className('book-announcement')}
      key={announcement.name}
      style={getBackground(announcement)}
      onClick={() => handleAnnouncementClick(announcement.link)}>
      {announcement.image?.assetUrl && (
        <img
          src={announcement.image.assetUrl}
          alt={announcement.image.assetAlt}
        />
      )}
    </div>
  );
}
