import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

const useCountdown = ({ isoDate }) => {
  const date = isoDate
    ? DateTime.fromISO(isoDate.replace(' ', 'T'), { zone: 'America/New_York' })
    : null;
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    if (isoDate) {
      const interval = setInterval(() => {
        const diff = date
          .diff(DateTime.local(), ['days', 'hours', 'minutes'])
          .toObject();
        setDiff({
          days: diff.days,
          hours: diff.hours,
          minutes: Math.ceil(diff.minutes),
        });

        if ([diff.days, diff.hours, diff.minutes].every((a) => a <= 0)) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  return [diff];
};

export default useCountdown;
