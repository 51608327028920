import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'common/lazy';
import { useClassName } from 'common/hooks';

import './export-json.less';

const ExportJson = ({ data, label, fileName, includeDate }) => {
  const className = useClassName('ExportJson');

  const parseHeader = (value) =>
    value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });

  const handleExport = () => {
    const header = Object.keys(data[0]);
    const csv = [
      header.map((value) => parseHeader(value)).join(','),
      ...data.map((row) =>
        header
          .map(
            (fieldName) =>
              // For allowing values containing commas
              // eslint-disable-next-line
              `\"${row[fieldName] !== null ? row[fieldName].toString().replace(/"/g, '""') : ''}\"`)
          .join(',')
      ),
    ].join('\r\n');
    const date = !includeDate
      ? ''
      : `-${new Date().toISOString().split('T')[0]}`;
    const url = window.URL.createObjectURL(
      new Blob([csv], { type: 'text/csv' })
    );
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', `${fileName}${date}.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Button
      className={className('button')}
      onClick={handleExport}
      type="button"
      positive>
      {label}
      <Icon name="file excel" size="large" className={className('icon')} />
    </Button>
  );
};

ExportJson.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  fileName: PropTypes.string,
  includeDate: PropTypes.bool,
};

ExportJson.defaultProps = {
  data: [],
  label: 'Export',
  fileName: `export`,
  includeDate: true,
};

export default ExportJson;
