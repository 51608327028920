import React, { useRef } from 'react';
import { chunk } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  LazyVisible,
  useClassName,
  useResize,
  useWindowSize,
  usePreload,
  preloadAssets,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import useLoop from '../../Components/useLoop';
import SliderBullets from '../../Components/SliderBullets';
import useSlider from '../../Components/useSlider';

import 'swiper/swiper.less';
import 'swiper/modules/autoplay/autoplay.less';
import './parks-gallery.less';

function Content({ fields, cta }) {
  const classNames = useClassName('ParksGallery');

  return (
    <>
      <HomepageButton
        cta={{
          ...cta,
          fields: {
            ...cta.fields,
            isHidden: true,
          },
        }}
        className={classNames(['park-card', fields.animation])}
        style={{
          background: `linear-gradient(180deg, ${fields.primaryColor} 0%, ${fields.secondaryColor}  100%)`,
        }}>
        <HomepageImage field={fields.icon} />
        {(fields?.figures || []).map((figure) => (
          <HomepageImage field={figure} key={figure.sys.id} />
        ))}
      </HomepageButton>
      <HomepageButton cta={cta} className={classNames('cta')} />
    </>
  );
}

function Mobile({ section }) {
  const classNames = useClassName('ParksGallery');
  const ref = useRef();
  const parks = section.fields.parks.filter(({ fields }) => !fields.isLarge);
  const loop = useLoop({
    breakpoints: { 0: { slidesPerView: 1 } },
    slidesLength: parks.length,
  });
  const [props, methods, state] = useSlider();

  return (
    <LazyVisible ref={ref} className={classNames('container')}>
      <Swiper
        height={542}
        {...loop}
        {...props}
        onSlideChange={({ realIndex }) => {
          trackHomepageSlide(() => ({
            activeIndex: realIndex,
            name: parks[realIndex].fields.name,
            section: section.fields.sectionId,
            url: parks[realIndex].fields.ctas[0].fields.url,
          }));
        }}
        className={classNames('wrapper')}>
        {parks.map(({ fields, sys }) => (
          <SwiperSlide key={sys.id} className={classNames('slide-container')}>
            <div className={classNames('park-container')}>
              {fields.ctas.map((cta) => {
                return <Content key={cta.sys.id} cta={cta} fields={fields} />;
              })}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {loop.loop && (
        <SliderBullets
          activeIndex={state.activeIndex}
          length={parks.length}
          slideTo={methods.slideTo}
          bulletColor={parks[state.activeIndex].fields.primaryColor}
        />
      )}
    </LazyVisible>
  );
}

function DesktopRow({ items, index }) {
  const classNames = useClassName('ParksGallery');
  const ref = useRef(null);

  return (
    <LazyVisible key={index} ref={ref}>
      {items.map(({ fields, sys }) => (
        <div key={sys.id} className={classNames('park-container')}>
          {fields.ctas.map((cta) => {
            return <Content key={cta.sys.id} cta={cta} fields={fields} />;
          })}
        </div>
      ))}
    </LazyVisible>
  );
}

function Desktop({ section }) {
  const classNames = useClassName('ParksGallery');
  const { width } = useResize(
    document.querySelector(`.${classNames('small-parks')}`)
  );

  return chunk(
    section.fields.parks.filter(({ fields }) => !fields.isLarge),
    width > 600 ? 3 : 2
  ).map((items, index) => (
    <DesktopRow items={items} index={index} key={index} />
  ));
}

export default function ParksGallery({ section }) {
  const classNames = useClassName('ParksGallery');
  const { isMobile } = useWindowSize();
  const ref = useRef();

  usePreload(() => {
    preloadAssets([
      ...section.fields.parks.map(({ fields }) => fields.icon),
      ...section.fields.parks.map(({ fields }) => fields.figures).flat(),
    ]);
  }, ref);

  return (
    <>
      <div className={classNames('small-parks')}>
        {isMobile ? (
          <Mobile section={section} />
        ) : (
          <Desktop section={section} />
        )}
      </div>
      <LazyVisible ref={ref} className={classNames('explore')}>
        <HomepageButton cta={section.fields.exploreCta} />
      </LazyVisible>
    </>
  );
}
