import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

// A deviation tolerance value to remove the swipe sensibility.
const MIN_DEVIATION_VALUE = 25;

export default class PaginationSwipe extends Component {
  state = {
    touchStartPosition: undefined,
  };

  onTouchStart = (event) => {
    const xPositionStart = event.touches[0].pageX;

    this.setState({ touchStartPosition: xPositionStart });
  };

  onTouchEnd = (event) => {
    const { touchStartPosition } = this.state;
    const { onSwipeLeft, onSwipeRight } = this.props;
    const xPositionEnd = event.changedTouches[0].pageX;

    // if end position is above the start position, user is swiping to the left.
    if (xPositionEnd - MIN_DEVIATION_VALUE > touchStartPosition) {
      onSwipeLeft();
    }

    // if end position is below the start position, user is swiping to the right.
    if (xPositionEnd + MIN_DEVIATION_VALUE < touchStartPosition) {
      onSwipeRight();
    }

    this.setState({ touchStartPosition: undefined });
  };

  render() {
    const { children } = this.props;

    return (
      <div onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd}>
        {children}
      </div>
    );
  }
}

PaginationSwipe.propTypes = {
  children: PropTypes.node.isRequired,
  onSwipeLeft: PropTypes.func.isRequired,
  onSwipeRight: PropTypes.func.isRequired,
};
