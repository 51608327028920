import React from 'react';

import {
  getUrls,
  getLocaleName,
  getCurrentLocaleCode,
} from 'utils/l10n/client';

export default class LocaleSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.urls = getUrls();
    this.currentLocaleCode = getCurrentLocaleCode(false);
  }

  render() {
    return <span>{this.renderLinks()}</span>;
  }

  renderLinks() {
    return this.urls.map((u) => {
      const name = getLocaleName(u.code);
      if (u.code === this.currentLocaleCode) {
        return name;
      } else {
        return (
          <a key={u.code} href={u.url}>
            {name}
          </a>
        );
      }
    });
  }
}
