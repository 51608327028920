import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class MenuStore extends BaseStore {
  @observable menu;

  promise = null;

  @action
  fetch(statusKey) {
    if (this.promise && statusKey === 'boot') {
      return this.promise;
    } else {
      const status = this.createStatus(statusKey);
      const promise = request({
        path: '/1/menu',
        method: 'GET',
      })
        .then((resp) => {
          this.menu = resp.data;
          status.success();
          return this.menu;
        })
        .catch((err) => {
          status.error(err);
          throw err;
        });
      if (statusKey === 'boot') {
        this.promise = promise;
      }
      return promise;
    }
  }
}
