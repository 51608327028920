import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/l10n';
import { NumericUpDown, DesktopOnly, MobileOnly } from 'common/components';
import { useClassName } from 'common/hooks';
import { ProductPropType } from 'public/schema';
import { isSoldOut } from 'common/helpers';
import { parseApiDate } from 'utils/api';
import { CustomMessage } from '../CustomMessage';
import { AddOnDetailModal } from '../AddOnCard';
const MAX_DESCRIPTION_LENGTH = 300;

import './add-on-card-new.less';

const AddOnCardNew = ({
  product,
  setQuantity,
  startTime,
  currentQuantity,
  minimumQuantity,
  newAddon,
}) => {
  const [quantity, setTicketQuantity] = useState(currentQuantity);
  const [showAddOnDetailModal, setShowAddOnDetailModal] = useState(false);

  const className = useClassName('AddOnCardNew');

  const handleChange = (quantity) => {
    setQuantity(product, quantity);
    setTicketQuantity(quantity);
  };

  const handleShowDetailModal = () => {
    setShowAddOnDetailModal(!showAddOnDetailModal);
  };

  const renderSoldOutLabel = (item) => {
    return (
      <CustomMessage type="error">
        {parseApiDate(item.date) < new Date()
          ? 'No Longer Available'
          : 'Sold Out'}
      </CustomMessage>
    );
  };

  const renderQuantity = () => {
    return (
      <div className="quantity">
        {isSoldOut(product, startTime) ? (
          renderSoldOutLabel(product)
        ) : (
          <NumericUpDown
            value={quantity}
            min={minimumQuantity}
            max={product.quantity}
            onChange={handleChange}
          />
        )}
      </div>
    );
  };

  const renderDescription = () => {
    const description = product.description
      .replace(/<br \/>/g, ' ')
      .replace(/<br\/>/g, ' ');

    return (
      <p className={className('description')}>
        {description.length <= MAX_DESCRIPTION_LENGTH
          ? description
          : description.slice(0, MAX_DESCRIPTION_LENGTH) + '...'}
      </p>
    );
  };

  const renderAddOnDetailModal = () => {
    return (
      <AddOnDetailModal
        product={product}
        open={showAddOnDetailModal}
        handleClose={handleShowDetailModal}
      />
    );
  };

  return (
    <div className={`${className('card')} ${newAddon ? 'new' : ''}`}>
      <div className={className(newAddon ? 'column' : 'row')}>
        <div className="icon">
          <img src={product.image} alt={product.name} />
        </div>
        {!newAddon && renderQuantity()}
      </div>
      <div className={className(newAddon ? 'column' : 'row')}>
        <div className={className(newAddon ? 'row' : 'column')}>
          <div className={className('name-price')}>
            <p className={className('name')}>{product.name}</p>
            <p className={className('price')}>
              {formatCurrency(product.price.toFixed(2))}
            </p>
          </div>
          {newAddon && <DesktopOnly>{renderQuantity()}</DesktopOnly>}
        </div>
        {newAddon && renderDescription()}
        <div className={className(newAddon ? 'row' : 'column')}>
          <span className={className('link')} onClick={handleShowDetailModal}>
            View detail
          </span>
          {newAddon && <MobileOnly>{renderQuantity()}</MobileOnly>}
        </div>
      </div>
      {renderAddOnDetailModal()}
    </div>
  );
};

AddOnCardNew.propTypes = {
  product: PropTypes.shape(ProductPropType).isRequired,
  currentQuantity: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  newAddon: PropTypes.bool,
};

AddOnCardNew.defaultProps = {
  currentQuantity: 0,
  minimumQuantity: 0,
  newAddon: false,
};

export default AddOnCardNew;
