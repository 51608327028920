import { track } from 'utils/analytics';

export default function trackHomepageSection(getProps) {
  try {
    const props = getProps();
    track('HP Section Rendered', {
      ...props,
      // GA4 custom dimensions
      category: props.id,
    });
  } catch (ex) {
    console.error('Failed to track homepage section rendered');
  }
}
