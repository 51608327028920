import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { API_URL } from 'utils/env/client';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';
import { WarningMessage } from 'public/components/Message';

import appleWalletLogo from 'common/assets/apple-wallet.svg';
import './wallet-button.less';

function shouldRender() {
  return ['iPhone', 'iPod touch', 'iPad'].some((environment) =>
    navigator.userAgent.toLowerCase().includes(environment.toLowerCase())
  );
}

async function downloadPasses(orderId) {
  const resp = await fetch(`${API_URL}/1/passkit/download/${orderId}`);
  if (!resp.ok) throw new Error('Failed to generate passes');

  const blob = await resp.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = '';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

function AppleWalletButton({ orderId }) {
  const classNames = useClassName('WalletButton');
  const [error, setError] = useState(false);

  const onClick = async (ev) => {
    ev.preventDefault && ev.preventDefault();
    ev.stopPropagation && ev.stopPropagation();

    try {
      await downloadPasses(orderId);
      track('Add to Wallet - Apple - Success');
    } catch (ex) {
      setError(ex);
      track('Add to Wallet - Apple - Error');
    }
  };

  if (!shouldRender()) return null;

  return (
    <div className={classNames('container')}>
      <a
        href={`${API_URL}/1/passkit/download/${orderId}`}
        download
        onClick={onClick}>
        <img
          src={appleWalletLogo}
          alt="logo"
          style={{ width: 160, marginTop: 24 }}
        />
      </a>
      {error && (
        <WarningMessage
          content="Failed to generate passes, please use your confirmation email or
          profile page."
        />
      )}
    </div>
  );
}

AppleWalletButton.propTypes = {
  orderId: PropTypes.string,
};

export default AppleWalletButton;
