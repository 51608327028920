import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import { Modal, ContentfulRichText } from 'common/components';
import { HoursTable } from 'public/components/Hours';
import { track } from 'utils/analytics';

import './view-hours.less';

export default class ViewHours extends Component {
  renderContent = () => {
    const { openingHours, closedMessage } = this.props;
    const { customMessage, temporarilyClosed } = openingHours;

    if (temporarilyClosed && closedMessage) {
      return <ContentfulRichText field={closedMessage} />;
    }

    if (customMessage) {
      return (
        <p className={this.getElementClass('customMessage')}>{customMessage}</p>
      );
    }

    return <HoursTable openingHours={openingHours} />;
  };

  render() {
    const { venueSlug, children } = this.props;
    return (
      <Modal
        size="tiny"
        trigger={
          <div
            className={this.getElementClass('link', 'small')}
            onClick={() =>
              venueSlug && track(`${venueSlug} - Viewed ViewHours`)
            }>
            {children || 'VIEW HOURS'}
          </div>
        }>
        {this.renderContent()}
      </Modal>
    );
  }
}

ViewHours.propTypes = {
  openingHours: PropTypes.shape({
    customMessage: PropTypes.string,
    openingHours: PropTypes.object,
    temporarilyClosed: PropTypes.bool,
  }).isRequired,
  closedMessage: PropTypes.object,
  venueSlug: PropTypes.string,
  children: PropTypes.node,
};

ViewHours.dedaultProps = {
  closedMessage: null,
  venueSlug: null,
};
