import { action } from 'mobx';
import { uniqueId } from 'lodash';
import { request, urlForUpload } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class AddonsStore extends BaseStore {
  @action
  getNames() {
    return request({
      method: 'GET',
      path: '/1/addons/roller',
    });
  }

  @action
  getCategory(categoryId) {
    return request({
      method: 'GET',
      path: `/1/addons/category/${categoryId}`,
    })
      .then((data) => {
        return {
          ...data,
          addons: data.addons.map((addon) => ({
            ...addon,
            id: uniqueId(),
            images: addon.images.map((image) => ({
              ...image,
              url: urlForUpload(image),
              selected: image.default,
            })),
          })),
        };
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  saveCategory(category) {
    return request({
      method: 'POST',
      path: '/1/addons/category',
      body: category,
    });
  }

  @action
  getCategories() {
    return request({
      method: 'GET',
      path: '/1/addons/categories',
    });
  }

  @action
  reorder(categories) {
    return request({
      method: 'POST',
      path: `/1/addons/categories/reorder`,
      body: categories.map(({ id }, index) => ({ id, index })),
    });
  }

  @action
  deleteCategory(categoryId) {
    return request({
      method: 'DELETE',
      path: `/1/addons/category/${categoryId}`,
    });
  }
}
