import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import './text.less';
import {
  DesktopOnly,
  MobileOnly,
  ContentfulRichText,
} from '../../../common/components';

const Text = ({ text, textAlign }) => {
  const className = useClassName('ModularText');
  const [containerExtraClasses, setContainerExtraClasses] = useState([]);

  const defineContainerExtraClasses = () => {
    const extraClasses = [];
    if (text.titleType) extraClasses.push(text.titleType);
    if (text.bold) extraClasses.push('bold');
    if (text.italic) extraClasses.push('italic');
    if (text.underline) extraClasses.push('underline');
    if (text.whiteLink) extraClasses.push('white-link');
    if (textAlign) extraClasses.push(`text-align-${textAlign}`);
    setContainerExtraClasses(extraClasses);
  };

  useEffect(() => {
    defineContainerExtraClasses();
  }, []);

  return (
    <>
      <DesktopOnly
        className={className(['container', ...containerExtraClasses])}
        style={{
          color: text.color,
          fontSize: text.fontSize,
        }}>
        {text.richTextBody ? (
          <ContentfulRichText field={text.richTextBody} />
        ) : (
          text.body
        )}
      </DesktopOnly>
      <MobileOnly
        className={className(['container', ...containerExtraClasses])}
        style={{
          color: text.color,
          fontSize: text.mobileFontSize || text.fontSize,
        }}>
        {text.richTextBody ? (
          <ContentfulRichText field={text.richTextBody} />
        ) : (
          text.body
        )}
      </MobileOnly>
    </>
  );
};

Text.propTypes = {
  text: PropTypes.object.isRequired,
  textAlign: PropTypes.string,
};

export default Text;
