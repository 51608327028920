import { DateTime } from 'luxon';

export function isToday(date, now = new Date()) {
  return (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  );
}

export function isTomorrow(date, now = new Date()) {
  return (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() + 1 === date.getDate()
  );
}

export function formatStartAndEndDate(startDate, endDate, format = 'LLL dd') {
  const start = startDate.toFormat(format);
  const end = endDate.toFormat(format);

  if (start === end) return start;

  return `${start} - ${end}`;
}

export function formatHourAndMinute(hour, minute) {
  return DateTime.fromFormat(
    `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
    'HH:mm'
  ).toFormat('hh:mm a');
}
