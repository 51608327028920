import React from 'react';
import { useClassName } from 'common/hooks';
const PRICE_TYPES = [
  { key: 'peak', label: 'Peak Pricing' },
  { key: 'non-peak', label: 'Non-Peak Pricing' },
];
import './price-type-indicator.less';

const PriceTypeIndicator = () => {
  const className = useClassName('price-type-indicator');

  return (
    <div className={className('container')}>
      {PRICE_TYPES.map(({ key, label }) => (
        <div key={key} className={className('price-type')}>
          <div className={className(['price-indicator', key])} />
          <span className={className('type')}>{label}</span>
        </div>
      ))}
    </div>
  );
};

export default PriceTypeIndicator;
