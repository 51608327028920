import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

const Dropdown = ({ isOpen, onClose, content, useTriggerWidth }) => {
  const ref = useRef();
  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, onClose);

  const showContent = () => {
    if (!ref.current) return null;

    const triggerRect = ref.current.getBoundingClientRect();
    const top = triggerRect.bottom + window.scrollY + 10;
    const left = triggerRect.left;

    const element = (
      <div
        ref={dropdownRef}
        style={{
          position: 'absolute',
          zIndex: 9999999,
          top,
          left,
          ...(useTriggerWidth && { width: ref.current.clientWidth }),
        }}>
        {content}
      </div>
    );

    return createPortal(element, document.getElementById('root'));
  };

  return <div ref={ref}>{isOpen && showContent()}</div>;
};

export default Dropdown;
