import React from 'react';
import { Layout } from 'common/components';
import { Component } from 'common/helpers';

export default class SubNavGroup extends Component {
  render() {
    if (!this.props.active) {
      return null;
    }
    return (
      <div {...this.getAttrs()}>
        <Layout horizontal spread>
          {this.props.children}
        </Layout>
      </div>
    );
  }
}
