import React, { useState } from 'react';
import { useClassName } from 'common/hooks';
import UpDownButton from '../../../common/components/NumericUpDown/UpDownButton';

import './carousel.less';
import PropTypes from 'prop-types';

const DEFAULT_BUTTON_COLOR = '#FFFFFF';
const TYPES = ['single-card', 'multi-card'];

const Carousel = ({ children, onPrevious, onNext, type = 'single-card' }) => {
  const className = useClassName('Carousel');
  const [selectedChildren, setSelectedChildren] = useState(0);

  const handleDecrement = () => {
    setSelectedChildren(selectedChildren - 1);

    onPrevious && onPrevious();
  };

  const handleIncrement = () => {
    setSelectedChildren(selectedChildren + 1);

    onNext && onNext();
  };

  const getChildStyle = () => {
    const itemPercentage = selectedChildren * -100;
    const transform = `translateX(${itemPercentage}%)`;

    return { transform };
  };

  const renderChild = (child, index) => {
    return (
      <div
        key={index}
        className={className(`child ${type}`)}
        style={getChildStyle(index)}>
        {child}
      </div>
    );
  };

  return (
    <div className={className(`container ${type}`)}>
      {children.map((child, index) => renderChild(child, index))}
      {children?.length > 1 && (
        <div className={className(`navigator`)}>
          <UpDownButton
            value={selectedChildren}
            onClick={handleDecrement}
            limit={0}
            incremental={false}
            arrowDisplay
            style={{ backgroundColor: DEFAULT_BUTTON_COLOR }}
          />
          <UpDownButton
            value={selectedChildren}
            onClick={handleIncrement}
            limit={children.length - 1}
            incremental
            arrowDisplay
            style={{ backgroundColor: DEFAULT_BUTTON_COLOR }}
          />
        </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  type: PropTypes.oneOf(TYPES),
};

export default Carousel;
