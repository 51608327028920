import React from 'react';
import propTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';
import { getProfileLinks } from '../utils';

import './my-profile.less';

const MyProfile = ({ handleLinkClick, appSession }) => {
  const className = useClassName('MyProfile');
  const { profileLink, profileText } = getProfileLinks(appSession);

  return (
    <div
      className={className('container')}
      onClick={() =>
        handleLinkClick(profileLink, 'Mobile nav profile clicked')
      }>
      <Icon name="nav-profile" className={className('icon')} color="black" />
      <span className={className('text')}>{profileText}</span>
    </div>
  );
};

MyProfile.propTypes = {
  handleLinkClick: propTypes.func.isRequired,
};

export default inject('appSession')(observer(MyProfile));
