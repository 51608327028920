import { DateTime } from 'luxon';

export const parseDate = (strDate) => {
  return DateTime.fromISO(strDate).toFormat('cccc, LLL dd');
};

export const getSessionName = (sessionName) => {
  if (!sessionName) return '';

  if (!/^[a-zA-Z_]/.test(sessionName)) return `(${sessionName})`;

  const sessionTime = sessionName.split(':');

  if (sessionTime[1]) return `(${sessionTime[1].trim()})`;

  return sessionName;
};

export const isTicketExpired = (reservationDate) => {
  const today = DateTime.now().startOf('day');
  const ticketDate = DateTime.fromISO(reservationDate).startOf('day');
  return today > ticketDate;
};
