import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { ResponsiveImageLight, ContentLink } from 'common/components';
import chevronRight from 'common/assets/chevron-right.svg';
import { AssetPropType } from 'public/schema';

import './link-card.less';

const LinkCard = ({ link, onClick }) => {
  if (!link?.url) return null;

  const className = useClassName('LinkCard');
  const { name, url, logo } = link;

  return (
    <ContentLink
      className={className('container')}
      href={url}
      onClick={onClick}>
      {logo && (
        <ResponsiveImageLight
          className={className('logo')}
          src={logo.assetUrl}
          alt={logo.assetAlt}
          fluid
        />
      )}
      {name && (
        <span className={className('name')}>
          {name}
          <img src={chevronRight} width={24} height={24} />
        </span>
      )}
    </ContentLink>
  );
};

LinkCard.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.shape(AssetPropType),
  }).isRequired,
  onClick: PropTypes.func,
};

export default LinkCard;
